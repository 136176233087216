import ShowOnCondition from "../../../../auth/ShowOnCondition.component"
import ContractTRow from "./ContractTRow/ContractTRow.component"
import { HeaterContractListType, HeaterListDataType } from "../../../../../redux/list/list.types"

type ContractTBodyProps = {
  sortParam: string
  listData: HeaterListDataType
}

const ContractTBody = ({
  sortParam,
  listData,
}: ContractTBodyProps) => (
  <tbody>
    <ShowOnCondition showWhen={!listData?.count}>
      <tr>
        <td colSpan={100} className="text-center">
          <strong>Nessun elemento trovato</strong>
        </td>
      </tr>
    </ShowOnCondition>
    <ShowOnCondition showWhen={listData?.count > 0}>
      {listData?.items?.map((item: HeaterContractListType) => (
        <ContractTRow 
          key={item.id} 
          item={item}
          sortParam={sortParam}
        />
      ))}
    </ShowOnCondition>
  </tbody>
)

export default ContractTBody