import { errorFormLabels } from "../../utils/formLabels"

export function isRealEstateTownNameRequired(entityName: string) {
  return (
    entityName === 'newActivation' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function realEstatePropertyTypeRequired(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? errorFormLabels.REQUIRED : false
}

export function administrationTownRequired(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? errorFormLabels.REQUIRED : false
}

export function cadastralTownRequired(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? errorFormLabels.REQUIRED : false
}

export function cadastralTownCodeRequired(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? errorFormLabels.REQUIRED : false
}

export function realEstateUnitTypeRequired(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? errorFormLabels.REQUIRED : false
}

export function cadastralDataSectionRequired(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? errorFormLabels.REQUIRED : false
}

export function cadastralDataSubaltRequired(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? errorFormLabels.REQUIRED : false
}

export function rendAdditionalInfoRequired(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? false : true
}