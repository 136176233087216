import { Nav, NavItem, NavLink } from "reactstrap"
import classNames from 'classnames'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import HeaterTabsStyle from "./style"
import { ContractStatusMap, HeaterTabsMap } from "../../../../redux/contract/contract.utils"
import { isMissingData, isSignContractAlertVisible, isTabDisabled } from "./utils"
import { useAppSelector } from "../../../../redux/hooks"
import { selectContractProposalData, selectContractStatusId, selectMaxFormStepAvailable } from "../../../../redux/contract/contract.selectors"
import { useSelectContractId, useSelectEntityName, useSelectSelectedStep, useSetSelectedStep } from "../../../providers/ContractsPageProvider.provider"
import ShowOnCondition from "../../../../components/auth/ShowOnCondition.component"
import SignContractAlert from "../../../../components/SignContractAlert/SignContractAlert.component"

const HeaterTabs = () => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  const selectedStep = useSelectSelectedStep()!
  const setSelectedStep = useSetSelectedStep()!
  const selectedContractStatusId = useAppSelector(selectContractStatusId({ entityName, contractId }))
  const maxFormStepAvailable = useAppSelector(selectMaxFormStepAvailable({ contractId, entityName }))
  const { data: customerData } = useAppSelector(selectContractProposalData({ contractId, entityName }))

  return (
    <HeaterTabsStyle>
      <Nav tabs className="subscription-nav">
        <NavItem>
          <NavLink
            onClick={() => setSelectedStep(HeaterTabsMap.CUSTOMER_DATA_STEP)}
            className={classNames({ active: selectedStep === HeaterTabsMap.CUSTOMER_DATA_STEP })}
          >
            <FontAwesomeIcon icon="address-card" />
            <span>Dati cliente</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            disabled={isTabDisabled(HeaterTabsMap.BANK_ACCOUNT_STEP, maxFormStepAvailable)}
            onClick={() => setSelectedStep(HeaterTabsMap.BANK_ACCOUNT_STEP)}
            className={classNames({ active: selectedStep === HeaterTabsMap.BANK_ACCOUNT_STEP })}
          >
            <FontAwesomeIcon icon="money-bill-wave" />
            <span>Dati pagamento</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            disabled={isTabDisabled(HeaterTabsMap.TECHNICAL_DATA_STEP, maxFormStepAvailable)}
            onClick={() => setSelectedStep(HeaterTabsMap.TECHNICAL_DATA_STEP)}
            className={classNames({ active: selectedStep === HeaterTabsMap.TECHNICAL_DATA_STEP })}
          >
            <FontAwesomeIcon icon="ruler" />
            <span>Dati tecnici</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            disabled={isTabDisabled(HeaterTabsMap.PRODUCT_STEP, maxFormStepAvailable)}
            onClick={() => setSelectedStep(HeaterTabsMap.PRODUCT_STEP)}
            className={classNames({ active: selectedStep === HeaterTabsMap.PRODUCT_STEP })}
          >
            {/* Check if is missing product data (could happen if the contract was made from the funnel website) */}
            {isMissingData(HeaterTabsMap.PRODUCT_STEP, maxFormStepAvailable, customerData?.steps, "hasProduct") ? (
              <FontAwesomeIcon 
                icon="circle-exclamation" 
                className="missing-data" 
                title="Dati mancanti"
              />
            ) : (
              <FontAwesomeIcon icon="memo-circle-info" />
            )}
            <span>Dati prodotto</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            disabled={isTabDisabled(HeaterTabsMap.ATTACHMENTS_STEP, maxFormStepAvailable)}
            onClick={() => setSelectedStep(HeaterTabsMap.ATTACHMENTS_STEP)}
            className={classNames({ active: selectedStep === HeaterTabsMap.ATTACHMENTS_STEP })}
          >
            <FontAwesomeIcon icon="paperclip" />
            <span>Documenti</span>
          </NavLink>
        </NavItem>
        <ShowOnCondition showWhen={selectedContractStatusId >= ContractStatusMap.DONE_STATUS}>
          <NavItem>
            <NavLink
              onClick={() => setSelectedStep(HeaterTabsMap.DOWNLOAD_CONTRACT_PDF_STEP)}
              className={classNames({ active: selectedStep === HeaterTabsMap.DOWNLOAD_CONTRACT_PDF_STEP })}
            >
              <FontAwesomeIcon icon="file-contract" className="me-2" />
              Contratto firmato
            </NavLink>
          </NavItem>
        </ShowOnCondition>
      </Nav>
      <ShowOnCondition
        showWhen={isSignContractAlertVisible({ maxFormStepAvailable, selectedContractStatusId })}
      >
        <SignContractAlert />
      </ShowOnCondition>
    </HeaterTabsStyle>
  )
}

export default HeaterTabs