export async function asyncFnWrapper<T>(
  params: T, 
  fetchFn: Function
) {
  const options = await fetchFn(params)
  return options.data 
}

export function getCustomerType(
  requestedCustomerType: number, 
  contractCustomerType: Record<string, string>
) {
  return requestedCustomerType === parseInt(contractCustomerType?.value) ? true : false
}

export enum CustomerTypes {
  DOMESTIC_CUSTOMER = 1,
  BUSINESS_CUSTOMER = 2,
}

export function setDefaultDate(value?: string) {
  if(value) {
    return new Date(value)
  }
  return null
}