import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap"

import { Permissions } from "../../redux/api/types"
import { useLazyFetchContractReportsQuery, useSubmitContractReportMutation } from "../../redux/contract/contract.api"
import ShowOnCondition from "../auth/ShowOnCondition.component"
import { canI } from "../auth/utils"
import ButtonWithLoader from "../ButtonWithLoader/ButtonWithLoader.component"
import TextAreaWrapper from "../forms/inputFields/TextArea/TextAreaWrapper.component"
import WithSkeleton from "../WithSkeleton/WithSkeleton.component"
import ChatList from "./ChatList/ChatList.component"

const ChatListWithSkeleton = WithSkeleton(ChatList)

type ChatModalProps = {
  itemId: string
  isOpen: boolean
  toggle: () => void
  fetchChatUrl: string
  permissions: Permissions
  entityName: string
}

type ChatFormType = {
  contractId: string,
  contractType: string,
  description: string
}

const ChatModal = ({
  isOpen,
  toggle,
  fetchChatUrl,
  itemId,
  permissions,
  entityName
}: ChatModalProps) => {
  const [fetchChat, { data: reports, isFetching: isFetchingChat, isLoading: isLoadingChat }] = useLazyFetchContractReportsQuery()
  const fetchingChat = isFetchingChat || isLoadingChat
  useEffect(() => {
    if(isOpen === true) {
      fetchChat({ apiUrl: fetchChatUrl, contractId: itemId })
    }
  }, [isOpen, fetchChat, fetchChatUrl, itemId])

  const { register, handleSubmit, reset, formState: { isSubmitting } } = useForm<ChatFormType>({
    defaultValues: {
      contractId: itemId,
      contractType: entityName
    }
  })
  const [submitReport] = useSubmitContractReportMutation()
  const onSubmit = async (values: ChatFormType) => {
    await submitReport({
      apiUrl: canI("communicationCREATE", permissions).actionUrl,
      values,
      contractId: itemId,
      contractType: entityName
    })
    reset()
  }

  return (
    <Modal
      size="lg" 
      isOpen={isOpen} 
      toggle={toggle}
    >
      <ModalHeader toggle={toggle} data-bs-theme="dark">
        <FontAwesomeIcon className="me-2" icon="envelope" />
        Elenco dei messaggi
      </ModalHeader>
      <ModalBody>
        <ChatListWithSkeleton 
          isLoading={fetchingChat}
          reports={reports}
        />
        <ShowOnCondition
          showWhen={canI("communicationCREATE", permissions).abilityCheck}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <TextAreaWrapper 
              className="form-control"
              name="description"
              label="Inserisci una nuovo messaggio"
              register={register}
            />
            <div className="text-end">
              <ButtonWithLoader
                isLoading={isSubmitting}
                type="submit"
                disabled={isSubmitting}
                label="Invia"
                fontAwesomeIcon={["fas", "paper-plane"]}
              />
            </div>
          </Form>
        </ShowOnCondition>
      </ModalBody>
    </Modal>
  )
}

export default ChatModal