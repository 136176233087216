import styled from "styled-components"

const BankAccountDashboardStyle = styled.div`
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    font-size: .9rem;
    font-weight: 700;
    color: ${({ theme}) => theme.colors.palette6};
  }
`

export default BankAccountDashboardStyle