export function setDefaultFormData(productData?: any) {
  return productData ? ({
    ...productData,
    heatedUsableArea1: productData.heatedUsableArea1 ?? null,
    heatedUsableArea2: productData.heatedUsableArea2 ?? null,
    heatedUsableArea3: productData.heatedUsableArea3 ?? null,
    modelPower1: productData.modelPower1 ?? null,
    modelAmount1: productData.modelAmount1 ?? null,
    modelPrice1: productData.modelPrice1 ?? null,
    modelPower2: productData.modelPower2 ?? null,
    modelAmount2: productData.modelAmount2 ?? null,
    modelPrice2: productData.modelPrice2 ?? null,
    modelPower3: productData.modelPower3 ?? null,
    modelAmount3: productData.modelAmount3 ?? null,
    modelPrice3: productData.modelPrice3 ?? null,
    procedureManagmentPrice: productData.procedureManagmentPrice ?? null,
    accessoriesPrice: productData.accessoriesPrice ?? null,
    priceVatNotIncluded: productData.priceVatNotIncluded ?? null,
    priceVatIncluded: productData.priceVatIncluded ?? null,
    priceToPay: productData.priceToPay ?? null,
  }) : ({
    heatedUsableArea1: null,
    heatedUsableArea2: null,
    heatedUsableArea3: null,
    modelPower1: null,
    modelAmount1: null,
    modelPrice1: null,
    modelPower2: null,
    modelAmount2: null,
    modelPrice2: null,
    modelPower3: null,
    modelAmount3: null,
    modelPrice3: null,
    procedureManagmentPrice: null,
    accessoriesPrice: null,
    priceVatNotIncluded: null,
    priceVatIncluded: null,
    priceToPay: null,
  })
}