import { FieldValues } from "react-hook-form"

import WithSpinner from "../../WithSpinner/WithSpinner.component"
import ProposalForm from "./ProposalForm.component"

type ProposalFormContainerProps = {
  fetchingProposalData: boolean
  proposalData?: FieldValues
  fieldsValues: FieldValues
}

const ProposalFormWithSpinner = WithSpinner(ProposalForm)

const ProposalFormContainer = ({ 
  fetchingProposalData,
  proposalData,
  fieldsValues
}: ProposalFormContainerProps) => (
  <ProposalFormWithSpinner
    proposalData={proposalData}
    userFieldsValues={fieldsValues?.user || {}}
    proposalFieldsValues={fieldsValues?.proposal || {}}
    isLoading={fetchingProposalData}
  />
)

export default ProposalFormContainer