import { Nav, NavItem, NavLink } from "reactstrap"
import classNames from 'classnames'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import GasAndPowerContractTabsStyle from "./style"
import { ContractStatusMap, ContractTabsMap } from "../../../../redux/contract/contract.utils"
import { isTabDisabled } from "./utils"
import { selectCanSign, selectContractStatusId, selectMaxFormStepAvailable } from "../../../../redux/contract/contract.selectors"
import SignContractAlert from "../../../../components/SignContractAlert/SignContractAlert.component"
import ShowOnCondition from "../../../../components/auth/ShowOnCondition.component"
import { useSelectContractId, useSelectEntityName, useSelectSelectedStep, useSetSelectedStep } from "../../../providers/ContractsPageProvider.provider"
import { useAppSelector } from "../../../../redux/hooks"

const GasAndPowerContractTabs = () => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  const selectedStep = useSelectSelectedStep()
  const setSelectedStep = useSetSelectedStep()!
  const selectedContractStatusId = useAppSelector(selectContractStatusId({ entityName, contractId }))
  const selctedCanSign: boolean = useAppSelector(selectCanSign({ entityName, contractId }))
  const maxFormStepAvailable = useAppSelector(selectMaxFormStepAvailable({ contractId, entityName }))

  return (
    <GasAndPowerContractTabsStyle>
      <Nav tabs className="subscription-nav">
        <NavItem>
          <NavLink
            onClick={() => setSelectedStep(ContractTabsMap.PROPOSAL_STEP)}
            className={classNames({ active: selectedStep === ContractTabsMap.PROPOSAL_STEP })}
          >
            <FontAwesomeIcon icon="address-card" className="me-2" />
            Proposta
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            disabled={isTabDisabled(ContractTabsMap.PAYMENT_STEP, maxFormStepAvailable)}
            onClick={() => setSelectedStep(ContractTabsMap.PAYMENT_STEP)}
            className={classNames({ active: selectedStep === ContractTabsMap.PAYMENT_STEP })}
          >
            <FontAwesomeIcon icon="money-bill-wave" className="me-2" />
            Dati pagamento
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            disabled={isTabDisabled(ContractTabsMap.CADASTRAL_STEP, maxFormStepAvailable)}
            onClick={() => setSelectedStep(ContractTabsMap.CADASTRAL_STEP)}
            className={classNames({ active: selectedStep === ContractTabsMap.CADASTRAL_STEP })}
          >
            <FontAwesomeIcon icon="home" className="me-2" />
            Utenze
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            disabled={isTabDisabled(ContractTabsMap.ATTACHMENTS_STEP, maxFormStepAvailable)}
            onClick={() => setSelectedStep(ContractTabsMap.ATTACHMENTS_STEP)}
            className={classNames({ active: selectedStep === ContractTabsMap.ATTACHMENTS_STEP })}
          >
            <FontAwesomeIcon icon="paperclip" className="me-2" />
            Allegati
          </NavLink>
        </NavItem>
        {selectedContractStatusId >= ContractStatusMap.DONE_STATUS &&
          <NavItem>
            <NavLink
              onClick={() => setSelectedStep(ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP)}
              className={classNames({ active: selectedStep === ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP })}
            >
              <FontAwesomeIcon icon="file-contract" className="me-2" />
              Contratto firmato
            </NavLink>
          </NavItem>
        }
      </Nav>
      <ShowOnCondition 
        showWhen={selctedCanSign}
      >
        <SignContractAlert />
      </ShowOnCondition>
    </GasAndPowerContractTabsStyle>
  )
}

export default GasAndPowerContractTabs