import { createContext, useContext } from "react"
import { ContractTabsMap, HeaterTabsMap, JobTabsMap } from "../../redux/contract/contract.utils"

export type ContractsPageContextType = {
  contractData: Record<string, any>
  setContractData: (args: { [key: string]: any }) => void,
  selectedStep?: ContractTabsMap | HeaterTabsMap | JobTabsMap,
  setSelectedStep?: (step: ContractTabsMap | HeaterTabsMap | JobTabsMap) => void,
}
export const ContractsPageContext = createContext<ContractsPageContextType>({
  contractData: {},
  setContractData: () => {},
  selectedStep: ContractTabsMap.PROPOSAL_STEP,
  setSelectedStep: () => {},
})

export const useContractsFormPageContext = () => useContext(ContractsPageContext)

export const useSelectContractDataItem = (searchedProperty: string) => {
  const { contractData } = useContractsFormPageContext()
  return contractData[searchedProperty] ?? undefined
}

export const useSelectEntityName = (): string => {
  const { contractData } = useContractsFormPageContext()
  return contractData?.entityName ?? ""
}

export const useSelectContractId = (): string => {
  const { contractData } = useContractsFormPageContext()
  return contractData?.contractId ?? ""
}

export const useSelectSelectedStep = () => {
  const { selectedStep } = useContractsFormPageContext()
  return selectedStep
}

export const useSetSelectedStep = () => {
  const { setSelectedStep } = useContractsFormPageContext()
  return setSelectedStep
}