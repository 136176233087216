import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { FirebaseErrorMessagesIt } from "../../firebase"
import { fetchData, getAPIUrl, mutateData } from "../api/utils"

export const AsyncStatusEnum = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export const LoginSteps = {
  USER_EMAIL_STEP: 1,
  USER_PASSWORD_STEP: 2
}

export const SignatureTypes = {
  OTP_SIGNATURE: 1,
  PAPER_SIGNATURE: 2,
  COMMIT_SIGNATURE: 3,
}

export const UserRoles = {
  USER_ROLE_ADMIN: 'admin',
  USER_ROLE_VERIFIER: 'validatore',
  USER_ROLE_READER: 'consultatore',
  USER_ROLE_SUPPORT: 'supporto',
  USER_ROLE_SHOP: 'negozio',
  USER_ROLE_RESPONSIBLE: 'responsabile',
  USER_ROLE_AGENCY: 'agenzia',
}

export function abLogin(accessToken: string) {
  return fetchData(`${getAPIUrl()}/login`, accessToken)
    .then(response => response)
}

export function getUserEntities(accessToken: string) {
  return fetchData(`${getAPIUrl('abStorePortal')}/login/entities`, accessToken)
    .then(response => response.entities)
}

export function checkUserPassword(email: string) {
  return fetchData(`${getAPIUrl()}/users/must-change-pwd?username=${email}`)
    .then(response => response.data)
}

export function sendResetPasswordEmail(values: Record<string, any>) {
  return mutateData(`${getAPIUrl()}/users/send-reset-email`, null, values, 'POST')
    .then(response => response.data)
}

export type TranslateFirebaseErrors = {
  code?: 'auth/too-many-requests' | 'auth/wrong-password'
  message?: string
}
export const translateFirebaseErrorMessage = 
  (error: TranslateFirebaseErrors): FetchBaseQueryError => {
    if(error.code) {
      return {
        status: 400,
        data: {
          error: {
            message: FirebaseErrorMessagesIt[error.code]
          }
        }
      }
    }

    if(error.message) {
      return {
        status: 400,
        data: {
          error: {
            message: error.message
          }
        }
      }
    }

    return {
      status: 400,
      data: {
        error: {
          message: "Si è verificato un problema!"
        }
      }
    }
  }