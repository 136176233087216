import { useEffect } from "react"
import { FieldValues, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { isUseTypeNeeded } from "../PodOfferConfig/utils"

type UpdateElectricUseTypeHookProps = {
  watch: UseFormWatch<FieldValues> 
  entityName: string
  setValue: UseFormSetValue<FieldValues>
  fetchElectricUseType: (values: Record<string, unknown>) => any
}

export const useUpdateElectricUseTypeHook = ({
  entityName,
  watch,
  setValue,
  fetchElectricUseType
}: UpdateElectricUseTypeHookProps) => {
  const updateElectricUseType = async (values: Record<string, unknown>) => {
    const response = await fetchElectricUseType(values)
    setValue("electricUseTypeId", response.data)
  }

  const subscription = watch((data, { name, type }) => {
    if(!isUseTypeNeeded(entityName) && name === "contractType") {
      updateElectricUseType({ entityName, contractTypeId: data[name].value })
    }
  })

  useEffect(() => {
    return () => subscription.unsubscribe()
  }, [subscription])
}