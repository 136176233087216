import ThTable from "../../../ThTable/ThTable.component";

const StetupStatusTHeader = () => (
  <thead className="table-light">
    <tr>
      <ThTable label="Stato" />
      <ThTable style={{ textAlign: "left" }} label="Descrizione" />
      <ThTable label="Data esecuzione" />
      <ThTable />
    </tr>
  </thead>
);

export default StetupStatusTHeader;
