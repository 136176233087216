import { createReducer } from "@reduxjs/toolkit";
import { setCurrentPalette } from "./settings.actions";
import { getStorageColors, setStorageColors } from "./settings.utils";

export type ColorPalette = {
  palette1: string;
  palette2: string;
  palette3: string;
  palette4: string;
  palette5: string;
  palette6: string;
  palette7: string;
};

export type SettingsState = {
  currentPalette: {
    name: string;
    displayName?: string;
    colors: ColorPalette;
  };
};

export const INITIAL_STATE: SettingsState = {
  currentPalette: {
    name: "blueHorizon",
    colors: {
      palette1: "#1c66ab",
      palette2: "#287ac3",
      palette3: "#d3d72c",
      palette4: "#287ac3",
      palette5: "#dde1e6",
      palette6: "#287ac3",
      palette7: "#53a0df",
    },
  },
};

const settingsReducer = createReducer(getStorageColors() ?? INITIAL_STATE, (builder) => {
  builder.addCase(setCurrentPalette, (state, action) => {
    setStorageColors(action.payload)
    state.currentPalette = action.payload ?? INITIAL_STATE.currentPalette
  })
});

export default settingsReducer;
