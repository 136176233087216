import { FieldValues } from "react-hook-form"

import { CustomerTypes, getCustomerType, setDefaultDate } from "../utils/utils"
import { isResidentNeeded } from "./PodOfferConfig/utils"

export const DefaultSelectValues = {
  IS_RESIDENT: { value: "1", label: "Sì" },
  IS_NOT_RESIDENT: { value: "2", label: "No" },
  IS_INVOICE_DIFFERENT_EMAIL: { value: "2", label: "No" },
  BILLING_SHIPPING_ADDRESS: { value: "1", label: "No" },
  ELECTRIC_USE_TYPE_ID: { label: "Domestico", value: 1 }
}

type DefaultFormDataType = {
  podFieldsValues: FieldValues
  podData?: FieldValues
  customerType: Record<string, string> 
  entityName: string
}
export function setDefaultFormData({
  podFieldsValues, 
  podData,
  customerType,
  entityName,
}: DefaultFormDataType) {
  return podData ? ({
    ...podData,
    serviceStartDate: setDefaultDate(podData.serviceStartDate),
    from: setDefaultDate(podData.from),
    authDate: setDefaultDate(podData.authDate),
    authDeclarationDate: setDefaultDate(podData.authDeclarationDate),
    plantAuthDate: setDefaultDate(podData.plantAuthDate),
    constructionStartDate: setDefaultDate(podData.constructionStartDate),
    meterReadingDate: setDefaultDate(podData.meterReadingDate),
    supplyStartDate: setDefaultDate(podData.supplyStartDate),
    temporaryConnectionFrom: setDefaultDate(podData.temporaryConnectionFrom),
    temporaryConnectionTo: setDefaultDate(podData.temporaryConnectionTo),
    deathDate: setDefaultDate(podData.deathDate),    
    doNotExecuteBefore: setDefaultDate(podData.doNotExecuteBefore),
  }) : ({
    electricExciseClassId: podFieldsValues.electricExciseClass[0],
    isResident: (
      getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, customerType) &&
      isResidentNeeded(entityName)
    ) ? DefaultSelectValues.IS_NOT_RESIDENT : undefined,
    billingShippingAddress: DefaultSelectValues.BILLING_SHIPPING_ADDRESS,
    isInvoiceDifferentEmail: DefaultSelectValues.IS_INVOICE_DIFFERENT_EMAIL,
  })
}

export function promotionsVisiblity(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? false : true
}

export function isDeactivationNeeded(entityName: string) {
  return (
    entityName === 'deactivation'
  ) ? true : false
}

export function isPriceListSwitchNeeded(entityName: string) {
  return (
    entityName === 'priceListSwitch'
  ) ? true : false
}

export function isContractualChangesNeeded(entityName: string) {
  return (
    entityName === 'contractualChanges'
  ) ? true : false
}

export function isPlantVerifyNeeded(entityName: string) {
  return (
    entityName === 'plantVerify'
  ) ? true : false
}

export function isMeasureGroupShiftNeeded(entityName: string) {
  return (
    entityName === 'measureGroupShift'
  ) ? true : false
}

export function isNonePopulated(formWatch: FieldValues) {
  return (
    !formWatch?.useType &&
    !formWatch?.requestedPowerValue &&
    !formWatch?.requestedConnectionVoltage
  ) ? false : true
}