import { Alert, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SignContractAlertStyle from './style'
import { useFetchSignatureTypesQuery } from '../../redux/auth/auth.api'
import { useState } from 'react'
import ContractFinalizationModal from '../ContratFinalizationModal/ContractFinalizationModal.component'
import ShowOnCondition from '../auth/ShowOnCondition.component'
import { useSelectContractId, useSelectEntityName } from '../../pages/providers/ContractsPageProvider.provider'
import { getButtonIcon } from './utils'

const SignContractAlert = () => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  const [finalizationIsOpen, setfinalizationIsOpen] = useState(false)
  const [signatureType, setSignatureType] = useState<number>()
  const toggleFinalization = () => setfinalizationIsOpen(prevState => !prevState)

  const {
    data: userSignatureTypes,
    isFetching,
    isLoading
  } = useFetchSignatureTypesQuery({ entityName, contractId })
  const isFetchingSignatureTypes = isFetching || isLoading

  return (
    <SignContractAlertStyle>
      <Alert color="success">
        <h4 className="alert-heading">Il contratto ora può essere finalizzato!</h4>
        <p className="alert-description">
          Sono stati inseriti i dati necessari alla sottoscrizione del contratto.
          È possibile procedere con la finalizzazione selezionando un metodo tra quelli disponibili, cliccando sul pulsante corrispondente qui in basso.
        </p>
        <hr />
        {isFetchingSignatureTypes ? (
          <div className="signature-types-loader">
            <Spinner size="sm" />
            <span className="ms-2 small loader-text">Caricamento dei metodi di finalizzazione in corso...</span>
          </div>
        ) : (
          <div className="signature-type-container mb-0">
            {userSignatureTypes?.map((item, i) => (
              <Button
                className="ab-button"
                key={i}
                onClick={() => {
                  setSignatureType(parseInt(item.value))
                  toggleFinalization()
                }}
              >
                <FontAwesomeIcon 
                  icon={getButtonIcon(item)} 
                  className="me-2" 
                />
                {item.label}
              </Button>
            ))}
            <ShowOnCondition showWhen={!userSignatureTypes?.length}>
              <p className="small">
                <FontAwesomeIcon icon="exclamation-triangle" size="lg" className="me-2" />{' '}
                Nessun metodo di finalizzazione disponibile.
              </p>
            </ShowOnCondition>
            <ShowOnCondition showWhen={!!userSignatureTypes?.length}>
              <ContractFinalizationModal 
                contractId={contractId}
                entityName={entityName}
                isOpen={finalizationIsOpen}
                toggle={toggleFinalization}
                signatureType={signatureType}
              />
            </ShowOnCondition>
          </div>
        )}
      </Alert>
    </SignContractAlertStyle>
  )
}

export default SignContractAlert