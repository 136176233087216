import { useCallback, useEffect, useState } from "react"

type UpdateVatClassListHookType = {
  fetchVatClassList: (values: Record<string, unknown>) => any
  entityName: string
  useType?: string
  customerType?: Record<string, unknown>
  podFieldsValues: []
}
export const useUpdateVatClassListHook = ({
  useType,
  fetchVatClassList,
  podFieldsValues,
  customerType,
  entityName
}: UpdateVatClassListHookType) => {
  const [vatClassList, setVatClassList] = useState(podFieldsValues)
  const updateContractPriceListCode = useCallback(async (values: Record<string, unknown>) => {
    const response = await fetchVatClassList(values)
    setVatClassList(response.data)
  }, [fetchVatClassList])

  //Fetch data on render if podData is set
  useEffect(() => {
    if(useType && customerType) {
      updateContractPriceListCode({
        entityName,
        useType,
        customerType: customerType.value
      })
    }
  }, [
    customerType,
    entityName,
    useType,
    updateContractPriceListCode,
  ])

  return vatClassList
}