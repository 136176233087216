import classNames from "classnames"
import { Table } from "reactstrap"

import UserTBody from "./UserTBody/UserTBody.component"
import UserTHeader from "./UserTHeader/UserTHeader.component"

type UserListTableProps = {
  page: number
  filters: string
  isFetching: boolean
}

const UserListTable = ({
  isFetching,
  ...rest
}: UserListTableProps) => {
  return (
    <Table 
      responsive
      className={
        classNames({
          'is-fetching': isFetching === true
        })
      }
    >
      <UserTHeader />
      <UserTBody 
        {...rest}
      />
    </Table>
  )
}

export default UserListTable