export function isUseTypeNeeded(entityName: string) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'transfer' ||
    entityName === 'contextualTransfer' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function isResidentNeeded(entityName: string) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'transfer' ||
    entityName === 'contextualTransfer' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}