import { UncontrolledAlert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MaintenanceAlertStyle from './style'

type MaintenancePageProps = {
  maintenanceAlertText?: string
}

const MaintenanceAlert = ({
  maintenanceAlertText
}: MaintenancePageProps) => {
  return (
    <MaintenanceAlertStyle>
      <UncontrolledAlert id="maintenance-alert" color="warning" className="container">
        <div className="content">
          <FontAwesomeIcon icon="construction" size="lg" className="me-2" />
          <span>{ maintenanceAlertText }</span>
        </div>
      </UncontrolledAlert>
    </MaintenanceAlertStyle>
  )
}

export default MaintenanceAlert