import { createReducer } from "@reduxjs/toolkit"
import { resetContract, setEntityName } from "./contract.actions"

import { ContractStatusMap, ContractTabsMap } from "./contract.utils"

type ContractState = {
  maxFormStepAvailable: number
  selectedStep: number
  selectedContractStatusId: number
  selectedEntityName: string | undefined
  utilityModal: {
    visibility: boolean
    modalTitle: string
    modalDescription: string
    modalIcon: string
  }
}

const INITIAL_STATE: ContractState = {
  selectedEntityName: undefined,
  maxFormStepAvailable: ContractTabsMap.PROPOSAL_STEP, //Bigger form step id available
  selectedStep: ContractTabsMap.PROPOSAL_STEP, //Form selected step id => see ContractTabsMap
  selectedContractStatusId: ContractStatusMap.ONGOING_STATUS,
  utilityModal: {
    visibility: false,
    modalTitle: '',
    modalDescription: '',
    modalIcon: ''
  }
}

const contractReducer = createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(setEntityName, (state, action) => {
      state.selectedEntityName = action.payload
    })
    .addCase(resetContract, (state, action) => {
      Object.assign(state, INITIAL_STATE)
    })
})

export default contractReducer