import { useMemo } from 'react'
import ReactPaginate from 'react-paginate'

type PaginationProps = {
  page: number
  setPage: (page: number) => void
  totalItems: number
  itemsPerPage: number
}

const Pagination = ({ 
  page,
  setPage,
  totalItems, 
  itemsPerPage,
}: PaginationProps) => {
  const totalPages = useMemo(() => Math.ceil(totalItems / itemsPerPage), [totalItems, itemsPerPage])
  //Fetch data with filters+sorting when clicking on page button
  const handlePageSelection = async (selectedItem: { selected: number }) => {
    const selectedPage = selectedItem.selected + 1
    setPage(selectedPage)
  }

  return(
    <ReactPaginate 
      className="pagination"
      breakLabel="..."
      nextLabel=">|"
      onPageChange={handlePageSelection}
      pageRangeDisplayed={5}
      pageCount={totalPages}
      previousLabel="|<"
      renderOnZeroPageCount={undefined}
    />
  )
}

export default Pagination
