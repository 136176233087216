import infoAllegati from './informativa-allegati.json'

type ContractAttachmentsInfoAlertProps = {
  entityName: keyof typeof infoAllegati
}

const ContractAttachmentsInfoAlert = ({
  entityName
}: ContractAttachmentsInfoAlertProps) => {
  return (
    // <ShowOnCondition showWhen={!!infoAllegati[entityName]}>
    //   <Alert color="warning" className="mb-4">
    //     <h5 className="alert-heading">
    //       <FontAwesomeIcon icon="warning" className="me-2" />
    //       Non dimenticare
    //     </h5>
    //     <div dangerouslySetInnerHTML={{__html: infoAllegati[entityName]}}></div>
    //   </Alert>
    // </ShowOnCondition>
    <span></span> //rimuovere se ShowOnCondition non è commentato
  );
}

export default ContractAttachmentsInfoAlert