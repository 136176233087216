import CadastralDataDashboard from "../../../../components/CadastralDataDashboard/CadastralDataDashBoard.component"
import { useFetchContractBankAccountItemsQuery } from "../../../../redux/contract/bankAccountDetail/bankAccountDetail.api"
import { useSelectContractId, useSelectEntityName } from "../../../providers/ContractsPageProvider.provider"

const GasAndPowerCadastralDataWrapper = () => {
    
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
    const { 
        data: bankAccountItems, 
        isFetching: isFetchingBankItems, 
        isLoading: isLoadingBankItems,
      } = useFetchContractBankAccountItemsQuery({ contractId, entityName })
    
      return (
        <CadastralDataDashboard
        bankAccountItems={bankAccountItems}
        isFetchingBankItems={isFetchingBankItems} 
        isLoadingBankItems={isLoadingBankItems}
        />
      )
}

export default GasAndPowerCadastralDataWrapper
