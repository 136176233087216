import { useEffect } from 'react'
import { Form, Button, Spinner } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import LoginEmailFormStyle from './style'
import InputWrapper from '../inputFields/Input/InputWrapper.component'
import { LoginSteps } from '../../../redux/auth/auth.utils'
import { ResetPasswordReqType } from '../../../redux/auth/auth.types'

type LoginEmailProps = {
  setLoginStep: React.Dispatch<React.SetStateAction<number>>
  setUserData: (args: ResetPasswordReqType) => void
}

type LoginEmailFormFields = {
  email: string
}

const LoginEmailForm = ({ setLoginStep, setUserData }: LoginEmailProps) => {  
  const { 
    register, 
    handleSubmit, 
    setFocus,
    formState: { 
      errors,      
      isSubmitting 
    } 
  } = useForm<LoginEmailFormFields>()
  const onSubmit = async(values: LoginEmailFormFields) => {
    setUserData(values)
    setLoginStep(LoginSteps.USER_PASSWORD_STEP)
  }
  
  useEffect(() => {
    setFocus("email", { shouldSelect: true })
  }, [setFocus])  

  return (
    <LoginEmailFormStyle>
      <div className="login-icon">
        <FontAwesomeIcon icon="at" />
      </div>
      <p className="lead">Inserisci la tua email per procedere:</p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper
          name="email"
          label="Email"
          placeholder="Email"
          type="text"
          className="form-control"
          errors={errors}
          register={register}
          rules={{
            required: true,
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Email non valida"
            }
          }}
        />
        <div className="d-flex justify-content-end">
          <Button type="submit">
            <span>Avanti</span>
            {isSubmitting ? (
              <Spinner size="sm" color="light" className="ms-1" />
            ) : (
              <FontAwesomeIcon icon="sign-in-alt" className="ms-1" />
            )}
          </Button>
        </div>
      </Form>
    </LoginEmailFormStyle>
  )
}

export default LoginEmailForm