import { Container } from "reactstrap";
import DefaultPageContainer from "../../../pages/DefaultPageContainer/DefaultPageContainer.component";
import PdfListStyle from "../../../pages/SetupImportPdfPage/style";
import { useState } from "react";
import { useFetchUploadedListQuery } from "../../../redux/setup/setup.api";
import WithSkeleton from "../../WithSkeleton/WithSkeleton.component";
import PdfListTable from "./PdfListTable/PdfListTable.component";
import TableFooter from "../TableFooter/TableFooter.component";
import { LIST_DEFAULT_ITEMS_PER_PAGE } from "../utils";

interface Props {
  setupUniqueName: string
}
const SetupImportPdfList = ({
  setupUniqueName
}: Props) => {

  const PdfListTableWithSkeleton = WithSkeleton(PdfListTable)

  const [filters] = useState("")
  const [page, setPage] = useState(1)

  //Fetch list data trigger
  const {
    data: listData,
    isFetching: isFetchingListData,
    isLoading: isLoadingListData, 
  } = useFetchUploadedListQuery({
    setupUniqueName,
    filters,
    page
  })

  return (
    <DefaultPageContainer>
      <PdfListStyle>
        <Container className='contract-container mt-0'>
          <section className="list-container">
            <PdfListTableWithSkeleton
              isLoading={isLoadingListData}
              isFetching={isFetchingListData}
              page={page}
              filters={filters}
              setupUniqueName={setupUniqueName}
            />
            <TableFooter
              listData={listData}
              page={page}
              setPage={setPage}
              itemsPerPage={LIST_DEFAULT_ITEMS_PER_PAGE}
            />
          </section>
        </Container>
      </PdfListStyle>
    </DefaultPageContainer>
  );
};

export default SetupImportPdfList;
