import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"

type ValidateAllModalProps = {
  isOpen: boolean
  toggle: () => void
  isSubmitting: boolean
  validateAllPdp: () => void
}

const ValidateAllModal = ({
  isOpen,
  toggle,
  isSubmitting,
  validateAllPdp
}: ValidateAllModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon="check" />
      </ModalHeader>
      <ModalBody>
        Valida tutti i punti di fornitura
      </ModalBody>
      <ModalFooter>
        <ButtonWithLoader 
          label="Valida"
          color="success"
          fontAwesomeIcon={["fas", "check-circle"]}
          isLoading={isSubmitting}
          onClick={validateAllPdp}
        />
        <Button color="secondary" onClick={toggle}>Annulla</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ValidateAllModal