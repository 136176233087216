import { selectUserAvailableNewCustomerEntities, selectUserAvailableOngoingCustomerEntities, selectUserAvailableOtherEntities } from "../../redux/auth/auth.selectors"
import { useAppSelector } from "../../redux/hooks"
import EntitySectionShortcuts from "./EntitiySectionShortcuts/EntitySectionShortcuts.component"
import AvailableEntitiesHeader from "./AvailableEntitiesHeader/AvailableEntitiesHeader.component"
import EntityRow from "./EntityRow/EntityRow.component"
import { ContractCategories, getCategoryLabel } from "../../redux/contract/contract.utils"

const AvailableHomepageEntities = () => {
    const availableNewCustomerContracts = useAppSelector(selectUserAvailableNewCustomerEntities)
    const availableOngoingCustomerContracts = useAppSelector(selectUserAvailableOngoingCustomerEntities)
    const availableOtherContracts = useAppSelector(selectUserAvailableOtherEntities)

    return (
        <>
            <AvailableEntitiesHeader />
            {!!availableNewCustomerContracts.length &&
                <section className="contracts-section new-clients" id="new-clients">
                    <h3 className="section-subtitle">Operazioni disponibili per</h3>
                    <h1 className="section-title">{getCategoryLabel(ContractCategories.NEW_CUSTOMER)}:</h1>
                    <div className="contracts-container">
                        {availableNewCustomerContracts.map((entity, i) => (
                        <EntityRow 
                            entity={entity}
                            key={i}
                        />
                        ))}
                    </div>
                </section>
            }
            {!!availableOngoingCustomerContracts.length &&
                <section className="contracts-section new-clients" id="active-clients">
                    <h3 className="section-subtitle">Operazioni disponibili per</h3>
                    <h1 className="section-title">{getCategoryLabel(ContractCategories.ONGOING_CUSTOMER)}:</h1>
                    <div className="contracts-container">
                        {availableOngoingCustomerContracts.map((entity, i) => (
                        <EntityRow 
                            entity={entity}
                            key={i}
                        />
                        ))}
                    </div>
                </section>
            }
            {!!availableOtherContracts.length &&
                <section className="contracts-section new-clients" id="other-clients">
                    <h3 className="section-subtitle">Operazioni disponibili per</h3>
                    <h1 className="section-title">{getCategoryLabel(ContractCategories.OTHER)}:</h1>
                    <div className="contracts-container">
                        {availableOtherContracts.map((entity, i) => (
                        <EntityRow 
                            entity={entity}
                            key={i}
                        />
                        ))}
                    </div>
                </section>
            }
            <EntitySectionShortcuts />
        </>
    )
}

export default AvailableHomepageEntities