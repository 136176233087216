import { useState } from "react"
import { useFormContext } from "react-hook-form"
import { Button, Col } from "reactstrap"

import { contractApi } from "../../../../../redux/contract/contract.api"
import ShowOnCondition from "../../../../auth/ShowOnCondition.component"
import AsyncSelectWrapper from "../../../inputFields/AsyncSelect/AsyncSelectWrapper.component"
import DatePickerWrapper from "../../../inputFields/DatePicker/DatePickerWrapper.component"
import InputWrapper from "../../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../../../utils/formLabels"
import { FormProps } from "../../../utils/types"
import { asyncFnWrapper } from "../../../utils/utils"
import { useWhenFieldChangesHook } from "../../hooks/useWhenFieldChangesHook.hooks"
import OtherUseRealEstateInstructions from "./OtherUseRealEstateInstructions.component"

type PodOtherUseRealEstateFormProps = {
  extendedPodDataNeeded: boolean
} & FormProps

const PodOtherUseRealEstateForm = ({
  canEdit,
  fieldsValues: podFieldsValues,
  extendedPodDataNeeded
}: PodOtherUseRealEstateFormProps) => {
  const { register, control, watch, setValue, formState: { errors } } = useFormContext()
  const formWatcher = watch()
  
  const [ instructionsIsOpen, setInstructionsIsOpen ] = useState(false)
  const toggleInstructionsModal = () => setInstructionsIsOpen(prevState => !prevState)
  const [fetchTowns] = contractApi.endpoints.fetchTowns.useLazyQuerySubscription()
  
  useWhenFieldChangesHook({ 
    watch,
    field: "realEstateAuthType", 
    set: "authNo", 
    to: null,
    setValue
  })
  useWhenFieldChangesHook({ 
    watch,
    field: "realEstateAuthType", 
    set: "authDate", 
    to: null,
    setValue
  })
  useWhenFieldChangesHook({ 
    watch,
    field: "realEstateAuthType", 
    set: "authTownName", 
    to: null,
    setValue
  })
  useWhenFieldChangesHook({ 
    watch,
    field: "realEstateAuthType", 
    set: "instructions", 
    to: null,
    setValue
  })
  useWhenFieldChangesHook({ 
    watch,
    field: "plantPresence", 
    set: "plantAuthNo", 
    to: null,
    setValue
  })
  useWhenFieldChangesHook({ 
    watch,
    field: "plantPresence", 
    set: "plantAuthDate", 
    to: null,
    setValue
  })
  useWhenFieldChangesHook({ 
    watch,
    field: "plantPresence", 
    set: "plantAuthTownName", 
    to: null,
    setValue
  })

  return (
    <ShowOnCondition showWhen={parseInt(formWatcher.realEstateUseType?.value) === 1}>
      <Col md={12}>
        <SelectWrapper
          name="realEstateAuthType"
          control={control}
          label="Immobile realizzato tramite"
          isDisabled={canEdit ? false : true}
          errors={errors}
          register={register}
          options={podFieldsValues?.realEstateAuthType || []}
          rules={{ required: errorFormLabels.REQUIRED }}
        />
      </Col>
      <ShowOnCondition showWhen={parseInt(formWatcher.realEstateAuthType?.value) < 3}>
        <Col md={12}>
          <InputWrapper
            type="text"
            className="form-control"
            disabled={canEdit ? false : true}
            name="authNo"
            label="Numero permesso"
            rules={{ maxLength: 80, required: errorFormLabels.REQUIRED }}
            register={register}
            errors={errors}
          />
        </Col>
        <Col md={6}>
          <DatePickerWrapper
            name="authDate"
            control={control}
            label="Data permesso"
            className="form-control"
            errors={errors}
            showMonthDropdown
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
            disabled={canEdit ? false : true}
            rules={{ required: errorFormLabels.REQUIRED }}
          />
        </Col>
        <Col md={6}>
          <AsyncSelectWrapper
            name="authTownName"
            control={control}
            register={register}
            label="Rilasciato dal comune"
            errors={errors}
            isDisabled={canEdit ? false : true}
            loadOptions={(input: string) => {
              if(input.length > 2) {
                return asyncFnWrapper(input, fetchTowns)
              }
            }}
            getOptionLabel={option => `${option.town} (${option.province})`}
            getOptionValue={option => `${option.town} (${option.province})`}
            rules={{ required: errorFormLabels.REQUIRED }}
            noOptionsMessage={() => 
              <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
            }
          />
        </Col>
      </ShowOnCondition>
      <ShowOnCondition showWhen={parseInt(formWatcher.realEstateAuthType?.value) === 7}>
        <Col md={12}>
          <InputWrapper
            type="text"
            className="form-control"
            disabled={canEdit ? false : true}
            name="instructions"
            label="Dichiarazione istruzioni"
            rules={{ maxLength: 80, required: errorFormLabels.REQUIRED }}
            register={register}
            errors={errors}
          />
          <p>
            Per maggiori informazioni su come compilare questo campo clicca 
            <Button
              color="link" 
              className="px-1"
              onClick={toggleInstructionsModal}
            >
              qui
            </Button>
            <OtherUseRealEstateInstructions 
              isOpen={instructionsIsOpen}
              toggle={toggleInstructionsModal}
            />
          </p>
        </Col>
      </ShowOnCondition>
      <Col md={12}>
        <SelectWrapper
          name="plantPresence"
          control={control}
          label="Presenza impianti"
          isDisabled={canEdit ? false : true}
          errors={errors}
          register={register}
          options={podFieldsValues?.plantPresence || []}
          rules={{ required: errorFormLabels.REQUIRED }}
        />
      </Col>
      <ShowOnCondition showWhen={parseInt(formWatcher.plantPresence?.value) === 1}>
        <Col md={6}>
          <InputWrapper
            type="text"
            className="form-control"
            disabled={canEdit ? false : true}
            name="plantAuthNo"
            label="Numero autorizzazione"
            rules={{ maxLength: 80, required: errorFormLabels.REQUIRED }}
            register={register}
            errors={errors}
          />
        </Col>
        <Col md={6}>
          <DatePickerWrapper
            name="plantAuthDate"
            control={control}
            label="Data autorizzazione"
            className="form-control"
            errors={errors}
            showMonthDropdown
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
            disabled={canEdit ? false : true}
            rules={{ required: errorFormLabels.REQUIRED }}
          />
        </Col>
        <Col md={12}>
          <AsyncSelectWrapper
            name="plantAuthTownName"
            control={control}
            register={register}
            label="Rilasciata dal comune"
            errors={errors}
            isDisabled={canEdit ? false : true}
            loadOptions={(input: string) => {
              if(input.length > 2) {
                return asyncFnWrapper(input, fetchTowns)
              }
            }}
            getOptionLabel={option => `${option.town} (${option.province})`}
            getOptionValue={option => `${option.town} (${option.province})`}
            rules={{ required: errorFormLabels.REQUIRED }}
            noOptionsMessage={() => 
              <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
            }
          />
        </Col>
      </ShowOnCondition>
      <ShowOnCondition showWhen={extendedPodDataNeeded}>
        <Col md={6}>
          <DatePickerWrapper
            name="authDeclarationDate"
            control={control}
            label="Certificato di agibilità ottenuto il"
            className="form-control"
            errors={errors}
            showMonthDropdown
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
            disabled={canEdit ? false : true}
            rules={{ required: errorFormLabels.REQUIRED }}
          />
        </Col>
        <Col md={6}>
          <AsyncSelectWrapper
            name="authDeclarationTownName"
            control={control}
            register={register}
            label="Certificato di agibilità ottenuto dal comune"
            errors={errors}
            isDisabled={canEdit ? false : true}
            loadOptions={(input: string) => {
              if(input.length > 2) {
                return asyncFnWrapper(input, fetchTowns)
              }
            }}
            getOptionLabel={option => `${option.town} (${option.province})`}
            getOptionValue={option => `${option.town} (${option.province})`}
            rules={{ required: errorFormLabels.REQUIRED }}
            noOptionsMessage={() => 
              <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
            }
          />
        </Col>
        <Col md={12}>
          <SelectWrapper
            name="authDeclarationAsl"
            control={control}
            label="Certificazione ASL"
            isDisabled={canEdit ? false : true}
            errors={errors}
            register={register}
            options={podFieldsValues?.authDeclarationAsl || []}
            rules={{ required: errorFormLabels.REQUIRED }}
          />
        </Col>
      </ShowOnCondition>
      <Col md={12}>
        <DatePickerWrapper
          name="constructionStartDate"
          control={control}
          label="Data inizio lavori"
          className="form-control"
          errors={errors}
          showMonthDropdown
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
          disabled={canEdit ? false : true}
          rules={{ required: errorFormLabels.REQUIRED }}
        />
      </Col>
    </ShowOnCondition>
  )
}

export default PodOtherUseRealEstateForm