import TdTable from "../../../../TdTable/TdTable.component";
import { SetupStatusItemType } from "../../../../../../redux/setup/setup.types";
import moment from "moment";
import ShowOnCondition from "../../../../../auth/ShowOnCondition.component";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defaultDescription, getIconByStatus } from "./utils";
import { fetchErrorDetail } from "../../../../../../redux/setup/setup.api";
import { downloadFile, mimeFileTypes } from "../../../../../../utils/files.utils";

type StetupStatusTRowProps = {
  item: SetupStatusItemType;
};

const StetupStatusTRow = ({ item }: StetupStatusTRowProps) => {
  const [errorDetail] = fetchErrorDetail.useLazyQuerySubscription();

  const downloadErrorDetails = async () => {
    const { data } = await errorDetail(item.id);
    downloadFile(data.body64, data.title, mimeFileTypes["txt"]);
  };

  return (
    <tr>
      <TdTable>
        <div className="text-center">
        <FontAwesomeIcon size="xl" spin={item.status?.toLocaleLowerCase() === 'running'} icon={getIconByStatus(item.status?.toLocaleLowerCase())} />

        </div>
      </TdTable>
      <TdTable value={item.errorMessage ?? defaultDescription(item.status?.toLocaleLowerCase())} />
      <TdTable
        value={
          item.executionDate
            ? moment(item.executionDate).format("DD/MM/YYYY HH:mm")
            : ""
        }
      />
      <TdTable>
        <div className="buttons-container float-end">
          <ShowOnCondition showWhen={item.hasErrorDetails}>
            <Button
              target="_blank"
              title="Vedi dettaglio"
              outline
              color="secondary"
              className="btn btn-sm mx-1"
              onClick={downloadErrorDetails}
            >
              <FontAwesomeIcon size="sm" icon="download" />
            </Button>
          </ShowOnCondition>
        </div>
      </TdTable>
    </tr>
  );
};

export default StetupStatusTRow;
