import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";
import { Col, Row } from "reactstrap";

import { useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider";
import {
  fetchRemiCodes,
  fetchGasVendors,
} from "../../../../redux/contract/pdr/pdr.api";
import ShowOnCondition from "../../../auth/ShowOnCondition.component";
import AsyncSelectWrapper from "../../inputFields/AsyncSelect/AsyncSelectWrapper.component";
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component";
import InputWrapper from "../../inputFields/Input/InputWrapper.component";
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component";
import { errorFormLabels } from "../../utils/formLabels";
import { FormProps } from "../../utils/types";
import { asyncFnWrapper } from "../../utils/utils";
import { useUpdateRemiCodesHook } from "../hooks/useUpdateRemiCodesHook.hook";
import Potentiality from "./Potentiality/Potentiality.component";
import {
  isMeterReadNumberNeeded,
  isOriginMarketNeeded,
  isPublicAuthorityNeeded,
  isServiceStartDateNeeded,
  isVendorNameNeeded,
} from "./utils";
import { fetchDistributors } from "../../../../redux/contract/contract.api";
import { isNewCustomerContract } from "../../../../redux/contract/contract.utils";

type PdrGeneralDataProps = {
  cadastralDataId: string;
} & FormProps;

const GeneralData = ({
  canEdit,
  fieldsValues: pdrFieldsValues,
  userRole,
  cadastralDataId,
  ...rest
}: PdrGeneralDataProps) => {
  const entityName = useSelectEntityName();
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const [triggerFetchVendors] = fetchGasVendors.useLazyQuerySubscription();
  const [triggerFetchDistributors] =
    fetchDistributors.useLazyQuerySubscription();
  const [triggerFetchRemiCodes] = fetchRemiCodes.useLazyQuerySubscription();

  //Fetch remi codes
  const remiCodes = useUpdateRemiCodesHook({
    entityName,
    cadastralDataId,
    fetchRemiCodes: triggerFetchRemiCodes,
  });

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="file" className="me-2" />
        Dati generali
      </div>
      <div className="grouped-body">
        <Row>
          <Col>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={canEdit ? false : true}
              name="no"
              label="Codice PDR"
              placeholder="Codice composta da 14 caratteri"
              maxLength={14}
              rules={{
                pattern: {
                  value: /^[a-zA-Z0-9]{14}$/,
                  message: "Deve essere composto da 14 caratteri!",
                },
                required: errorFormLabels.REQUIRED
              }}
              register={register}
              errors={errors}
            />
          </Col>
          <ShowOnCondition
            showWhen={
              isMeterReadNumberNeeded(entityName) &&
              isNewCustomerContract(entityName)
            }
          >
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="meterReadNumber"
                label="Matricola contatore"
                rules={{
                  maxLength: 80,
                  required: errorFormLabels.REQUIRED,
                }}
                register={register}
                errors={errors}
              />
            </Col>
          </ShowOnCondition>
          <ShowOnCondition
            showWhen={
              isVendorNameNeeded(entityName) &&
              isNewCustomerContract(entityName)
            }
          >
            <Col md={6}>
              <AsyncSelectWrapper
                name="vendorName"
                control={control}
                register={register}
                label="Attuale fornitore"
                errors={errors}
                isDisabled={canEdit ? false : true}
                loadOptions={(input: string) => {
                  if (input.length > 2) {
                    return asyncFnWrapper(
                      { searchedValue: input, entityName },
                      triggerFetchVendors
                    );
                  }
                }}
                rules={{ required: errorFormLabels.REQUIRED }}
                noOptionsMessage={() => (
                  <span className="autocomplete-suggestion">
                    Indica le prime lettere dell'attuale fornitore per trovarlo
                    nella lista
                  </span>
                )}
              />
            </Col>
          </ShowOnCondition>
          <ShowOnCondition showWhen={isNewCustomerContract(entityName)}>
            <Col md={6}>
              <InputWrapper
                type="number"
                className="form-control"
                disabled={canEdit ? false : true}
                name="annualConsumption"
                label="Consumo annuo (smc)"
                rules={{
                  min: 0,
                  maxLength: 80,
                  required: errorFormLabels.REQUIRED,                  
                  valueAsNumber: true,
                  setValueAs: (v) => (v === "" ? null : parseInt(v)),
                }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <SelectWrapper
                name="gasUseCategoryCode"
                control={control}
                label="Categoria uso AEEG"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={pdrFieldsValues?.gasUseCategory || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <Col md={6}>
              <AsyncSelectWrapper
                name="distributorCode"
                control={control}
                register={register}
                label="Distributore"
                errors={errors}
                isDisabled={canEdit ? false : true}
                loadOptions={(input: string) => {
                  if (input.length > 2) {
                    return asyncFnWrapper(
                      { searchedValue: input, entityName, cadastralDataId },
                      triggerFetchDistributors
                    );
                  }
                }}
                rules={{ required: errorFormLabels.REQUIRED }}
                noOptionsMessage={() => (
                  <span className="autocomplete-suggestion">
                    Indica le prime lettere del distributore per trovarlo nella
                    lista
                  </span>
                )}
              />
            </Col>
            <Col md={6}>
              <SelectWrapper
                name="logicalPoint"
                control={control}
                label="Codice REMI"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={remiCodes}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
          </ShowOnCondition>
          <ShowOnCondition
            showWhen={
              isServiceStartDateNeeded(entityName) &&
              isNewCustomerContract(entityName)
            }
          >
            <Col md={6}>
              <DatePickerWrapper
                name="serviceStartDate"
                control={control}
                label="Inizio stimato fornitura"
                className="form-control"
                errors={errors}
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                disabled={canEdit ? false : true}
              />
            </Col>
          </ShowOnCondition>
          <ShowOnCondition
            showWhen={
              isOriginMarketNeeded(entityName) &&
              isNewCustomerContract(entityName)
            }
          >
            <Col md={6}>
              <SelectWrapper
                name="originMarket"
                control={control}
                label="Mercato di provenienza"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={pdrFieldsValues?.originMarket || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
          </ShowOnCondition>
          <ShowOnCondition
            showWhen={
              isPublicAuthorityNeeded(entityName) &&
              isNewCustomerContract(entityName)
            }
          >
            <Col md={3}>
              <SelectWrapper
                name="publicAuthority"
                control={control}
                label="Ente pubblico?"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={pdrFieldsValues?.publicAuthority || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <Col md={3}>
              <SelectWrapper
                name="condominium"
                control={control}
                label="Condominio?"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={pdrFieldsValues?.condominium || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <Col md={3}>
              <InputWrapper
                type="number"
                className="form-control"
                disabled={canEdit ? false : true}
                name="annualConsumption"
                label="N° punti di prelievo"
                rules={{
                  min: 0,
                  maxLength: 20,
                  required: errorFormLabels.REQUIRED,
                  valueAsNumber: true,
                  setValueAs: (v) => v === "" ? null : parseInt(v)
                }}
                register={register}
                errors={errors}
              />
            </Col>
          </ShowOnCondition>
        </Row>
        <ShowOnCondition showWhen={isNewCustomerContract(entityName)}>
          <Potentiality entityName={entityName} canEdit={canEdit!} />
        </ShowOnCondition>
      </div>
    </div>
  );
};

export default GeneralData;
