import { OtpSignerType } from "../../../redux/contract/contract.types";

export function isAttachmentsFormNeeded(entityName: string) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'takeover' ||
    entityName === 'transfer' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection' ||
    entityName === 'contextualTransfer' 
  )
}

export function checkLastSigner(signers: OtpSignerType[]) {
  const totalSigners = signers.length
  const signed = signers.filter(item => item.signed === true) 
  
  return (signed.length === totalSigners - 1) ? true : false
}