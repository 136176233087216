import { createGlobalStyle } from "styled-components"

const FormStyles = createGlobalStyle`
  form {
    button[type="submit"] {
      background-color: ${({ theme}) => theme.colors.palette6};
      color: ${({ theme }) => theme.colors.grayscale[100]};
    }

    .score-check-box-succeeded {
      color: ${({ theme }) => theme.colors.success[600]};
      border: 2px solid;
      border-radius: 5px;
      border-color: ${({ theme }) => theme.colors.success[300]};
    }

    .score-check-box-failed {
      color: ${({ theme }) => theme.colors.danger[600]};
      border: 2px solid;
      border-radius: 5px;
      border-color: ${({ theme }) => theme.colors.danger[300]};
    }

    .score-check-box-none {
      color: ${({ theme }) => theme.colors.graySeashell[300]};
      border: 2px solid;
      border-radius: 5px;
      border-color: ${({ theme }) => theme.colors.graySeashell[200]};
    }

    .grouped {
      border-radius: .75rem;
      margin-bottom: 1.5rem;

      .grouped-header {
        border: 1px solid ${({ theme }) => theme.colors.grayscale[200]};
        padding: .5rem .75rem;
        border-radius: .75rem .75rem 0 0;
        background-color: ${({ theme }) => theme.colors.grayscale[50]};
        color: ${({ theme }) => theme.colors.grayscale[600]};
      }

      .grouped-body {
        border-radius: 0 0 .75rem .75rem;
        border: 1px solid ${({ theme }) => theme.colors.grayscale[200]};
        border-top: none;
        padding: .5rem .75rem;
      }
    }

    label {
      color: ${({ theme}) => theme.colors.palette6};
      font-size: .8rem;
      font-weight: 600;
      margin-bottom: .15rem;
    }

    legend {
      font-size: .8rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.grayscale[600]};
    }

    .text-muted {
      font-size: 70%;
    }

    .invalid-feedback {
      font-size: .8rem;
    }

    .form-control {
      padding: .5rem .75rem;
      border-radius: 4px;
    }

    .custom-select__control,
    .form-control {
      font-size: .85rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.grayscale[600]};
      border-color: ${({ theme }) => theme.colors.grayscale[200]};

      &[readonly] {
        background-color: ${({ theme }) => theme.colors.grayscale[50]};
      }
      
      ::placeholder {
        color: ${({ theme }) => theme.colors.grayscale[100]};
      }
    }

    .custom-select__placeholder {
      color: ${({ theme }) => theme.colors.grayscale[600]};
    }

    .form-control.text-uppercase {
      ::placeholder {
        text-transform: none;
      }
    }

    .custom-select__option {
      font-size: .8rem;
      padding: 8px;
    }

    .penso-in-verde-icon {
      width: 16px;
      fill: ${({ theme }) => theme.colors.success[500]};
      margin-right: .25rem;
    }

  }

  input.phone-number::-webkit-outer-spin-button,
  input.phone-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input.phone-number[type=number] {
    -moz-appearance: textfield;
  }
`

export default FormStyles