import ThTable from "../../../ThTable/ThTable.component"

const UserTHeader = () => (
  <thead className="table-light">
    <tr>
      <ThTable style={{ textAlign: "left" }} label="Nome" />
      <ThTable label="Codice" />
      <ThTable label="Email" />
      <ThTable label="Abilitato" />
      <ThTable />
    </tr>
  </thead>
)


export default UserTHeader