import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import LandingPage from '../../pages/LandingPage/LandingPage.component'
import SetupPage from '../../pages/SetupPage/SetupPage.component'
import SettingsPage from '../../pages/SettingsPage/SettingsPage.component'
import LoginPage from '../../pages/LoginPage/LoginPage.component'
import WithSpinner from '../WithSpinner/WithSpinner.component'
import PrivateRoute from './PrivateRoute.component'
import ContractsFormPage from '../../pages/ContractsFormPage/ContractsFormPage.component'
import ContractsListPage from '../../pages/ContractsListPage/ContractsListPage.component'
import UserPage from '../../pages/UserPage/UserPage.component'
import UserListPage from '../../pages/UserListPage/UserListPage.component'
import UserMassiveCreationPage from '../../pages/UserMassiveCreationPage/UserMassiveCreationPage.component'
import SetupImportCsvPage from '../../pages/SetupImportCsvPage/SetupImportCsvPage.component'
import FirebaseAuthPage from '../../pages/FirebaseAuthPage/FirebaseAuthPage.component'
import NoMatchPage from '../../pages/NoMatchPage/NoMatchPage.component'
import { selectCurrentUser, selectIsCheckingUserSession } from '../../redux/auth/auth.selectors'
import SetupImportPdfPage from '../../pages/SetupImportPdfPage/SetupImportPdfPage.component'

const ContractsFormPageContainer = WithSpinner(ContractsFormPage)
const ContractsListPageContainer = WithSpinner(ContractsListPage)
const UserListPageContainer = WithSpinner(UserListPage)
const UserPageContainer = WithSpinner(UserPage)
const UserMassiveCreationPageContainer = WithSpinner(UserMassiveCreationPage)
const SetupPageContainer = WithSpinner(SetupPage)
const SetupImportPdfContainer = WithSpinner(SetupImportPdfPage);
const SetupImportCsvPageContainer = WithSpinner(SetupImportCsvPage);
const SettingsPageContainer = WithSpinner(SettingsPage)

const AppRouter = () => {
  const userLoggedIn = useSelector(selectCurrentUser) ? true : false
  const isLoading = useSelector(selectIsCheckingUserSession)

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={<>{userLoggedIn ? <LandingPage /> : <LoginPage />}</>}
        />

        <Route
          path='/settings'
          element={
            <PrivateRoute redirectTo='/' condition={userLoggedIn}>
              <SettingsPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />

        <Route
          path='/setup'
          element={
            <PrivateRoute redirectTo='/' condition={userLoggedIn}>
              <SetupPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />
        <Route
          path='/setup/:setupPathName/importa-csv'
          element={
            <PrivateRoute redirectTo='/' condition={userLoggedIn}>
              <SetupImportCsvPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />

        <Route
          path='/setup/:setupPathName/importa-pdf'
          element={
            <PrivateRoute redirectTo='/' condition={userLoggedIn}>
              <SetupImportPdfContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />

        {/*Gestione utenti*/}
        <Route
          path='/utenti/all'
          element={
            <PrivateRoute redirectTo='/' condition={userLoggedIn}>
              <UserListPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />
        <Route
          path='/utenti/creazione-massiva'
          element={
            <PrivateRoute redirectTo='/' condition={userLoggedIn}>
              <UserMassiveCreationPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />
        <Route
          path='utenti/:userId'
          element={
            <PrivateRoute redirectTo='/' condition={userLoggedIn}>
              <UserPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />
        <Route
          path='utenti'
          element={
            <PrivateRoute redirectTo='/' condition={userLoggedIn}>
              <UserPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />

        {/* Contracts list */}
        <Route
          path=':categoryEntity/:pathEntityName/all'
          element={
            <PrivateRoute redirectTo='/' condition={userLoggedIn}>
              <ContractsListPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />

        {/* Contract form page */}
        <Route
          path=':categoryEntity/:pathEntityName/:contractId'
          element={
            <PrivateRoute redirectTo='/' condition={userLoggedIn}>
              <ContractsFormPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />
        <Route
          path=':categoryEntity/:pathEntityName'
          element={
            <PrivateRoute redirectTo='/' condition={userLoggedIn}>
              <ContractsFormPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />

        <Route path={"/firebase-auth/*"} element={<FirebaseAuthPage />} />

        <Route path='*' element={<NoMatchPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter