import { Container } from "reactstrap"
import DefaultPageContainer from "../DefaultPageContainer/DefaultPageContainer.component"
import PageHeader from "../PageHeader/PageHeader.component"
import SetupCreationPageStyle from "./style"
import { useParams } from "react-router"
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop.component"
import { createJobName, getUniqueNameFromPathName, mapTitleToPathOrTitle } from "../../redux/setup/setup.utils"
import { useEffect, useState } from "react"
import SetupImportFilesForm from "../../components/forms/SetupImportFilesForm/SetupImportFilesForm.component"
import { useAppDispatch } from "../../redux/hooks"
import { setSetupJobName, setSetupUniqueName } from "../../redux/setup/setup.actions"
import SetupImportStatusModal from "../../components/SetupImportStatusModal/SetupImportStatusModal.component"
import SetupImportPdfList from "../../components/lists/SetupImportPdfList/SetupImportPdfList.component"

const SetupImportPdfPage = () => {
  const { setupPathName } = useParams()
  const title = mapTitleToPathOrTitle(setupPathName, false)
  const setupUniqueName = getUniqueNameFromPathName(setupPathName)

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setSetupUniqueName(setupUniqueName))
    dispatch(setSetupJobName(createJobName(setupUniqueName, 'pdf')))
  }, [dispatch, setupUniqueName])

  const [visibility, setVisibility] = useState(false)
  const toggle = () => setVisibility(!visibility)

  return (
    <DefaultPageContainer>
      <ScrollToTop />
      <SetupCreationPageStyle>
        <PageHeader pageTitle={`Importa PDF - ${title}`} icon="table" />
        <Container className="contract-container">
          <SetupImportFilesForm 
          toggle={toggle} 
          fileTypeLabel="ZIP"
          importType="import-pdf" 
          contentType="application/zip"
          />
          <SetupImportPdfList setupUniqueName={setupUniqueName}/>
          <SetupImportStatusModal
            isOpen={visibility}
            toggle={toggle}
          />
        </Container>
      </SetupCreationPageStyle>
    </DefaultPageContainer>
  )
}

export default SetupImportPdfPage
