import { FieldValues } from "react-hook-form"

import WithSpinner from "../../WithSpinner/WithSpinner.component"
import PermanentJobForm from "./PermanentJobForm.component"

type PermanentJobFormContainerProps = {
  fetchingProposalData: boolean
  proposalData?: FieldValues
  fieldsValues?: FieldValues
}

const PermanentJobFormWithSpinner = WithSpinner(PermanentJobForm)

const PermanentJobFormContainer = ({ 
  fetchingProposalData,
  proposalData,
  fieldsValues
}: PermanentJobFormContainerProps) => (
  <PermanentJobFormWithSpinner
    proposalData={proposalData}
    userFieldsValues={fieldsValues?.user || {}}
    proposalFieldsValues={fieldsValues?.proposal || {}}
    isLoading={fetchingProposalData}
  />
)

export default PermanentJobFormContainer