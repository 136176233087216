import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"

import { useSendUserResetPasswordEmailMutation } from "../../../../redux/auth/auth.api"
import { ResetPasswordReqType } from "../../../../redux/auth/auth.types"

type ResetPasswordModalProps = {
  visibility: boolean
  toggle: () => void
  userData: ResetPasswordReqType
}

const ResetPasswordModal = ({ visibility, toggle, userData }: ResetPasswordModalProps) => {
  const [triggerResetPassword, { isLoading }] = useSendUserResetPasswordEmailMutation()
  const sendResetEmail = async() => {
    await triggerResetPassword(userData)
    toggle()
  }

  return (
    <Modal isOpen={visibility} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Reset password
      </ModalHeader>
      <ModalBody>
        Confermando verrà inviata un'email contenente il link necessario 
        al reset della password per l'utente <strong>{userData.email}</strong>.
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={isLoading}
          onClick={sendResetEmail}
        >
          {isLoading &&
            <Spinner className="me-2" size="sm" />
          }
          Conferma
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ResetPasswordModal