export function noValidationRequired(entityName: string) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'transfer' ||
    entityName === 'contextualTransfer' ||
    entityName === 'newActivation' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isMeterReadNumberNeeded(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransfer' ||
    entityName === 'takeover' ||
    entityName === 'newConnection' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isVendorNameNeeded(entityName: string) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'contextualTransfer' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isServiceStartDateNeeded(entityName: string) {
  return (
    entityName === 'newConnection'
  ) ? true : false
}

export function isOriginMarketNeeded(entityName: string) {
  return (
    entityName === 'newActivation' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isPublicAuthorityNeeded(entityName: string) {
  return (
    entityName === 'newConnection'
  ) ? true : false
}

export function isRemiAlertNeeded(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? false : true
}