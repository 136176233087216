import { Modal, ModalBody, ModalHeader, Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StetupStatusList from "../lists/StetupStatusList/StetupStatusList.component";

type Props = {
  isOpen: boolean;
  toggle: () => void;
};

const SetupImportStatusModal = ({ isOpen, toggle }: Props) => {
  return (
    <Modal  size="lg" className="entity-info-modal" toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon className="me-2" icon="clock-rotate-left" />
        Esito importazioni
      </ModalHeader>
      <ModalBody>
        <Container className="contract-container mt-0">
          <StetupStatusList />
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default SetupImportStatusModal;
