import { useFormContext } from "react-hook-form"
import { Col } from "reactstrap"

import { useSelectEntityName } from "../../../../../pages/providers/ContractsPageProvider.provider"
import ShowOnCondition from "../../../../auth/ShowOnCondition.component"
import DatePickerWrapper from "../../../inputFields/DatePicker/DatePickerWrapper.component"
import InputWrapper from "../../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../../../utils/formLabels"
import { FormProps } from "../../../utils/types"
import { isResidence, isResidenceSelfDeclarationNeeded, isSupplyEqualToResidence } from "./utils"

const PodResidenceSelfDeclaration = ({
  canEdit,
  fieldsValues: podFieldsValues,
}: FormProps) => {
  const entityName = useSelectEntityName()
  const { register, control, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  return (
    <ShowOnCondition
      showWhen={
        isResidenceSelfDeclarationNeeded(entityName) &&
        isResidence(formWatcher.isResident?.value)
      }
    >
      <Col md={6}>
        <DatePickerWrapper
          name="from"
          control={control}
          label="Residente dal"
          className="form-control"
          errors={errors}
          showMonthDropdown
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
          disabled={canEdit ? false : true}
          rules={{ required: errorFormLabels.REQUIRED }}
        />
      </Col>
      <Col md={6}>
        <SelectWrapper
          name="supplyEqualToResidence"
          control={control}
          label="L'indirizzo di fornitura corrisponde a quello di residenza"
          isDisabled={canEdit ? false : true}
          errors={errors}
          register={register}
          options={podFieldsValues?.supplyEqualToResidence || []}
          rules={{ required: errorFormLabels.REQUIRED }}
        />
      </Col>
      <ShowOnCondition showWhen={parseInt(formWatcher.supplyEqualToResidence?.value) === 2}>
        <Col md={3}>
          <SelectWrapper
            name="supplyToponym"
            control={control}
            label="Toponimo"
            isDisabled={canEdit ? false : true}
            errors={errors}
            register={register}
            options={podFieldsValues?.supplyToponym || []}
            rules={{ required: errorFormLabels.REQUIRED }}
          />
        </Col>
        <Col md={7}>
          <InputWrapper
            type="text"
            className="form-control"
            disabled={canEdit ? false : true}
            name="supplyAddress"
            label="Indirizzo"
            rules={{ 
              required: errorFormLabels.REQUIRED,
              maxLength: 128,
            }}
            register={register}
            errors={errors}
          />
        </Col>
        <Col md={2}>
          <InputWrapper
            type="text"
            className="form-control"
            disabled={canEdit ? false : true}
            name="supplyCivicNo"
            label="Civico"
            rules={{ 
              required: errorFormLabels.REQUIRED,
              maxLength: 4,
            }}
            register={register}
            errors={errors}
          />
        </Col>
      </ShowOnCondition>
      <ShowOnCondition
        showWhen={
          isSupplyEqualToResidence(entityName) &&
          parseInt(formWatcher.electricUseTypeId?.value) === 1 &&
          parseInt(formWatcher.isResident?.value) === 1
        }
      >
        <Col md={4}>
          <InputWrapper
            type="text"
            className="form-control"
            disabled={canEdit ? false : true}
            name="supplyScale"
            label="Scala"
            rules={{ maxLength: 20 }}
            register={register}
            errors={errors}
          />
        </Col>
        <Col md={4}>
          <InputWrapper
            type="text"
            className="form-control"
            disabled={canEdit ? false : true}
            name="supplyFloor"
            label="Piano"
            rules={{ maxLength: 20 }}
            register={register}
            errors={errors}
          />
        </Col>
        <Col md={4}>
          <InputWrapper
            type="text"
            className="form-control"
            disabled={canEdit ? false : true}
            name="supplyApartmentNumber"
            label="Interno"
            rules={{ maxLength: 20 }}
            register={register}
            errors={errors}
          />
        </Col>
      </ShowOnCondition>
    </ShowOnCondition>
  )
}

export default PodResidenceSelfDeclaration