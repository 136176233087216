import { useMemo } from "react"

import WithSkeleton from "../../WithSkeleton/WithSkeleton.component"
import HeaterAttachmentsForm from "./HeaterAttachmentsForm.component"
import { useAppSelector } from "../../../redux/hooks"
import { useFetchAttachmentsQuery } from "../../../redux/contract/attachment/attachment.api"
import { useSelectContractId, useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { selectContractProposalData } from "../../../redux/contract/contract.selectors"

const HeaterAttachmentsFormWithSpinner = WithSkeleton(HeaterAttachmentsForm)

const AttachmentsFormContainer = () => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()

  //Select contract data with permissions
  const selectCustomerData = useMemo(() => selectContractProposalData({ entityName, contractId }), [entityName, contractId])
  const { data: customerData } = useAppSelector(selectCustomerData)

  const {
    data: attachmentsData,
    isFetching: isFetchingAttachmentsData,
    isLoading: isLoadingAttachmentsData,
  } = useFetchAttachmentsQuery({ entityName, contractId })
  const fetchingAttachmentsData = isFetchingAttachmentsData || isLoadingAttachmentsData

  const isLoading = fetchingAttachmentsData
  
  return (
    <HeaterAttachmentsFormWithSpinner
      isLoading={isLoading}
      permissions={customerData?.links}
      entityName={entityName}
      contractId={contractId}
      attachmentsData={attachmentsData}
    />
  )
}

export default AttachmentsFormContainer