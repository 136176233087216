
export const getIconByStatus = (status: string) => {
    switch (status) {
        case 'failure':
            return 'circle-xmark';
        case 'warning':
            return 'circle-exclamation'
        case 'success': 
            return 'circle-check'
        case 'running': 
            return 'spinner-third'
        default:
            return 'circle-question'
    }
}

export const defaultDescription = (status: string) => {
    switch (status) {
        case 'success': 
            return 'Importazione avvenuta con successo.'
        case 'running': 
            return 'Importazione in corso.'
        default:
            return 'Nessun dettaglio.'
    }
}