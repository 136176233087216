import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FieldValues, useFormContext } from "react-hook-form"
import { Button, Col, Row } from "reactstrap"

import AttachmentsDropzone from "../../../AttachmentsDropzone/AttachmentsDropzone.component"
import ShowOnCondition from "../../../auth/ShowOnCondition.component"
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import { errorFormLabels } from "../../utils/formLabels"
import { calculateDaysBeforeNow } from "../utils"

type PaperContractDropzoneProps = {
  entityName: string
}

const PaperContractDropzone = ({
  entityName
}: PaperContractDropzoneProps) => {
  const { register, control, watch, setValue, resetField, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="file" className="me-2" />
        Carica il contratto firmato<sup>*</sup>
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={12}>
            <AttachmentsDropzone
              entityName={entityName}
              addFile={(data: FieldValues) => {
                //Clears dropzone fields errors before updating with new data
                resetField("paperContract.userFileName")
                setValue("paperContract", data)
              }}
              isMulti={false}
              acceptedFiles={{ "application/pdf": [] }}
            />
            <InputWrapper
              type="text"
              className="form-control"
              label="File"
              name="paperContract.userFileName"
              readOnly
              register={register}
              errors={errors}
              rules={{ required: errorFormLabels.REQUIRED }}
            />
            <ShowOnCondition showWhen={formWatcher.paperContract?.size}>
              <InputWrapper 
                name="paperContract.size"
                type="text"
                readOnly
                label="Dimensione"
                className="form-control"
                register={register}
              />
              <Button
                color="danger"
                className="mb-2"
                onClick={() => {
                  //Reset dropzone file fields
                  setValue("paperContract.userFileName", null)
                  setValue("paperContract.size", null)
                  setValue("paperContract", null)
                }}
              >
                <FontAwesomeIcon icon="xmark" className="me-2" />
                Rimuovi
              </Button>
            </ShowOnCondition>
          </Col>
          <Col md={12}>
            <DatePickerWrapper
              name="signingDate"
              control={control}
              label="Data firma"
              className="form-control"
              errors={errors}
              showMonthDropdown
              dateFormat="dd/MM/yyyy"
              maxDate={new Date()}
              minDate={calculateDaysBeforeNow(60)}
              rules={{ required: errorFormLabels.REQUIRED }}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default PaperContractDropzone