import { useSelectContractId, useSelectEntityName } from "../../pages/providers/ContractsPageProvider.provider"
import { useFetchContractPdfUrlQuery } from "../../redux/contract/contract.api"
import WithSpinner from "../WithSpinner/WithSpinner.component"
import SignedContractDashboard from "./SignedContractDashboard.component"

const SignedContractDashboardWithSpinner = WithSpinner(SignedContractDashboard)

const SignedContractDashboardContainer = () => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()

  const { 
    data,
    isFetching,
    isLoading,
   } = useFetchContractPdfUrlQuery({ contractId, entityName })
   const fetchingPdfUrl = isFetching || isLoading

  return (
    <SignedContractDashboardWithSpinner 
      isLoading={fetchingPdfUrl}
      title="Operazione completata!"
      message="Grazie per averci scelto, di seguito il link per visualizzare il contratto in formato PDF."
      buttonLabel="Vedi pdf"
      icon="file-pdf"
      contractPdfUrl={data}
    />
  )
}

export default SignedContractDashboardContainer