import { FieldValues } from "react-hook-form";
import { setDefaultDate } from "../utils/utils";

export function setDefaultFormData(cadastralDetailData?: FieldValues) {
  return cadastralDetailData ? ({
    ...cadastralDetailData,
    rentRegisteredDate: setDefaultDate(cadastralDetailData.rentRegisteredDate),
    authBirthDate: setDefaultDate(cadastralDetailData.authBirthDate),
    authRentRegisteredDate: setDefaultDate(cadastralDetailData.authRentRegisteredDate),
  }) : ({})
}