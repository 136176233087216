import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"
import ShowOnCondition from "../../../auth/ShowOnCondition.component"

import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { maxLengthLabel } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { useUpdateProductDescription } from "../hooks/useUpdateProductDescription.hooks"

const AirConditionerData = ({
  canEdit,
  fieldsValues
}: FormProps) => {
  const { register, control, watch } = useFormContext()
  const formWatcher = watch()  

  //Update text fields on change
  useUpdateProductDescription({
    fieldToWatch: "airConditionerType1",
    fieldToPasteTo: "modelDescription1"
  })

  //Update text fields on change
  useUpdateProductDescription({
    fieldToWatch: "airConditionerType2",
    fieldToPasteTo: "modelDescription2"
  })

  //Update text fields on change
  useUpdateProductDescription({
    fieldToWatch: "airConditionerType3",
    fieldToPasteTo: "modelDescription3"
  })

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="id-card" className="me-2" />
        Dati climatizzatore
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={4}>
            <InputWrapper
              name="airConditionerType1"
              label="Modello climatizzatore 1"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              rules={{ 
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                } 
              }}
            />
          </Col>
          <Col md={4}>
            <InputWrapper
              name="heatedUsableArea1"
              label="Superficie utile m2 raffreddata/riscaldata 1"
              type="number"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
            />
          </Col>
          <Col md={4}>
            <SelectWrapper
              name="replacedGenerator1"
              control={control}
              register={register}
              label="Generatore sostituito 1"
              isDisabled={canEdit? false : true}
              options={fieldsValues?.replacedGenerator || []}
            />
          </Col>
          <ShowOnCondition showWhen={parseInt(formWatcher.replacedGenerator1?.value) === 1}>
            <Col md={12}>
              <InputWrapper
                name="otherReplacedGenerator1"
                label="Altro generatore sostituito 1"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                rules={{ 
                  maxLength: {
                    value: 80,
                    message: maxLengthLabel(80)
                  } 
                }}
              />
            </Col>
          </ShowOnCondition>
          <Col md={4}>
            <InputWrapper
              name="airConditionerType2"
              label="Modello climatizzatore 2"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              rules={{ 
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                } 
              }}
            />
          </Col>
          <Col md={4}>
            <InputWrapper
              name="heatedUsableArea2"
              label="Superficie utile m2 raffreddata/riscaldata 2"
              type="number"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
            />
          </Col>
          <Col md={4}>
            <SelectWrapper
              name="replacedGenerator2"
              control={control}
              register={register}
              label="Generatore sostituito 2"
              isDisabled={canEdit? false : true}
              options={fieldsValues?.replacedGenerator || []}
            />
          </Col>
          <ShowOnCondition showWhen={parseInt(formWatcher.replacedGenerator2?.value) === 1}>
            <Col md={12}>
              <InputWrapper
                name="otherReplacedGenerator2"
                label="Altro generatore sostituito 2"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                rules={{ 
                  maxLength: {
                    value: 80,
                    message: maxLengthLabel(80)
                  } 
                }}
              />
            </Col>
          </ShowOnCondition>
          <Col md={4}>
            <InputWrapper
              name="airConditionerType3"
              label="Modello climatizzatore 3"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              rules={{ 
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                } 
              }}
            />
          </Col>
          <Col md={4}>
            <InputWrapper
              name="heatedUsableArea3"
              label="Superficie utile m2 raffreddata/riscaldata 3"
              type="number"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
            />
          </Col>
          <Col md={4}>
            <SelectWrapper
              name="replacedGenerator3"
              control={control}
              register={register}
              label="Generatore sostituito 3"
              isDisabled={canEdit? false : true}
              options={fieldsValues?.replacedGenerator || []}
            />
          </Col>
          <ShowOnCondition showWhen={parseInt(formWatcher.replacedGenerator3?.value) === 1}>
            <Col md={12}>
              <InputWrapper
                name="otherReplacedGenerator3"
                label="Altro generatore sostituito 3"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                rules={{ 
                  maxLength: {
                    value: 80,
                    message: maxLengthLabel(80)
                  } 
                }}
              />
            </Col>
          </ShowOnCondition>
        </Row>
      </div>
    </div>
  )
}

export default AirConditionerData