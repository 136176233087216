import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"

import AsyncSelectWrapper from "../../inputFields/AsyncSelect/AsyncSelectWrapper.component"
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { contractApi } from "../../../../redux/contract/contract.api"
import { errorFormLabels, maxLengthLabel } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { asyncFnWrapper } from "../../utils/utils"

const DurationTerms = ({ canEdit }: FormProps) => {
  const { control, register, setValue, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  const [fetchTowns] = contractApi.endpoints.fetchTowns.useLazyQuerySubscription()
  const [fetchPostalCodes] = contractApi.endpoints.fetchPostalCodes.useLazyQuerySubscription()

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="timer" className="me-2" />
        Durata della somministrazione
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={4}>
            <DatePickerWrapper
              name="registrationDate"
              control={control}
              label="Data stipula"
              className="form-control"
              errors={errors}
              showMonthDropdown
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              rules={{
                required: errorFormLabels.REQUIRED,
              }}
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={4}>
            <DatePickerWrapper
              name="startDate"
              control={control}
              label="Data inizio"
              className="form-control"
              errors={errors}
              showMonthDropdown
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              rules={{
                required: errorFormLabels.REQUIRED,
              }}
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={4}>
            <InputWrapper
              name="expectedDuration"
              label="Durata prevista"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="startingTime"
              label="Orario d'inizio"
              type="time"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="endingTime"
              label="Orario di fine"
              type="time"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={10}>
            <InputWrapper
              name="workLocationAddress"
              label="Indirizzo sede di lavoro"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 128,
                  message: maxLengthLabel(128)
                }
              }}
            />
          </Col>
          <Col md={2}>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={canEdit ? false : true}
              name="workLocationStreetNo"
              label="Civico"
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: 4,
              }}
              register={register}
              errors={errors}
            />
          </Col>
          <Col md={8}>
            <AsyncSelectWrapper
              name="workLocationTownName"
              control={control}
              register={register}
              label="Comune"
              errors={errors}
              isDisabled={canEdit ? false : true}
              loadOptions={(input: string) => {
                if(input.length > 2) {
                  return asyncFnWrapper(input, fetchTowns)
                }
              }}
              getOptionLabel={option => `${option.town} (${option.province})`}
              getOptionValue={option => `${option.town} (${option.province})`}
              rules={{ required: errorFormLabels.REQUIRED }}
              whenFieldChanges={option => {
                setValue("workLocationPostalCode", null)
                setValue("workLocationProvince", option.province)
              }}
              noOptionsMessage={() => 
                <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
              }
            />
          </Col>
          <Col md={2}>
            <InputWrapper
              name="workLocationProvince"
              label="Provincia nascita"
              type="text"
              register={register}
              className="form-control"
              readOnly
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={2}>
            <SelectWrapper
              name="workLocationPostalCode"
              control={control}
              register={register}
              label="CAP"
              isDisabled={canEdit? false : true}
              errors={errors}
              rules={{ required: errorFormLabels.REQUIRED }}
              watchedFieldProps={{
                watch: watch,
                watchedFieldName: "workLocationTownName",
                triggerWatchFn: fetchPostalCodes,
                returnedValuePropertyName: "town",
                defaultWatchedFieldValue: formWatcher.workLocationTownName || null,
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default DurationTerms