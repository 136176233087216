import { FieldValues } from "react-hook-form"
import { UserRoles } from "../../../../redux/auth/auth.utils"

export function isEneltelNeeded(entityName: string) {
  return (
    entityName === 'takeover' ||
    entityName === 'newActivation'
  ) ? true : false
}

export function isEneltelRequired(entityName: string, values: FieldValues) {
  return (
    isEneltelNeeded(entityName) &&
    (values?.no || entityName === 'newConnection')
  ) ? false : true
}

export function isPodNoRequired(entityName: string, values: FieldValues) {
  return (
    values?.eneltel || entityName === 'newConnection'
  ) ? false : true
}

export function isRequestTypeNeeded(entityName: string) {
  return (
    entityName === 'takeover' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function getRequestTypeLabel(entityName: string) {
  switch(entityName) {
    case 'newActivation': 
      return {
        title: "In riferimento al contratto stipulato con ABenergie fa richiesta di nuova attivazione:",
        label: "Condizioni nuova attivazione*"
      }

    case 'newConnection':
      return {
        title: "In riferimento al contratto stipulato con ABenergie fa richiesta di nuova connessione:",
        label: "Condizioni nuova connessione*"
      }

    default:
      return {
        title: "In riferimento al contratto stipulato con ABenergie fa richiesta di subentro:",
        label: "Condizioni di subentro*"
      }
  }
}

export function isConnectionVoltageIdNeeded(entityName: string, values: FieldValues) {
  if(
      (
        entityName === "takeover" ||
        entityName === "newActivation"
      ) && parseInt(values.requestType?.value) !== 2
  ) {
    return false
  }
  return true
}

export function isServiceStartDateNeeded(entityName: string) {
  return (
    entityName === 'newConnection'
  ) ? true : false
}

export function isOriginMarketNeeded(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isCurrentSupplierNeeded(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isAnnualConsumptionNeeded(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isNoDisabled(
  entityName: string, 
  userRole: string, 
  values: Record<string, unknown>, 
  canEdit: boolean
) {
  if(
      (
        entityName === 'takeover' || 
        entityName === 'newActivation' ||
        entityName === 'newConnection'
      ) && 
      userRole !== UserRoles.USER_ROLE_VERIFIER
  ) {    
    return (canEdit ? (values?.eneltel ? true : false) : true)
  }
  else {
    return (canEdit ? false : true)
  }
}

export function isEneltelDisabled(
  userRole: string, 
  values: Record<string, unknown>, 
  canEdit: boolean
) {
  if(userRole !== UserRoles.USER_ROLE_VERIFIER) {
    return (canEdit ? (values?.no ? true : false) :  true)
  }
  else {
    return (canEdit ? false : true)
  }
}