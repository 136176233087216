import moment from "moment"
import { ListGroup, ListGroupItem } from "reactstrap"

import { ReportType } from "../../../redux/contract/contract.types"
import ChatListStyle from "./style"

type ChatListProps = {
  reports: ReportType[]
}

const ChatList = ({
  reports
}: ChatListProps) => {
  return (
    <ChatListStyle>
      {reports && reports.length ?
        <ListGroup>
          {reports.map((communication, i: number) => 
            <ListGroupItem key={i}>
              <p>
                Messaggio inviato il <strong>{moment.utc(communication.sendingDate).tz('Europe/Rome').format('DD-MM-YYYY HH:mm') }</strong>
                <br />
                da <strong>{communication.sender}</strong>
              </p>
              <blockquote>
                "{communication.message}"
              </blockquote>
            </ListGroupItem>
          )}
        </ListGroup>
      :
        <span>Nessuna comunicazione presente</span>
      }
    </ChatListStyle>
  )
}

export default ChatList