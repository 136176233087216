import { FieldValues } from "react-hook-form"

import WithSkeleton from "../../WithSkeleton/WithSkeleton.component"
import CustomerForm from "./CustomerForm.component"

type CustomerFormContainerProps = {
  fetchingCustomerData?: boolean
  customerData?: FieldValues
  fieldsValues: FieldValues
}

const CustomerFormWithSpinner = WithSkeleton(CustomerForm)

const CustomerFormContainer = ({ 
  fetchingCustomerData = false,
  customerData,
  fieldsValues
}: CustomerFormContainerProps) => {
  return (
    <CustomerFormWithSpinner
      customerData={customerData}
      customerFieldsValues={fieldsValues || {}}
      isLoading={fetchingCustomerData}
    />
  )
}

export default CustomerFormContainer