import { fetchData, getAPIUrl, mutateData } from "../api/utils";

export enum ContractTabsMap {
  PROPOSAL_STEP = 1,
  PAYMENT_STEP = 2,
  CADASTRAL_STEP = 3,
  SUPPLY_POINTS_STEP = 3,
  ATTACHMENTS_STEP = 5,
  DOWNLOAD_CONTRACT_PDF_STEP = 6,
}

export enum HeaterTabsMap {
  CUSTOMER_DATA_STEP = 1,
  BANK_ACCOUNT_STEP = 2,
  TECHNICAL_DATA_STEP = 3,
  PRODUCT_STEP = 4,
  ATTACHMENTS_STEP = 5,
  DOWNLOAD_CONTRACT_PDF_STEP = 6,
}

export enum JobTabsMap {
  PROPOSAL_STEP = 1,
  DOWNLOAD_CONTRACT_PDF_STEP = 6,
}

export enum ListsTabsMap {
  PROPOSAL_TAB = 1,
  POD_TAB = 2,
  PDR_TAB = 3,
}

export enum ContractStatusMap {
  ONGOING_STATUS = 10,
  TO_COMPLETE_STATUS = 15,
  DONE_STATUS = 20,
  WAITING_SIGNATURE_STATUS = 30,
  SIGNED_STATUS = 40,
  DUPLICATE_STATUS = 50,
  KO_STATUS = 60,
}

export enum ContractCategories {
  NEW_CUSTOMER = "newCustomer",
  ONGOING_CUSTOMER = "ongoingCustomer",
  OTHER = "other",
}

export enum ContractTypes {
  CAMBIO_FORNITORE = "changeSupplier",
  VOLTURA = "transfer",
  SUBENTRO = "takeover",
  NUOVA_ATTIVAZIONE = "newActivation",
  NUOVA_CONNESSIONE = "newConnection",
  VOLTURA_CONTESTUALE = "contextualTransfer"
}

export enum ElectricUseTypes {
  Domestico = "1",
  AltriUsi = "2",
  LuciScale = "3",
}

export enum GasUseTypes {
  Domestico = "1",
  Condominio = "2",
  CommercioEServizi = "3",
  Industria = "4",
  GeneratoreElettricita = "5",
  Autotrazione = "6",
  PubblicaAmministrazione = "7",
  CondominioDomestico = "8",
}

export enum VatTypes {
  VENTIDUE = "3",
  DIECI = "2",
  NAZIONALI = "1",
}

export enum GasExcicesClasses {
  No = 13,
  Other = 999,
}

export const getGasExceiseClass = (value: string) => {
  if(value === "" || typeof value === "undefined" || value === null)
    return ""

  return value.toString() === GasExcicesClasses.No.toString()
    ? GasExcicesClasses.No.toString()
    : GasExcicesClasses.Other.toString();
}


export type ContractStep = {
  hasProposal: boolean;
  hasPaymentMode: boolean;
  hasCadastralData: boolean;
  hasPdr: boolean;
  hasPod: boolean;
  hasAttachments?: boolean;
};

export type HeaterStep = {
  hasCustomer: boolean;
  hasBankAccount: boolean;
  hasTechData: boolean;
  hasProduct: boolean;
  hasAttachments: boolean;
};

export type JobStep = {
  hasCustomer: boolean;
};

export function getFormStep(
  contractStep: ContractStep | HeaterStep | JobStep,
  entityName: string
) {
  if (isNewCustomerContract(entityName)) {
    const {
      hasAttachments,
      hasPod,
      hasPdr,
      hasCadastralData,
      hasPaymentMode,
      hasProposal,
    } = contractStep as ContractStep;
    if (hasAttachments) return ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP;
    if (hasPod || hasPdr) return ContractTabsMap.ATTACHMENTS_STEP;
    if (hasCadastralData) return ContractTabsMap.SUPPLY_POINTS_STEP;
    if (hasPaymentMode) return ContractTabsMap.CADASTRAL_STEP;
    if (hasProposal) return ContractTabsMap.PAYMENT_STEP;
    return ContractTabsMap.PROPOSAL_STEP;
  } else if (isOngoingCustomerContract(entityName)) {
    const { hasAttachments, hasPod, hasPdr, hasCadastralData, hasProposal } =
      contractStep as ContractStep;
    if (hasAttachments) return ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP;
    if (hasPod || hasPdr) return ContractTabsMap.ATTACHMENTS_STEP;
    if (hasCadastralData) return ContractTabsMap.SUPPLY_POINTS_STEP;
    if (hasProposal) return ContractTabsMap.CADASTRAL_STEP;
    return ContractTabsMap.PROPOSAL_STEP;
  } else if (entityName === "heater") {
    const {
      hasCustomer,
      hasBankAccount,
      hasTechData,
      hasProduct,
      hasAttachments,
    } = contractStep as HeaterStep;
    if (hasAttachments) return HeaterTabsMap.DOWNLOAD_CONTRACT_PDF_STEP;
    if (hasProduct) return HeaterTabsMap.ATTACHMENTS_STEP;
    if (hasTechData) return HeaterTabsMap.PRODUCT_STEP;
    if (hasBankAccount) return HeaterTabsMap.TECHNICAL_DATA_STEP;
    if (hasCustomer) return HeaterTabsMap.BANK_ACCOUNT_STEP;
    return HeaterTabsMap.CUSTOMER_DATA_STEP;
  } else {
    const { hasCustomer } = contractStep as JobStep;
    if (hasCustomer) return ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP;
    return JobTabsMap.PROPOSAL_STEP;
  }
}

export function getContractStatusIcon(supplyPointStatusId: number) {
  switch (supplyPointStatusId) {
    case 20:
      return "signature";

    case 30:
      return "gears";

    case 40:
      return "arrows-rotate";

    case 50:
      return "hand";

    case 60:
      return "circle-half-stroke";

    case 70:
      return "circle-xmark";

    default:
      return "door-open";
  }
}

export function getSupplyPointStatusIcon(supplyPointStatusId: number) {
  switch (supplyPointStatusId) {
    case 20:
      return "gears";

    case 30:
      return "circle-check";

    case 40:
      return "hand";

    case 50:
      return "arrows-rotate";

    case 60:
      return "circle-xmark";

    default:
      return "signature";
  }
}

export function getItemStatusLabel(supplyPointStatusId: number) {
  switch (supplyPointStatusId) {
    case 20:
      return "In lavorazione";

    case 30:
      return "Validato";

    case 40:
      return "Bloccato";

    case 50:
      return "Sincronizzato";

    case 60:
      return "K.O.";

    default:
      return "Inserito";
  }
}

export function getCategoryLabel(value: ContractCategories) {
  switch (value) {
    case ContractCategories.NEW_CUSTOMER:
      return "Nuovi clienti";
    case ContractCategories.ONGOING_CUSTOMER:
      return "Clienti attivi";
    case ContractCategories.OTHER:
      return "Altre operazioni";
  }
}

export function getCategoryPath(value: ContractCategories) {
  switch (value) {
    case ContractCategories.NEW_CUSTOMER:
      return "nuovi-clienti/";
    case ContractCategories.ONGOING_CUSTOMER:
      return "clienti-attivi/";
    case ContractCategories.OTHER:
      return "altre-operazioni/";
  }
}

export function getEntityLabelFromPath(pathName: string | undefined) {
  switch (pathName) {
    case "cambio-fornitore":
      return "Cambio fornitore";

    case "voltura":
      return "Voltura";

    case "subentro":
      return "Subentro";

    case "nuova-connessione":
      return "Nuova connessione";

    case "nuova-attivazione":
      return "Nuova attivazione";

    case "voltura-contestuale":
      return "voltura ncontestuale";

    case "disattivazione":
      return "Disattivazione";

    case "modifiche-contrattuali":
      return "Modifiche contrattuali";

    case "cambio-listino":
      return "Cambio listino";

    case "verifica-impianti":
      return "Verifica impianti";

    case "spostamento-gruppi-di-misura":
      return "Spostamento gruppi di misura";

    case "caldaia":
      return "Caldaia";

    case "condizionatore":
      return "Condizionatore";

    case "contratto-determinato":
      return "CDL determinato";

    case "contratto-indeterminato":
      return "CDL indeterminato";

    case "utenti":
      return "Utenti";

    default:
      return "all";
  }
}

export const getContractTypeLabel = (entityName: string) => {
  switch (entityName) {
    case "changeSupplier":
      return "Cambio fornitore";

    case "transfer":
      return "Voltura";

    case "takeover":
      return "Subentro";
      
    case "newConnection":
      return "Nuova connessione";

    case "newActivation":
      return "Nuova attivazione";

    case "contextualTransfer":
      return "voltura ncontestuale";

    case "deactivation":
      return "Disattivazione";

    case "contractualChanges":
      return "Modifiche contrattuali";

    case "priceListSwitch":
      return "Cambio listino";

    case "plantVerify":
      return "Verifica impianti";

    case "measureGroupShift":
      return "Spostamento gruppi di misura";

    case "heater":
      return "Caldaia";

    case "airConditioner":
      return "Condizionatore";

    case "fixedTermContract":
      return "CDL determinato";

    case "permanentContract":
      return "CDL indeterminato";

    default:
      return "";
  }
};

export function getEntityUriName(entityName: string) {
  switch (entityName) {
    case "changeSupplier":
      return "change-suppliers";

    case "transfer":
      return "transfers";

    case "takeover":
      return "takeovers";
      
    case "newConnection":
      return "new-connections";

    case "newActivation":
      return "new-activations";

    case "contextualTransfer":
      return "contextual-transfers";

    case "deactivation":
      return "deactivations";

    case "contractualChanges":
      return "contractual-changes";

    case "priceListSwitch":
      return "price-list-switches";

    case "plantVerify":
      return "plant-verifies";

    case "measureGroupShift":
      return "measure-group-shift";

    case "heater":
      return "heaters";

    case "airConditioner":
      return "air-conditioners";

    case "fixedTermContract":
      return "fixed-term-contracts";

    case "permanentContract":
      return "permanent-contracts";

    case "user":
      return "users";

    default:
      return "contracts";
  }
}

export function getEntityNameFromPath(pathName: string | undefined) {
  switch (pathName) {
    case "cambio-fornitore":
      return "changeSupplier";

    case "voltura":
      return "transfer";

    case "subentro":
      return "takeover";
            
    case "nuova-connessione":
      return "newConnection";

    case "nuova-attivazione":
      return "newActivation";

    case "voltura-contestuale":
      return "contextualTransfer";

    case "disattivazione":
      return "deactivation";

    case "modifiche-contrattuali":
      return "contractualChanges";

    case "cambio-listino":
      return "priceListSwitch";

    case "verifica-impianti":
      return "plantVerify";

    case "spostamento-gruppi-di-misura":
      return "measureGroupShift";

    case "caldaia":
      return "heater";

    case "condizionatore":
      return "airConditioner";

    case "contratto-determinato":
      return "fixedTermContract";

    case "contratto-indeterminato":
      return "permanentContract";

    case "utenti":
      return "user";

    default:
      return "all";
  }
}

export function getEntityPath(entityName: string) {
  switch (entityName) {
    case "changeSupplier":
      return `${getCategoryPath(
        ContractCategories.NEW_CUSTOMER
      )}cambio-fornitore`;

    case "transfer":
      return `${getCategoryPath(ContractCategories.NEW_CUSTOMER)}voltura`;

    case "takeover":
      return `${getCategoryPath(ContractCategories.NEW_CUSTOMER)}subentro`;

    case "newConnection":
      return `${getCategoryPath(ContractCategories.NEW_CUSTOMER)}nuova-connessione`;

    case "newActivation":
      return `${getCategoryPath(ContractCategories.NEW_CUSTOMER)}nuova-attivazione`;

    case "contextualTransfer":
      return `${getCategoryPath(ContractCategories.NEW_CUSTOMER)}voltura-contestuale`;

    case "deactivation":
      return `${getCategoryPath(
        ContractCategories.ONGOING_CUSTOMER
      )}disattivazione`;

    case "contractualChanges":
      return `${getCategoryPath(
        ContractCategories.ONGOING_CUSTOMER
      )}modifiche-contrattuali`;

    case "priceListSwitch":
      return `${getCategoryPath(
        ContractCategories.ONGOING_CUSTOMER
      )}cambio-listino`;

    case "plantVerify":
      return `${getCategoryPath(
        ContractCategories.ONGOING_CUSTOMER
      )}verifica-impianti`;

    case "measureGroupShift":
      return `${getCategoryPath(
        ContractCategories.ONGOING_CUSTOMER
      )}spostamento-gruppi-di-misura`;

    case "heater":
      return `${getCategoryPath(ContractCategories.OTHER)}caldaia`;

    case "airConditioner":
      return `${getCategoryPath(ContractCategories.OTHER)}condizionatore`;

    case "fixedTermContract":
      return `${getCategoryPath(
        ContractCategories.OTHER
      )}contratto-determinato`;

    case "permanentContract":
      return `${getCategoryPath(
        ContractCategories.OTHER
      )}contratto-indeterminato`;

    case "user":
      return "utenti";

    default:
      return "contratti";
  }
}

export const getEntityPluralName = (entityName: string) => {
  switch (entityName) {
    case "changeSupplier":
      return "changeSupplierAll";

    case "transfer":
      return "transferAll";

    case "takeover":
      return "takeoverAll";

    case "newConnection":
      return "newConnectionAll";

    case "newActivation":
      return "newActivationAll";

    case "contextualTransfer":
      return "contextualTransferAll";

    case "deactivation":
      return "deactivationAll";

    case "contractualChanges":
      return "contractualChangesAll";

    case "priceListSwitch":
      return "priceListSwitchAll";

    case "plantVerify":
      return "plantVerifyAll";

    case "measureGroupShift":
      return "measureGroupShiftAll";

    case "heater":
      return "heaterAll";

    case "airConditioner":
      return "airConditionerAll";

    case "fixedTermContract":
      return "fixedTermContractAll";

    case "permanentContract":
      return "permanentContractAll";

    case "user":
      return "userAll";

    default:
      return "";
  }
};

export function isOngoingCustomerContract(entityName: string) {
  return entityName === "deactivation" ||
    entityName === "priceListSwitch" ||
    entityName === "plantVerify" ||
    entityName === "measureGroupShift" ||
    entityName === "contractualChanges" ||
    entityName === "measureGroupShift" ||
    entityName === "plantVerify" ||
    entityName === "audaxChangeSupplier"
    ? true
    : false;
}

export function isNewCustomerContract(entityName: string) {
  return entityName === "changeSupplier" ||
    entityName === "transfer" ||
    entityName === "takeover" ||
    entityName === "newActivation" ||
    entityName === "newConnection" ||
    entityName === "contextualTransfer"
    ? true
    : false;
}

export const getUserScoreLabel = (userPassed: boolean | null) => {
  switch (userPassed) {
    case true:
      return "Cliente affidabile";

    case false:
      return "Cliente non affidabile";

    default:
      return "Nessuna informazione sul cliente";
  }
};

export function handleError(err: unknown) {
  let status = 500;
  let data = "";
  if (typeof err === "string") {
    data = err;
  }
  if (err instanceof Error) {
    data = err.message;
  }

  return {
    error: {
      status: status,
      data: data,
    },
  };
}

type GetSupplyPointsParams = {
  contractId: string;
  entityName: string;
  type?: string;
  accessToken: string;
};
export function getSupplyPointsByClientCode({
  contractId,
  entityName,
  type,
  accessToken,
}: GetSupplyPointsParams) {
  return fetchData(
    `${getAPIUrl()}/${getEntityUriName(
      entityName
    )}/${type}/customers/${contractId}`,
    accessToken
  ).then((response) => response);
}

export function getActiveUserValidateBlockAll({
  contractId,
  entityName,
  accessToken,
}: GetSupplyPointsParams) {
  return fetchData(
    `${getAPIUrl()}/${getEntityUriName(
      entityName
    )}/supply-points/${contractId}/validate-block-all`,
    accessToken
  ).then((response) => response);
}

export function activeSupplyPointsFirst(supplyPoints: Record<string, any>[]) {
  let activeSupplyPoints: Record<string, any> = [];
  let otherSupplyPoints: Record<string, any> = [];
  supplyPoints.forEach((item) => {
    if (item.statusId >= 1) {
      activeSupplyPoints.push(item);
    } else {
      otherSupplyPoints.push(item);
    }
  });
  return activeSupplyPoints.concat(otherSupplyPoints);
}

export function uploadPaperContract(
  values: any,
  contractId: string,
  entityName: string,
  accessToken: string
) {
  return mutateData(
    `${getAPIUrl()}/${getEntityUriName(
      entityName
    )}/${contractId}/paper-contract`,
    accessToken,
    values,
    "POST"
  ).then((response) => response);
}
