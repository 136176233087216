import { useSelector } from 'react-redux'
import { Navbar } from 'reactstrap'
import { NavLink } from 'react-router-dom'

import HeaderStyle from "./style"
import { ReactComponent as Logo } from '../../assets/svg/emvelop-logo-white.svg'
import { selectCurrentUser } from '../../redux/auth/auth.selectors'
import NavMenu from '../NavMenu/NavMenu.component'

const Header = () => {
  const userLoggedIn = useSelector(selectCurrentUser)

  return (
    <HeaderStyle id="navigation-header">
      <Navbar className="align-items-center" dark expand="lg" container={true}>
        <NavLink className="navbar-brand" end to="/">
          <Logo />
        </NavLink>
        {userLoggedIn &&
          <NavMenu />
        }
      </Navbar>
    </HeaderStyle>
  )
}

export default Header