import { FieldValues } from "react-hook-form"
import { api } from "../../api"
import { getAPIUrl } from "../../api/utils"
import { CadasdralDataListType } from "../contract.types"
import { getEntityUriName } from "../contract.utils"

export const cadastralDataApi = api.injectEndpoints({
  endpoints: build => ({
    fetchCadastralData: build.query<CadasdralDataListType, any>({
      query: ({ entityName, contractId }: { entityName: string, contractId: string }) => ({
        url: `${getAPIUrl()}/${getEntityUriName(entityName)}/cadastral-data?contractId=${contractId}`,
      }),
      transformResponse: (response: CadasdralDataListType, meta, arg) => response,
      providesTags: (result, error, args) => result?.items ? [
        ...result!.items.map((item) => ({ type: "CadastralData", id: item.id } as const)),
        { type: "CadastralData" as const, id: "LIST"}
      ] : [],
    }),
    fetchCadastralItem: build.query({
      query: ({ entityName, contractId, itemId }) => ({
        url: `${getAPIUrl()}/${getEntityUriName(entityName)}/cadastral-data/${itemId}?contractId=${contractId}`
      }),
      providesTags: ({ id }, error, arg) => [{ type: "CadastralData", id }]
    }),
    submitCadastralItem: build.mutation({
      query: ({ values, entityName }: { values: FieldValues, entityName: string }) => ({
        url: `${getAPIUrl()}/${getEntityUriName(entityName)}/cadastral-data${values.id ? `/${values.id}` : ''}`,
        method: values.id ? "PUT" : "POST",
        body: values
      }),
      invalidatesTags: (result, error, args) => [
        { type: "Contract" },
        { type: "CadastralData", id: "LIST"}
      ],
    }),
    deleteCadastralItem: build.mutation({
      query: (apiUrl) => ({
        url: apiUrl,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { itemId }) => [
        { type: "Contract" },
        { type: "CadastralData" }
      ],
    }),
    blockAllPdp: build.mutation({
      query: ({ entityName, contractId }) => ({
        url: `${getAPIUrl()}/${getEntityUriName(entityName)}/${contractId}?supplyPointStatus=4`,
        method: "PATCH"
      }),
      invalidatesTags: (result, error, { itemId }) => [
        { type: "Contract" },
        { type: "CadastralData", id: "LIST" },
        { type: "Pod", id: "LIST" },
        { type: "Pdr", id: "LIST" }, 
      ],
    }),
    validateAllPdp: build.mutation({
      query: ({ entityName, contractId }) => ({
        url: `${getAPIUrl()}/${getEntityUriName(entityName)}/${contractId}?supplyPointStatus=3`,
        method: "PATCH"
      }),
      invalidatesTags: (result, error, { itemId }) => [
        { type: "Contract" },
        { type: "CadastralData", id: "LIST" },
        { type: "Pod", id: "LIST" },
        { type: "Pdr", id: "LIST" },
      ],
    })
  })
})

export const {
  useFetchCadastralDataQuery,
  useLazyFetchCadastralItemQuery,
  useSubmitCadastralItemMutation,
  useDeleteCadastralItemMutation,
  useBlockAllPdpMutation,
  useValidateAllPdpMutation,
} = cadastralDataApi