import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"

import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import MoneyInputWrapper from "../../inputFields/MoneyInput/MoneyInputWrapper.component"
import TextAreaWrapper from "../../inputFields/TextArea/TextAreaWrapper.component"
import { errorFormLabels, maxLengthLabel } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { useUpdateTotalPrice } from "../hooks/useUpdateTotalPrice.hook"

const HeaterData = ({
  canEdit,
  customerType
}: FormProps) => {
  const { register, control, setValue, formState: { errors } } = useFormContext()
  useUpdateTotalPrice({
    control,
    setValue,
    customerType,
    fieldsToWatch: [
      "heaterLayingPrice", 
      "thermostatPrice", 
      "valveLayingPrice", 
      "accessoriesPrice"
    ]
  })

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="id-card" className="me-2" />
        Dati caldaia
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={6}>
            <InputWrapper
              name="ticket"
              label="Ticket piattaforma XRoad"
              type="text"
              errors={errors}
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              rules={{ 
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                } 
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="heaterName"
              label="Nome prodotto caldaia"
              type="text"
              errors={errors}
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                } 
              }}
            />
          </Col>
          <Col md={12}>
            <TextAreaWrapper
              name="heaterLayingDescription"
              label="Descrizione fornitura posa caldaia"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED, 
                maxLength: {
                  value: 512,
                  message: maxLengthLabel(512)
                } 
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="heaterLayingAmount"
              label="Quantità fornitura posa caldaia"
              type="number"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              rules={{ 
                required: errorFormLabels.REQUIRED,
                min: {
                  value: 0,
                  message: errorFormLabels.NOT_NULL
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                } 
              }}
              errors={errors}
            />
          </Col>
          <Col md={6}>
            <MoneyInputWrapper
              name="heaterLayingPrice"
              label="Totale fornitura posa caldaia (IVA esclusa)"
              type="number"
              step=".01"
              register={register}
              errors={errors}
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
                required: errorFormLabels.REQUIRED
              }}
              currency="€"
            />
          </Col>
          <Col md={5}>
            <InputWrapper
              name="thermostatDescription"
              label="Descrizione termostato"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={2}>
            <InputWrapper
              name="thermostatAmount"
              label="Quantità termostato"
              type="number"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                min: {
                  value: 0,
                  message: errorFormLabels.NOT_NULL
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                } 
              }}
            />
          </Col>
          <Col md={5}>
            <MoneyInputWrapper
              name="thermostatPrice"
              label="Totale termostato (IVA esclusa)"
              type="number"
              step=".01"
              register={register}
              errors={errors}
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
                required: errorFormLabels.REQUIRED
              }}
              currency="€"
            />
          </Col>
          <Col md={12}>
            <TextAreaWrapper
              name="valveLayingDescription"
              label="Descrizione fornitura posa valvole"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                maxLength: {
                  value: 512,
                  message: maxLengthLabel(512)
                } 
              }}
            />
          </Col>
          <Col md={6}>
            <MoneyInputWrapper
              name="valveLayingPrice"
              label="Totale fornitura posa valvole (IVA esclusa)"
              type="number"
              step=".01"
              register={register}
              errors={errors}
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
              currency="€"
            />
          </Col>
          <Col md={6}>
            <MoneyInputWrapper
              name="accessoriesPrice"
              label="Totale opere extra (IVA esclusa)"
              type="number"
              step=".01"
              register={register}
              errors={errors}
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={4}>
            <MoneyInputWrapper
              name="priceVatNotIncluded"
              label="Totale IVA esclusa"
              type="number"
              step=".01"
              readOnly
              register={register}
              className="form-control"
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={4}>
            <MoneyInputWrapper
              name="priceVatIncluded"
              label="Totale IVA inclusa"
              type="number"
              errors={errors}
              register={register}
              readOnly
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
                required: errorFormLabels.REQUIRED
              }}
              currency="€"
              step=".01"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={4}>
            <MoneyInputWrapper
              name="priceToPay"
              label="Totale da corrispondere"
              type="number"
              errors={errors}
              register={register}
              readOnly
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
                required: errorFormLabels.REQUIRED
              }}
              currency="€"
              step=".01"
              disabled={canEdit ? false : true}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default HeaterData