import { useFetchStatusListQuery } from "../../../../../redux/setup/setup.api"
import { SetupStatusItemType } from "../../../../../redux/setup/setup.types"
import ShowOnCondition from "../../../../auth/ShowOnCondition.component"
import StetupStatusTRow from "./StetupStatusTRow/StetupStatusTRow.component"

type Props = {
  filters: string
  page: number
}

const StetupStatusTBody = ({
  filters,
  page,
}: Props) => {
  //Fetch list data trigger
  const { listItems }: {listItems: SetupStatusItemType[]} = useFetchStatusListQuery({
    page,
    filters
  }, {
    selectFromResult: ({ data }) => ({
      listItems: data?.items
    })
  })
  
  return (
    <tbody>
      <ShowOnCondition showWhen={!listItems?.length}>
        <tr>
          <td colSpan={100} className="text-center">
            <strong>Nessun elemento trovato</strong>
          </td>
        </tr>
      </ShowOnCondition>
      <ShowOnCondition showWhen={!!listItems?.length}>
        {listItems?.map((item) => (
          <StetupStatusTRow
            key={item.id} 
            item={item}
          />
        ))}
      </ShowOnCondition>
    </tbody>
  )
}

export default StetupStatusTBody