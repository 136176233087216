import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Col, Row } from "reactstrap";

import {
  fetchElectricContractPriceList,
  fetchElectricUseTypeId,
  fetchExciseClass,
  fetchElectricVatClassList,
} from "../../../../redux/contract/pod/pod.api";
import ShowOnCondition from "../../../auth/ShowOnCondition.component";
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component";
import { ReactComponent as PensoInVerdeIcon } from "../../../../assets/svg/penso-verde.svg";
import { errorFormLabels } from "../../utils/formLabels";
import { CustomerTypes, getCustomerType } from "../../utils/utils";
import PodResidenceSelfDeclaration from "./PodResidenceSelfDeclaration/PodResidenceSelfDeclaration.component";
import { isResidentNeeded, isUseTypeNeeded } from "./utils";
import { useUpdateContractPriceListHook } from "../hooks/useUpdateContractPriceListHook.hook";
import { useUpdateVatClassListHook } from "../hooks/useUpdateVatClassListHook.hooks";
import { useUpdateExciseClassListHook } from "../hooks/useUpdateExciseClassListHook.hooks";
import { useUpdateElectricUseTypeHook } from "../hooks/useUpdateElectricUseTypeHook.hooks";
import { useUpdateVatClassHook } from "../hooks/useUpdateVatClassHook.hooks";
import { FormProps } from "../../utils/types";
import {
  useSelectContractId,
  useSelectEntityName,
} from "../../../../pages/providers/ContractsPageProvider.provider";
import ShowableSection from "../../../ShowableSection/ShowableSection.component";
import { isShowableSection } from "../../../../redux/contract/pod/pod.utils";
import { podQueriebleSections } from "../../../../redux/contract/pod/combinations";

type PodOfferConfigProps = {
  contractId: string;
  userEmail: string;
} & FormProps;

const PodOfferConfig = ({
  canEdit,
  fieldsValues: podFieldsValues,
  customerType,
  userEmail,
}: PodOfferConfigProps) => {
  const entityName = useSelectEntityName();
  const contractId = useSelectContractId();
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const formWatcher = watch();
  const [triggerFetchContractPriceList] =
    fetchElectricContractPriceList.useLazyQuerySubscription();
  const [triggerFetchVatClassList] =
    fetchElectricVatClassList.useLazyQuerySubscription();
  const [triggerFetchExciseClass] = fetchExciseClass.useLazyQuerySubscription();

  const [useType, setUseType] = useState(
    formWatcher.electricUseTypeId?.value || ""
  );
  //Fetch contract price list from API on values change
  const contractPriceList = useUpdateContractPriceListHook({
    podId: formWatcher?.id,
    electricUseType: useType,
    fetchContractPriceListCode: triggerFetchContractPriceList,
    contractId,
    entityName,
    userEmail,
    watch,
    setUseType,
  });

  const vatClassList = useUpdateVatClassListHook({
    useType,
    customerType,
    podFieldsValues: podFieldsValues?.vatClass || [],
    fetchVatClassList: triggerFetchVatClassList,
    entityName,
  });

  //Fetch contract excise list from API on values change
  const vatClass = useUpdateVatClassHook({
    watch,
    defaultValue: formWatcher.vatClassId?.value || "",
  });

  // const [vatClass, setVatClass] = useState(formWatcher.vatClassId?.value || "")
  const exciseClassList = useUpdateExciseClassListHook({
    useType,
    customerType,
    vatClass,
    podFieldsValues: podFieldsValues?.electricExciseClass || [],
    fetchExciseClass: triggerFetchExciseClass,
    entityName,
  });

  /* 
    Updates "electricUseTypeId" when field is hidden (it is visible only when contract is changeSupplier) 
    and "contractType" triggers onChange event.
  */
  const [fetchElectricUseType] =
    fetchElectricUseTypeId.useLazyQuerySubscription();
  useUpdateElectricUseTypeHook({
    watch,
    setValue,
    entityName,
    fetchElectricUseType,
  });

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="list" className="me-2" />
        Configurazione offerta
      </div>
      <div className="grouped-body">
        <Row>
          <ShowOnCondition showWhen={isUseTypeNeeded(entityName)}>
            <Col md={6}>
              <SelectWrapper
                name="electricUseTypeId"
                control={control}
                label="Tipo uso"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={podFieldsValues?.electricUseType || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
          </ShowOnCondition>
          <ShowOnCondition showWhen={!isUseTypeNeeded(entityName)}>
            <input type="hidden" {...register("electricUseTypeId")} />
          </ShowOnCondition>
          <Col md={6}>
            <SelectWrapper
              name="contractPriceListCode"
              control={control}
              label="Offerta"
              isDisabled={canEdit ? false : true}
              errors={errors}
              register={register}
              options={contractPriceList}
              rules={{ required: errorFormLabels.REQUIRED }}
              getOptionLabel={(option) => (
                <>
                  {option.hasGreenEnergy === true && (
                    <PensoInVerdeIcon className="penso-in-verde-icon" />
                  )}
                  <span>{option.label}</span>
                </>
              )}
            />
            <p className="small text-muted">
              <PensoInVerdeIcon className="penso-in-verde-icon" /> Indica
              elettricità 100% verde.
            </p>
          </Col>
          <Col md={6}>
            <SelectWrapper
              name="vatClassId"
              control={control}
              label="Classe IVA"
              isDisabled={canEdit ? false : true}
              errors={errors}
              register={register}
              options={vatClassList}
              rules={{ required: errorFormLabels.REQUIRED }}
            />
          </Col>
          <ShowableSection
            section={podQueriebleSections.ACCISE}
            conditions={[
              entityName ?? "",
              customerType?.value ?? "",
              formWatcher.electricUseTypeId?.value ?? "",
              formWatcher.vatClassId?.value ?? "",
            ]}            
            checkCallback={isShowableSection}
          >
            <Col md={6}>
              <SelectWrapper
                name="electricExciseClassId"
                control={control}
                label="Classe accise"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={exciseClassList}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
          </ShowableSection>
          <ShowOnCondition
            showWhen={
              getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, customerType!) &&
              isResidentNeeded(entityName)
            }
          >
            <Col md={6}>
              <SelectWrapper
                name="isResident"
                control={control}
                label="Residente"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={podFieldsValues?.podResident || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
          </ShowOnCondition>
          <PodResidenceSelfDeclaration
            entityName={entityName}
            canEdit={canEdit!}
            fieldsValues={podFieldsValues}
          />
        </Row>
      </div>
    </div>
  );
};

export default PodOfferConfig;
