import { ContractListType, ListDataType } from "../../../../../redux/list/list.types"
import ShowOnCondition from "../../../../auth/ShowOnCondition.component"
import OngoingCustomersTRow from "./OngoingCustomersTRow/OngoingCustomersTRow.component"

type OngoingCustomersTBodyProps = {
  sortParam: string
  filters: string
  page: number
  listData: ListDataType
}

const OngoingCustomersTBody = ({
  sortParam,
  listData,
}: OngoingCustomersTBodyProps) => {
  
  return (
    <tbody>
      <ShowOnCondition showWhen={!listData?.count}>
        <tr>
          <td colSpan={100} className="text-center">
            <strong>Nessun elemento trovato</strong>
          </td>
        </tr>
      </ShowOnCondition>
      <ShowOnCondition showWhen={!!listData?.count}>
        {listData?.items.map((item) => (
          <OngoingCustomersTRow 
            key={item.id} 
            item={item as ContractListType}
            sortParam={sortParam}
          />
        ))}
      </ShowOnCondition>
    </tbody>
  )
}

export default OngoingCustomersTBody