import { FieldValues, useForm } from "react-hook-form";
import { useImportFileMutation } from "../../../redux/setup/setup.api";
import { useAppSelector } from "../../../redux/hooks";
import { selectSetupUniqueName } from "../../../redux/setup/setup.selectors";
import AttachmentsDropzone from "../../AttachmentsDropzone/AttachmentsDropzone.component";
import { isUniqueNameFound } from "../../../redux/setup/setup.utils";
import InputWrapper from "../inputFields/Input/InputWrapper.component";
import { errorFormLabels } from "../utils/formLabels";
import ShowOnCondition from "../../auth/ShowOnCondition.component";
import { Button, Form } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component";

interface Props {
    toggle: () => void
    isStepped?: boolean,
    fileTypeLabel: string,
    importType: string,
    contentType: string,
}
const SetupImportFilesForm = ({
    toggle,
    isStepped = false,
    fileTypeLabel,
    importType,
    contentType
}: Props) => {
    const setupUniqueName = useAppSelector(selectSetupUniqueName)

    const {
        register,
        setValue,
        reset,
        watch,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm();
    const formWatcher = watch();

    const onSubmit = async (values: FieldValues) => {
        const builtObj = await buildObjectToSend(values[`massiveCreation${fileTypeLabel}`]);
        await runImport({
            setupUniqueName: setupUniqueName,
            values: builtObj,
            importType: importType
        });
        reset();
    };
    const [runImport] = useImportFileMutation();

    const buildObjectToSend = (obj: FieldValues) => {
        const formattedObj = {
            id: obj.id,
            userFileName: obj.userFileName,
            fileBase64: obj.fileBase64,
            size: obj.size,
        };
        return formattedObj;
    };


    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="grouped">
                <div className="grouped-header">
                    <ShowOnCondition showWhen={isStepped}>
                        <span className="step">3</span>
                    </ShowOnCondition>
                    Importa il file {fileTypeLabel}<sup>*</sup>
                </div>
                <div className="grouped-body">
                    <div className="dropzone-container">
                        <AttachmentsDropzone
                            disabled={!isUniqueNameFound(setupUniqueName)}
                            isMulti={false}
                            addFile={(values: FieldValues) =>
                                setValue(`massiveCreation${fileTypeLabel}`, values)
                            }
                            openDialogWithBtn={true}
                            isAccepptedFilesOnly={false}
                        />
                        <InputWrapper
                            type="text"
                            className="form-control"
                            label="File"
                            name={`massiveCreation${fileTypeLabel}.userFileName`}
                            readOnly
                            register={register}
                            errors={errors}
                            rules={{ required: errorFormLabels.REQUIRED }}
                        />
                        <ShowOnCondition showWhen={formWatcher[`massiveCreation${fileTypeLabel}`]?.size}>
                            <InputWrapper
                                name={`massiveCreation${fileTypeLabel}.size`}
                                type="text"
                                readOnly
                                label="Dimensione"
                                className="form-control"
                                register={register}
                            />
                        </ShowOnCondition>
                    </div>
                    <div className="text-end">
                        <Button
                            size="md"
                            color="secondary"
                            className="me-2"
                            outline
                            onClick={toggle}
                            disabled={!isUniqueNameFound(setupUniqueName)}
                        >
                            <FontAwesomeIcon
                                icon={["fas", "clock-rotate-left"]}
                                className="me-2"
                            />
                            Esito importazioni
                        </Button>
                        <ButtonWithLoader
                            isLoading={isSubmitting}
                            type="submit"
                            disabled={isSubmitting || !isUniqueNameFound(setupUniqueName)}
                            label={`Importa ${fileTypeLabel}`}
                            fontAwesomeIcon={["fas", "upload"]}
                        />
                    </div>
                </div>
            </div>
        </Form>
    )
}

export default SetupImportFilesForm