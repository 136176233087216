import { useSelector } from "react-redux"
import { selectUsername } from "../../redux/auth/auth.selectors"
import { Col, Container, Row } from "reactstrap"

const WelcomeHeader = () => {
    const username = useSelector(selectUsername)

    return (
        <header>
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6}>
                        <p className="lead">Benvenuto <strong>{username}</strong></p>
                    </Col>
                </Row>
            </Container>
        </header>
    )
}

export default WelcomeHeader