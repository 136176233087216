import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Button, Spinner } from 'reactstrap'

import { useLoginMutation } from '../../../redux/auth/auth.api'
import { LoginPasswordFormStyle } from './style'
import { LoginSteps } from '../../../redux/auth/auth.utils'
import InputWrapper from '../inputFields/Input/InputWrapper.component'
import ResetPasswordModal from './ResetPasswordModal/ResetPasswordModal.component'
import { ResetPasswordReqType } from '../../../redux/auth/auth.types'

type LoginPasswordProps = {
  userData: ResetPasswordReqType
  setLoginStep: React.Dispatch<React.SetStateAction<number>>
}

type LoginPasswordFormFields = {
  password: string
}

const LoginPasswordForm = ({ userData, setLoginStep }: LoginPasswordProps) => {
  const [resetVisibility, setResetVisibility] = useState(false)
  const toggleReset = () => setResetVisibility(!resetVisibility)
  const [login] = useLoginMutation()
  const {
    register,
    handleSubmit,
    setFocus,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<LoginPasswordFormFields>()

  const onSubmit = async (values: LoginPasswordFormFields) => {
    await login({ email: userData.email, password: values.password })
  }

  useEffect(() => {
    setFocus("password", { shouldSelect: true })
  }, [setFocus])

  return (
    <LoginPasswordFormStyle>
      <p className="lead">Inserisci la tua password:</p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper
          name="password"
          label="Password"
          placeholder="Password"
          type="password"
          className="form-control"
          errors={errors}
          register={register}
          rules={{
            required: "Campo obbligatorio",
            maxLength: 80,
          }}
        />
        <div>
          <p className="small text-right">
            <Button
              color="link"
              className="link-button"
              onClick={toggleReset}
              size="sm"
            >
              Hai dimenticato la password?
            </Button>
            <ResetPasswordModal
              toggle={toggleReset}
              visibility={resetVisibility}
              userData={userData}
            />
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <Button
            size="lg"
            outline
            onClick={() => setLoginStep(LoginSteps.USER_EMAIL_STEP)}
          >
            <FontAwesomeIcon icon="step-backward" className="me-1" />
            Indietro
          </Button>
          <Button type="submit">
            <span>Avanti</span>
            {isSubmitting ? (
              <Spinner size="sm" color="light" className="ms-1" />
            ) : (
              <FontAwesomeIcon icon="sign-in-alt" className="ms-1" />
            )}
          </Button>
        </div>
      </Form>
    </LoginPasswordFormStyle>
  )
}

export default LoginPasswordForm