import { CustomerTypes } from "../../../components/forms/utils/utils";
import { ContractTypes, GasExcicesClasses, GasUseTypes, VatTypes } from "../contract.utils";

export enum pdrQueriebleSections {
  ACCISE = "excise",
  ALLEGATO_E = "attachmentE",
  ALLEGATO_F = "attachmentF",
  ALLEGATO_E_F = "attachmentEF",
}

export type PdrFormCombinations = {
  conditions: string[];
  visibleSections: string[];
};

export const changeSupplierCombos: PdrFormCombinations[] = [
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.DIECI,
      "",
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString(),
    ],
    visibleSections: [
      pdrQueriebleSections.ALLEGATO_E,
      pdrQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CondominioDomestico,
      VatTypes.DIECI,
      "",
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E_F],
  },
];

export const transferCombos: PdrFormCombinations[] = [
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CondominioDomestico,
      VatTypes.DIECI,
      ""
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
];

export const takeoverCombos: PdrFormCombinations[] = [
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CondominioDomestico,
      VatTypes.DIECI,
      ""
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
];

export const newActivationCombos: PdrFormCombinations[] = [
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CondominioDomestico,
      VatTypes.DIECI,
      ""
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
];

export const contextualTransferCombos: PdrFormCombinations[] = [
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [pdrQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Condominio,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CommercioEServizi,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Industria,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.GeneratoreElettricita,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.Autotrazione,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.VENTIDUE,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.DIECI,
      GasExcicesClasses.No.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.PubblicaAmministrazione,
      VatTypes.NAZIONALI,
      GasExcicesClasses.Other.toString()
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_E, pdrQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      GasUseTypes.CondominioDomestico,
      VatTypes.DIECI,
      ""
    ],
    visibleSections: [pdrQueriebleSections.ALLEGATO_F, pdrQueriebleSections.ALLEGATO_E_F],
  },
];