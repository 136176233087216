import { useEffect } from "react"
import { useFormContext, useWatch } from "react-hook-form"

type UpdateProductDescriptionProps = {
  fieldToWatch: string
  fieldToPasteTo: string
}

export const useUpdateProductDescription = ({
  fieldToWatch,
  fieldToPasteTo
}: UpdateProductDescriptionProps) => {
  const { control, setValue } = useFormContext()
  const value = useWatch({
    control,
    name: fieldToWatch
  })

  useEffect(() => {
    setValue(fieldToPasteTo, value)
  }, [value, fieldToPasteTo, setValue])
}