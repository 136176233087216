import ThTable from "../../../ThTable/ThTable.component"

const PdfTHeader = () => (
  <thead className="table-light">
    <tr>
      <ThTable style={{ textAlign: "left" }} label="Tipologia" />
      <ThTable label="Codice" />
      <ThTable label="Nome" />
      <ThTable label="Tipo cliente" />
      <ThTable label="Inizio validità" />
      <ThTable label="Fine validità" />
      <ThTable />
    </tr>
  </thead>
)


export default PdfTHeader