import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"

import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import MoneyInputWrapper from "../../inputFields/MoneyInput/MoneyInputWrapper.component"
import TextAreaWrapper from "../../inputFields/TextArea/TextAreaWrapper.component"
import { errorFormLabels, maxLengthLabel } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { useUpdateTotalPrice } from "../hooks/useUpdateTotalPrice.hook"

const AirConditionerTable = ({
  canEdit,
  customerType
}: FormProps) => {
  const { register, control, setValue, formState: { errors } } = useFormContext()

  //Update total price fields when fieldsToWatch change
  useUpdateTotalPrice({
    control,
    setValue,
    customerType,
    fieldsToWatch: [
      "modelPrice1", 
      "modelPrice2",
      "modelPrice3", 
      "procedureManagmentPrice", 
      "accessoriesPrice"
    ]
  })

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="id-card" className="me-2" />
        Dettagli
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={6}>
            <InputWrapper
              name="ticket"
              label="Ticket"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                } 
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="airConditionerName"
              label="Nome prodotto climatizzatore"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED, 
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                } 
              }}
            />
          </Col>
          <Col md={12}>
            <TextAreaWrapper
              name="modelDescription1"
              label="Descrizione modello 1"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED, 
                maxLength: {
                  value: 512,
                  message: maxLengthLabel(512)
                } 
              }}
            />
          </Col>
          <Col md={4}>
            <InputWrapper
              name="modelPower1"
              label="KW Modello e pacchetto 1"
              type="number"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              rules={{ min: 0 }}
            />
          </Col>
          <Col md={4}>
            <InputWrapper
              name="modelAmount1"
              label="Quantità modello 1"
              type="number"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
                required: errorFormLabels.REQUIRED
              }}
            />
          </Col>
          <Col md={4}>
            <MoneyInputWrapper
              name="modelPrice1"
              step=".01"
              label="Totale modello 1 (IVA esclusa)"
              type="number"
              errors={errors}
              register={register}
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
                required: errorFormLabels.REQUIRED
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={12}>
            <TextAreaWrapper
              name="modelDescription2"
              label="Descrizione modello 2"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                maxLength: {
                  value: 512,
                  message: maxLengthLabel(512)
                } 
              }}
            />
          </Col>
          <Col md={4}>
            <InputWrapper
              name="modelPower2"
              label="KW Modello e pacchetto 2"
              type="number"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              rules={{ min: 0 }}
            />
          </Col>
          <Col md={4}>
            <InputWrapper
              name="modelAmount2"
              label="Quantità modello 2"
              type="number"
              register={register}
              errors={errors}
              disabled={canEdit ? false : true}
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
            />
          </Col>
          <Col md={4}>
            <MoneyInputWrapper
              name="modelPrice2"
              label="Totale modello 2 (IVA esclusa)"
              type="number"
              step=".01"
              errors={errors}
              register={register}
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={12}>
            <TextAreaWrapper
              name="modelDescription3"
              label="Descrizione modello 3"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                maxLength: {
                  value: 512,
                  message: maxLengthLabel(512)
                } 
              }}
            />
          </Col>
          <Col md={4}>
            <InputWrapper
              name="modelPower3"
              label="KW Modello e pacchetto 3"
              type="number"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              rules={{ min: 0 }}
            />
          </Col>
          <Col md={4}>
            <InputWrapper
              name="modelAmount3"
              label="Quantità modello 3"
              type="number"
              register={register}
              errors={errors}
              disabled={canEdit ? false : true}
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
            />
          </Col>
          <Col md={4}>
            <MoneyInputWrapper
              name="modelPrice3"
              label="Totale modello 3 (IVA esclusa)"
              type="number"
              step=".01"
              errors={errors}
              register={register}
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={12}>
            <TextAreaWrapper
              name="procedureManagmentDescription"
              label="Descrizione gestione pratica"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                maxLength: {
                  value: 512,
                  message: maxLengthLabel(512)
                } 
              }}
            />
          </Col>
          <Col md={12}>
            <MoneyInputWrapper
              name="procedureManagmentPrice"
              label="Totale gestione pratica (IVA esclusa)"
              type="number"
              step=".01"
              errors={errors}
              register={register}
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={12}>
            <TextAreaWrapper
              name="accessoriesDescription"
              label="Descrizione opere extra"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                maxLength: {
                  value: 512,
                  message: maxLengthLabel(512)
                } 
              }}
            />
          </Col>
          <Col md={3}>
            <MoneyInputWrapper
              name="accessoriesPrice"
              label="Totale opere extra (IVA esclusa)"
              type="number"
              step=".01"
              errors={errors}
              register={register}
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={3}>
            <MoneyInputWrapper
              name="priceVatNotIncluded"
              label="Totale IVA esclusa"
              step=".01"
              type="number"
              readOnly
              register={register}
              className="form-control"
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={3}>
            <MoneyInputWrapper
              name="priceVatIncluded"
              label="Totale IVA inclusa"
              type="number"
              step=".01"
              readOnly
              errors={errors}
              register={register}
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
                required: errorFormLabels.REQUIRED
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={3}>
            <MoneyInputWrapper
              name="priceToPay"
              label="Totale da corrispondere"
              type="number"
              step=".01"
              readOnly
              errors={errors}
              register={register}
              className="form-control"
              rules={{ 
                min: {
                  value: 0,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
                required: errorFormLabels.REQUIRED
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AirConditionerTable