import AttachmentsDashboard from "../../../components/AttachmentsDashboard/AttachmentsDashboard.component"
import CadastralDataDashboard from "../../../components/CadastralDataDashboard/CadastralDataDashBoard.component"
import SignedContractDashboardContainer from "../../../components/SignedContractDashboard/SignedContractDashboardContainer.component"
import OngoingCustomersContractTabs from "./OngoingCustomersContractTabs/OngoingCustomersContractTabs.component"
import { useFetchContractProposalDataQuery, useFetchFormFieldsValuesQuery } from "../../../redux/contract/contract.api"
import { ContractTabsMap } from "../../../redux/contract/contract.utils"
import { useSelectContractId, useSelectEntityName, useSelectSelectedStep } from "../../providers/ContractsPageProvider.provider"
import OngoingCustomersFormContainer from "../../../components/forms/OngoingCustomersForm/OngoingCustomersFormContainer.component"

const OngoingCustomersContainer = () => {
  const contractId = useSelectContractId()
  const entityName = useSelectEntityName()
  const selectedStep = useSelectSelectedStep()
  const { 
    data: fieldsValues,
    isFetching: isFetchingFormFields, 
    isLoading: isLoadingFormFields,
  } = useFetchFormFieldsValuesQuery({ entityName })
  const fetchingFormfields = isFetchingFormFields || isLoadingFormFields

  const { 
    data: proposalData,
    isFetching: isFetchingProposalData, 
    isLoading: isLoadingProposalData,
    isUninitialized
  } = useFetchContractProposalDataQuery({ entityName, contractId }, { skip: !contractId })
  const fetchingProposalData = isFetchingProposalData || isLoadingProposalData

  const isFetchingData = fetchingFormfields || fetchingProposalData

  return (
    <>
      <OngoingCustomersContractTabs />
      {selectedStep === ContractTabsMap.PROPOSAL_STEP &&
        <OngoingCustomersFormContainer
          proposalData={isUninitialized ? undefined : proposalData}
          fetchingProposalData={isFetchingData}
          fieldsValues={fieldsValues ?? {}}
        />
      }
      {selectedStep === ContractTabsMap.CADASTRAL_STEP &&
        <CadastralDataDashboard />
      }
      {selectedStep === ContractTabsMap.ATTACHMENTS_STEP &&
        <AttachmentsDashboard />
      }
      {selectedStep === ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP &&
        <SignedContractDashboardContainer />
      }
    </>
  )
}

export default OngoingCustomersContainer