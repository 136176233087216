import styled from 'styled-components'

const SignedContractDashboardStyle = styled.div`
  .jumbotron {
    background-color: transparent !important;
    padding: 0;

    .title {
      font-size: 1.75rem;
    }

    .description {
      font-size: 1rem;
    }

    .ab-button:hover {
      background-color: transparent;
      color: ${({ theme}) => theme.colors.palette6};
      border: 1px solid ${({ theme}) => theme.colors.palette6};
    }
  }
`

export default SignedContractDashboardStyle