type PdpItem = {
  id?: string
  no?: string
}

export function isPdrsListNeeded(entityName: string) {
  return (
    entityName !== 'newConnection'
  ) ? true : false
}

export const buildPodOptions = (items: PdpItem[]) => {
  return items.map((pod, i) => (
    {
      label: `POD-${pod.no}`,
      value: pod.id
    }
  ))
}

export const buildPdrOptions = (items: PdpItem[]) => {
  return items.map(pdr => (
    {
      label: `PDR-${pdr.no}`,
      value: pdr.id
    }
  ))
}


export function setUpContractPdpsOptions(contractPods: PdpItem[], contractPdrs: PdpItem[], entityName: string) {
  const podOptions = buildPodOptions(contractPods || [])
  const pdrOptions = buildPdrOptions(contractPdrs || [])
  if(isPdrsListNeeded(entityName)) {
    return podOptions.concat(pdrOptions)
  }
  else {
    return podOptions
  }
}