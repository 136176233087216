import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"
import { useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider"

import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { CustomerTypes, getCustomerType } from "../../utils/utils"
import { hasReconsiderationVisibility, isMortisCausaVisibility, propertyTypeVisibility, singleInvoicePdrVisibility, singleInvoicePodVisibility } from "../utils"

const OtherFields = ({
  canEdit, 
  fieldsValues: proposalFormValues
}: FormProps) => {
  const entityName = useSelectEntityName()
  const { register, control, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  return (
    <>
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="layer-group" className="me-2" />
          Tipologia fattura
        </div>
        <div className="grouped-body">
          <Row>
            {singleInvoicePodVisibility(entityName) &&
              <Col>
                <SelectWrapper
                  name="singleInvoicePod"
                  control={control}
                  label="Fattura unica per tutti i POD che aggiungerai?"
                  isDisabled={canEdit? false : true}
                  errors={errors}
                  options={proposalFormValues?.singleInvoicePod ?? []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
            }
            {singleInvoicePdrVisibility(entityName) &&
              <Col>
                <SelectWrapper
                  name="singleInvoicePdr"
                  control={control}
                  label="Fattura unica per tutti i PDR che aggiungerai?"
                  isDisabled={canEdit? false : true}
                  errors={errors}
                  options={proposalFormValues?.singleInvoicePdr ?? []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
            }
          </Row>
        </div>
      </div>
      {propertyTypeVisibility(entityName) &&
        <div className="grouped">
          <div className="grouped-header">
            <FontAwesomeIcon icon="home" className="me-2" />
            Tipologia occupazione immobile
          </div>
          <div className="grouped-body">
            <Row>
              <Col>
                <SelectWrapper
                  name="propertyType"
                  control={control}
                  label="Tipo proprietà"
                  isDisabled={canEdit? false : true}
                  errors={errors}
                  options={proposalFormValues?.propertyType ?? []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              {parseInt(formWatcher.propertyType?.value) === 4 &&
                <Col>
                  <InputWrapper 
                    name="otherPropertyType"
                    label="Altro (specificare)"
                    type="text"
                    register={register}
                    disabled={canEdit ? false : true}
                    className="form-control"
                    errors={errors}
                    rules={{ 
                      value: formWatcher.otherPropertyType || null,
                      required: errorFormLabels.REQUIRED,
                      maxLength: 80
                    }}
                  />
                </Col>
              }
            </Row>
          </div>
        </div>
      }
      {
        getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, formWatcher.customerType) &&
        isMortisCausaVisibility(entityName) &&
          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="question-circle" className="me-2" />
              Mortis causa
            </div>
            <div className="grouped-body">
              <Col md={6}>
                <SelectWrapper
                  name="isMortisCausa"
                  control={control}
                  label="Mortis causa"
                  isDisabled={canEdit? false : true}
                  errors={errors}
                  options={proposalFormValues?.isMortisCausa ?? []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
            </div>
          </div>
      }
      {
        hasReconsiderationVisibility(entityName) &&
        getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, formWatcher.customerType) &&
          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="gavel" className="me-2" />
              Diritto di ripensamento
            </div>
            <div className="grouped-body">
              <Row>
                <Col md={12}>
                  <SelectWrapper
                    name="hasReconsideration"
                    control={control}
                    label="Diritto ripensamento"
                    isDisabled={canEdit? false : true}
                    errors={errors}
                    options={proposalFormValues?.reconsideration ?? []}
                    rules={{ required: errorFormLabels.REQUIRED }}
                  />
                </Col>
              </Row>
            </div>
          </div>
      }
    </>
  )
}

export default OtherFields