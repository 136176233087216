import { signInWithEmailAndPassword, confirmPasswordReset } from "firebase/auth"

import { auth, getCurrentUser, userSignOut } from "../../firebase"
import { api } from "../api"
import { buildReadableError, getAPIUrl } from "../api/utils"
import { ResetPasswordFormType, ResetPasswordReqType, SignatureType } from "./auth.types"
import { 
  abLogin, checkUserPassword, getUserEntities, 
  sendResetPasswordEmail, translateFirebaseErrorMessage, TranslateFirebaseErrors 
} from "./auth.utils"

export const authApi = api.injectEndpoints({
  endpoints: build => ({
    checkUserEmail: build.query({
      async queryFn(args) {
        try {
          const response = await checkUserPassword(args.email)
          return { data: response }
        }
        catch(err) {
          return buildReadableError(500, err as Error)
        }
      }
    }),
    sendUserResetPasswordEmail: build.mutation({
      async queryFn(args: ResetPasswordReqType) {
        await sendResetPasswordEmail(args)
        return { data: undefined }
      }
    }),
    resetUserPassword: build.mutation({
      async queryFn(args: ResetPasswordFormType) {
        try {
          await confirmPasswordReset(auth, args.oobCode, args.password)
          return { data: undefined }
        }
        catch(err) {
          return buildReadableError(400, new Error("Codice OOBCODE scaduto, richiedi nuovamente il reset password per riceverne uno valido via email."))
        }
      }
    }),
    login: build.mutation({
      async queryFn(args) {
        try {
          const { user } = await signInWithEmailAndPassword(auth, args.email, args.password)
          const accessToken = await user.getIdToken()
          const abUser = await abLogin(accessToken)
          const entities = await getUserEntities(accessToken)
          const currentUser = {
            ...abUser
          }
          return { data: { currentUser, entities } }
        }
        catch(err) {
          const message = translateFirebaseErrorMessage(err as TranslateFirebaseErrors)
          return { error: message }
        }
      }
    }),
    checkUserSession: build.query({
      async queryFn(args) {
        const user = await getCurrentUser()
        if(user) {
          const accessToken = await user.getIdToken()
          const abUser = await abLogin(accessToken)
          const entities = await getUserEntities(accessToken)
          const currentUser = {
            ...abUser
          }
          return { data: { currentUser, entities} }  
        }
        return { data: null }
      }
    }),
    logOut: build.mutation({
      async queryFn(args) {
        await userSignOut()
        return { data: null }
      }
    }),
    fetchSignatureTypes: build.query<SignatureType[], any>({
      query: ({ entityName, contractId }) => ({
        url: `${getAPIUrl()}/users/signature-methods?contractId=${contractId}&openingCausal=${entityName}`
      }),
      transformResponse: (response: { data: SignatureType[] }, meta, arg) => response.data,
    }),
  })
})

export const {
  useLazyCheckUserEmailQuery,
  useSendUserResetPasswordEmailMutation,
  useLoginMutation,
  useCheckUserSessionQuery,
  useLogOutMutation,
  useFetchSignatureTypesQuery,
  useResetUserPasswordMutation,
} = authApi

export const {
  endpoints: {
    checkUserEmail,
    sendUserResetPasswordEmail
  }
} = authApi