import styled from "styled-components"

const NotesListStyle = styled.div`
  .list-group-item {
    font-size: .85rem;
    padding: 0.5rem;

    strong {
      color: ${({ theme}) => theme.colors.palette6};
    }

    p {
      margin-bottom: 0.5rem;
    }

    blockquote {
      font-style: italic;
      margin-bottom: 0;
    }
  }
`

export default NotesListStyle