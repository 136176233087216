import { Container } from "reactstrap"
import DefaultPageContainer from "../DefaultPageContainer/DefaultPageContainer.component"
import PageHeader from "../PageHeader/PageHeader.component"
import SetupCreationPageStyle from "./style"
import { useParams } from "react-router"
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop.component"
import { createJobName, getUniqueNameFromPathName, mapTitleToPathOrTitle } from "../../redux/setup/setup.utils"
import { useAppDispatch } from "../../redux/hooks"
import { setSetupJobName, setSetupUniqueName } from "../../redux/setup/setup.actions"
import { useEffect } from "react"
import SetupImportCsvForm from "../../components/forms/SetupImportCsvForm/SetupImportCsvForm.component"

const SetupImportCsvPage = () => {
  const { setupPathName } = useParams()
  const title = mapTitleToPathOrTitle(setupPathName ?? '', false)
  const setupUniqueName = getUniqueNameFromPathName(setupPathName ?? '')

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setSetupUniqueName(setupUniqueName ?? ''))
    dispatch(setSetupJobName(createJobName(setupUniqueName, 'csv')))
  }, [dispatch, setupUniqueName])

  return (  
    <DefaultPageContainer>      
      <ScrollToTop />
      <SetupCreationPageStyle>
        <PageHeader pageTitle={`Importa CSV - ${title}`} icon="table" />
        <Container className="contract-container">
          <SetupImportCsvForm/>
        </Container>
      </SetupCreationPageStyle>
    </DefaultPageContainer>
  )
}

export default SetupImportCsvPage