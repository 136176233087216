import SetupRow from "../SetupRow/SetupRow.component";

const AvailableSetupRows = () => {

  return (
    <>
      <section className='contracts-section new-clients' id='new-clients'>
        <div className='contracts-container'>
          <SetupRow title="Distributori" iconName="person-dolly" isCsvImportAvailable />
          <SetupRow title="Fornitori" iconName="plug" isCsvImportAvailable />
          <SetupRow title="Codici REMI" iconName="file-binary" isCsvImportAvailable />
          <SetupRow title="Codici ATECO" iconName="file-binary" isCsvImportAvailable />
          <SetupRow title="Cattivi pagatori" iconName="siren-on" isCsvImportAvailable />
          <SetupRow title="Listini" iconName="file-invoice" isCsvImportAvailable isPdfImportAvailable />
          <SetupRow title="Canali vendita" iconName="store" isCsvImportAvailable />
        </div>
      </section>
    </>
  );
};

export default AvailableSetupRows;
