import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";
import { Col, Row } from "reactstrap";
import { useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider";

import ShowOnCondition from "../../../auth/ShowOnCondition.component";
import InputWrapper from "../../inputFields/Input/InputWrapper.component";
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component";
import { errorFormLabels } from "../../utils/formLabels";
import { FormProps } from "../../utils/types";
import PodOtherAttachmentsDataForm from "./PodOtherAttachmentsDataForm/PodOtherAttachmentsDataForm.component";
import { isShowableSection } from "../../../../redux/contract/pod/pod.utils";
import ShowableSection from "../../../ShowableSection/ShowableSection.component";
import { podQueriebleSections } from "../../../../redux/contract/pod/combinations";

const PodFAttachment = ({
  customerType,
  fieldsValues: podFieldsValues,
  canEdit,
}: FormProps) => {
  const entityName = useSelectEntityName();
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const formWatcher = watch();

  return (
    <ShowableSection
            section={podQueriebleSections.ALLEGATO_F}
            conditions={[
              entityName ?? "",
              customerType?.value ?? "",
              formWatcher.electricUseTypeId?.value ?? "",
              formWatcher.vatClassId?.value ?? "",
            ]}
            checkCallback={isShowableSection}
          >
      <>
        <div className="grouped">
          <div className="grouped-header">
            <FontAwesomeIcon icon="list" className="me-2" />
            Allegato F
          </div>
          <div className="grouped-body">
            <Row>
              <Col md={6}>
                <SelectWrapper
                  name="scope"
                  control={control}
                  label="Finalità uso energia elettrica"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  register={register}
                  options={podFieldsValues?.scope || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              <ShowOnCondition
                showWhen={parseInt(formWatcher.scope?.value) === 1}
              >
                <Col md={6}>
                  <SelectWrapper
                    name="domesticScope"
                    control={control}
                    label="Tipologia uso domestico"
                    isDisabled={canEdit ? false : true}
                    errors={errors}
                    register={register}
                    options={podFieldsValues?.domesticScope || []}
                    rules={{ required: errorFormLabels.REQUIRED }}
                  />
                </Col>
              </ShowOnCondition>
              <ShowOnCondition
                showWhen={parseInt(formWatcher.scope?.value) === 3}
              >
                <Col md={6}>
                  <SelectWrapper
                    name="manifacturingScope"
                    control={control}
                    label="Tipologia esercizio d'impresa"
                    isDisabled={canEdit ? false : true}
                    errors={errors}
                    register={register}
                    options={podFieldsValues?.manifacturingScope || []}
                    rules={{ required: errorFormLabels.REQUIRED }}
                  />
                </Col>
              </ShowOnCondition>
              <ShowOnCondition
                showWhen={
                  parseInt(formWatcher.scope?.value) === 3 &&
                  parseInt(formWatcher.manifacturingScope?.value) === 4
                }
              >
                <Col>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="manifacturingScopeOther"
                    label="Altro (specificare)"
                    rules={{
                      maxLength: 80,
                      required: errorFormLabels.REQUIRED,
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
              </ShowOnCondition>
            </Row>
          </div>
        </div>
        <PodOtherAttachmentsDataForm
          customerType={customerType!}
          canEdit={canEdit!}
          fieldsValues={podFieldsValues}
        />
      </>
    </ShowableSection>
  );
};

export default PodFAttachment;
