import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component";
import { FieldValues, useFormContext } from "react-hook-form";
import { errorFormLabels } from "../../utils/formLabels";
import InputWrapper from "../../inputFields/Input/InputWrapper.component";
import { CustomerTypes, getCustomerType } from "../../utils/utils";

interface Props {
  canEdit?: boolean;
  formFields: FieldValues;
  entityName: string;
}
const PdrMeasureGroupShift = ({ canEdit, formFields, entityName }: Props) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const formWatcher = watch()
  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="fire" className="me-2" />
        Spostamento gruppo di misura
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={12}>
            {getCustomerType(
              CustomerTypes.BUSINESS_CUSTOMER,
              formWatcher.customerType
            ) ? (
              <SelectWrapper
                name="variationType"
                control={control}
                label="Richiede la seguente variazione"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={formFields?.variationType || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            ) : (
              <SelectWrapper
                name="displacementType"
                control={control}
                label="Richiede il seguente spostamento"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={formFields?.displacementType || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            )}
          </Col>
          <Col md={12}>
            <legend className="col-form-label">
              Potenzialità attuale installata:
            </legend>
          </Col>
          <Col md={6}>
            <InputWrapper
              type="number"
              className="form-control"
              disabled={canEdit ? false : true}
              name="requestedPower"
              label="Potenzialità richiesta"
              rules={{
                min: 0,
                maxLength: 80,
                required: errorFormLabels.REQUIRED,
              }}
              errors={errors}
              register={register}
            />
          </Col>
          <Col md={6}>
            <SelectWrapper
              name="measureUnit"
              control={control}
              label="Unità di misura"
              isDisabled={canEdit ? false : true}
              errors={errors}
              register={register}
              isClearable
              rules={{ required: errorFormLabels.REQUIRED }}
              options={formFields?.measureUnit || []}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PdrMeasureGroupShift;
