import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

const selectSetup = (state: RootState) => state.setup
export const selectSetupUniqueName = createDraftSafeSelector(
    [selectSetup],
    setup => setup.setupUniqueName
)

export const selectSetupJobName = createDraftSafeSelector(
    [selectSetup],
    setup => setup.setupJobName
)

export const selectSetupListItems = createDraftSafeSelector(
    [selectSetup],
    setup => setup.setupStatusListData
)

export const selectPdfListItems = createDraftSafeSelector(
    [selectSetup],
    setup => setup.setupPdfListData
)