import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import { FormProps } from "../../utils/types"

const Promotions = ({
  canEdit
}: FormProps) => {
  const { register, formState: { errors } } = useFormContext()

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="gift" className="me-2" />
        Promozioni
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={12}>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={canEdit ? false : true}
              name="podFriend"
              label="POD amico"
              rules={{ maxLength: 14 }}
              register={register}
              errors={errors}
            />
          </Col>
          <Col md={12}>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={canEdit ? false : true}
              name="customerFriend"
              label="Cliente amico"
              rules={{ maxLength: 80 }}
              register={register}
              errors={errors}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Promotions