const PrivacyPolicyText = () => (
  <>
    <p className="text">
      ABenergie  S.p.A.  (di  seguito  anche  solo  “ABenergie”)  Le  rilascia  la  suddetta  informativa  ai  sensi  dell’art.  13  del  Regolamento EU 2016/679 (di seguito anche solo “GDPR”) e del D.Lgs. n°196/2003 e s.m.i nel rispetto dei principi di correttezza,  liceità,  trasparenza  e  riservatezza,  ferme  le  modifiche  e  gli  adattamenti  necessari  a  seguito  di  interventi  nazionali,  europei  e/o  provvedimenti  delle  Autorità  di  controllo  successive  alla  pubblicazione  della  presente.
    </p>
    <h6 className="strong">1. Origine dei dati personali</h6>
    <p className="text">
      I Dati Personali raccolti, oggetto di trattamento, sono forniti dal Cliente in fase di vendita. I dati del potenziale Cliente (di seguito “Prospect”) sono raccolti in fase precontrattuale, nel momento in cui è richiesta un’offerta.
    </p>
    <h6 className="strong">2. Natura dei dati trattati</h6>
    <p className="text">
      Il  trattamento  oggetto  della  presente  informativa,  riguarda  dati  personali  comuni  quali,  a  titolo  esemplificativo  e  non esaustivo, la denominazione sociale/dati anagrafici, codice fiscale/P.IVA, numero di telefono, indirizzo di posta elettronica, IBAN.
    </p>
    <h6 className="strong">3. Finalità del trattamento </h6>
    <p className="text">
      I dati personali sono raccolti e trattati per le seguenti finalità:
    </p>
    <ol className="letter-list">
      <li>finalità precontrattuali: per rispondere a richieste sollecitate dal Prospect e/o dal Cliente e per ogni ulteriore attività strumentale alla sottoscrizione e/o attivazione dei Servizi offerti da ABenergie;</li>
      <li>finalità  contrattuali:  per  l’erogazione  dei  servizi  di  fornitura  di  energia,  gas  o  degli  altri  prodotti  e  servizi  offerti da ABenergie o attraverso partner commerciali (compresi i servizi di accessori eventualmente richiestidal  Cliente  stesso);  per  la  gestione  tecnica,  amministrativa  e  contabile  del  servizio;  per  la  gestione  di  di reclami  e  contenziosi;  per  l’invio  di  informazioni  e  comunicazioni  di  servizio;  per  considerare  l’adesione  da  parte di ABenergie a successive proposte contrattuali sollecitate dal Cliente, nei limiti indicati dall’art. 3 lett. (d); per la consegna, l’attivazione e la manutenzione dei prodotti acquistati o forniti in comodato d’uso; per la cessione  dei  crediti  derivanti  dai  contratti  di  fornitura;  per  attribuire  al  Cliente  i  vantaggi  connessi  al  suo  duraturo e “fidelizzato” rapporto con ABenergie, mediante l’attribuzione diretta di particolari Bonus relativi a servizi  di  fornitura  di  energia,  gas  o  degli  altri  prodotti  e  servizi  acquistati  dal  Cliente  ;  per  migliorare  i  prodotti e i servizi offerti alla clientela;</li>
      <li>finalità di adempimento ad obblighi previsti da normative comunitarie e nazionali, incluse leggi, regolamenti pro tempore vigenti e provvedimenti anche impartiti da Autorità a ciò legittimate, nonché per finalità correlate all’accertamento e repressione dei reati;</li>
      <li>finalità di  informazione  commerciale  per  procedere  alla  vendita  di  determinati  prodotti  o  servizi  sono  utilizzati alcuni dati personali provenienti da archivi o registri pubblici relativi ad eventuali protesti, iscrizioni o  trascrizioni  pregiudizievoli  (quali  pignoramenti,  procedure  concorsuali,  sequestri,  ipoteche,  domande  giudiziali)  e  dati  di  visura  e  di  bilancio.  Tali  dati  sono  acquisiti  attraverso  l’accesso  ai  sistemi  informativi  di  società autorizzate e sono trattati, laddove necessario, esclusivamente ai fini della verifica sulla affidabilità e puntualità nei pagamenti. ABenergie potrà, inoltre, trattare i dati sullo stato e sulla puntualità del Cliente nei pagamenti  dei  Servizi  forniti  anche  in  passato:  tali  informazioni,  una  volta  acquisite  da  ABenergie,  saranno  conservate e trattate per la tutela delle ragioni di credito di ABenergie, al fine della valutazione di eventuali ulteriori  adesioni  e/o  proposte  contrattuali  sottoscrivibili  dal  Cliente;  inoltre  per  la  prevenzione  di  frodi  nel  settore del credito al consumo e del furto di identità, ABenergie potrà comunicare i dati all’Archivio Centrale Informatizzato  per  finalità  di  verifica  dell’autenticità  dei  dati  contenuti  nei  documenti  forniti  dal  Cliente,  in  linea con la normativa vigente;</li>
      <li>finalità di marketing diretto: per l’invio di materiale pubblicitario, di vendita , di comunicazione commerciale diretta –  ad  opera  del  Titolare  del  trattamento  -  relativa  ai  Prodotti  e/o  Servizi  offerti  da  ABenergie;  tale  attività     potrà     essere     eseguita,     previo     espresso     consenso,     mediante     l’invio     di     materiale     pubblicitario/informativo/promozionale  sia  a  Clienti  che  a  Prospect,  e/o  di  inviti  di  partecipazione  ad  iniziative   effettuato   mediante   sistemi   “automatizzati”   di   contatto   (a   titolo   esemplificativo   chiamate   telefoniche senza l’intervento dell’operatore, e-mail, fax, applicazioni interattive e social network);</li>
      <li>finalità  di  marketing  indiretto:  per  il  contatto  telefonico  di  Prospect,  previa  manifestazione  di  specifico  consenso,   da   parte   di   società   di   telemarketing   nostre   partners,   che   si   avvalgono   di   operatori   per   l'effettuazione di chiamate commerciali in nome e per conto di ABenergie;</li>
      <li>finalità   di   profilazione:   per   la   rilevazione   delle   preferenze   di   consumo   e   analisi   anche   in   forma   personalizzata,  automatizzata  od  elettronica,  delle  informazioni  acquisite  attraverso  l’utilizzo  dei  prodotti  e  servizi allo scopo di migliorare ed accrescere la capacità di adeguare l’offerta complessiva della Società alle esigenze dei Clienti/Prospect;</li>
      <li>finalità  di  cessione  a  terzi:  per  la  cessione  a  soggetti  terzi  partner  di  ABenergie  S.p.A.,  per  comunicazioni  informative e promozionali relative ai prodotti e servizi offerti ( a titolo esemplificativo, servizi riferibili alla c.d. sostenibilità ambientale e riferiti all’mbito del servizio insurtech);</li>
      <li>finalità di tutela: necessarie ad accertare, esercitare o difendere un diritto in sede giudiziaria o ogniqualvolta le autorità giurisdizionali esercitino le loro funzioni giurisdizionali.</li>
    </ol>
    <h6 className="strong">4.	Modalità, logiche del trattamento</h6>
    <p className="text">
      Il trattamento dei dati è effettuato da ABenergie sia attraverso l’utilizzo di strumenti manuali sia mediante l’ausilio di  mezzi  elettronici  e  automatizzati.  Il  trattamento  dei  dati  sarà  effettuato  con  logiche  di  organizzazione  ed  elaborazione dei dati dei Clienti/Prospect correlate alle finalità di cui alla presente informativa, comunque, in modo da garantire la sicurezza e la riservatezza dei dati. Più precisamente, i dati verranno conservati in server, protetti da sottrazione o alterazione mediante appositi sistemi di archiviazione e di sicurezza informatica.
    </p>
    <h6 className="strong">5.	Tempi di conservazione</h6>
    <p className="text">
      I  dati  personali  verranno  trattati  da  ABenergie  per  tutta  la  durata  contrattuale  ed  anche  successivamente  per  far  valere o tutelare i propri diritti. Tali dati potranno, inoltre, essere trattati da ABenergie per la durata massima di 24 mesi per le finalità di cui al punto 3 lett. e) e f) e di 12 mesi per quella di cui alla finalità al punto 3 lett. g) per le suddette finalità il Cliente/Prospect potrà sempre opporsi con le modalità indicate al punto 10. Per la finalità invece di  cui  al  punto  3  lett.  h)  sino  a  revoca  del  consenso  eventualmente  prestato.  Successivamente  alla  cessazione  del  contratto  sottoscritto,  i  dati  potranno  essere  trattati  esclusivamente  per  finalità  amministrative  e/o  legali  (es.  in  caso  di  illeciti  come  ad  esempio  multe  danneggiamenti,  furti  etc.)  e  per  dare  esecuzione  ad  obblighi  derivanti  dal  quadro  regolamentare  e  normativo  pro  temporeapplicabile.  Al  termine  del  periodo  necessario  agli  scopi  predetti  i  dati vengono cancellati con modalità sicure.
    </p>
    <h6 className="strong">6.	Categorie di soggetti ai quali i dati possono essere comunicati</h6>
    <p className="text">
      Per il perseguimento delle finalità sopra indicate, ABenergie potrebbe avere necessità di comunicare in Italia i dati personali dei Clienti/Prospect a soggetti terzi per dare compiuta esecuzione al contratto, in adempimento di obblighi di legge o per lo svolgimento di attività strumentali alla prestazione dei servizi richiesti. Detti soggetti appartengono alle seguenti categorie:
    </p>
    <ol className="letter-list">
      <li>Autorità  pubbliche  e  organi  di  vigilanza  e  controllo  quando  sia  richiesto  da  specifiche  previsioni  normative,  regolamentari e autorizzative;</li>
      <li>Soggetti che svolgono per conto di ABenergie compiti di natura tecnica o organizzativa; soggetti che svolgono servizi  di  supporto  o  strumentali  all’esecuzione  contrattuale;  soggetti  che  effettuano  servizi  di  acquisizione,  lavorazione  ed  elaborazione  dei  dati  necessari  per  la  fruizione  dei  servizi  per  la  Clientela;  soggetti  che  svolgono  attività  di  trasmissione,  imbustamento,  trasporto  e  smistamento  delle  comunicazioni  al  Cliente;  soggetti  che  svolgono  attività  di  archiviazione  e  data  entry;  studi  e  società  nell’ambito  dei  rapporti  di  assistenza   e   consulenza   anche   legale;   soggetti   che   svolgono   adempimenti   di   controllo,   revisione   e   certificazione  delle  attività  poste  in  essere  da  ABenergie  anche  nell’interesse  dei  propri  Clienti  e  utenti;  società di factoring, istituti di credito o bancari e società emittenti carte di credito; compagnie assicurative; società di leasing; società di servizi cui sono state affidate le attività di gestione, liquidazione e pagamento di eventuali  sinistri;  società  del  settore  energia  e  dei  servizi  di  dispacciamento  dell’energia  elettrica  e  del  gas  per la gestione dei relativi rapporti; società di telemarketing;</li>
      <li>a  società  esterne  che  operano  nel  settore  del  recupero  credito  incluse  dilazioni  di  pagamento,  quando  consentito dalla legislazione vigente, per finalità di prevenzione e controllo del rischio di insolvenza, controllo delle frodi e di tutela del credito, a tal fine nominate responsabili esterni del trattamento; </li>
      <li>solo in caso di esplicito consenso manifestato dall’utente, a società che effettuano attività di telemarketing per conto di ABenergie SpA o società terze partner della Società per loro attività promozionali.</li>
    </ol>
    <p className="text">
      I  soggetti  appartenenti  alle  categorie  sopra  riportate  trattano  i  dati  personali  come  autonomi  Titolari  del  trattamento o in qualità di Responsabili esterni del trattamento appositamente nominati da ABenergie.
      L’elenco  dei  Responsabili  Esterni  è  a  disposizione  dell’interessato,  che  ne  faccia  esplicita  richiesta,  presso  la  sede  legale del Titolare del trattamento.
      Tutti i dipendenti, consulenti, collaboratori e/o ogni altra “persona fisica”, ex. Art. 4 lett.10 del GDPR e del D.Lgs. 196/2003  e  s.m.i.,  che  svolgono  la  propria  attività  sulla  base  delle  istruzioni  ricevute  da  ABenergie  sono  nominati  “soggetti  autorizzati  al  trattamento”.  ABenergie  impartisce  ai  Responsabili  e  ai  soggetti  autorizzati  adeguate  istruzioni  operative,  con  particolare  riferimento  all’adozione  delle  misure  tecniche  organizzative  adeguate  a  garantire la riservatezza e la sicurezza dei dati.
    </p>
    <h6 className="strong">7.	Natura del consenso e base giuridica</h6>
    <p className="text">
      Il  conferimento  dei  dati  personali  da  parte  del  Cliente  è  necessario  per  la  stipulazione  e  l’esecuzione  dei  rapporti  contrattuali,  per  adempiere  ad  obblighi  di  legge  o  per  motivi  di  legittimo  interesse  come  specificato  alle  finalità  descritte  al  punto  3  alle  lett.  a),  b),  c),  d)  e  i)  (in  conformità  all’art.  6  lett.  b),  c)  e  f)  del  GDPR).  Un  eventuale  rifiuto comporterebbe l’impossibilità per ABenergie di procedere all’esecuzione del contratto stipulato.
      In relazione alle finalità del trattamento di cui al punto 3, lett. e), f) , g) e h) della presente Informativa, il consenso al  trattamento  dei  dati  da  parte  del  Cliente/Prospect  è  invece  facoltativo  e  sempre  revocabile.  ABenergie  potrà  inviare  al  Cliente/Prospect  comunicazioni  commerciali  relative  a  prodotti  e/o  servizi  analoghi  a  quelli  a  lui  già  forniti,  utilizzando  le  coordinate  di  posta  elettronica  da  lui  indicate  in  tali  occasioni,  alle  quali  il  Cliente/Prospect  potrà opporsi con le modalità indicate al punto 10.
    </p>
    <h6 className="strong">8.	Ulteriori Informazioni</h6>
    <p className="text">
      Eventuali aggiornamenti della presente Informativa saranno immediatamente resi disponibili sul sito web e attraverso i canali commerciali di ABenergie.
    </p>
    <h6 className="strong">9.	Il Titolare del trattamento</h6>
    <p className="text">
      Il Titolare del trattamento è ABenergie S.p.A nella persona del suo Rappresentante Legale, avente sede legale in Via Pordenone 2, 00182 Roma.
    </p>
    <h6 className="strong">10. Diritti dell’interessato</h6>
    <p className="text">
      Ai sensi degli artt. 15 e seguenti del Regolamento EU 2016/679 si conferisce all’interessato l’esercizio specifici diritti nei nostri confronti. In particolare, potrà:
    </p>
    <ol>
      <li>ottenere la conferma dell'esistenza di dati personali che lo riguardano, anche se non ancora registrati, l’accesso dei  medesimi  dati  e  della  loro  origine,  nonché  delle  finalità,  dei  tempi  di  conservazione,  delle  modalità  del  trattamento e della logica applicata in caso di trattamento effettuato con strumenti automatizzati;</li>
      <li>revocare  il  consenso  eventualmente  prestato  relativamente  al  trattamento  per  finalità  di  marketing  diretto  e  indiretto, nonché per profilazione e cessione del dato a terzi, fatta salva la liceità del trattamento posto in essere fino a quel momento;</li>
      <li>icevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali trattati e, salvo il caso in cui non sia tecnicamente fattibile, trasmettere direttamente i dati personali a un altro Titolare del trattamento;</li>
      <li>ottenere  la  cancellazione,  la  trasformazione  in  forma  anonima  o  il  blocco  dei  dati  di  cui  non  è  necessaria  la  conservazione in relazione agli scopi per i quali i dati sono stati raccolti e trattati;</li>
      <li>ottenere l'aggiornamento, la rettificazione e l'integrazione dei dati trattati;</li>
      <li>opporsi, in tutto o in parte, per motivi legittimi, al trattamento dei dati personali che lo riguardano, ancorché pertinenti allo scopo della raccolta;</li>
      <li>proporre reclamo alle Autorità di controllo.</li>
    </ol>
    <p className="text">
      Si precisa che l’esercizio di questi diritti non deve pregiudicare e/o ledere i diritti e le libertà altrui.
      Potrete  esercitare  tali  diritti  in  qualsiasi  momento  scrivendo  a  mezzo  posta  a  ABenergie  S.p.A.,  Via  Pordenone  2,  00182 Roma contattando all’indirizzo e-mail: privacy@abenergie.it .
      Resta  inteso  che,  laddove  le  richieste  siano  presentate  tramite  mezzi  elettronici,  le  informazioni  saranno  fornite  gratuitamente e in un formato elettronico di uso comune.
      Qualora le richieste dell'interessato siano manifestamente infondate o eccessive, in particolare per il loro carattere ripetitivo,  la  Società  può  alternativamente:  addebitare  un  contributo  spese  ragionevole  tenendo  conto  dei  costi  amministrativi  sostenuti  per  fornire  le  informazioni  o  la  comunicazione  o  intraprendere  l'azione  richiesta;  oppure  rifiutare  di  soddisfare  la  richiesta.  In  entrambi  le  circostanze  la  Società  fornisce  un’adeguata  giustificazione  all’interessato.
    </p>
    <h6 className="strong">11. Data Protection Officer</h6>
    <p className="text">
      Il  Titolare  ha  nominato  il  responsabile  per  la  protezione  dei  dati  (“Data  Protection  Officer”  o  “DPO”)  raggiungibile  all’indirizzo e-mail dpo@abenergie.it .
    </p>
  </>
)

export default PrivacyPolicyText
