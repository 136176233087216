import classNames from "classnames"
import { Table } from "reactstrap"

import { HeaterListDataType } from "../../../../redux/list/list.types"
import ContractTBody from "./ContractTBody/ContractTBody.component"
import ContractTHeader from "./ContractTHeader/ContractTHeader.component"

type ContractTableProps = {
  sortParam: string
  setSortParam: (value: string) => void
  page: number
  filters: string
  isFetching: boolean
  listData: HeaterListDataType
}

const ContractTable = ({
  isFetching,
  ...rest
}: ContractTableProps) => {
  return (
    <Table 
      responsive
      className={
        classNames({
          'is-fetching': isFetching === true
        })
      }
    >
      <ContractTHeader 
        {...rest}
      />
      <ContractTBody 
        {...rest}
      />
    </Table>
  )
}

export default ContractTable