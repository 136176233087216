import { useFetchUploadedListQuery } from "../../../../../redux/setup/setup.api"
import { SetupPdfListItemType } from "../../../../../redux/setup/setup.types"
import ShowOnCondition from "../../../../auth/ShowOnCondition.component"
import PdfTRow from "./PdfTRow/PdfTRow.component"

type PdfTBodyProps = {
  filters: string
  page: number
  setupUniqueName: string
}

const PdfTBody = ({
  filters,
  page,
  setupUniqueName
}: PdfTBodyProps) => {
  const { listItems }: {listItems: SetupPdfListItemType[]} = useFetchUploadedListQuery({
    setupUniqueName,
    page,
    filters
  }, {
    selectFromResult: ({ data }) => ({
      listItems: data?.items
    })
  })
  
  return (
    <tbody>
      <ShowOnCondition showWhen={!listItems?.length}>
        <tr>
          <td colSpan={100} className="text-center">
            <strong>Nessun elemento trovato</strong>
          </td>
        </tr>
      </ShowOnCondition>
      <ShowOnCondition showWhen={!!listItems?.length}>
        {listItems?.map((item) => (
          <PdfTRow
            key={item.code} 
            item={item}
          />
        ))}
      </ShowOnCondition>
    </tbody>
  )
}

export default PdfTBody