import { detect } from "detect-browser"

function buildExcelFile(file64: string) {
  //Convert base64 string to byte array and then to blob
  const byteCharacters = atob(file64)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  return byteArray
}

export function downloadFile(body64: string, title: string, mimeType: string) {
  const byteArray = buildExcelFile(body64)
  //Build a link and trigger click on it to download the file
  const a = window.document.createElement('a')
  a.href = window.URL.createObjectURL(new Blob([byteArray], { type: mimeType }))
  a.download = title
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const mimeFileTypes: Record<'excel' | 'csv' | 'txt', string> = {
  'excel': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'csv': 'text/csv',
  'txt': 'text/plain'
}

export const detectBrowser = () => detect()

export const a2b = (a: string) => {
  let b, c, d, e: Record<string, any> = {}, f = 0, g = 0, h = "", i = String.fromCharCode, j = a.length
  for (b = 0; 64 > b; b++) e["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".charAt(b)] = b
  for (c = 0; j > c; c++) for (b = e[a.charAt(c)], f = (f << 6) + b, g += 6; g >= 8;) ((d = 255 && f >>> (g -= 8)) || j - 2 > c) && (h += i(d))
  return h
}


export const downloadAttachment = (attachment: string) => {
  const binary = a2b(attachment.replace(/\s/g, ''))
  const len = binary.length
  const buffer = new ArrayBuffer(len)
  const view = new Uint8Array(buffer)
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i)
  }

  // Create the blob object with content-type "application/pdf"
  const blob = new Blob([view], { type: "application/pdf" })
  const url = URL.createObjectURL(blob)
  const browser = detectBrowser()
  if (browser!.name === 'safari') {
    //Is iOS
    window.location.href = url
  }

  window.open(url)
}