import { FieldValues } from "react-hook-form"

export function setQueryString(values: FieldValues) {
  let queryParams: string[] = []
  const keys = Object.keys(values) as (keyof typeof values)[]
  keys.forEach(itemKey => {
    if(values[itemKey]) {
      if(typeof values[itemKey] === 'object') {
        queryParams = [...queryParams, `${itemKey}=${encodeURIComponent(values[itemKey].value)}`]
      }
      else {
        queryParams = [...queryParams, `${itemKey}=${encodeURIComponent(values[itemKey])}`]
      }
    }
  })

  return queryParams
}