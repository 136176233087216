import { useCallback, useEffect, useState } from "react"
import { FieldValues, UseFormWatch } from "react-hook-form"

type UpdateContractPriceListHookType = {
  fetchContractPriceListCode: (values: Record<string, unknown>) => any
  contractId: string
  entityName: string
  userEmail: string
  electricUseType: string | number
  podId?: string
  watch: UseFormWatch<FieldValues>
  setUseType: (value: any) => void
}
export const useUpdateContractPriceListHook = ({
  podId,
  electricUseType,
  fetchContractPriceListCode,
  watch,
  setUseType,
  ...rest
}: UpdateContractPriceListHookType) => {
  const [contractPriceList, setContractPriceList] = useState([])
  const updateContractPriceListCode = useCallback(
    async (values: Record<string, unknown>) => {
      const response = await fetchContractPriceListCode(values)
      setContractPriceList(response.data)
    },
    [fetchContractPriceListCode]
  )

  //Fetch data on render if podData is set
  useEffect(() => {
    if (podId && electricUseType) {
      updateContractPriceListCode({
        podId,
        electricUseType,
        ...rest,
      })
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Fetch data when "electricUseTypeId" field fires onChange event
  const subscription = watch((data, { name, type }) => {
    if (name === "electricUseTypeId" && data[name]) {
      //Update "useType" state for triggering other hooks depending on this value
      setUseType(data[name].value)
      updateContractPriceListCode({
        ...rest,
        podId,
        electricUseType: data[name].value,
      })
    }
  })
  useEffect(() => {
    return () => subscription.unsubscribe()
  }, [subscription])

  return contractPriceList
}
