import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NavLink } from "react-router-dom"
import { NavItem } from "reactstrap"

const HomeNav = () => {
    return (
        <NavItem>
            <NavLink end className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`} to="/">
                <FontAwesomeIcon icon={['fas', 'home']} />{' '}
                home
            </NavLink>
        </NavItem>
    )
}

export default HomeNav