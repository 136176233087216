import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { CSSProperties, useCallback, useMemo } from "react"

import ShowOnCondition from "../../auth/ShowOnCondition.component"

type ThTableProps = {
  label?: string
  sortingName?: string
  isSortable?: boolean
  sortParam?: string
  setSortParam?: (value: string) => void
  page?: number
  filters?: string
  style?: CSSProperties
}

const ThTable = ({
  isSortable = false,
  sortParam,
  setSortParam,
  label,
  sortingName,
  style,
  ...rest
}: ThTableProps) => {
  const isActive = useMemo(() => 
    sortingName && sortParam?.indexOf(sortingName) !== -1
  , [sortingName, sortParam])

  const handleSorting = useCallback(async () => {
    const newParam = sortParam === sortingName ? `-${sortingName}` : sortingName
    setSortParam!(newParam!)
  }, [sortParam, sortingName, setSortParam])

  return (
    <th
      className={classNames({
        'sortable-column': isSortable,
        'active-col': isActive
      })}
      style={style ?? undefined}
      onClick={() => isSortable && handleSorting()}
    >
      {label}
      <ShowOnCondition showWhen={isSortable}>
        {' '}
        {isActive ? (
          <FontAwesomeIcon
            icon={sortParam!.indexOf("-") === -1 ? "sort-up" : "sort-down"}
          />
        ) : (
          <FontAwesomeIcon icon="sort" />
        )}
      </ShowOnCondition>
    </th>
  )
}

export default ThTable