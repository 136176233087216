import { api } from "../../api"
import { getEntityUriName } from "../contract.utils"
import { BankAccountItemData } from '../contract.types'

type FetchListResponse = BankAccountItemData[]

export const bankAccountDetailApi = api.injectEndpoints({
  endpoints: build => ({
    fetchContractBankAccountItems: build.query<FetchListResponse, any>({
      query: ({ contractId, entityName }) => ({
        url: `${getEntityUriName(entityName)}/bank-account-details?contractId=${contractId}`
      }),
      providesTags: (result = [], error, args) => [
        ...result.map((item) => ({ type: "BankAccountDetail", id: item.id } as const)),
        { type: "BankAccountDetail" as const, id: "LIST"}
      ],
      transformResponse: (response: { items: FetchListResponse }, meta, args) => response.items,
    }),
    fetchBankAccountData: build.query({
      query: ({ contractId, entityName }) => ({
        url: `${getEntityUriName(entityName)}/bank-account-details?contractId=${contractId}`
      }),
      providesTags: ["BankAccountDetail"]
    }),
    fetchContractBankAccoutItem: build.query({
      query: ({ contractId, itemId, entityName }: { contractId: string, itemId: string, entityName: string }) => ({
        url: `${getEntityUriName(entityName)}/bank-account-details/${itemId}?contractId=${contractId}`
      }),
    }),
    submitContractBankAccountItem: build.mutation({
      query: ({ values, entityName }: { values: Record<string, unknown>, entityName: string }) => ({
        url: `${getEntityUriName(entityName)}/bank-account-details${values.id ? `/${values.id}` : ''}`,
        method: values.id ? "PUT" : "POST",
        body: values
      }),
      invalidatesTags: (result, error, args) => [
        { type: "Contract" },
        { type: "BankAccountDetail", id: result.id },
        { type: "BankAccountDetail", id: "LIST"}
      ],
    })
  })
})

export const {
  useFetchContractBankAccountItemsQuery,
  useFetchBankAccountDataQuery,
  useLazyFetchContractBankAccoutItemQuery,
  useSubmitContractBankAccountItemMutation,
} = bankAccountDetailApi