import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import getColorByScoreCheck, { getLabelByScoreCheck } from "./utils"
import ShowOnCondition from "../auth/ShowOnCondition.component"

interface Props {
    isFiscalCode?: boolean
    customerScore?: boolean
}
const ScoreCheck = ({
    isFiscalCode,
    customerScore
}: Props) => {
  return (
    <div className={`score-check-box-${getColorByScoreCheck(customerScore)} d-flex justify-content-start mb-4`}>
        <div className="px-3 py-2 rounded d-flex gap-3">
            <FontAwesomeIcon icon={["fas", "circle"]} size="xs" className="mt-1"/>
            <div className=" d-flex flex-column justify-content-start gap-1">
                <small className="fw-bold">{getLabelByScoreCheck(customerScore)}</small>
                <ShowOnCondition showWhen={customerScore !== undefined}>  
                    <small className="text-muted">Dato calcolato {isFiscalCode ? "sul Codice Fiscale" : "sulla Partita IVA" } del cliente.</small>
                </ShowOnCondition>
            </div>        
        </div>
    </div>
  )
}

export default ScoreCheck