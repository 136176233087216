import { PdrFormCombinations, changeSupplierCombos, contextualTransferCombos, newActivationCombos, takeoverCombos, transferCombos } from "./combinations";

const pdrFormCombos: PdrFormCombinations[] = [
  ...changeSupplierCombos,
  ...transferCombos,
  ...takeoverCombos,
  ...newActivationCombos,
  ...contextualTransferCombos
];

export const isShowableSection = (
  queriebleSection: string,
  conditionSnapshot: string[]
) => {
  let queryCombo = pdrFormCombos.find(
    (combo) =>
      JSON.stringify(combo.conditions) === JSON.stringify(conditionSnapshot)
  );
  if (queryCombo) {
    let visibleSection = queryCombo.visibleSections.find(
      (section) => section === queriebleSection
    );
    return visibleSection ? true : false;
  }
  return false;
};
