import { useEffect, useState } from "react"
import { getMaintenanceStatusUpdates } from ".."

const useMaintenanceDbUpdates = () => {
  const [maintenanceData, setMaintenanceData] = useState<Record<string, any>>()
  useEffect(() => {
    getMaintenanceStatusUpdates(setMaintenanceData)
  }, [])

  return maintenanceData
}

export default useMaintenanceDbUpdates