import { Container, Row, Col } from "reactstrap";

import SettingsPageStyle from "./style";
import DefaultPageContainer from "../DefaultPageContainer/DefaultPageContainer.component";
import WelcomeHeader from "../../components/WelcomeHeader/WelcomeHeader.component";
import ColorPaletteForm from "../../components/forms/ColorPaletteForm/ColorPaletteForm.component";

function SettingsPage() {
  return (
    <div>
      <DefaultPageContainer>
        <SettingsPageStyle>
          <WelcomeHeader />
          <main>
            <Container>
              <Row>
                <Col>
                  <h1 className='main-title mb-3'>Settings</h1>
                </Col>
              </Row>
              <Row>
                <h5>Palette colori</h5>
                <ColorPaletteForm />
              </Row>
            </Container>
          </main>
        </SettingsPageStyle>
      </DefaultPageContainer>
    </div>
  );
}

export default SettingsPage;
