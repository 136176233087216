import { useMemo } from "react"

import WithSkeleton from "../../WithSkeleton/WithSkeleton.component"
import PaymentForm from "./PaymentForm.component"
import { selectContractProposalData } from "../../../redux/contract/contract.selectors"
import { useAppSelector } from "../../../redux/hooks"
import { useFetchBankAccountDataQuery } from "../../../redux/contract/bankAccountDetail/bankAccountDetail.api"
import { useSelectContractId, useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { useFetchFormFieldsValuesQuery } from "../../../redux/contract/contract.api"

const PaymentFormWithSpinner = WithSkeleton(PaymentForm)

const PaymentFormContainer = () => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  //Select form fields values
  const { 
    data: formFields,
    isFetching: isFetchingFormFields, 
    isLoading: isLoadingFormFields,
  } = useFetchFormFieldsValuesQuery({ entityName, formName: "bankAccount" })
  const fetchingFormfields = isFetchingFormFields || isLoadingFormFields

  //Select contract data with permissions
  const selectCustomerData = useMemo(() => selectContractProposalData({ entityName, contractId }), [entityName, contractId])
  const { data: customerData } = useAppSelector(selectCustomerData)
  
  const { 
    data: bankAccountDetailData, 
    isFetching: isFetchingBankAccount, 
    isLoading: isLoadingBankAccount,
  } = useFetchBankAccountDataQuery({ contractId, entityName })
  const fetchingBankAccountData = isFetchingBankAccount || isLoadingBankAccount
  
  const fetchingData = fetchingBankAccountData || fetchingFormfields

  return (
    <PaymentFormWithSpinner 
      isLoading={fetchingData}
      permissions={customerData.links}
      bankAccountFieldsValues={formFields ?? {}}
      bankAccountDetailData={bankAccountDetailData}
      contractId={contractId}
      entityName={entityName}
    />
  )
}

export default PaymentFormContainer