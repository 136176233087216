import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"

type BlockAllModalProps = {
  isOpen: boolean
  toggle: () => void
  isSubmitting: boolean
  blockAllPdp: () => void
}

const BlockAllModal = ({
  isOpen,
  toggle,
  isSubmitting,
  blockAllPdp
}: BlockAllModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon="exclamation-triangle" />
      </ModalHeader>
      <ModalBody>
        Vuoi davvero bloccare tutti i punti di fornitura di questo contratto?
      </ModalBody>
      <ModalFooter>
        <ButtonWithLoader 
          label="Blocca"
          color="danger"
          fontAwesomeIcon={["fas", "hand-paper"]}
          isLoading={isSubmitting}
          onClick={blockAllPdp}
        />
        <Button color="secondary" onClick={toggle}>Annulla</Button>
      </ModalFooter>
    </Modal>
  )
}

export default BlockAllModal