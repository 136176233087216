import { useSelectEntityName } from "../../../../../pages/providers/ListsPageProvider.provider"
import ShowOnCondition from "../../../../auth/ShowOnCondition.component"
import ThTable from "../../../ThTable/ThTable.component"

type ContractTHeaderProps = {
  sortParam: string
  setSortParam: (value: string) => void
  page: number
  filters: string
}

const ContractTHeader = (props: ContractTHeaderProps) => {
  const entityName = useSelectEntityName()

  return (
    <thead className="table-light">
      <tr>
        <ThTable label="Status" />
        <ShowOnCondition showWhen={entityName === "all"}>
          <ThTable label="Tipo contratto" />
        </ShowOnCondition>
        <ThTable 
          isSortable
          sortingName="contractCode"
          label="Codice contratto"
          {...props}
        />
        <ThTable label="Tipologia cliente" />
        <ThTable label="Valutazione" />
        <ThTable label="Nome cliente" />
        <ThTable label="Email cliente" />
        <ThTable label="Nome agente" />
        <ThTable 
          isSortable
          sortingName="insertDate"
          label="Data caricamento"
          {...props}
        />
        <ThTable 
          isSortable
          sortingName="signingDate"
          label="Data firma"
          {...props}
        />
        <ThTable />
      </tr>
    </thead>
  )
}

export default ContractTHeader