import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component";
import { FieldValues, useFormContext } from "react-hook-form";
import { errorFormLabels } from "../../utils/formLabels";
import InputWrapper from "../../inputFields/Input/InputWrapper.component";
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component";
import ShowOnCondition from "../../../auth/ShowOnCondition.component";
import { ElectricUseTypes } from "../../../../redux/contract/contract.utils";

interface Props {
  canEdit?: boolean;
  formFields: FieldValues;
  entityName: string;
}
const PodContractualChanges = ({ canEdit, formFields, entityName }: Props) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const formWatcher = watch();

  return (
    <>
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="bolt" className="me-2" />
          Modifiche contrattuali
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={12}>
              <SelectWrapper
                name="useType"
                control={control}
                label="Tipo uso"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                isClearable
                options={formFields?.electricUseType || []}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <SelectWrapper
                name="requestedPowerValue"
                control={control}
                label="Potenza richiesta totale in kW"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                isClearable
                options={formFields?.powerValue || []}
              />
            </Col>
            <Col md={6}>
              <SelectWrapper
                name="requestedConnectionVoltage"
                control={control}
                label="Tensione richiesta"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                isClearable
                options={formFields?.connectionVoltage || []}
              />
            </Col>
          </Row>
        </div>
      </div>
      <ShowOnCondition showWhen={formWatcher?.useType?.value === ElectricUseTypes.Domestico}>
        <div className="grouped">
          <div className="grouped-header">
            <FontAwesomeIcon icon="location-dot" className="me-2" />
            Indirizzo di residenza
          </div>
          <div className="grouped-body">
            <Row>
              <Col md={6}>
                <InputWrapper
                  type="text"
                  disabled={canEdit ? false : true}
                  className="form-control"
                  name="stair"
                  label="Scala"
                  rules={{
                    maxLength: 20,
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md={6}>
                <InputWrapper
                  type="text"
                  disabled={canEdit ? false : true}
                  className="form-control"
                  name="floor"
                  label="Piano"
                  rules={{
                    maxLength: 50,
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md={6}>
                <InputWrapper
                  type="text"
                  disabled={canEdit ? false : true}
                  className="form-control"
                  name="indoor"
                  label="Interno"
                  rules={{
                    maxLength: 50,
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md={6}>
                <DatePickerWrapper
                  name="from"
                  control={control}
                  label="Residente dal"
                  className="form-control"
                  errors={errors}
                  showMonthDropdown
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  rules={{ required: errorFormLabels.REQUIRED }}
                  disabled={canEdit ? false : true}
                />
              </Col>
            </Row>
          </div>
        </div>
      </ShowOnCondition>
    </>
  );
};

export default PodContractualChanges;
