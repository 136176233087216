import { Nav, NavItem, NavLink } from "reactstrap"
import classNames from 'classnames'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import FixedTermJobTabsStyle from "./style"
import { ContractStatusMap, JobTabsMap } from "../../../../redux/contract/contract.utils"
import { useAppSelector } from "../../../../redux/hooks"
import { selectContractStatusId, selectMaxFormStepAvailable } from "../../../../redux/contract/contract.selectors"
import ShowOnCondition from "../../../../components/auth/ShowOnCondition.component"
import SignContractAlert from "../../../../components/SignContractAlert/SignContractAlert.component"
import { useSelectContractId, useSelectEntityName, useSelectSelectedStep, useSetSelectedStep } from "../../../providers/ContractsPageProvider.provider"
import { isSignContractAlertVisible } from "./utils"

const FixedTermJobTabs = () => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  const selectedStep = useSelectSelectedStep()!
  const setSelectedStep = useSetSelectedStep()!
  const selectedContractStatusId = useAppSelector(selectContractStatusId({ entityName, contractId }))
  const maxFormStepAvailable = useAppSelector(selectMaxFormStepAvailable({ contractId, entityName }))

  return (
    <FixedTermJobTabsStyle>
      <Nav tabs className="subscription-nav">
        <NavItem>
          <NavLink
            onClick={() => setSelectedStep(JobTabsMap.PROPOSAL_STEP)}
            className={classNames({ active: selectedStep === JobTabsMap.PROPOSAL_STEP })}
          >
            <FontAwesomeIcon icon="address-card" />
            <span>Dati utente</span>
          </NavLink>
        </NavItem>
        <ShowOnCondition showWhen={selectedContractStatusId >= ContractStatusMap.DONE_STATUS}>
          <NavItem>
            <NavLink
              onClick={() => setSelectedStep(JobTabsMap.DOWNLOAD_CONTRACT_PDF_STEP)}
              className={classNames({ active: selectedStep === JobTabsMap.DOWNLOAD_CONTRACT_PDF_STEP })}
            >
              <FontAwesomeIcon icon="file-contract" className="me-2" />
              Contratto firmato
            </NavLink>
          </NavItem>
        </ShowOnCondition>
      </Nav>
      <ShowOnCondition
        showWhen={isSignContractAlertVisible({ maxFormStepAvailable, selectedContractStatusId })}
      >
        <SignContractAlert />
      </ShowOnCondition>
    </FixedTermJobTabsStyle>
  )
}

export default FixedTermJobTabs