import { useSelectContractId, useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { useCommitContractFinalizationMutation } from "../../../redux/contract/contract.api"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import ScrollToTop from "../../ScrollToTop/ScrollToTop.component"

const CommitContractForm = () => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  const [contractFinalization, { isLoading }] = useCommitContractFinalizationMutation()

  return (
    <div className="my-2">
      <ScrollToTop />
      <h3 className="mb-4">Finalizza il contratto</h3>
      <p>
        Se vuoi puoi rivedere o modificare i tuoi dati e,   
        quando pensi che sia tutto a posto, concludi usando 
        il tasto &quot;Conferma&quot; qui sotto.
      </p>
      <div className="d-flex justify-content-end mt-5">
        <ButtonWithLoader
          label="Conferma"
          className="ab-button"
          isLoading={isLoading}
          size="lg"
          onClick={() => contractFinalization({ entityName, contractId })}
          disabled={isLoading}
        />
      </div>
    </div>
  )
}

export default CommitContractForm