import { FieldValues } from "react-hook-form"

import WithSpinner from "../../WithSpinner/WithSpinner.component"
import FixedTermJobForm from "./FixedTermJobForm.component"

type ProposalFormContainerProps = {
  fetchingProposalData: boolean
  proposalData?: FieldValues
  fieldsValues?: FieldValues
}

const FixedTermJobFormWithSpinner = WithSpinner(FixedTermJobForm)

const FixedTermJobFormContainer = ({ 
  fetchingProposalData,
  proposalData,
  fieldsValues
}: ProposalFormContainerProps) => (
  <FixedTermJobFormWithSpinner
    proposalData={proposalData}
    userFieldsValues={fieldsValues?.user || {}}
    proposalFieldsValues={fieldsValues?.proposal || {}}
    isLoading={fetchingProposalData}
  />
)

export default FixedTermJobFormContainer