import { useMemo, useState } from "react"
import { Container } from "reactstrap"

import UserList from "../../components/lists/UserList/UserList.component"
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop.component"
import DefaultPageContainer from "../DefaultPageContainer/DefaultPageContainer.component"
import PageHeader from "../PageHeader/PageHeader.component"
import UserListStyle from "./style"
import { ListsPageContext } from "../providers/ListsPageProvider.provider"

const UserListPage = () => {
  const entityName = useMemo(() => "user", [])
  //Set shared contract data like entityName/contractId for easy retrieve in children components
  const [listData, setListData] = useState<{[key: string]: any}>({ entityName })

  return (
    <ListsPageContext.Provider
      value={{
        listData,
        setListData,
      }}
    >
      <DefaultPageContainer>
        <ScrollToTop />
        <UserListStyle>
          <PageHeader pageTitle="Utenti" icon="list" />
          <Container className="contract-container">
            <UserList />
          </Container>
        </UserListStyle>
      </DefaultPageContainer>
    </ListsPageContext.Provider>
  )
}

export default UserListPage