import styled from "styled-components"

const PermanentJobTabsStyle = styled.section`
  .subscription-nav {
    margin-bottom: 2.5rem;
    align-items: center;
    flex-wrap: nowrap;

    @media (min-width: 991.9px) {
      flex-wrap: wrap;
    }

    .nav-link {
      color: ${({ theme}) => theme.colors.palette6};
      cursor: pointer;
      padding: .5rem .95rem;
      font-size: .85rem;

      > svg {
        margin-right: .15rem;
      }

      > span {
        display: none;
      }

      .missing-data {
        color: ${({ theme }) => theme.colors.secondary[400]};
      }

      @media (min-width: 767.9px) {
        padding: .5rem .5rem;

        > span {
          display: inline-block;
        }
      }

      @media (min-width: 991.9px) {
        padding: .5rem;
        font-size: 1rem;
        
        > svg {
          margin-right: .5rem;
        }
      }

      &.disabled {
        color: ${({ theme }) => theme.colors.grayscale[200]};
      }
    }
  }
`

export default PermanentJobTabsStyle