import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "reactstrap"

import { useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { Permissions } from "../../../redux/api/types"
import CanI from "../../auth/CanI.component"
import BankAccountDashboardControlButtonsStyle from "./style"

type BankAccountDashboardControlButtonsProps = {
  toggleBankAccountDetailModal: () => void,
  permissions: Permissions
}

const BankAccountDashboardControlButtons = ({ 
  toggleBankAccountDetailModal,
  permissions,
}: BankAccountDashboardControlButtonsProps) => {
  const entityName = useSelectEntityName()

  return (
    <BankAccountDashboardControlButtonsStyle>
      <CanI doWhat={"CREATE"} withPermissions={permissions} entityName={`${entityName}BankAccountDetail`}>
        {() => (
          <Button
            size="md"
            color="secondary"
            outline
            onClick={toggleBankAccountDetailModal}
          >
            <FontAwesomeIcon icon={['fas', 'plus-circle']} className="me-2" />
            Aggiungi metodo di pagamento
          </Button>
        )}
      </CanI>
    </BankAccountDashboardControlButtonsStyle>
  )
}

export default BankAccountDashboardControlButtons