import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"

import { errorFormLabels, maxLengthLabel } from "../../utils/formLabels"

import { FormProps } from "../../utils/types"

const ProfessionalRisks = ({
  canEdit,
}: FormProps) => {
  const { register, formState: { errors } } = useFormContext()

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="triangle-exclamation" className="me-2" />
        Rischi professionali
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={6}>
            <InputWrapper
              name="inailRiskClass"
              label="Classe di rischio INAIL"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="inailRate"
              label="Tasso INAIL"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={12}>
            <InputWrapper
              name="inailEnsurancePosition"
              label="Posizione assicurativa INAIL"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
            <p className="small">
              Per le informazioni sui rischi per l’integrità e la salute dei lavoratori e misure di prevenzione 
              adottate, nonché per le informazioni di cu all’art. 40 ccnl apl, si rimanda al “modulo rilevamento rischi” 
              allegato al presente contratto, del quale é parte integrante.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ProfessionalRisks