import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FieldValues, useFormContext } from "react-hook-form"
import { useSelector } from "react-redux"
import { Col, Row } from "reactstrap"

import { selectCurrentUser } from "../../../../redux/auth/auth.selectors"
import { contractApi } from "../../../../redux/contract/contract.api"
import AsyncSelectWrapper from "../../inputFields/AsyncSelect/AsyncSelectWrapper.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../../utils/formLabels"
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component"
import { selectContractStatusId } from "../../../../redux/contract/contract.selectors"
import { ContractStatusMap } from "../../../../redux/contract/contract.utils"
import { asyncFnWrapper } from "../../utils/utils"
import { FormProps } from "../../utils/types"
import { useSelectContractId, useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider"

type GeneralDataProps = {
  userFormValues: FieldValues
} & FormProps

const GeneralData = ({ 
  canEdit, 
  fieldsValues: proposalFormValues,
  userFormValues,
}: GeneralDataProps ) => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  const { register, control, watch, setValue, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  const [fetchTowns] = contractApi.endpoints.fetchTowns.useLazyQuerySubscription()
  const { name } = useSelector(selectCurrentUser)!
  const selectedContractStatusId = useSelector(selectContractStatusId({ entityName, contractId }))
  
  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="file" className="me-2" />
        Dati
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={12}>
            <InputWrapper 
              name="userLogged"
              label="Utente"
              type="text"
              register={register}
              readOnly
              className="form-control"
              rules={{ value: formWatcher.userLogged || name }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <SelectWrapper 
              name="customerType"
              control={control}
              register={register}
              label="Tipologia cliente"
              isDisabled={formWatcher.id ? true : false}
              errors={errors}
              options={proposalFormValues?.customerType ?? []}
              rules={{
                required: errorFormLabels.REQUIRED
              }}
              whenFieldChanges={() => {
                setValue("customerVatNumber", null)
              }}
            />
            <p className="small text-muted">**Attenzione: una volta inserito non potrà essere più modificato!</p>
          </Col>
          <Col md={6}>
            <AsyncSelectWrapper 
              name="subscriptionTownName"
              control={control}
              register={register}
              label="Comune"
              errors={errors}
              isDisabled={canEdit ? false : true}
              loadOptions={(input: string) => {
                if(input.length > 2) {
                  return asyncFnWrapper(input, fetchTowns)
                }
              }}
              getOptionLabel={option => `${option.town} (${option.province})`}
              getOptionValue={option => `${option.town} (${option.province})`}
              rules={{ required: errorFormLabels.REQUIRED }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <DatePickerWrapper 
              name="compilationDate"
              control={control}
              label="Data caricamento"
              className="form-control"
              readOnly
              errors={errors}
              showMonthDropdown
              dateFormat="dd/MM/yyyy"
            />
          </Col>
          {selectedContractStatusId >= ContractStatusMap.DONE_STATUS &&
            <Col md={6}>
              <DatePickerWrapper 
                name="signingDate"
                control={control}
                label="Data firma"
                className="form-control"
                readOnly
                errors={errors}
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
              />
            </Col>
          }
        </Row>
      </div>
    </div>
  )
}

export default GeneralData