import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FieldValues, useForm } from "react-hook-form"
import { Col, Form, Row } from "reactstrap"

import { useSelectContractId, useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { Permissions } from "../../../redux/api/types"
import { useSubmitContractBankAccountItemMutation } from "../../../redux/contract/bankAccountDetail/bankAccountDetail.api"
import ShowOnCondition from "../../auth/ShowOnCondition.component"
import { canI } from "../../auth/utils"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import InputWrapper from "../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../utils/formLabels"
import { codiceFISCALE, isValidaIban } from "../utils/validations"

type BankAccountFormProps = {
  bankAccountFieldsValues: FieldValues
  bankAccountDetailData?: any
  permissions: Permissions
  toggleBankAccountDetailModal: () => void 
}

const BankAccountForm = ({
  bankAccountFieldsValues,
  bankAccountDetailData,
  permissions,
  toggleBankAccountDetailModal,
}: BankAccountFormProps) => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  let canEdit = canI(`${entityName}BankAccountDetailCREATE`, permissions).abilityCheck
  if(bankAccountDetailData) {
    canEdit = canI(`${entityName}BankAccountDetailUPDATE`, bankAccountDetailData.links).abilityCheck
  }
  const { register, control, handleSubmit, formState: { errors, isSubmitting } } = useForm<FieldValues>({
    defaultValues: bankAccountDetailData ? (
        { contractId, ...bankAccountDetailData }
      ) : (
        { contractId }
      )
  })
  const [submitBankAccountItem] = useSubmitContractBankAccountItemMutation()
  const onSubmit = async (values: FieldValues) => {
    await submitBankAccountItem({ values, entityName })
    toggleBankAccountDetailModal()
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="credit-card" className="me-2" />
          Metodo di pagamento
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={6}>
              <SelectWrapper
                name="paymentModeId"
                control={control}
                label="Modalità"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={bankAccountFieldsValues.paymentMode || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <Col md={6}>
              <InputWrapper 
                type="text"
                disabled={canEdit ? false : true}
                className="form-control text-uppercase"
                name="fiscalCode"
                label="Codice fiscale Titolare/Delegato"
                placeholder="Codice fiscale Titolare/Delegato"
                rules={{
                  required: errorFormLabels.REQUIRED,
                  validate: value => codiceFISCALE(value) || errorFormLabels.INVALID_CF
                }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <InputWrapper 
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="name"
                label="Nome Titolare/Delegato"
                placeholder="Nome Titolare/Delegato"
                rules={{ required: errorFormLabels.REQUIRED }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <InputWrapper 
                type="text"
                disabled={canEdit ? false : true}
                className="form-control"
                name="surname"
                label="Cognome Titolare/Delegato"
                placeholder="Cognome Titolare/Delegato"
                rules={{ required: errorFormLabels.REQUIRED }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <SelectWrapper
                name="billingTimeId"
                control={control}
                label="Tempistica di fatturazione"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={bankAccountFieldsValues.billingTime || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <Col md={6}>
              <InputWrapper 
                type="text"
                disabled={canEdit ? false : true}
                className="form-control text-uppercase"
                name="iban"
                label="IBAN"
                placeholder="IBAN"
                rules={{
                  required: errorFormLabels.REQUIRED,
                  validate: value => isValidaIban(value) || errorFormLabels.INVALID_IBAN
                }}
                register={register}
                errors={errors}
              />
            </Col>
          </Row>
        </div>
      </div>
      <ShowOnCondition showWhen={canEdit}>
        <div className="text-end">
          <ButtonWithLoader
            isLoading={isSubmitting}
            type="submit"
            disabled={isSubmitting}
            label="Salva"
            fontAwesomeIcon={["fas", "save"]}
          />
        </div>
      </ShowOnCondition>
    </Form>
  )
}

export default BankAccountForm