import { Col, Row } from "reactstrap"

const AvailableSetupHeader = () => {

    return (
            <>
                <Row>
                    <Col>
                        <h1 className="main-title mb-3">Setup</h1>
                    </Col>
                </Row>
            </>
    )
}

export default AvailableSetupHeader