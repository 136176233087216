import { createDraftSafeSelector } from "@reduxjs/toolkit"

import { RootState } from "../store"
import { canI } from "../../components/auth/utils"
import { ContractCategories, getEntityPluralName } from "../contract/contract.utils"
import { AsyncStatusEnum } from "./auth.utils"

export const selectAuth = (state: RootState) => state.auth

export const selectCurrentUser = createDraftSafeSelector(
  [selectAuth],
  auth => auth.currentUser
)

export const selectFetchStatus = createDraftSafeSelector(
  [selectAuth],
  user => user.fetchStatus
)

export const selectIsCheckingUserSession = createDraftSafeSelector(
  [selectCurrentUser, selectFetchStatus],
  (currentUser, fetchStatus) => 
    currentUser === null && 
    (
      fetchStatus[0] === AsyncStatusEnum.LOADING || 
      fetchStatus[0] === AsyncStatusEnum.IDLE
    )  ? true : false
)

export const selectCurrentUserId = createDraftSafeSelector(
  [selectCurrentUser],
  currentUser => currentUser?.id ?? ""
)

export const selectUsername = createDraftSafeSelector(
  [selectCurrentUser],
  currentUser => currentUser ? currentUser.email.slice(0, currentUser.email.indexOf('@')) : null
)

export const selectDisplayName = createDraftSafeSelector(
  [selectCurrentUser],
  currentUser => {
    return currentUser?.name || currentUser?.email || ""
  }
)

export const selectCurrentUserRole = createDraftSafeSelector(
  [selectCurrentUser],
  currentUser => currentUser?.role ?? ""
)

export const selectEntities = createDraftSafeSelector(
  [selectAuth],
  auth => auth.entities || []
)

export const selectUserActiveEntities = createDraftSafeSelector(
  [selectEntities],
  entities => entities?.filter(({ active }) => active === true) || []
)

export const selectUserNewCustomerEntities = createDraftSafeSelector(
  [selectUserActiveEntities],
  activeEntities => activeEntities.filter(({ category }) => category === ContractCategories.NEW_CUSTOMER) || []
)

export const selectUserOngoingCustomerEntities = createDraftSafeSelector(
  [selectUserActiveEntities],
  activeEntities => activeEntities.filter(({ category }) => category === ContractCategories.ONGOING_CUSTOMER) || []
)

export const selectUserOtherEntities = createDraftSafeSelector(
  [selectUserActiveEntities],
  activeEntities => activeEntities.filter(({ category }) => category === ContractCategories.OTHER) || []
)

export const selectUserContracts = createDraftSafeSelector(
  [selectEntities],
  entities => entities.filter(({ type, active }) => type === 'C' && active === true)
)

export const selectUserOt = createDraftSafeSelector(
  [selectEntities],
  userEntities => userEntities.filter(({ type, active }) => type === 'OT' && active === true)
)

export const selectUserAvailableContracts = createDraftSafeSelector(
  [selectUserActiveEntities, selectCurrentUser],
  (userContracts, currentUser) => {
    if(currentUser?.links) {
      const { links } = currentUser
      const availableContracts = userContracts.filter(item => (
        canI(`${getEntityPluralName(item.entityName)}GET`, links).abilityCheck ||
        canI(`${item.entityName}CREATE`, links).abilityCheck
      ))

      return availableContracts
    }

    return []
  }
)

export const selectUserAvailableOt = createDraftSafeSelector(
  [selectUserOt, selectCurrentUser],
  (userOt, currentUser) => {
    const { links } = currentUser!
    const availableOt = userOt.filter(item => (
      canI(`${getEntityPluralName(item.entityName)}GET`, links).abilityCheck ||
      canI(`${item.entityName}CREATE`, links).abilityCheck
    ))

    return availableOt
  }
)

export const selectUserAvailableNewCustomerEntities = createDraftSafeSelector(
  [selectUserNewCustomerEntities, selectCurrentUser],
  (newCustomerEntities, userData) => {
    const { links } = userData!
    const availableEntities = newCustomerEntities.filter(item => (
      canI(`${getEntityPluralName(item.entityName)}GET`, links).abilityCheck ||
      canI(`${item.entityName}CREATE`, links).abilityCheck ||
      canI(`${item.entityName}PaperDocumentGET`, links).abilityCheck
    ))

    return availableEntities
  }
)

export const selectUserAvailableOngoingCustomerEntities = createDraftSafeSelector(
  [selectUserOngoingCustomerEntities, selectCurrentUser],
  (ongoingCustomerEntities, userData) => {
    const { links } = userData!
    const availableEntities = ongoingCustomerEntities.filter(item => (
      canI(`${getEntityPluralName(item.entityName)}GET`, links).abilityCheck ||
      canI(`${item.entityName}CREATE`, links).abilityCheck ||
      canI(`${item.entityName}PaperDocumentGET`, links).abilityCheck
    ))

    return availableEntities
  }
)

export const selectUserAvailableOtherEntities = createDraftSafeSelector(
  [selectUserOtherEntities, selectCurrentUser],
  (OtherEntities, userData) => {
    const { links } = userData!
    const availableEntities = OtherEntities.filter(item => (
      canI(`${getEntityPluralName(item.entityName)}GET`, links).abilityCheck ||
      canI(`${item.entityName}CREATE`, links).abilityCheck ||
      canI(`${item.entityName}PaperDocumentGET`, links).abilityCheck
    ))

    return availableEntities
  }
)

export const selectCurrentEntity = (entityName: string) => createDraftSafeSelector(
  [selectEntities],
  entities => {
    let currentEntity
    entities.forEach(entity => {
      if(entity.entityName === entityName) {
        currentEntity = entity
      }
    })

    return currentEntity
  }
)