import { Col, Row } from "reactstrap"

import { ListDataType } from "../../../redux/list/list.types"
import Pagination from "../Pagination/Pagination.component"
import { LIST_DEFAULT_ITEMS_PER_PAGE } from "../utils"

type TableFooterProps = {
  listData: ListDataType
  page: number
  setPage: (page: number) => void
  itemsPerPage?: number
  buildExcelExportUrl?: (entityName: string, itemsPerPage: number) => string
  buildPdpExcelExportUrl?: (entityName: string, itemsPerPage: number) => string
}

const TableFooter = ({
  listData,
  page,
  setPage,
  buildExcelExportUrl,
  buildPdpExcelExportUrl,
  itemsPerPage = LIST_DEFAULT_ITEMS_PER_PAGE
}: TableFooterProps) => {

  return (
    <>
      <Row className="align-items-center">
        <Col sm={9}>
          <Pagination
            totalItems={listData?.count ?? 0}
            page={page}
            setPage={setPage}
            itemsPerPage={itemsPerPage}
          />      
        </Col>
        <Col sm={3}>
          <p className="total-items"><strong>Elementi totali:</strong> {listData?.count ?? 0}</p>
        </Col>
      </Row>
    </>
  )
}

export default TableFooter