import { Container } from "reactstrap"

import LandingPageStyle from "./style"
import DefaultPageContainer from "../DefaultPageContainer/DefaultPageContainer.component"
import WelcomeHeader from "../../components/WelcomeHeader/WelcomeHeader.component"
import AvailableSetup from "../../components/SetupEntities/AvailableSetupEntities.component"

const LandingPage = () => {

  return (
    <DefaultPageContainer>
      <LandingPageStyle>
        <WelcomeHeader />
        <main>
          <Container>
            <AvailableSetup />
          </Container>
        </main>
      </LandingPageStyle>
    </DefaultPageContainer>
  )
}

export default LandingPage