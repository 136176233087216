import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ShowOnCondition from "../../auth/ShowOnCondition.component";
import { IconName } from "@fortawesome/fontawesome-common-types";
import ScrollToTop from "../../ScrollToTop/ScrollToTop.component";
import { mapTitleToPathOrTitle } from "../../../redux/setup/setup.utils";

interface Props {
  title: string;
  iconName: IconName;
  isCsvImportAvailable?: boolean;
  isPdfImportAvailable?: boolean;
}
const SetupRow = ({
  title,
  iconName,
  isCsvImportAvailable,
  isPdfImportAvailable,
}: Props) => {

  return (
    <>
      <ScrollToTop />
      <div className='entity-row'>
        <div className='text-container'>
          <FontAwesomeIcon
            size='lg'
            className='me-2'
            icon={["fas", iconName]}
          />{" "}
          {title}
        </div>
        <div className='actions-container'>
          <ShowOnCondition showWhen={isCsvImportAvailable ?? false}>
            <Link
              className='btn btn-secondary'
              to={`/setup/${mapTitleToPathOrTitle(title)}/importa-csv`}
            >
              <FontAwesomeIcon icon='table' className='me-1' /> Importa CSV
            </Link>
          </ShowOnCondition>
          <ShowOnCondition showWhen={isPdfImportAvailable ?? false}>
            <Link
              className='btn btn-secondary'
              to={`/setup/${mapTitleToPathOrTitle(title)}/importa-pdf`}
            >
              <FontAwesomeIcon icon='files' className='me-1' /> Importa PDF
            </Link>
          </ShowOnCondition>
        </div>
      </div>
    </>
  );
};

export default SetupRow;
