import styled from "styled-components"

const HeaderStyle = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  .navbar {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.primary[600]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary[600]};
    transition: background-color 0.3s ease;

    .navbar-brand {
      padding: 1rem 0;
      width: 160px;
      height: 80px;
      display: inline-block;

      > svg {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .nav-link {
    color: ${({ theme }) => theme.colors.palette5};
    display: block;
    font-size: 0.75rem;
    font-weight: 400;
    padding: 0.15rem 0.25rem !important;
    text-align: left;
    text-decoration: none;
    text-transform: lowercase;
    transition: color 0.2s ease-out;
    position: relative;
    margin-bottom: 1rem;

    @media (min-width: 992px) {
      margin-bottom: 0;
    }

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      right: 100%;
      left: auto;
      top: 0;
      margin-top: 0;
      transform-origin: 0 50%;
      width: 5px;
      transition: transform 0.3s ease-out;
      transform: scaleX(0);
      background-color: ${({ theme }) => theme.colors.secondary[400]};

      @media (min-width: 992px) {
        width: 100%;
        left: 0;
        top: 100%;
        right: auto;
        height: 5px;
      }
    }

    &:hover:before,
    &:active:before,
    &.active:before {
      transform: scaleX(1);
    }

    > svg {
      margin-right: 0.15rem;
      vertical-align: sub;
    }

    &:hover,
    &.active {
      color: ${({ theme }) => theme.colors.grayscale[100]};
    }
  }

  .navbar-nav > .nav-item {
    margin: 0 0.15rem;
  }

  .navbar-nav .show > .nav-link {
    color: ${({ theme }) => theme.colors.grayscale[100]};
  }

  .dropdown-menu {
    border: none;
    border-radius: 0;
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;

    @media (min-width: 992px) {
      width: max-content;
      top: calc(100% + 5px);
      transform: translateX(-50%);
      border-left: 5px solid ${({ theme }) => theme.colors.secondary[400]};
      border-radius: 0 0.25rem 0.25rem 0;
      box-shadow: ${({ theme }) => theme.boxShadow};
      left: 50%;
      padding: 1rem 0.5rem;
      margin-bottom: 0;
    }

    &:before {
      border: 5px solid transparent;
      border-bottom-color: ${({ theme }) => theme.colors.grayscale[50]};
      bottom: 100%;
      content: "";
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      z-index: 1;
    }

    .dropdown-header {
      background-color: ${({ theme}) => theme.colors.palette6};
      border-radius: 2px;
      color: ${({ theme }) => theme.colors.grayscale[100]};
      font-size: 0.8rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
      padding: 0.35rem 0.5rem;

      svg {
        margin-right: 0.5rem;
      }
    }

    .dropdown-item {
      padding: 0;
      margin-bottom: 0.75rem;

      .nav-link {
        color: ${({ theme }) => theme.colors.grayscale[600]};
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background: linear-gradient(
          90deg,
          ${({ theme }) => theme.colors.grayscale[100]},
          ${({ theme }) => theme.colors.grayscale[50]}
        );
      }
    }
  }
`;

export default HeaderStyle