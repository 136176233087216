import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldValues, useFormContext } from "react-hook-form";
import { Row, Col } from "reactstrap";
import { useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider";

import { contractApi } from "../../../../redux/contract/contract.api";
import AsyncSelectWrapper from "../../inputFields/AsyncSelect/AsyncSelectWrapper.component";
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component";
import InputWrapper from "../../inputFields/Input/InputWrapper.component";
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component";
import { errorFormLabels } from "../../utils/formLabels";
import {
  asyncFnWrapper,
  CustomerTypes,
  getCustomerType,
} from "../../utils/utils";
import {
  codiceFISCALE,
  emailCheck,
  euPhoneCheck,
  mustBeAdult,
  vatNumberRegExpCheck,
} from "../../utils/validations";
import {
  electronicInvoiceRecipientCodeVisibility,
  extendedCompanyDataVisibility,
} from "../utils";
import ScoreCheck from "../../../ScoreCheck/ScoreCheck.component";
import { useUpdateScoreCheckHook } from "./hooks/useUpdateScoreCheckHook.hooks";
import { useEffect, useState } from "react";
import WithSkeleton from "../../../WithSkeleton/WithSkeleton.component";
import ShowOnCondition from "../../../auth/ShowOnCondition.component";

export type CustomerDataFormProps = {
  canEdit?: boolean
  fieldsValues?: FieldValues
  setScoreCheckResult: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  scoreCheckResult?: boolean
}

const CustomerData = ({
  canEdit,
  fieldsValues: proposalFormValues,
  setScoreCheckResult,
  scoreCheckResult
}: CustomerDataFormProps) => {
  const entityName = useSelectEntityName();
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const formWatcher = watch();

  const [fetchTowns] =
    contractApi.endpoints.fetchTowns.useLazyQuerySubscription();
  const [fetchPostalCodes] =
    contractApi.endpoints.fetchPostalCodes.useLazyQuerySubscription();
  const [fetchScoreCheck] =
    contractApi.endpoints.fetchScoreCheck.useLazyQuerySubscription();

  const [isScoreCheckLoading, setIsScoreCheckLoading] = useState(false);
  const isScoreCheckPassed = useUpdateScoreCheckHook({
    watch,
    defaultValue: formWatcher?.scoreCheckPassed,
    setIsLoading: setIsScoreCheckLoading,
    isDomestic: getCustomerType(
      CustomerTypes.DOMESTIC_CUSTOMER,
      formWatcher.customerType
    ),
    fetchScoreCheck,
  });

  const ScoreCheckWithSkeleton = WithSkeleton(ScoreCheck);

  useEffect(() => {
    setScoreCheckResult(isScoreCheckPassed);
  }, [isScoreCheckPassed, setScoreCheckResult]);
  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="address-book" className="me-2" />
        Dati cliente
      </div>
      <div className="grouped-body">
        <Row>
          {getCustomerType(
            CustomerTypes.BUSINESS_CUSTOMER,
            formWatcher.customerType
          ) && (
            <Col md={12}>
              <InputWrapper
                name="customerBusinessName"
                label="Ragione sociale"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{
                  value: formWatcher.customerBusinessName || null,
                  required: errorFormLabels.REQUIRED,
                  maxLength: 45,
                }}
              />
            </Col>
          )}
          {getCustomerType(
            CustomerTypes.DOMESTIC_CUSTOMER,
            formWatcher.customerType
          ) && (
            <>
              <Col md={5}>
                <InputWrapper
                  name="customerName"
                  label="Nome"
                  type="text"
                  register={register}
                  disabled={canEdit ? false : true}
                  className="form-control"
                  errors={errors}
                  rules={{
                    value: formWatcher.customerName || null,
                    required: errorFormLabels.REQUIRED,
                    maxLength: 80,
                  }}
                />
              </Col>
              <Col md={5}>
                <InputWrapper
                  name="customerSurname"
                  label="Cognome"
                  type="text"
                  register={register}
                  disabled={canEdit ? false : true}
                  className="form-control"
                  errors={errors}
                  rules={{
                    value: formWatcher.customerSurname || null,
                    required: errorFormLabels.REQUIRED,
                    maxLength: 80,
                  }}
                />
              </Col>
              <Col md={2}>
                <SelectWrapper
                  name="customerSex"
                  control={control}
                  register={register}
                  label="Sesso"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  options={proposalFormValues?.customerSex ?? []}
                  rules={{
                    required: errorFormLabels.REQUIRED,
                  }}
                />
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col>
            <InputWrapper
              name="customerFiscalCode"
              label="Codice fiscale"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control text-uppercase"
              errors={errors}
              rules={{
                value: formWatcher.customerFiscalCode || null,
                required: errorFormLabels.REQUIRED,
                validate: (value) => {
                  if (
                    getCustomerType(
                      CustomerTypes.DOMESTIC_CUSTOMER,
                      formWatcher.customerType
                    )
                  ) {
                    return codiceFISCALE(value) || errorFormLabels.INVALID_CF;
                  } else {
                    return (
                      codiceFISCALE(value) ||
                      vatNumberRegExpCheck(value) ||
                      errorFormLabels.INVALID_CF_VAT
                    );
                  }
                },
                maxLength: 20,
              }}
            />
          </Col>
          {getCustomerType(
            CustomerTypes.DOMESTIC_CUSTOMER,
            formWatcher.customerType
          ) && (
            <Col md={6}>
              <DatePickerWrapper
                name="customerBirthDate"
                control={control}
                label="Data di nascita"
                className="form-control"
                maxDate={new Date()}
                errors={errors}
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                rules={{
                  required: errorFormLabels.REQUIRED,
                  validate: (value) =>
                    mustBeAdult(value) || errorFormLabels.ADULT_REQUIRED,
                }}
                disabled={canEdit ? false : true}
              />
            </Col>
          )}
          {getCustomerType(
            CustomerTypes.BUSINESS_CUSTOMER,
            formWatcher.customerType
          ) && (
            <Col md={6}>
              <InputWrapper
                name="customerVatNumber"
                label="Partita IVA"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{
                  value: formWatcher.customerSurname || null,
                  required: errorFormLabels.REQUIRED,
                  pattern: {
                    value: /^[0-9]{11}$/,
                    message: errorFormLabels.INVALID_VAT,
                  },
                  maxLength: 11,
                }}
              />
            </Col>
          )}
          <ShowOnCondition showWhen={proposalFormValues?.isScoreCheckActive}>
            <Col md={12}>
              <ScoreCheckWithSkeleton
                isLoading={isScoreCheckLoading}
                isSmall
                isFiscalCode={getCustomerType(
                  CustomerTypes.DOMESTIC_CUSTOMER,
                  formWatcher.customerType
                )}
                customerScore={scoreCheckResult}
              />
            </Col>
          </ShowOnCondition>
          {getCustomerType(
            CustomerTypes.BUSINESS_CUSTOMER,
            formWatcher.customerType
          ) &&
            electronicInvoiceRecipientCodeVisibility(entityName) && (
              <>
                <Col md={6}>
                  <SelectWrapper
                    name="hasPublicAdministration"
                    control={control}
                    register={register}
                    label="Pubblica amministrazione"
                    isDisabled={canEdit ? false : true}
                    errors={errors}
                    options={proposalFormValues?.hasPublicAdministration ?? []}
                    rules={{
                      required: errorFormLabels.REQUIRED,
                    }}
                    fieldDescription="La pubblica amministrazione è l'insieme degli enti pubblici che concorrono all'esercizio e alle funzioni dell'amministrazione di uno Stato nelle materie di sua competenza."
                    whenFieldChanges={() => {
                      setValue("hasSplitPayment", null);
                    }}
                  />
                </Col>
                <Col md={6}>
                  <SelectWrapper
                    name="hasSplitPayment"
                    control={control}
                    register={register}
                    label="Split payment"
                    isDisabled={canEdit ? false : true}
                    errors={errors}
                    options={proposalFormValues?.hasSplitPayment ?? []}
                    rules={{
                      required: errorFormLabels.REQUIRED,
                    }}
                    filterOption={(candidate, input) => {
                      if (
                        parseInt(formWatcher.hasPublicAdministration?.value) ===
                        1
                      ) {
                        return parseInt(candidate.data.value) === 1;
                      }
                      return true;
                    }}
                    fieldDescription="Lo split payment, o scissione dei pagamenti , è un regime particolare che stabilisce che il debitore dell’IVA sia il cessionario/committente anziché, come avviene normalmente, il cedente/prestatore."
                  />
                </Col>
                <Col md={12}>
                  <InputWrapper
                    name="electronicInvoiceRecipientCode"
                    label="Codice destinatario fatturazione elettronica"
                    type="text"
                    register={register}
                    disabled={canEdit ? false : true}
                    className="form-control"
                    errors={errors}
                    rules={{
                      value: formWatcher.electronicInvoiceRecipientCode || null,
                      required: errorFormLabels.REQUIRED,
                      maxLength: 80,
                    }}
                  />
                </Col>
              </>
            )}
        </Row>
        <Row>
          {getCustomerType(
            CustomerTypes.DOMESTIC_CUSTOMER,
            formWatcher.customerType
          ) && (
            <>
              <Col>
                <SelectWrapper
                  name="customerBirthCountry"
                  control={control}
                  register={register}
                  label="Nazionalità"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  options={proposalFormValues?.customerBirthCountry ?? []}
                  rules={{
                    required: errorFormLabels.REQUIRED,
                  }}
                />
              </Col>
              {formWatcher.customerBirthCountry?.label === "Italia" && (
                <>
                  <Col md={6}>
                    <InputWrapper
                      name="customerBirthPlace"
                      label="Comune di nascita"
                      type="text"
                      register={register}
                      disabled={canEdit ? false : true}
                      className="form-control"
                      errors={errors}
                      rules={{
                        value: formWatcher.customerBirthPlace || null,
                        required: errorFormLabels.REQUIRED,
                        maxLength: 80,
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <InputWrapper
                      name="customerBirthProvince"
                      label="Provincia di nascita"
                      type="text"
                      register={register}
                      disabled={canEdit ? false : true}
                      className="form-control"
                      errors={errors}
                      rules={{
                        value: formWatcher.customerBirthProvince || null,
                        required: errorFormLabels.REQUIRED,
                        maxLength: 2,
                      }}
                    />
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
        <Row>
          <Col md={6}>
            <InputWrapper
              name="contactEmail"
              label="E-mail"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control text-lowercase"
              errors={errors}
              rules={{
                value: formWatcher.contactEmail || null,
                required: errorFormLabels.REQUIRED,
                validate: (value) =>
                  emailCheck(value) || errorFormLabels.INVALID_EMAIL,
                maxLength: 80,
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="contactPec"
              label="E-mail PEC"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control text-lowercase"
              errors={errors}
              rules={{
                value: formWatcher.contactPec || null,
                required: getCustomerType(
                  CustomerTypes.BUSINESS_CUSTOMER,
                  formWatcher.customerType
                )
                  ? errorFormLabels.REQUIRED
                  : false,
                validate: (value) =>
                  emailCheck(value) || errorFormLabels.INVALID_EMAIL,
                maxLength: 80,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InputWrapper
              name="contactMobileNumber"
              label="Cellulare"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control phone-number"
              errors={errors}
              rules={{
                value: formWatcher.contactMobileNumber || null,
                required: errorFormLabels.REQUIRED,
                validate: (value) =>
                  euPhoneCheck(value) || errorFormLabels.INVALID_PHONE,
                maxLength: 20,
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="contactPhoneNumber"
              label="Telefono fisso"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control phone-number"
              errors={errors}
              rules={{
                value: formWatcher.contactPhoneNumber || null,
                validate: (value) =>
                  euPhoneCheck(value) || errorFormLabels.INVALID_PHONE,
                maxLength: 20,
              }}
            />
          </Col>
        </Row>
        {getCustomerType(
          CustomerTypes.BUSINESS_CUSTOMER,
          formWatcher.customerType
        ) && (
          <>
            <Row>
              <Col md={12}>
                <legend>Indirizzo sede legale:</legend>
              </Col>
              <Col md={3}>
                <SelectWrapper
                  name="customerToponymId"
                  control={control}
                  register={register}
                  label="Toponimo"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  options={proposalFormValues?.customerToponym ?? []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              <Col md={7}>
                <InputWrapper
                  name="customerAddress"
                  label="Indirizzo"
                  type="text"
                  register={register}
                  disabled={canEdit ? false : true}
                  className="form-control"
                  errors={errors}
                  rules={{
                    value: formWatcher.customerAddress || null,
                    required: errorFormLabels.REQUIRED,
                    maxLength: 128,
                  }}
                />
              </Col>
              <Col md={2}>
                <InputWrapper
                  name="customerStreetNumber"
                  label="Civico"
                  type="text"
                  register={register}
                  disabled={canEdit ? false : true}
                  className="form-control"
                  errors={errors}
                  rules={{
                    value: formWatcher.customerStreetNumber || null,
                    required: errorFormLabels.REQUIRED,
                    maxLength: 4,
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <AsyncSelectWrapper
                  name="customerTownName"
                  control={control}
                  register={register}
                  label="Comune"
                  errors={errors}
                  isDisabled={canEdit ? false : true}
                  loadOptions={(input: string) => {
                    if (input.length > 2) {
                      return asyncFnWrapper(input, fetchTowns);
                    }
                  }}
                  getOptionLabel={(option) =>
                    `${option.town} (${option.province})`
                  }
                  getOptionValue={(option) =>
                    `${option.town} (${option.province})`
                  }
                  rules={{ required: errorFormLabels.REQUIRED }}
                  whenFieldChanges={() => setValue("customerPostalCode", null)}
                  noOptionsMessage={() => (
                    <span className="autocomplete-suggestion">
                      Indica le prime lettere del comune per trovarlo nella
                      lista
                    </span>
                  )}
                />
              </Col>
              <Col md={2}>
                <SelectWrapper
                  name="customerPostalCode"
                  control={control}
                  register={register}
                  label="CAP"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  rules={{
                    required: errorFormLabels.REQUIRED,
                  }}
                  watchedFieldProps={{
                    watch: watch,
                    watchedFieldName: "customerTownName",
                    triggerWatchFn: fetchPostalCodes,
                    returnedValuePropertyName: "town",
                    defaultWatchedFieldValue:
                      formWatcher.customerTownName || null,
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        {getCustomerType(
          CustomerTypes.BUSINESS_CUSTOMER,
          formWatcher.customerType
        ) && (
          <>
            <Row>
              <Col md={12}>
                <legend>Legale rappresentante:</legend>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <InputWrapper
                  name="identityName"
                  label="Nome"
                  type="text"
                  register={register}
                  disabled={canEdit ? false : true}
                  className="form-control"
                  errors={errors}
                  rules={{
                    value: formWatcher.identityName || null,
                    required: errorFormLabels.REQUIRED,
                    maxLength: 80,
                  }}
                />
              </Col>
              <Col md={6}>
                <InputWrapper
                  name="identitySurname"
                  label="Cognome"
                  type="text"
                  register={register}
                  disabled={canEdit ? false : true}
                  className="form-control"
                  errors={errors}
                  rules={{
                    value: formWatcher.identitySurname || null,
                    required: errorFormLabels.REQUIRED,
                    maxLength: 80,
                  }}
                />
              </Col>
              <Col md={6}>
                <DatePickerWrapper
                  name="identityBirthDate"
                  control={control}
                  label="Data di nascita"
                  className="form-control"
                  errors={errors}
                  maxDate={new Date()}
                  showYearDropdown
                  dateFormat="dd/MM/yyyy"
                  rules={{
                    required: errorFormLabels.REQUIRED,
                    validate: (value) =>
                      formWatcher.id
                        ? mustBeAdult(value) || errorFormLabels.ADULT_REQUIRED
                        : true,
                  }}
                  disabled={canEdit ? false : true}
                />
              </Col>
              <Col md={6}>
                <SelectWrapper
                  name="identityBirthCountry"
                  control={control}
                  register={register}
                  label="Nazionalità"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  options={proposalFormValues?.customerBirthCountry || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
            </Row>
            {formWatcher.identityBirthCountry?.label === "Italia" && (
              <Row>
                <Col md={10}>
                  <InputWrapper
                    name="identityTownName"
                    label="Comune di nascita"
                    type="text"
                    register={register}
                    disabled={canEdit ? false : true}
                    className="form-control"
                    errors={errors}
                    rules={{
                      value: formWatcher.identityTownName || null,
                      required: formWatcher.id
                        ? false
                        : errorFormLabels.REQUIRED,
                      maxLength: 80,
                    }}
                  />
                </Col>
                <Col md={2}>
                  <InputWrapper
                    name="identityProvince"
                    label="Provincia di nascita"
                    type="text"
                    register={register}
                    disabled={canEdit ? false : true}
                    className="form-control"
                    errors={errors}
                    rules={{
                      value: formWatcher.identityProvince || null,
                      required: formWatcher.id
                        ? false
                        : errorFormLabels.REQUIRED,
                      maxLength: 2,
                    }}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md={2}>
                <SelectWrapper
                  name="customerSex"
                  control={control}
                  register={register}
                  label="Sesso"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  options={proposalFormValues?.customerSex || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              <Col md={10}>
                <InputWrapper
                  name="identityFiscalCode"
                  label="Codice fiscale"
                  type="text"
                  register={register}
                  disabled={canEdit ? false : true}
                  className="form-control text-uppercase"
                  errors={errors}
                  rules={{
                    required: errorFormLabels.REQUIRED,
                    validate: (value) =>
                      codiceFISCALE(value) || errorFormLabels.INVALID_CF,
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col md={6}>
            <SelectWrapper
              name="identityDocumentType"
              control={control}
              register={register}
              label="Tipo documento"
              isDisabled={canEdit ? false : true}
              errors={errors}
              options={proposalFormValues?.identityDocumentType || []}
              rules={{ required: errorFormLabels.REQUIRED }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="identityDocumentNumber"
              label="Numero documento"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{
                value: formWatcher.identityDocumentNumber || null,
                required: errorFormLabels.REQUIRED,
                maxLength: 20,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <DatePickerWrapper
              name="identityDocumentReleaseDate"
              control={control}
              label="Rilasciato il"
              className="form-control"
              errors={errors}
              showYearDropdown
              dateFormat="dd/MM/yyyy"
              maxDate={new Date()}
              rules={{ required: errorFormLabels.REQUIRED }}
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={6}>
            <SelectWrapper
              name="identityDocumentEntity"
              control={control}
              register={register}
              label="Rilasciato da"
              isDisabled={canEdit ? false : true}
              errors={errors}
              options={proposalFormValues?.identityDocumentEntity || []}
              rules={{ required: errorFormLabels.REQUIRED }}
            />
          </Col>
        </Row>
        {getCustomerType(
          CustomerTypes.BUSINESS_CUSTOMER,
          formWatcher.customerType
        ) &&
          extendedCompanyDataVisibility(entityName) && (
            <Row>
              <Col md={6}>
                <SelectWrapper
                  name="companyType"
                  control={control}
                  register={register}
                  label="Tipologia azienda"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  options={proposalFormValues?.companyType || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              <Col md={6}>
                <SelectWrapper
                  name="cciaaRegistered"
                  control={control}
                  register={register}
                  label="Iscritta CCIAA"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  options={proposalFormValues?.cciaaRegistered || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              {parseInt(formWatcher.cciaaRegistered?.value) === 1 && (
                <>
                  <Col md={6}>
                    <AsyncSelectWrapper
                      name="cciaaTownName"
                      control={control}
                      register={register}
                      label="Comune CCIAA"
                      errors={errors}
                      isDisabled={canEdit ? false : true}
                      loadOptions={(input: string) => {
                        if (input.length > 2) {
                          return asyncFnWrapper(input, fetchTowns);
                        }
                      }}
                      getOptionLabel={(option) =>
                        `${option.town} (${option.province})`
                      }
                      getOptionValue={(option) =>
                        `${option.town} (${option.province})`
                      }
                      rules={{
                        required: errorFormLabels.REQUIRED,
                      }}
                      noOptionsMessage={() => (
                        <span className="autocomplete-suggestion">
                          Indica le prime lettere del comune per trovarlo nella
                          lista
                        </span>
                      )}
                    />
                  </Col>
                  <Col md={6}>
                    <InputWrapper
                      name="cciaaSection"
                      label="Sezione CCIAA"
                      type="text"
                      register={register}
                      disabled={canEdit ? false : true}
                      className="form-control"
                      errors={errors}
                      rules={{
                        value: formWatcher.cciaaSection || null,
                        required: errorFormLabels.REQUIRED,
                        maxLength: 80,
                      }}
                    />
                  </Col>
                  <Col md={12}>
                    <InputWrapper
                      name="rea"
                      label="REA"
                      type="text"
                      register={register}
                      disabled={canEdit ? false : true}
                      className="form-control"
                      errors={errors}
                      rules={{
                        value: formWatcher.rea || null,
                        required: errorFormLabels.REQUIRED,
                        maxLength: 80,
                      }}
                    />
                  </Col>
                </>
              )}
            </Row>
          )}
      </div>
    </div>
  );
};

export default CustomerData;
