import { CustomerTypes } from "../../../components/forms/utils/utils";
import { ContractTypes, ElectricUseTypes, VatTypes } from "../contract.utils";

export enum podQueriebleSections {
  ACCISE = "excise",
  ALLEGATO_F = "attachmentF",
  ALLEGATO_E_F = "attachmentEF",
}

export type PodFormCombinations = {
  conditions: string[];
  visibleSections: string[];
};

export const changeSupplierCombos: PodFormCombinations[] = [
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.CAMBIO_FORNITORE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
];

export const transferCombos: PodFormCombinations[] = [
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.DOMESTIC_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.DOMESTIC_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ALLEGATO_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.Domestico,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.DIECI,
    ],
    visibleSections: [
      podQueriebleSections.ACCISE,
      podQueriebleSections.ALLEGATO_F,
      podQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.DIECI,
    ],
    visibleSections: [
      podQueriebleSections.ACCISE,
      podQueriebleSections.ALLEGATO_F,
      podQueriebleSections.ALLEGATO_E_F,
    ],
  },
  {
    conditions: [
      ContractTypes.VOLTURA,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
];

export const takeoverCombos: PodFormCombinations[] = [
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.DOMESTIC_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.DOMESTIC_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ACCISE, podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.DOMESTIC_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.DOMESTIC_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.DOMESTIC_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ACCISE, podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.DOMESTIC_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.Domestico,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ACCISE, podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ACCISE, podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.SUBENTRO,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
];

export const newConnectionCombos: PodFormCombinations[] = [
  {
    conditions: [
      ContractTypes.NUOVA_CONNESSIONE,
      CustomerTypes.DOMESTIC_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ALLEGATO_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_CONNESSIONE,
      CustomerTypes.DOMESTIC_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_CONNESSIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.Domestico,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_CONNESSIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_CONNESSIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ACCISE, podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_CONNESSIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_CONNESSIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_CONNESSIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ACCISE, podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_CONNESSIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
];

export const newActivationCombos: PodFormCombinations[] = [
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.DOMESTIC_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ALLEGATO_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.DOMESTIC_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.Domestico,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ACCISE, podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ACCISE, podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.NUOVA_ATTIVAZIONE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
];

export const contextualTransferCombos: PodFormCombinations[] = [
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.DOMESTIC_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ALLEGATO_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.DOMESTIC_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.Domestico,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ACCISE, podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.AltriUsi,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.VENTIDUE,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.DIECI,
    ],
    visibleSections: [podQueriebleSections.ACCISE, podQueriebleSections.ALLEGATO_F, podQueriebleSections.ALLEGATO_E_F],
  },
  {
    conditions: [
      ContractTypes.VOLTURA_CONTESTUALE,
      CustomerTypes.BUSINESS_CUSTOMER.toString(),
      ElectricUseTypes.LuciScale,
      VatTypes.NAZIONALI,
    ],
    visibleSections: [podQueriebleSections.ACCISE],
  },
];
