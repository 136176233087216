import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useForm } from "react-hook-form"
import { Button, Col, Form, Row } from "reactstrap"

import ButtonWithLoader from "../../../ButtonWithLoader/ButtonWithLoader.component"
import InputWrapper from "../../../forms/inputFields/Input/InputWrapper.component"
import { setQueryString } from "./utils"

type UserListFiltersProps = {
  setFilters: (filters: string) => void
}

const UserListFilters = ({
  setFilters
}: UserListFiltersProps) => {
  const { register, reset, handleSubmit, formState: { isSubmitting } } = useForm()

  const onSubmit = async (values: any) => {
    const queryParams = setQueryString(values)
    const filters = queryParams.length ? queryParams.join('&') : ""
    setFilters(filters)    
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="filter" className="me-2" />
          Applica filtri
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={4}>
              <InputWrapper 
                name="name"
                label="Nome"
                className="form-control"
                type="text"
                register={register}
              />
            </Col>
            <Col md={4}>
              <InputWrapper 
                register={register}
                name="code"
                type="text"
                className="form-control"
                label="Codice"
              />
            </Col>
            <Col md={4}>
              <InputWrapper 
                register={register}
                name="email"
                type="text"
                className="form-control text-lowercase"
                label="Email"
              />
            </Col>
          </Row>
          <div className="text-end my-3">
            <Button
              className="me-1"
              outline
              size="lg"
              color="warning"
              onClick={() => reset()}
            >
              <FontAwesomeIcon icon="undo" className="me-2" />
              Reset
            </Button>
            <ButtonWithLoader
              isLoading={isSubmitting}
              type="submit"
              size="lg"
              disabled={isSubmitting}
              label="Filtra"
              fontAwesomeIcon={["fas", "filter"]}
            />
          </div>
        </div>
      </div>
    </Form>
  )
}

export default UserListFilters