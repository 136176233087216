import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component";
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component";
import { FieldValues, useFormContext } from "react-hook-form";
import { errorFormLabels } from "../../utils/formLabels";

interface Props {
  canEdit?: boolean;
  formFields: FieldValues;
  entityName: string;
}
const PodDeactivation = ({ canEdit, formFields, entityName }: Props) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="bolt" className="me-2" />
          Disattivazione utenza
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={6}>
              <SelectWrapper
                name="socketDemolition"
                control={control}
                label="Con demolizione presa"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={formFields?.socketDemolition || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <Col md={6}>
              <DatePickerWrapper
                name="doNotExecuteBefore"
                control={control}
                label="Da effettuare non prima del"
                className="form-control"
                errors={errors}
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                rules={{ required: errorFormLabels.REQUIRED }}
                disabled={canEdit ? false : true}
              />
            </Col>
          </Row>
        </div>
      </div>
  );
};

export default PodDeactivation;
