import { useFetchListDataQuery } from "../../../../../redux/list/list.api"
import { UserItemType } from "../../../../../redux/list/list.types"
import ShowOnCondition from "../../../../auth/ShowOnCondition.component"
import { buildFetchUrl } from "../../utils"
import UserTRow from "./UserTRow/UserTRow.component"

type UserTBodyProps = {
  filters: string
  page: number
}

const UserTBody = ({
  filters,
  page,
}: UserTBodyProps) => {
  const { listItems } = useFetchListDataQuery({
    apiUrl: buildFetchUrl(),
    filters,
    page,
  }, {
    selectFromResult: ({ data }) => ({
      listItems: data.items
    })
  })
  
  return (
    <tbody>
      <ShowOnCondition showWhen={!listItems?.length}>
        <tr>
          <td colSpan={100} className="text-center">
            <strong>Nessun elemento trovato</strong>
          </td>
        </tr>
      </ShowOnCondition>
      <ShowOnCondition showWhen={!!listItems?.length}>
        {listItems.map((item: UserItemType) => (
          <UserTRow
            key={item.id} 
            item={item}
          />
        ))}
      </ShowOnCondition>
    </tbody>
  )
}

export default UserTBody