import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { FieldValues, useFormContext } from "react-hook-form"
import { Button, Col, Row } from "reactstrap"

import AttachmentsDropzone from "../../../AttachmentsDropzone/AttachmentsDropzone.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import { errorFormLabels } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import HealthInsuranceDropzoneStyle from "./style"

const HealthInsuranceDropzone = ({
  canEdit,
}: FormProps) => {
  const { register, setValue, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  return (
    <HealthInsuranceDropzoneStyle className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="notes-medical" className="me-2" />
        Carica il fronte e retro della Tessera sanitaria
      </div>
      <div className="grouped-body">
        <Row>
          <Col>
            <AttachmentsDropzone
              addFile={(data: FieldValues) => {
                setValue("mandatory.healthInsuranceFront", data)
              }}
              isMulti={false}
              openDialogWithBtn={canEdit}
              disabled={!canEdit}
            />
            <section className="file-info">
              <InputWrapper
                name="mandatory.healthInsuranceFront.userFileName"
                register={register}
                label="File tessera sanitaria fronte"
                className="form-control"
                type="text"
                readOnly
                errors={errors}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
              {formWatcher.mandatory?.healthInsuranceFront?.userFileName &&
                <span className="file-loaded-check">
                  <FontAwesomeIcon 
                    icon={['fas', 'circle-check']} 
                  />
                </span>
              }
              {!!formWatcher.mandatory?.healthInsuranceFront?.id &&
                <p className="attachment-datetime">
                  Caricato il &nbsp;
                  <strong>{moment(formWatcher.mandatory.healthInsuranceFront.insertDate).format('DD/MM/YYYY')}</strong> &nbsp;
                  alle <strong>{moment.utc(formWatcher.mandatory.healthInsuranceFront.insertDate).tz('Europe/Rome').format('HH:mm')}</strong>
                  {' '} - {' '}
                  <Button
                    className="p-0"
                    color="link"
                    href={formWatcher.mandatory.healthInsuranceFront.internalFileURL}
                  >
                    Scarica allegato
                  </Button>
                </p>
              }
            </section>
          </Col>
          <Col>
            <AttachmentsDropzone
              addFile={(data: FieldValues) => {
                setValue("mandatory.healthInsuranceBack", data)
              }}
              isMulti={false}
              openDialogWithBtn={canEdit}
              disabled={!canEdit}
            />
            <section className="file-info">
              <InputWrapper
                name="mandatory.healthInsuranceBack.userFileName"
                register={register}
                label="File tessera sanitaria retro"
                className="form-control"
                type="text"
                readOnly
                errors={errors}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
              {formWatcher.mandatory?.healthInsuranceBack?.userFileName &&
                <span className="file-loaded-check">
                  <FontAwesomeIcon 
                    icon={['fas', 'circle-check']} 
                  />
                </span>
              }
              {!!formWatcher.mandatory?.healthInsuranceBack?.id &&
                <p className="attachment-datetime">
                  Caricato il &nbsp;
                  <strong>{moment(formWatcher.mandatory.healthInsuranceBack.insertDate).format('DD/MM/YYYY')}</strong> &nbsp;
                  alle <strong>{moment.utc(formWatcher.mandatory.healthInsuranceBack.insertDate).tz('Europe/Rome').format('HH:mm')}</strong>
                  {' '} - {' '}
                  <Button
                    className="p-0"
                    color="link"
                    href={formWatcher.mandatory.healthInsuranceBack.internalFileURL}
                  >
                    Scarica allegato
                  </Button>
                </p>
              }
            </section>
          </Col>
        </Row>
      </div>
    </HealthInsuranceDropzoneStyle>
  )
}

export default HealthInsuranceDropzone