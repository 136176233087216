import { Collapse } from "reactstrap"
import styled from "styled-components"

const NavMenuStyle = styled(Collapse)`
  .dropdown-menu {
    border-left: 5px solid ${({ theme }) => theme.colors.secondary[400]};
    border-radius: 0 0.25rem 0.25rem 0;
    box-shadow: ${({ theme }) => theme.boxShadow};
    left: 50%;
    padding: 0.5rem 0;
    top: calc(100% + 5px);
    transform: translateX(-50%);
    width: max-content;

    @media (max-width: 991.9px) {
      width: 100%;
      transform: none;
      border-top: none;
      border-radius: 0;
      border-left: none;

      &:before {
        display: none;
      }
    }

    &:before {
      border: 5px solid transparent;
      border-bottom-color: ${({ theme }) => theme.colors.grayscale[100]};
      bottom: 100%;
      content: "";
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      z-index: 1;
    }

    .menu-items {
      display: flex;
      flex-wrap: wrap;
    }

    .dropdown-group {
      padding: .5rem .5rem;
      flex-basis: 25%;
      flex-shrink: 0;
      min-width: 200px;
      margin-bottom: .75rem;
      border-right: 1px solid ${({ theme }) => theme.colors.grayscale[100]};

      @media (max-width: 991.9px) {
        min-width: 100%;
        margin-bottom: 0;
        padding: .5rem .5rem;
      }

      &:last-child,
      &:nth-child(4n) {
        border-right: none;
      }
    }

    .dropdown-header {
      background-color: ${({ theme}) => theme.colors.palette6};
      border-radius: 2px;
      color: ${({ theme }) => theme.colors.grayscale[100]};
      font-size: .8rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
      padding: 0.35rem 0.5rem;

      svg {
        margin-right: .5rem;
      }
    }

    .dropdown-item {
      padding: 0;

      &:hover {
        background: linear-gradient(90deg,${({ theme }) => theme.colors.grayscale[100]},${({ theme }) => theme.colors.grayscale[50]});
      }

      .nav-link {
        color: ${({ theme }) => theme.colors.grayscale[600]};
        display: block;
        font-size: .8rem;
        font-weight: 600;
        padding: 0.15rem 0.7rem !important;
        text-align: left;
        text-decoration: none;
        transition: color .2s ease-out;
        position: relative;

        &:before {
          content: "";
          left: 0;
          height: 100%;
          margin-top: 0;
          top: 0;
          width: 5px;
          position: absolute;
          transform: scaleX(0);
          transform-origin: 0 50%;
          transition: transform .3s ease-out;
        }
        
        &:hover:before,
        &:active:before {
          transform: scaleX(1);
        }
      }
    }
  }
`

export default NavMenuStyle