import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component";
import { FieldValues, useFormContext } from "react-hook-form";
import InputWrapper from "../../inputFields/Input/InputWrapper.component";
import { isRequesedVariationsNonePopulated } from "./utils";
import { errorFormLabels } from "../../utils/formLabels";

interface Props {
  canEdit?: boolean;
  formFields: FieldValues;
  entityName: string;
}
const PdrContractualChanges = ({ canEdit, formFields, entityName }: Props) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const formWatch = watch();

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="fire" className="me-2" />
        Modifiche contrattuali
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={6}>
            <SelectWrapper
              name="supplyUseType"
              control={control}
              label="Utilizzo fornitura"
              isDisabled={canEdit ? false : true}
              errors={errors}
              register={register}
              isClearable
              options={formFields?.gasUseCategory || []}
            />
          </Col>
          <Col md={6}>
            <SelectWrapper
              name="useType"
              control={control}
              label="Tipo uso"
              isDisabled={canEdit ? false : true}
              errors={errors}
              register={register}
              isClearable
              options={formFields?.gasUseType || []}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <legend className="col-form-label">
              Variazione potenzialità impianto:
            </legend>
          </Col>
          <Col md={6}>
            <InputWrapper
              type="number"
              className="form-control"
              disabled={canEdit ? false : true}
              name="requestedTotalPower"
              label="Potenzialità richiesta"
              rules={{
                min: 0,
                maxLength: 80,
                validate: (value) =>
                  value
                    ? true
                    : !isRequesedVariationsNonePopulated(formWatch) ||
                      errorFormLabels.REQUIRED,
              }}
              errors={errors}
              register={register}
            />
          </Col>
          <Col md={6}>
            <SelectWrapper
              name="requestedTotalPowerMesureUnit"
              control={control}
              label="Unità di misura"
              isDisabled={canEdit ? false : true}
              errors={errors}
              register={register}
              isClearable
              rules={{
                validate: (value) =>
                  value
                    ? true
                    : !isRequesedVariationsNonePopulated(formWatch) ||
                      errorFormLabels.REQUIRED,
              }}
              options={formFields?.powerMesureUnit || []}
            />
          </Col>
          <Col md={12}>
            <legend className="col-form-label">
              Potenzialità attuale installata:
            </legend>
          </Col>
          <Col md={6}>
            <InputWrapper
              type="number"
              className="form-control"
              disabled={canEdit ? false : true}
              name="actualTotalPower"
              label="Potenzialità attuale"
              rules={{
                min: 0,
                maxLength: 80,
                validate: (value) =>
                  value
                    ? true
                    : !isRequesedVariationsNonePopulated(formWatch) ||
                      errorFormLabels.REQUIRED,
              }}
              errors={errors}
              register={register}
            />
          </Col>
          <Col md={6}>
            <SelectWrapper
              name="actualTotalPowerMesureUnit"
              control={control}
              label="Unità di misura"
              isDisabled={canEdit ? false : true}
              errors={errors}
              register={register}
              isClearable
              rules={{
                validate: (value) =>
                  value
                    ? true
                    : !isRequesedVariationsNonePopulated(formWatch) ||
                      errorFormLabels.REQUIRED,
              }}
              options={formFields?.powerMesureUnit || []}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PdrContractualChanges;
