import { createContext, useContext } from "react"
import { ListsTabsMap } from "../../redux/contract/contract.utils"

export type ListsPageContextType = {
  listData: Record<string, any>
  setListData: (args: { [key: string]: any }) => void,
  selectedTab?: ListsTabsMap,
  setSelectedTab?: (step: ListsTabsMap) => void,
}

export const ListsPageContext = createContext<ListsPageContextType>({
  listData: {},
  setListData: () => {},
  selectedTab: ListsTabsMap.PROPOSAL_TAB,
  setSelectedTab: () => {}
})

export const useListsPageContext = () => useContext(ListsPageContext)

export const useSelectSelectedTab = () => {
  const { selectedTab } = useListsPageContext()
  return selectedTab
}

export const useSetSelectSelectedTab = () => {
  const { setSelectedTab } = useListsPageContext()
  return setSelectedTab
}

export const useSelectEntityName = (): string => {
  const { listData } = useListsPageContext()
  return listData?.entityName ?? ""
}

export const useSelectContractId = (): string => {
  const { listData } = useListsPageContext()
  return listData?.contractId ?? ""
}