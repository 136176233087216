import { HeaterStep, HeaterTabsMap, ContractStatusMap } from "../../../../redux/contract/contract.utils"

export function isTabDisabled(tabNumber: number, formStep: number) {
  if(tabNumber > formStep) return true
  return false
}

//Check wether data for this tab exists or not
export function isMissingData(
  tabNumber: number, 
  maxFormStepAvailable: number, 
  contractSteps: HeaterStep,
  tabName: keyof HeaterStep
) {
  if(
    tabNumber < maxFormStepAvailable &&
    contractSteps[tabName] === false
  ) {
    //data is missing for this tab
    return true 
  }
  //all data is there
  return false 
}

//Show the alert when at least hasPod or hasPdr step is TRUE.
type IsSignContractAlertType = {
  maxFormStepAvailable: number
  selectedContractStatusId: number
}
export function isSignContractAlertVisible({
  maxFormStepAvailable,
  selectedContractStatusId,
}: IsSignContractAlertType) {  
  return (
    maxFormStepAvailable >= HeaterTabsMap.DOWNLOAD_CONTRACT_PDF_STEP && 
    selectedContractStatusId === ContractStatusMap.ONGOING_STATUS
  ) ? true : false
}