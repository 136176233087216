import ButtonStyles from "./Button.styles"
import FormStyles from "./Form.styles"
import GenericStyles from "./Generic.styles"
import ModalStyles from "./Modal.styles"
import TableStyles from "./Table.styles"
import CustomStyles from "./Custom.styles"

const GlobalStyles = () => (
  <>
    <GenericStyles />
    <ButtonStyles />
    <ModalStyles />
    <FormStyles />
    <TableStyles />
    <CustomStyles />
  </>
)

export default GlobalStyles