import { FieldValues } from "react-hook-form"

import WithSpinner from "../../WithSpinner/WithSpinner.component"
import OngoingCustomersForm from "./OngoingCustomersForm.component"

type OngoingCustomersFormContainerProps = {
  fetchingProposalData: boolean
  proposalData?: FieldValues
  fieldsValues: FieldValues
}

const OngoingCustomersFormWithSpinner = WithSpinner(OngoingCustomersForm)

const OngoingCustomersFormContainer = ({ 
  fetchingProposalData,
  proposalData,
  fieldsValues
}: OngoingCustomersFormContainerProps) => (
  <OngoingCustomersFormWithSpinner
    proposalData={proposalData}
    userFieldsValues={fieldsValues?.user || {}}
    proposalFieldsValues={fieldsValues?.proposal || {}}
    isLoading={fetchingProposalData}
  />
)

export default OngoingCustomersFormContainer