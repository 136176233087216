import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";
import { Col, Row } from "reactstrap";

import InputWrapper from "../../../inputFields/Input/InputWrapper.component";
import SelectWrapper from "../../../inputFields/Select/SelectWrapper.component";
import { errorFormLabels } from "../../../utils/formLabels";
import { FormProps } from "../../../utils/types";
import { useSelectEntityName } from "../../../../../pages/providers/ContractsPageProvider.provider";
import ShowableSection from "../../../../ShowableSection/ShowableSection.component";
import { podQueriebleSections } from "../../../../../redux/contract/pod/combinations";
import { isShowableSection } from "../../../../../redux/contract/pod/pod.utils";

const PodOtherAttachmentsDataForm = ({
  customerType,
  canEdit,
  fieldsValues: podFieldsValues,
}: FormProps) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const formWatcher = watch();
  const entityName = useSelectEntityName();

  return (
    <ShowableSection
            section={podQueriebleSections.ALLEGATO_E_F}
            conditions={[
              entityName ?? "",
              customerType?.value ?? "",
              formWatcher.electricUseTypeId?.value ?? "",
              formWatcher.vatClassId?.value ?? "",
            ]}            
            checkCallback={isShowableSection}
          >
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="list" className="me-2" />
          Altri dati per allegati E ed F
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="cciaa"
                label="Cciaa"
                rules={{
                  maxLength: 80,
                  required: errorFormLabels.REQUIRED,
                }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <SelectWrapper
                name="section"
                control={control}
                label="Sezione"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={podFieldsValues?.section || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <Col md={4}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="rea"
                label="Rea"
                rules={{
                  maxLength: 80,
                  required: errorFormLabels.REQUIRED,
                }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={4}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="atecoCode"
                label="Codice Ateco"
                rules={{
                  maxLength: 8,
                  required: errorFormLabels.REQUIRED,
                }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={4}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="tableYear"
                label="Tabella anno"
                rules={{
                  maxLength: 80,
                  required: errorFormLabels.REQUIRED,
                }}
                register={register}
                errors={errors}
              />
            </Col>
          </Row>
        </div>
      </div>
    </ShowableSection>
  );
};

export default PodOtherAttachmentsDataForm;
