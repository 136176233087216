import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { Form } from "reactstrap"
import { useSelectContractId, useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"

import { Permissions } from "../../../redux/api/types"
import { useSubmitContractProductMutation } from "../../../redux/contract/product/product.api"
import ShowOnCondition from "../../auth/ShowOnCondition.component"
import { canI } from "../../auth/utils"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import AirConditionerData from "./AirConditionerData/AirConditionerData.component"
import AirConditionerTable from "./AirConditionerTable/AirConditionerTable.component"
import HeaterData from "./HeaterData/HeaterData.component"
import { setDefaultFormData } from "./utils"

type ProductFormProps = {
  productFieldsValues: FieldValues
  productData?: any
  permissions: Permissions,
  customerType: Record<string, string>
}

const ProductForm = ({
  permissions,
  productData,
  productFieldsValues,
  customerType
}: ProductFormProps) => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()  
  let canEdit = canI(`${entityName}ProductDetailCREATE`, permissions).abilityCheck
  if(productData) {
    canEdit = canI(`${entityName}ProductDetailUPDATE`, productData.links).abilityCheck
  }

  const formProps = useForm<FieldValues>({
    defaultValues: {
      contractId,
      ...setDefaultFormData(productData),
    }
  })
  const { handleSubmit, formState: { isSubmitting } } = formProps
  const [submitProductData] = useSubmitContractProductMutation()
  const onSubmit = async (values: FieldValues) => {
    await submitProductData({ values, entityName })
  }

  return (
    <FormProvider {...formProps}>
      <Form 
        onSubmit={handleSubmit(onSubmit)}
        className="my-5"
      >
        <ShowOnCondition showWhen={entityName === "airConditioner"}>
          <AirConditionerData
            canEdit={canEdit}
            fieldsValues={productFieldsValues}
          />
          <AirConditionerTable 
            canEdit={canEdit}
            fieldsValues={productFieldsValues}
            customerType={customerType}
          />
        </ShowOnCondition>
        <ShowOnCondition showWhen={entityName === "heater"}>
          <HeaterData 
            canEdit={canEdit}
            customerType={customerType}
          />
        </ShowOnCondition>
        <ShowOnCondition showWhen={canEdit}>
          <div className="text-end">
            <ButtonWithLoader
              isLoading={isSubmitting}
              type="submit"
              disabled={isSubmitting}
              label="Salva"
              fontAwesomeIcon={["fas", "save"]}
            />
          </div>
        </ShowOnCondition>
      </Form>
    </FormProvider>
  )
}

export default ProductForm