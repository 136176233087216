import DefaultPageContainerStyle from './style'
import Header from '../../components/Header/Header.component'

interface Props {
  children: React.ReactNode;
}

const DefaultPageContainer: React.FC<Props> = ({ children }) => {
  return (
    <DefaultPageContainerStyle>
      <Header />
      {children}
    </DefaultPageContainerStyle>
  )
}

export default DefaultPageContainer