import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CadastralDataForm, {
  CadastralDataFormProps,
} from "../../forms/CadastralDataForm/CadastralDataForm.component";
import ShowOnCondition from "../../auth/ShowOnCondition.component";
import { isNewCustomerContract, isOngoingCustomerContract } from "../../../redux/contract/contract.utils";
import { useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider";
import OngoingCustomerCadastralDataForm from "../../forms/OngoingCustomerCadastralDataForm/OngoingCustomerCadastralDataForm.component";

export type CadastralDataDetailModalProps = {
  isOpen: boolean;
  toggle: () => void;
} & Omit<
  CadastralDataFormProps,
  "entityName" | "contractId" | "toggleCadastralDataDetailModal"
>;

const CadastralDataDetailModal = ({
  isOpen,
  toggle,
  ...rest
}: CadastralDataDetailModalProps) => {
  const entityName = useSelectEntityName()
  return (
  <div className="action-modal">
    <Modal
      className="status-modal"
      size="lg"
      backdrop="static"
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle} data-bs-theme="dark">
        <FontAwesomeIcon icon="location-dot" className="me-2" />
        Punti di fornitura
      </ModalHeader>
      <ModalBody>
        <ShowOnCondition showWhen={isNewCustomerContract(entityName)}>
          <CadastralDataForm
            toggleCadastralDataDetailModal={toggle}
            {...rest}
          />
        </ShowOnCondition>
        <ShowOnCondition showWhen={isOngoingCustomerContract(entityName)}>
          <OngoingCustomerCadastralDataForm
            toggleCadastralDataDetailModal={toggle}
            {...rest}
          />
        </ShowOnCondition>
      </ModalBody>
    </Modal>
  </div>
)};

export default CadastralDataDetailModal;
