import { useEffect, useState } from "react"
import { FieldValues, UseFormWatch } from "react-hook-form"

type UpdateScoreCheckHookProps = {
  watch: UseFormWatch<FieldValues>
  defaultValue?: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  isDomestic: boolean,
  fetchScoreCheck: (values: Record<string, unknown>) => any
}

export const useUpdateScoreCheckHook = ({
  watch,
  defaultValue,
  setIsLoading,
  isDomestic,
  fetchScoreCheck
}: UpdateScoreCheckHookProps) => {
  const [scoreCheckPassed, setScoreCheckPassed] = useState(defaultValue)
  const isTriggerValid = (value: string) => {
    if(value?.length > 10) return true
  }
  const getFieldNameByCustomerType = (isDomestic: boolean) =>  isDomestic ? "customerFiscalCode" : "customerVatNumber"
  const updateScoreCheck = async (values: Record<string, unknown>) => {
    const response = await fetchScoreCheck(values)
    setScoreCheckPassed(response?.data?.scoreCheckPassed)    
    setIsLoading(false)
  }

  const subscription = watch((data, { name, type }) => { 
    if(name === 'customerType')
      setScoreCheckPassed(undefined)
    if(name === getFieldNameByCustomerType(isDomestic) && isTriggerValid(data[name])) {
      setIsLoading(true)
      updateScoreCheck({
        fiscalCode: isDomestic ? data["customerFiscalCode"] : data["customerVatNumber"]
      })
    }
  })
  useEffect(() => {    
    return () => subscription.unsubscribe()
  }, [subscription, defaultValue])

  return scoreCheckPassed
}