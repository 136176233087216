import React from 'react'
import { ToastContainer } from "react-toastify"
import { ThemeProvider } from 'styled-components'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'

import GlobalStyles from './styles/GlobalStyles.styles'
import WithSpinner from './components/WithSpinner/WithSpinner.component'
import AppRouter from './components/AppRouter/AppRouter.component'
import { appTheme } from './styles/ThemeVariables'
import { useCheckUserSessionQuery } from './redux/auth/auth.api'
import useMaintenanceDbUpdates from './firebase/hooks/useMaintenanceDbUpdates.hooks'
import MaintenancePage from './pages/MaintenancePage/MaintenancePage.component'
import MaintenanceAlert from './components/MaintenanceAlert/MaintenanceAlert.component'
import { useAppSelector } from './redux/hooks'
import { selectCurrentColors } from './redux/settings/settings.selectors'

//Font awesome library
registerLocale("it", it)
setDefaultLocale("it")
library.add(fas)
const AppRouterWithSpinner = WithSpinner(AppRouter)

function App() {
  const { isLoading } = useCheckUserSessionQuery(undefined)
  const maintenanceData = useMaintenanceDbUpdates()
  
  const currentPalette = useAppSelector(selectCurrentColors)
  const defaultTheme = appTheme(currentPalette)

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      {maintenanceData?.is_under_maintenance === true ? (
        <MaintenancePage maintenanceData={maintenanceData}/>
      ) : (
        <>
          <AppRouterWithSpinner isLoading={isLoading} />
          <ToastContainer autoClose={2000} />
          {maintenanceData?.show_maintenance_alert === true &&
            <MaintenanceAlert maintenanceAlertText={maintenanceData?.maintenance_alert_text} />
          }
        </>
      )}
    </ThemeProvider>
  )
}

export default App