import { useLocation, useNavigate } from "react-router"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { Form } from "reactstrap"

import { canI } from "../../auth/utils"
import { Permissions } from "../../../redux/api/types"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import GeneralData from "./GeneralData/GeneralData.component"
import ShowOnCondition from "../../auth/ShowOnCondition.component"
import GeneralConditions from "./GeneralConditions/GeneralConditions.component"
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy.component"
import { setDefaultDate } from "../utils/utils"
import { useSubmitProposalDataMutation } from "../../../redux/contract/contract.api"
import { useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
// import { testData } from "./utils"

type CustomerFormProps = {
  customerFieldsValues: FieldValues
  customerData?: any
}

const CustomerForm = ({ 
  customerFieldsValues, 
  customerData,
}: CustomerFormProps) => {
  const entityName = useSelectEntityName()
  const navigate = useNavigate()
  const actualUrl = useLocation()
  const canEdit = customerData ? canI(`${entityName}UPDATE`, customerData.links as Permissions).abilityCheck : true

  const [submitCustomer] = useSubmitProposalDataMutation()
  
  const formProps = useForm<FieldValues>({ 
    defaultValues: customerData ? ({
      ...customerData,
      customerBirthDate: setDefaultDate(customerData.customerBirthDate),
      documentIdentityReleaseDate: setDefaultDate(customerData.documentIdentityReleaseDate),
      documentIdentityDueDate: setDefaultDate(customerData.documentIdentityDueDate),
    }) : {}
  })
  const { handleSubmit, formState: { isSubmitting } } = formProps

  const onSubmit = async (values: FieldValues) => {
    const response = await submitCustomer({ values, entityName })    
    //Update actual URL with contract id if it's a POST mutation
    if(!values.id && "data" in response) {
      navigate(`${actualUrl.pathname}/${response.data.id}`)
    }
  }

  return (
    <FormProvider {...formProps}>
      <Form 
        onSubmit={handleSubmit(onSubmit)} 
        className="my-5"
      >
        <GeneralData 
          fieldsValues={customerFieldsValues}
          canEdit={canEdit}
        />
        <GeneralConditions />
        <PrivacyPolicy />
        <ShowOnCondition showWhen={canEdit}>
          <div className="text-end">
            <ButtonWithLoader
              isLoading={isSubmitting}
              type="submit"
              disabled={isSubmitting}
              label="Salva"
              fontAwesomeIcon={["fas", "save"]}
            />
          </div>
        </ShowOnCondition>
      </Form>
    </FormProvider>
  )
}

export default CustomerForm