import { initializeApp } from 'firebase/app'
import { 
  getAuth, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged,
  User,
  sendPasswordResetEmail,
} from "firebase/auth"
import { getDatabase, ref, onValue } from "firebase/database"

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
}

export const FirebaseErrorMessagesIt = {
  'auth/user-not-found' : "La mail inserita non è autorizzata ad accedere",
  'auth/too-many-requests': "L'accesso a questo account è stato temporaneamente disabilitato a causa di troppi tentativi falliti di login. Puoi ripristinarlo immediatamente reimpostando la password o puoi provare nuovamente più tardi.",
  'auth/wrong-password': "La password inserita non è valida.",
}

const app = initializeApp(config)
export const auth = getAuth(app)
const db = getDatabase(app)

export const signIn = (email: string, password: string) => { 
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user
        resolve(user)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const userSignOut = () => signOut(auth)

export const getCurrentUser = () => {
  return new Promise<User>((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, userAuth => {
      unsubscribe()
      resolve(userAuth!)
    }, reject)
  })
}

export const resetUserPassword = (email: string) => {
  return sendPasswordResetEmail(auth, email)
}

export const getMaintenanceStatusUpdates = (
  setManintenanceData: (data: Record<string, any>) => void
) => {
  const dbRef = ref(db)
  onValue(dbRef, (snapshot) => {
    const data = snapshot.val()
    console.log(data)
    setManintenanceData(data)
  })
}