import { FieldValues } from "react-hook-form"
import { ListGroup } from "reactstrap"

import CadastralDataItem from "./CadastralDataItem/CadastralDataItem.component"
import { BankAccountItemData, CadastralDataItemType } from "../../../redux/contract/contract.types"

type CadastralDataItemsProps = {
  cadastralFieldsValues: FieldValues
  cadastralDataItems: CadastralDataItemType[] | []
  customerType: Record<string, string>
  bankAccountData: BankAccountItemData[]
}

const CadastralDataItems = ({
  cadastralDataItems,
  cadastralFieldsValues,
  ...rest
}: CadastralDataItemsProps) => (    
  <>
    {cadastralDataItems.length ? 
      <ListGroup>
        {cadastralDataItems.map((item: CadastralDataItemType) => (
          <CadastralDataItem 
            key={item.id}
            item={item}
            cadastralFieldsValues={cadastralFieldsValues}
            {...rest}
          />
        ))}
      </ListGroup>
    :
      <p>Nessun <strong>punto di fornitura</strong> presente</p>
    }
  </>
)

export default CadastralDataItems