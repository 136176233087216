import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"

import { useSelectEntityName } from "../../../../../pages/providers/ContractsPageProvider.provider"
import ShowOnCondition from "../../../../auth/ShowOnCondition.component"
import DatePickerWrapper from "../../../inputFields/DatePicker/DatePickerWrapper.component"
import InputWrapper from "../../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../../../utils/formLabels"
import { FormProps } from "../../../utils/types"
import { codiceFISCALE, euPhoneCheck, vatNumberRegExpCheck } from "../../../utils/validations"
import {isOldAccountHolderClientCodeNeeded, isOldAccountHolderPersonTypeNeeded, isPreviousHolderRequired } from "./utils"

const PreviousHolder = ({
  fieldsValues: pdrFieldsValues,
  canEdit,
}: FormProps) => {
  const entityName = useSelectEntityName()
  const { register, control, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  return (
    <ShowOnCondition showWhen={isPreviousHolderRequired(entityName)}>
      <Row>
        <Col>
          <legend className="col-form-label">Dati vecchio intestatario:</legend>
          <p className="small text-muted">
            A seconda dei dati inseriti alcuni campi saranno da compilare obbligatoriamente. 
            Ad esempio il campo "Partita IVA" diventa obbligatorio se si inserisce una "Ragione Sociale", 
            diventa invece NON obbligatorio inserendo "Nome" e "Cognome" del vecchio intestatario.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <SelectWrapper
            name="oldAccountHolderCustomerType"
            control={control}
            label="Tipologia cliente vecchio intestatario"
            isDisabled={canEdit ? false : true}
            errors={errors}
            register={register}
            options={pdrFieldsValues?.oldAccountHolderCustomerType || []}
            rules={{ required: errorFormLabels.REQUIRED }}
          />
        </Col>
        <ShowOnCondition showWhen={isOldAccountHolderClientCodeNeeded(entityName)}>
          <Col md={12}>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={canEdit ? false : true}
              name="oldAccountHolderClientCode"
              label="Codice cliente"
              rules={{ maxLength: 20 }}
              register={register}
            />
          </Col>
        </ShowOnCondition>
        <ShowOnCondition showWhen={parseInt(formWatcher.oldAccountHolderCustomerType?.value) === 2}>
          <Col md={6}>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={canEdit ? false : true}
              name="oldAccountHolderClientBusinessName"
              label="Ragione Sociale"
              register={register}
              rules={{ required: errorFormLabels.REQUIRED, maxLength: 80 }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={canEdit ? false : true}
              name="oldAccountHolderVatNumber"
              label="Partita IVA"
              register={register}
              rules={{ 
                maxLength: 11,
                required: errorFormLabels.REQUIRED,
                pattern: {
                  value: /^[0-9]{11}$/,
                  message: errorFormLabels.INVALID_VAT
                }
              }}
            />
          </Col>
        </ShowOnCondition>
        <ShowOnCondition showWhen={parseInt(formWatcher.oldAccountHolderCustomerType?.value) === 1}>
          <Col md={6}>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={canEdit ? false : true}
              name="oldAccountHolderName"
              label="Nome"
              register={register}
              rules={{ required: errorFormLabels.REQUIRED, maxLength: 80 }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={canEdit ? false : true}
              name="oldAccountHolderSurname"
              label="Cognome"
              register={register}
              rules={{ required: errorFormLabels.REQUIRED, maxLength: 80 }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={canEdit ? false : true}
              name="oldAccountHolderFiscalCode"
              label="Codice fiscale"
              register={register}
              rules={{ 
                maxLength: 16,
                required: errorFormLabels.REQUIRED,
                validate: value => (codiceFISCALE(value) || vatNumberRegExpCheck(value)) || errorFormLabels.INVALID_CF_VAT
              }}
            />
          </Col>
        </ShowOnCondition>
        <ShowOnCondition showWhen={isOldAccountHolderPersonTypeNeeded(entityName)}>
          <Col md={6}>
            <SelectWrapper
              name="oldAccountHolderPersonType"
              control={control}
              label="Il vecchio intestatario è"
              isDisabled={canEdit ? false : true}
              errors={errors}
              register={register}
              options={formWatcher.oldAccountHolderVatNumber ? (
                (pdrFieldsValues?.oldAccountHolderPersonTypeBUS || [])
              ) : (
                (pdrFieldsValues?.oldAccountHolderPersonTypeDOM || [])
              )}
              rules={{ required: errorFormLabels.REQUIRED }}
            />
          </Col>
          <ShowOnCondition showWhen={parseInt(formWatcher.oldAccountHolderPersonType?.value) === 1}>
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control phone-number"
                disabled={canEdit ? false : true}
                name="oldAccountHolderPhone"
                label="Telefono"
                register={register}
                errors={errors}
                rules={{ 
                  maxLength: 20,
                  required: errorFormLabels.REQUIRED,
                  validate: value => euPhoneCheck(value) || errorFormLabels.INVALID_PHONE,
                }}
              />
            </Col>
          </ShowOnCondition>
          <ShowOnCondition showWhen={parseInt(formWatcher.oldAccountHolderPersonType?.value) === 2}>
            <Col md={12}>
              <SelectWrapper
                name="unavailabilityReason"
                control={control}
                label="Motivo irreperibilità"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={pdrFieldsValues?.unavailabilityReason || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
          </ShowOnCondition>
        </ShowOnCondition>
        <ShowOnCondition 
          showWhen={
            isOldAccountHolderPersonTypeNeeded(entityName) &&
            parseInt(formWatcher.oldAccountHolderPersonType?.value) === 3
          }
        >
          <Col md={6}>
            <DatePickerWrapper
              name="deathDate"
              control={control}
              label="Data decesso"
              className="form-control"
              errors={errors}
              showMonthDropdown
              dateFormat="dd/MM/yyyy"
              maxDate={new Date()}
              disabled={canEdit ? false : true}
              rules={{ required: errorFormLabels.REQUIRED }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={canEdit ? false : true}
              name="deathPlace"
              label="Luogo decesso"
              register={register}
              rules={{ 
                maxLength: 80,
                required: errorFormLabels.REQUIRED,
              }}
            />
          </Col>
          <Col md={12}>
            <SelectWrapper
              name="deceasedTie"
              control={control}
              label="Legame con il deceduto"
              isDisabled={canEdit ? false : true}
              errors={errors}
              register={register}
              options={pdrFieldsValues?.deceasedTie || []}
              rules={{ required: errorFormLabels.REQUIRED }}
            />
          </Col>
          <ShowOnCondition showWhen={formWatcher.deceasedTie?.label.toLowerCase() === 'altro'}>
            <Col md={12}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="otherTie"
                label="Altro legame"
                register={register}
                rules={{ 
                  maxLength: 80,
                  required: errorFormLabels.REQUIRED,
                }}
              />
            </Col>
          </ShowOnCondition>
        </ShowOnCondition>
      </Row>
    </ShowOnCondition>
  )
}

export default PreviousHolder