import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext, useWatch } from "react-hook-form"
import { Col, Row } from "reactstrap"

import ShowOnCondition from "../../../auth/ShowOnCondition.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { codiceFISCALE, emailCheck, euPhoneCheck } from "../../utils/validations"

const ApplicantData = ({
  canEdit,
  fieldsValues
}: FormProps) => {
  const { register, control, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()
  const applicantRole = useWatch({ 
    control, 
    name: "applicantRole" 
  }) 

  return (
    <ShowOnCondition showWhen={!!formWatcher.customerCode}>
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="user" className="me-2" />
          Il richiedente:
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={6}>
              <InputWrapper 
                name="applicantName"
                className="form-control"
                label="Nome"
                type="text"
                register={register}
                errors={errors}
                disabled={canEdit ? false : true}
                rules={{ required: errorFormLabels.REQUIRED, maxLength: 80 }}
              />
            </Col>
            <Col md={6}>
              <InputWrapper 
                name="applicantSurname"
                label="Cognome"
                className="form-control"
                type="text"
                register={register}
                errors={errors}
                disabled={canEdit ? false : true}
                rules={{ required: errorFormLabels.REQUIRED, maxLength: 80 }}
              />
            </Col>
            <Col md={6}>
              <InputWrapper 
                type="text"
                disabled={canEdit ? false : true}
                className="form-control text-uppercase"
                name="applicantFiscalCode"
                label="Codice fiscale"
                rules={{
                  maxLength: 20,
                  required: errorFormLabels.REQUIRED,
                  validate: value => codiceFISCALE(value) || errorFormLabels.INVALID_CF
                }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control phone-number"
                disabled={canEdit ? false : true}
                name="applicantPhone"
                label="Telefono"
                register={register}
                errors={errors}
                rules={{ 
                  maxLength: 20,
                  required: errorFormLabels.REQUIRED,
                  validate: value => euPhoneCheck(value) || errorFormLabels.INVALID_PHONE,
                }}
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="applicantEmail"
                label="E-mail"
                rules={{ 
                  maxLength: 80,
                  validate: value => emailCheck(value) || errorFormLabels.INVALID_EMAIL,
                }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <SelectWrapper
                name="applicantRole"
                control={control}
                label="Il richiedente in qualità di"
                isDisabled={canEdit ? false : true}
                errors={errors}
                options={fieldsValues?.applicantRole || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <ShowOnCondition showWhen={parseInt(applicantRole?.value) === 6}>
              <Col md={12}>
                <InputWrapper
                  type="text"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="applicantRoleOther"
                  label="Altro (specificare)"
                  rules={{ 
                    maxLength: 80,
                    required: errorFormLabels.REQUIRED,
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
            </ShowOnCondition>
          </Row>
        </div>
      </div>
    </ShowOnCondition>
  )
}

export default ApplicantData