import { ContractListType, ListDataType } from "../../../../../redux/list/list.types"
import ShowOnCondition from "../../../../auth/ShowOnCondition.component"
import ProposalTRow from "./ProposalTRow/ProposalTRow.component"

type ProposalTBodyProps = {
  sortParam: string
  filters: string
  page: number
  listData: ListDataType
}

const ProposalTBody = ({
  sortParam,
  listData,
}: ProposalTBodyProps) => {
  
  return (
    <tbody>
      <ShowOnCondition showWhen={!listData?.count}>
        <tr>
          <td colSpan={100} className="text-center">
            <strong>Nessun elemento trovato</strong>
          </td>
        </tr>
      </ShowOnCondition>
      <ShowOnCondition showWhen={!!listData?.count}>
        {listData?.items.map((item) => (
          <ProposalTRow 
            key={item.id} 
            item={item as ContractListType}
            sortParam={sortParam}
          />
        ))}
      </ShowOnCondition>
    </tbody>
  )
}

export default ProposalTBody