import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { FieldValues } from "react-hook-form"
import { Button, Card, CardBody, CardTitle } from "reactstrap"

import { useSelectContractId, useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { selectCurrentUserRole } from "../../../redux/auth/auth.selectors"
import { UserRoles } from "../../../redux/auth/auth.utils"
import { selectContractStatusId } from "../../../redux/contract/contract.selectors"
import { BankAccountItemData, CadastralDataItemType } from "../../../redux/contract/contract.types"
import { ContractStatusMap, getItemStatusLabel, getSupplyPointStatusIcon } from "../../../redux/contract/contract.utils"
import { useDeletePodItemMutation, useLazyFetchPodDataQuery, useLazyFetchPodItemHistoryQuery } from "../../../redux/contract/pod/pod.api"
import { useAppSelector } from "../../../redux/hooks"
import { isThisItemLoading } from "../../../utils"
import CanI from "../../auth/CanI.component"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import DeleteItem from "../../DeleteItemModal/DeleteItemModal.component"
import PdpStatusHistoryModal from "../../PdpStatusHistoryModal/PdpStatusHistoryModal.component"
import PdpStatusUpdateModal from "../../PdpStatusUpdateModal/PdpStatusUpdateModal.component"
import PdpDetailModal from "../PdpDetailModal/PdpDetailModal.component"
import PodItemStyle from "./style"

type PodItemProps = {
  item: any
  cadastralItem: CadastralDataItemType
  formFields: FieldValues
  customerType: Record<string, string>
  bankAccountData: BankAccountItemData[]
}

const PodItem = ({
  item,
  cadastralItem,
  ...rest
}: PodItemProps) => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  const role = useAppSelector(selectCurrentUserRole)
  const contractStatus = useAppSelector(selectContractStatusId({ entityName, contractId }))
  const [isOpenNewPdp, setIsOpenNewPdp] = useState(false)
  const toggleNewPdp = () => setIsOpenNewPdp(prevState => !prevState)
  const [isOpenEditPdp, setIsOpenEditPdp] = useState(false)
  const toggleEditPdp = () => setIsOpenEditPdp(prevState => !prevState)
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const toggleDelete = () => setDeleteIsOpen(prevState => !prevState)
  const [isOpenStatusHistory, setIsOpenStatusHistory] = useState(false)
  const toggleStatusHistory = () => setIsOpenStatusHistory(prevState => !prevState)
  const [isOpenUpdateStatus, setIsOpenUpdateStatus] = useState(false)
  const toggleUpdateStatus = () => setIsOpenUpdateStatus(prevState => !prevState)

  const [fetchPodItemHistoryData, {
    data: podHistoryData,
    isLoading: isLoadingPodHistoryData,
    isFetching: isFetchingPodHistoryData,
  }, { 
    lastArg: { itemId: lastPodHistoryLoadedItemId },
  }] = useLazyFetchPodItemHistoryQuery()
  const fetchingHistoryData = isLoadingPodHistoryData || isFetchingPodHistoryData

  const [fetchPodData, {
    data: podData,
    isLoading: isLoadingPodData,
    isFetching: isFetchingPodData,
  }, {
    lastArg: { podId: lastPodLoadedItemId }
  }] = useLazyFetchPodDataQuery()
  const fetchingPodData = isLoadingPodData || isFetchingPodData
  
  const [deletePodItem, { isLoading: isDeletingPod }] = useDeletePodItemMutation()

  return (
    <PodItemStyle>
      <header>
        <FontAwesomeIcon icon="bolt" className="me-2" />
        POD
      </header>
      {item ? (
        <Card>
          <CardBody>
            <CardTitle>
              <FontAwesomeIcon
                className="me-2"
                icon={getSupplyPointStatusIcon(item.supplyPointStatusId)}
                title={getItemStatusLabel(item.supplyPointStatusId)}
              />
              {item.no}
            </CardTitle>
            {
              (role === UserRoles.USER_ROLE_ADMIN || role === UserRoles.USER_ROLE_VERIFIER) &&
              contractStatus >= ContractStatusMap.DONE_STATUS &&
                <CanI 
                  doWhat="GET" 
                  withPermissions={item.links} 
                  entityName={`${entityName}Pod`}
                >
                  {({ action }) => (
                    <div className="mb-2">
                      <ButtonWithLoader 
                        label="Vedi lo storico del contratto"
                        fontAwesomeIcon={["fas", "history"]}
                        size="sm"
                        color="secondary"
                        outline
                        disabled={isThisItemLoading({ 
                          itemId: item.id, 
                          lastLoadedItemId: lastPodHistoryLoadedItemId, 
                          otherLoadingChecks: fetchingHistoryData 
                        })}
                        isLoading={isThisItemLoading({ 
                          itemId: item.id, 
                          lastLoadedItemId: lastPodHistoryLoadedItemId, 
                          otherLoadingChecks: fetchingHistoryData 
                        })}
                        onClick={async() => {
                          await fetchPodItemHistoryData({ entityName, pdpType: "pod", itemId: item.id })
                          toggleStatusHistory()
                        }}
                      />
                      <PdpStatusHistoryModal
                        isOpen={isOpenStatusHistory}
                        toggle={toggleStatusHistory}
                        itemHistoryData={podHistoryData!}
                      />
                    </div>
                  )}
                </CanI>
            }
            <CanI 
              doWhat="PATCH" 
              withPermissions={item.links} 
              entityName={`${entityName}Pod`}
            >
              {({ action }) => (
                <div className="mb-2">
                  <Button 
                    outline 
                    size="sm" 
                    color="secondary"
                    onClick={toggleUpdateStatus}
                  >
                    <FontAwesomeIcon icon="exchange-alt" className="me-2" />
                    Modifica stato contratto
                  </Button>
                  <PdpStatusUpdateModal
                    isOpen={isOpenUpdateStatus}
                    toggle={toggleUpdateStatus}
                    currentPdpStatus={item.supplyPointStatusId}
                    actionUrl={action}
                    pdpType="pod"
                  />
                </div>
              )}
            </CanI>
            <ButtonWithLoader
              label="Dettagli"
              fontAwesomeIcon={["fas", "eye"]}
              color="secondary"
              outline
              size="sm"
              className="mb-2"
              isLoading={isThisItemLoading({ 
                itemId: item.id, 
                lastLoadedItemId: lastPodLoadedItemId, 
                otherLoadingChecks: fetchingPodData
              })}
              onClick={async() => {
                await fetchPodData({ podId: item.id, entityName })
                setIsOpenEditPdp(true)
              }}
            />
            <CanI 
              doWhat="DELETE" 
              withPermissions={item.links} 
              entityName={`${entityName}Pod`}
            >
              {({ action }) => (
                <>
                  <div className="mb-2">
                    <Button 
                      outline 
                      size="sm" 
                      color="danger"
                      onClick={toggleDelete}
                    >
                      <FontAwesomeIcon icon="trash-alt" size="sm" className="me-2" />
                      Elimina
                    </Button>
                  </div>
                  <DeleteItem
                    isOpen={deleteIsOpen}
                    toggle={toggleDelete}
                    deleteFn={async () => {
                      await deletePodItem(action)
                      toggleDelete()
                    }}
                    isDeleting={isDeletingPod}
                  />
                </>
              )}
            </CanI>
          </CardBody>
          <PdpDetailModal
            pdpType="pod"
            cadastralDetailData={cadastralItem}
            isOpen={isOpenEditPdp}
            toggle={toggleEditPdp}
            pdpData={podData}
            {...rest}
          />
        </Card>
      ) : (
        <Card>
          <CardBody>
            <p className="small">Nessun POD associato a questo punto di fornitura</p>
            <CanI
              doWhat="CREATE" 
              withPermissions={cadastralItem.links} 
              entityName={`${entityName}Pod`}
            >
              {() => (
                <>
                  <Button
                    size="md"
                    color="secondary"
                    outline
                    onClick={toggleNewPdp}
                  >
                    <FontAwesomeIcon icon={['fas', 'plus-circle']} className="me-2" />
                    Aggiungi punto di fornitura
                  </Button>
                  <PdpDetailModal 
                    pdpType="pod"
                    cadastralDetailData={cadastralItem}
                    isOpen={isOpenNewPdp}
                    toggle={toggleNewPdp}
                    {...rest}
                  />
                </>
              )}
            </CanI>
          </CardBody>
        </Card>
      )}
    </PodItemStyle>
  )
}

export default PodItem