type IsThisItemLoadingType = {
  itemId: string;
  lastLoadedItemId: string;
  otherLoadingChecks?: boolean;
};
export function isThisItemLoading({
  itemId,
  lastLoadedItemId,
  otherLoadingChecks,
}: IsThisItemLoadingType) {
  return itemId === lastLoadedItemId && otherLoadingChecks === true
    ? true
    : false;
}

export function convertArrayToObject(dataArray: Record<string, any>[]) {
  return dataArray.reduce((accumulator, item) => {
    accumulator[item.id] = item;
    return accumulator;
  }, {});
}
