import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

const selectSettings = (state: RootState) => state.settings
export const selectCurrentColors = createDraftSafeSelector(
    [selectSettings],
    settings => settings.currentPalette.colors
)
export const selectCurrentName = createDraftSafeSelector(
    [selectSettings],
    settings => settings.currentPalette.name
)