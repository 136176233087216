import { useEffect } from "react"
import { Control, FieldValues, UseFormSetValue, useWatch } from "react-hook-form"

type UpdateTotalPriceProps = {
  fieldsToWatch: string[]
  customerType?: Record<string, string>
  control: Control<FieldValues, any>
  setValue: UseFormSetValue<FieldValues>
}

export const useUpdateTotalPrice = ({
  fieldsToWatch,
  customerType,
  control,
  setValue,
}: UpdateTotalPriceProps) => {
  const values = useWatch({
    control,
    name: fieldsToWatch,
  })

  useEffect(() => {
    if (values.length && customerType) {
      //Calculate TOT values with these rules:
      //priceVatIncluded: Totale (IVA inclusa) = Totale (IVA esclusa) con IVA al 10% (se cliente DOM) oppure 
      //con IVA al 22% (se cliente BUS) - arrotondamento a 2 cifre decimali per eccesso
      //priceToPay: Totale da corrispondere = priceVatIncluded
      const totalNoTaxes = values.reduce(
        (accumulator, item) => {  
          if(item) { 
            accumulator = parseFloat(accumulator) + parseFloat(item)
          }
          return accumulator
        }
      , 0)      
      //Calculate TOT with taxes and rounds it to the first two decimals
      const taxes = totalNoTaxes * (parseInt(customerType.value) === 1 ? 0.10 : 0.22)
      const priceVatIncluded = Math.round((totalNoTaxes + taxes) * 100) / 100
      const priceToPay = priceVatIncluded
      
      setValue("priceVatNotIncluded", totalNoTaxes.toFixed(2))
      setValue("priceVatIncluded", priceVatIncluded.toFixed(2) ?? null)
      setValue("priceToPay", priceToPay.toFixed(2) ?? null)
    }
  }, [values, setValue, customerType])
}
