import { useSelector } from 'react-redux'
import { DropdownItem } from 'reactstrap'
import { NavLink, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CanI from '../../auth/CanI.component'
import { Entity } from '../../../redux/auth/auth.reducer'
import { selectCurrentUser } from '../../../redux/auth/auth.selectors'
import { getEntityPluralName, getEntityPath } from '../../../redux/contract/contract.utils'
import { useAppDispatch } from '../../../redux/hooks'
import { resetContract } from '../../../redux/contract/contract.actions'

type MenuItemProps = {
  item: Pick<Entity, "name" | "entityName" | "icon">
}

const MenuItem = ({ item: { name, entityName, icon } }: MenuItemProps ) => {
  const user = useSelector(selectCurrentUser)!
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  return (
    <div className="dropdown-group">
      <DropdownItem header>
        <FontAwesomeIcon icon={['fas', icon]} />
        {name}
      </DropdownItem>
      <CanI doWhat={"CREATE"} withPermissions={user.links} entityName={entityName}>
        {() => (
          <DropdownItem tag="div">
            <NavLink 
              onClick={() => {
                if(pathname !== `/${getEntityPath(entityName)}`) {
                  dispatch(resetContract())}
                }
              }
              end
              className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
              to={`/${getEntityPath(entityName)}`}
            >
              Nuovo
            </NavLink>
          </DropdownItem>
        )}
      </CanI>
      <CanI doWhat={"GET"} withPermissions={user.links} entityName={getEntityPluralName(entityName)}>
        {() => (
          <DropdownItem tag="div">
            <NavLink 
              className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
              to={`/${getEntityPath(entityName)}/all`}
            >
              Vedi tutti
            </NavLink>
          </DropdownItem>
        )}
      </CanI>
    </div>
  )
}

export default MenuItem
