import { Nav, NavbarToggler } from "reactstrap"
import UserNav from "./UserNav/UserNav.component"
import ContractsMenu from "./ContractsMenu/ContractsMenu.component"
import { useState } from "react"
import { useAppSelector } from "../../redux/hooks"
import { selectUserAvailableNewCustomerEntities, selectUserAvailableOngoingCustomerEntities, selectUserAvailableOtherEntities } from "../../redux/auth/auth.selectors"
import { handleMenuClick } from "./utils"
import HomeNav from "./HomeNav/HomeNav.component"
import LogoutNav from "./LogoutNav/LogoutNav.component"
import NavMenuStyle from "./style"
import { ContractCategories, getCategoryLabel, getCategoryPath } from "../../redux/contract/contract.utils"
import ShowOnCondition from "../auth/ShowOnCondition.component"
import SetupNav from "./SetupNav/SetupNav.component"

const NavMenu = () => {
    
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(!isOpen)
    const availableNewCustomerContracts = useAppSelector(selectUserAvailableNewCustomerEntities)
    const availableOngoingCustomerContracts = useAppSelector(selectUserAvailableOngoingCustomerEntities)
    const availableOtherContracts = useAppSelector(selectUserAvailableOtherEntities)
    
    return (
        <>
            <NavbarToggler onClick={toggle} />
            <NavMenuStyle isOpen={isOpen} navbar>
                <Nav className="ms-auto" navbar onClick={e => handleMenuClick(e.target as HTMLElement, toggle)}>
                    <HomeNav />
                    <UserNav />
                    <SetupNav />
                    <ShowOnCondition showWhen={availableNewCustomerContracts.length > 0}>
                        <ContractsMenu categoryName={getCategoryLabel(ContractCategories.NEW_CUSTOMER)} categoryType={getCategoryPath(ContractCategories.NEW_CUSTOMER)} contracts={availableNewCustomerContracts} />
                    </ShowOnCondition>
                    <ShowOnCondition showWhen={availableOngoingCustomerContracts.length > 0}>
                        <ContractsMenu categoryName={getCategoryLabel(ContractCategories.ONGOING_CUSTOMER)} categoryType={getCategoryPath(ContractCategories.ONGOING_CUSTOMER)} contracts={availableOngoingCustomerContracts} />
                    </ShowOnCondition>
                    <ShowOnCondition showWhen={availableOtherContracts.length > 0}>
                        <ContractsMenu categoryName={getCategoryLabel(ContractCategories.OTHER)} categoryType={getCategoryPath(ContractCategories.OTHER)} contracts={availableOtherContracts} />
                    </ShowOnCondition>
                    <LogoutNav />
                </Nav>
            </NavMenuStyle>
        </>
    )
}

export default NavMenu