import { useEffect } from "react"
import { FieldValues, UseFormSetValue, UseFormWatch } from "react-hook-form"

import { isUseTypeNeeded } from "../PdrOfferConfig/utils"

type UpdateGasUseTypeHookProps = {
  watch: UseFormWatch<FieldValues> 
  entityName: string
  setValue: UseFormSetValue<FieldValues>
  fetchGasUseType: (values: Record<string, unknown>) => any
}

export const useUpdateGasUseTypeHook = ({
  entityName,
  watch,
  setValue,
  fetchGasUseType
}: UpdateGasUseTypeHookProps) => {
  const updateGasUseType = async (values: Record<string, unknown>) => {
    const response = await fetchGasUseType(values)
    setValue("gasUseTypeId", response.data)
  }

  const subscription = watch((data, { name, type }) => {
    if(!isUseTypeNeeded(entityName) && name === "contractType") {
      updateGasUseType({ entityName, contractTypeId: data[name].value })
    }
  })
  
  useEffect(() => {
    return () => subscription.unsubscribe()
  }, [subscription])
}