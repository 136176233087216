import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { FieldValues } from "react-hook-form"
import { Col, ListGroupItem, Row } from "reactstrap"

import CanI from "../../../auth/CanI.component"
import ButtonWithLoader from "../../../ButtonWithLoader/ButtonWithLoader.component"
import DeleteItem from "../../../DeleteItemModal/DeleteItemModal.component"
import CadastralDataDetailModal from "../../CadastralDataDetailModal/CadastralDataDetailModal.component"
import PdrItem from "../../PdrItem/PdrItem.component"
import PodItem from "../../PodItem/PodItem.component"
import { useSelectContractId, useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider"
import { useDeleteCadastralItemMutation, useLazyFetchCadastralItemQuery } from "../../../../redux/contract/cadastralData/cadastralData.api"
import { useFetchFormFieldsValuesQuery } from "../../../../redux/contract/contract.api"
import { BankAccountItemData, CadastralDataItemType } from "../../../../redux/contract/contract.types"
import { isThisItemLoading } from "../../../../utils"

type CadastralDataItemProps = {
  item: CadastralDataItemType
  cadastralFieldsValues: FieldValues
  customerType: Record<string, string>
  bankAccountData: BankAccountItemData[]
}

const CadastralDataItem = ({
  item,
  cadastralFieldsValues,
  ...rest
}: CadastralDataItemProps) => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(prevState => !prevState)
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const toggleDelete = () => setDeleteIsOpen(prevState => !prevState)
  
  const { data: formFields } = useFetchFormFieldsValuesQuery({ entityName })

  const [fetchItemData, {
    data: itemData,
    isFetching,
    isLoading,
  }, { lastArg: { itemId: lastLoadedItemId } }] = useLazyFetchCadastralItemQuery()
  const cadastralItemLoading = isFetching || isLoading
  
  const [deleteItem, { isLoading: isDeleting }] = useDeleteCadastralItemMutation()

  return (
    <ListGroupItem tag="div" action>
      <header>
        <div className="cadastral-info">
          <FontAwesomeIcon icon="map-marker-alt" className="me-2" />
          <span>{item.address}, {item.postalCode} {item.town} ({item.province})</span>
        </div>
        <div className="context-menu">
          
          <CanI
            doWhat="GET" 
            withPermissions={item.links} 
            entityName={`${entityName}CadastralData`}
          >
            {() => (
              <>
                <ButtonWithLoader
                  outline
                  size="sm"
                  label="Dettagli"
                  disabled={isThisItemLoading({ 
                    itemId: item.id, 
                    lastLoadedItemId, 
                    otherLoadingChecks: cadastralItemLoading 
                  })}
                  isLoading={isThisItemLoading({ 
                    itemId: item.id, 
                    lastLoadedItemId, 
                    otherLoadingChecks: cadastralItemLoading 
                  })}
                  fontAwesomeIcon={['fas', 'eye']}
                  onClick={async () => {
                    await fetchItemData({ contractId, itemId: item.id, entityName })
                    setIsOpen(true)
                  }}
                />
                <CadastralDataDetailModal
                  isOpen={isOpen}
                  toggle={toggle}
                  cadastralDetailData={itemData}
                  cadastralFieldsValues={cadastralFieldsValues}
                />
              </>
            )}
          </CanI>
          <CanI 
            doWhat="DELETE" 
            withPermissions={item.links} 
            entityName={`${entityName}CadastralData`}
          >
            {({ action }) => (
              <>
                <ButtonWithLoader 
                  label="Elimina"
                  fontAwesomeIcon={['fas', 'trash-alt']}
                  isLoading={isDeleting}
                  outline
                  onClick={toggleDelete}
                  color="danger"
                />
                <DeleteItem
                  isOpen={deleteIsOpen}
                  toggle={toggleDelete}
                  deleteFn={async () => {
                    await deleteItem(action)
                    toggleDelete()
                  }}
                  isDeleting={isDeleting}
                />
              </>
            )}
          </CanI>
        </div>
      </header>
      <main className="sub-items">
        <Row>
          <Col sm={6}>
            <PodItem 
              item={item.pod}
              cadastralItem={item}
              formFields={formFields?.pod || {}}
              {...rest}
            />
          </Col>
          <Col sm={6}>
            <PdrItem
              item={item.pdr}
              cadastralItem={item}
              formFields={formFields?.pdr || {}}
              {...rest}
            />
          </Col>
        </Row>
      </main>
    </ListGroupItem>
  )
}

export default CadastralDataItem