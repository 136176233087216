import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import PageHeaderStyle from './style'

type PageHeaderType = {
  icon?: IconProp
  pageTitle: string
}

const PageHeader = ({ icon, pageTitle }: PageHeaderType ) => (
  <PageHeaderStyle>
    <Container>
      <Row>
        <Col xs={12}>
          <h1 className="page-title">
            <FontAwesomeIcon 
              className="me-1" 
              icon={icon ?? 'file-signature'} 
            />
            {' '}
            {pageTitle}
          </h1>
        </Col>
      </Row>
    </Container>
  </PageHeaderStyle>
)

export default PageHeader