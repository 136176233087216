import ShowOnCondition from "../../../components/auth/ShowOnCondition.component"
import HeaterTabs from "./HeaterTabs/HeaterTabs.component"
import CustomerFormContainer from "../../../components/forms/CustomerForm/CustomerFormContainer.component"
import PaymentFormContainer from "../../../components/forms/PaymentForm/PaymentFormContainer.component"
import ProductFormContainer from "../../../components/forms/ProductForm/ProductFormContainer.component"
import TechDataFormContainer from "../../../components/forms/TechDataForm/TechDataFormContainer.component"
import HeaterAttachmentsFormContainer from "../../../components/forms/HeaterAttachmentsForm/HeaterAttachmentsFormContainer.component"
import { HeaterTabsMap } from "../../../redux/contract/contract.utils"
import { useSelectContractId, useSelectEntityName, useSelectSelectedStep } from "../../providers/ContractsPageProvider.provider"
import { useFetchContractProposalDataQuery, useFetchFormFieldsValuesQuery } from "../../../redux/contract/contract.api"
import SignedContractDashboardContainer from "../../../components/SignedContractDashboard/SignedContractDashboardContainer.component"

const HeaterContainer = () => {
  const contractId = useSelectContractId()
  const entityName = useSelectEntityName()
  const selectedStep = useSelectSelectedStep()

  const { 
    data: fieldsValues,
    isFetching: isFetchingFormFields, 
    isLoading: isLoadingFormFields,
  } = useFetchFormFieldsValuesQuery({ entityName, formName: "customer" })
  const fetchingFormfields = isFetchingFormFields || isLoadingFormFields

  const { 
    data: proposalData,
    isFetching: isFetchingProposalData, 
    isLoading: isLoadingProposalData,
    isUninitialized
  } = useFetchContractProposalDataQuery({ entityName, contractId }, { skip: !contractId })
  const fetchingCustomerData = isFetchingProposalData || isLoadingProposalData

  const isLoading = fetchingCustomerData || fetchingFormfields

  return (
    <>
      <HeaterTabs />
      <ShowOnCondition showWhen={selectedStep === HeaterTabsMap.CUSTOMER_DATA_STEP}>
        <CustomerFormContainer
          customerData={isUninitialized ? undefined : proposalData}
          fetchingCustomerData={isLoading}
          fieldsValues={fieldsValues ?? {}}
        />
      </ShowOnCondition>
      
      <ShowOnCondition showWhen={selectedStep === HeaterTabsMap.BANK_ACCOUNT_STEP}>
        <PaymentFormContainer />
      </ShowOnCondition>
      
      <ShowOnCondition showWhen={selectedStep === HeaterTabsMap.TECHNICAL_DATA_STEP}>
        <TechDataFormContainer />
      </ShowOnCondition>

      <ShowOnCondition showWhen={selectedStep === HeaterTabsMap.PRODUCT_STEP}>
        <ProductFormContainer />
      </ShowOnCondition>
      
      <ShowOnCondition showWhen={selectedStep === HeaterTabsMap.ATTACHMENTS_STEP}>
        <HeaterAttachmentsFormContainer />
      </ShowOnCondition>

      <ShowOnCondition showWhen={selectedStep === HeaterTabsMap.DOWNLOAD_CONTRACT_PDF_STEP}>
        <SignedContractDashboardContainer />
      </ShowOnCondition>
      
    </>
  )
}

export default HeaterContainer