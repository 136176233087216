import { useCallback, useEffect, useState } from "react"
import { FieldValues, UseFormWatch } from "react-hook-form"

type Props = {
  fetchContractPriceListCode: (values: Record<string, unknown>) => any
  contractId: string
  entityName: string
  userEmail: string
  electricUseType: string | number
  watch: UseFormWatch<FieldValues>
}
export const useSwitchContractPriceListHook = ({
  electricUseType,
  fetchContractPriceListCode,
  watch,
  ...rest
}: Props) => {
  const [contractPriceList, setContractPriceList] = useState([])
  const updateContractPriceListCode = useCallback(
    async (values: Record<string, unknown>) => {
      const response = await fetchContractPriceListCode(values)
      setContractPriceList(response.data)
    },
    [fetchContractPriceListCode]
  )

  useEffect(() => {
    if (electricUseType) {
      updateContractPriceListCode({
        electricUseType,
        ...rest,
      })
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const subscription = watch((data, { name, type }) => {
    if (name === "useType" && data[name]) {
      updateContractPriceListCode({
        ...rest,
        electricUseType: data[name].value,
      })
    }
  })
  useEffect(() => {
    return () => subscription.unsubscribe()
  }, [subscription])

  return contractPriceList
}
