import { Navigate, Route, Routes } from "react-router"
import { useSearchParams } from "react-router-dom"
import ResetPasswordForm from "../../components/forms/firebase-auth/ResetPasswordForm/ResetPasswordForm.component"
import DefaultPageContainer from "../DefaultPageContainer/DefaultPageContainer.component"

const FirebaseAuthPage = () => {
  let [searchParams] = useSearchParams()
  const mode = searchParams.get("mode")
  const oobCode = searchParams.get("oobCode")

  return (
    <DefaultPageContainer>
      <Routes>
        <Route
          path="*"
          element={
            <>
              {!mode && <Navigate to="/" />}
              {mode === 'resetPassword' &&
                <ResetPasswordForm 
                  oobCode={oobCode!}
                />
              }
            </>
          }
        />
      </Routes>
    </DefaultPageContainer>
  )
}

export default FirebaseAuthPage