import { Col, Row } from "reactstrap"
import { selectUserAvailableNewCustomerEntities, selectUserAvailableOngoingCustomerEntities, selectUserAvailableOtherEntities } from "../../../redux/auth/auth.selectors"
import { useAppSelector } from "../../../redux/hooks"
import { scrollTo } from "../../SupplyPointsList/ContractsShortcuts/utils"
import { ContractCategories, getCategoryLabel } from "../../../redux/contract/contract.utils"

const EntitySectionShortcuts = () => {
    const availableNewCustomerContracts = useAppSelector(selectUserAvailableNewCustomerEntities)
    const availableOngoingCustomerContracts = useAppSelector(selectUserAvailableOngoingCustomerEntities)
    const availableOtherContracts = useAppSelector(selectUserAvailableOtherEntities)

    return (
        <Row>
            <Col>
            <div className="contracts-shortcuts">
                <h3 className="title">Categorie di operazioni:</h3>
                <div className="links-container">
                {!!availableNewCustomerContracts.length &&
                    <a 
                    onClick={e => scrollTo(e, '#new-clients')} 
                    className="btn btn-outline-primary" 
                    href="#new-clients"
                    >
                        {getCategoryLabel(ContractCategories.NEW_CUSTOMER)}
                    </a>
                }
                {!!availableOngoingCustomerContracts.length === true &&
                    <a 
                    onClick={e => scrollTo(e, '#active-clients')} 
                    className="btn btn-outline-primary" 
                    href="#active-clients"
                    >
                        {getCategoryLabel(ContractCategories.ONGOING_CUSTOMER)}
                    </a>
                }
                {!!availableOtherContracts.length === true &&
                    <a 
                    onClick={e => scrollTo(e, '#other-clients')} 
                    className="btn btn-outline-primary" 
                    href="#other-clients"
                    >
                        {getCategoryLabel(ContractCategories.OTHER)}
                    </a>
                }
                </div>
            </div>
            </Col>
        </Row>
    )
}

export default EntitySectionShortcuts