import { GasUseTypes } from "../../../../redux/contract/contract.utils"

export function isUseTypeNeeded(entityName: string) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'transfer' ||
    entityName === 'contextualTransfer' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function isGasExciseClassIdNeeded(entityName: string, gasUseTypeId: string) {
  return (
    ( entityName === 'changeSupplier' && (
      gasUseTypeId === GasUseTypes.Industria ||
      gasUseTypeId === GasUseTypes.GeneratoreElettricita ||
      gasUseTypeId === GasUseTypes.Autotrazione ||
      gasUseTypeId === GasUseTypes.PubblicaAmministrazione ||
      gasUseTypeId === GasUseTypes.CommercioEServizi
    )) || 
    ( entityName === 'transfer' && (
      gasUseTypeId === GasUseTypes.Condominio ||
      gasUseTypeId === GasUseTypes.Industria ||
      gasUseTypeId === GasUseTypes.GeneratoreElettricita ||
      gasUseTypeId === GasUseTypes.Autotrazione ||
      gasUseTypeId === GasUseTypes.PubblicaAmministrazione ||
      gasUseTypeId === GasUseTypes.CommercioEServizi
    ))
  ) ? true : false
}

export function isResidentNeeded(entityName: string) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'transfer' ||
    entityName === 'contextualTransfer' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}