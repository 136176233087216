import ShowOnCondition from "../../../components/auth/ShowOnCondition.component"
import FixedTermJobFormContainer from "../../../components/forms/FixedTermJobForm/FixedTermJobFormContainer.component"
import SignedContractDashboardContainer from "../../../components/SignedContractDashboard/SignedContractDashboardContainer.component"
import { useFetchContractProposalDataQuery } from "../../../redux/contract/contract.api"
import { JobTabsMap } from "../../../redux/contract/contract.utils"
import { useSelectContractId, useSelectEntityName, useSelectSelectedStep } from "../../providers/ContractsPageProvider.provider"
import FixedTermJobTabs from "./FixedTermJobTabs/FixedTermJobTabs.component"

const FixedTermJobContainer = () => {
  const contractId = useSelectContractId()
  const entityName = useSelectEntityName()
  const selectedStep = useSelectSelectedStep()
  
  const { 
    data: proposalData,
    isFetching: isFetchingProposalData, 
    isLoading: isLoadingProposalData,
    isUninitialized
  } = useFetchContractProposalDataQuery({ entityName, contractId }, { skip: !contractId })
  const fetchingProposalData = isFetchingProposalData || isLoadingProposalData

  return (
    <>
      <FixedTermJobTabs />
      <ShowOnCondition showWhen={selectedStep === JobTabsMap.PROPOSAL_STEP}>
        <FixedTermJobFormContainer
          proposalData={isUninitialized ? undefined : proposalData}
          fetchingProposalData={fetchingProposalData}
        />
      </ShowOnCondition>
      <ShowOnCondition showWhen={selectedStep === JobTabsMap.DOWNLOAD_CONTRACT_PDF_STEP}>
        <SignedContractDashboardContainer />
      </ShowOnCondition>
    </>
  )
}

export default FixedTermJobContainer