import { useEffect, useState } from "react"

export const useUpdateEntityName = (entityName: string) => {
  const [listData, setListData] = useState<{[key: string]: any}>({ entityName })

  //Update entityName on url change
  useEffect(() => {
    setListData(prevState => ({
      ...prevState,
      entityName
    }))
  }, [entityName])

  return [listData, setListData] as const
}