import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

import ButtonWithLoader from "../../../ButtonWithLoader/ButtonWithLoader.component"

type AttachmentsIntegrationModalProps = {
  isOpen: boolean
  toggle: () => void
  action: string
  isLoading: boolean
  onClick: (e: any) => void
}

const AttachmentsIntegrationModal = ({
  isOpen,
  toggle,
  action,
  ...rest
}: AttachmentsIntegrationModalProps) => {
  return (
    <Modal
      isOpen={isOpen} 
      toggle={toggle} 
      className="utility-modal"
    >
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon size="lg" icon="paperclip" className="me-2" />
        Integrazione allegati
      </ModalHeader>
      <ModalBody>
        <p className="desc">
          Confermando verrà inviata a Logistica un'e-mail di avvenuta integrazione degli 
          allegati affinché possa verificare che tutto il materiale richiesto sia stato 
          caricato e si possa quindi procedere con l'elaborazione del contratto.
        </p>
        <div className="d-flex justify-content-end mt-5">
          <ButtonWithLoader
            className="ab-button"
            fontAwesomeIcon="paper-plane"
            label="Conferma chiusura"
            {...rest}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default AttachmentsIntegrationModal