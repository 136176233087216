import { useSelector } from "react-redux"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from "react-router-dom"
import { useLocation } from "react-router-dom"

import DropdownActiveCheck from "../DropdownActiveCheck/DropdownActiveCheck.component"
import CanI from "../../auth/CanI.component"
import { selectCurrentUser } from "../../../redux/auth/auth.selectors"
import { getEntityPluralName } from '../../../redux/contract/contract.utils'
import { useAppDispatch } from "../../../redux/hooks"
import { resetContract } from "../../../redux/contract/contract.actions"

const UserNav = () => {
  const dispatch = useAppDispatch()
  const user = useSelector(selectCurrentUser)
  const { pathname } = useLocation()

  return (
    <CanI doWhat={"GET"} withPermissions={user!.links} entityName={getEntityPluralName("user")}>
      {() => (
        <DropdownActiveCheck searchedValue="utenti">
          {props => (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret {...props}>
                <FontAwesomeIcon icon="users-cog" />{' '}
                utenti
              </DropdownToggle>
              <DropdownMenu>
                <div className="dropdown-group">
                  <CanI doWhat={"CREATE"} withPermissions={user!.links} entityName="user">
                    {() => (
                      <DropdownItem tag="div">
                        <NavLink 
                          onClick={() => {
                            if(pathname !== `/utenti`) {
                              dispatch(resetContract())}
                            }
                          }
                          end
                          className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
                          to="/utenti"
                        >
                            Crea nuovo
                        </NavLink>
                      </DropdownItem>
                    )}
                  </CanI>
                  <CanI doWhat={"CREATE"} withPermissions={user!.links} entityName="user">
                    {() => (
                      <DropdownItem tag="div">
                        <NavLink
                          onClick={() => {
                            if(pathname !== `/utenti/creazione-massiva`) {
                              dispatch(resetContract())}
                            }
                          } 
                          end
                          className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
                          to="/utenti/creazione-massiva"
                        >
                          Creazione massiva
                        </NavLink>
                      </DropdownItem>
                    )}
                  </CanI>
                  <DropdownItem tag="div">
                    <NavLink 
                      onClick={() => {
                        if(pathname !== `/utenti/all`) {
                          dispatch(resetContract())}
                        }
                      }
                      end
                      className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
                      to="/utenti/all"
                    >
                      Vedi tutti
                    </NavLink>
                  </DropdownItem>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </DropdownActiveCheck>
      )}
    </CanI>
  )
}

export default UserNav