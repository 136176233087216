import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"
import { useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider"

import { contractApi } from "../../../../redux/contract/contract.api"
import ShowOnCondition from "../../../auth/ShowOnCondition.component"
import AsyncSelectWrapper from "../../inputFields/AsyncSelect/AsyncSelectWrapper.component"
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels, maxLengthLabel } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { asyncFnWrapper } from "../../utils/utils"
import { codiceFISCALE, mustBeAdult } from "../../utils/validations"
import { administrationTownRequired, cadastralDataSectionRequired, cadastralDataSubaltRequired, cadastralTownCodeRequired, cadastralTownRequired, isRealEstateTownNameRequired, realEstatePropertyTypeRequired, realEstateUnitTypeRequired, rendAdditionalInfoRequired } from "./utils"

const PropertyDeclaration = ({
  canEdit,
  fieldsValues: cadastralFieldsValues,
}: FormProps) => {
  const entityName = useSelectEntityName()
  const { register, control, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  const [fetchTowns] = contractApi.endpoints.fetchTowns.useLazyQuerySubscription()

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="list" className="me-2" />
        Dichiarazione sostitutiva di atto notorio relativa alla proprietà o titolarità e Allegato H
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={12}>
            <SelectWrapper
              name="supplyEqualToProperty"
              control={control}
              label="L'indirizzo di fornitura corrisponde a quello dell'immobile"
              isDisabled={canEdit? false : true}
              errors={errors}
              options={cadastralFieldsValues?.supplyEqualToProperty ?? []}
              rules={{ required: errorFormLabels.REQUIRED }}
            />
          </Col>
          <ShowOnCondition 
            showWhen={
              isRealEstateTownNameRequired(entityName) &&
              parseInt(formWatcher.supplyEqualToProperty?.value) === 2
            }
          >
            <Col md={12}>
              <AsyncSelectWrapper
                name="realEstateTownName"
                control={control}
                register={register}
                label="Comune"
                errors={errors}
                isDisabled={canEdit ? false : true}
                loadOptions={(input: string) => {
                  if(input.length > 2) {
                    return asyncFnWrapper(input, fetchTowns)
                  }
                }}
                getOptionLabel={option => `${option.town} (${option.province})`}
                getOptionValue={option => `${option.town} (${option.province})`}
                rules={{ maxLength: 80, required: errorFormLabels.REQUIRED }}
                noOptionsMessage={() => 
                  <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                }
              />
            </Col>
          </ShowOnCondition>
          <ShowOnCondition 
            showWhen={parseInt(formWatcher.supplyEqualToProperty?.value) === 2}
          >
            <Col md={3}>
              <SelectWrapper
                name="propertyToponym"
                control={control}
                register={register}
                label="Toponimo"
                isDisabled={canEdit? false : true}
                errors={errors}
                options={cadastralFieldsValues?.propertyToponym ?? []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <Col md={7}>
              <InputWrapper
                name="propertyAddress"
                label="Indirizzo"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: 128
                }}
              />
            </Col>
            <Col md={2}>
              <InputWrapper
                name="propertyStreetNo"
                label="Civico"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: 4
                }}
              />
            </Col>
          </ShowOnCondition>
        </Row>
        <Row>
          <Col md={4}>
            <InputWrapper
              name="stair"
              label="Scala"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ maxLength: 50 }}
            />
          </Col>
          <Col md={4}>
            <InputWrapper
              name="floor"
              label="Piano"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ maxLength: 50 }}
            />
          </Col>
          <Col md={4}>
            <InputWrapper
              name="indoor"
              label="Interno"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ maxLength: 50 }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <SelectWrapper
              name="realEstatePropertyType"
              control={control}
              label="Il sottoscritto in qualità di:"
              isDisabled={canEdit? false : true}
              errors={errors}
              options={cadastralFieldsValues?.realEstatePropertyType ?? []}
              rules={{ required: realEstatePropertyTypeRequired(entityName) }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="administrationTown"
              label="Comune amministrativo"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                },
                required: administrationTownRequired(entityName),
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="cadastralTown"
              label="Comune catastale (se diverso da quello ammin.)"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                },
                required: cadastralTownRequired(entityName),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InputWrapper
              name="cadastralTownCode"
              label="Cod. Comune catastale"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                },
                required: cadastralTownCodeRequired(entityName),
              }}
            />
          </Col>
          <Col md={6}>
            <SelectWrapper
              name="realEstateUnitType"
              control={control}
              label="Tipologia Unità Immobiliare"
              isDisabled={canEdit? false : true}
              errors={errors}
              options={cadastralFieldsValues?.realEstateUnitType ?? []}
              rules={{ 
                required: realEstateUnitTypeRequired(entityName),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <InputWrapper
              name="cadastralDataSection"
              label="Sez."
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                maxLength: {
                  value: 50,
                  message: maxLengthLabel(80)
                },
                required: cadastralDataSectionRequired(entityName),
              }}
            />
          </Col>
          <Col md={3}>
            <InputWrapper
              name="cadastralDataSheet"
              label="Foglio"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: 50 
              }}
            />
          </Col>
          <Col md={3}>
            <InputWrapper
              name="cadastralDataPart"
              label="Part/Map"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: 50 
              }}
            />
          </Col>
          <Col md={3}>
            <InputWrapper
              name="cadastralDataSubalt"
              label="Subalt."
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                maxLength: {
                  value: 50,
                  message: maxLengthLabel(80)
                },
                required: cadastralDataSubaltRequired(entityName),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InputWrapper
              name="particleExtension"
              label="Estensione Particella"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ maxLength: 50 }}
            />
          </Col>
          <Col md={6}>
            <SelectWrapper
              name="particleType"
              control={control}
              label="Tipo particella"
              isDisabled={canEdit? false : true}
              errors={errors}
              options={cadastralFieldsValues?.particleType ?? []}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <SelectWrapper
              name="propertyType"
              control={control}
              label="In conformità all’art. 5 comma 1 del DL 28/03/14, n 47, convertito in L. 80/14, di occupare l’immobile in virtù del seguente titolo:"
              isDisabled={canEdit? false : true}
              errors={errors}
              options={cadastralFieldsValues?.propertyType ?? []}
              rules={{ required: errorFormLabels.REQUIRED }}
            />
          </Col>
        </Row>
        <ShowOnCondition 
          showWhen={
            rendAdditionalInfoRequired(entityName) &&
            parseInt(formWatcher.propertyType?.value) === 2
          }
        >
          <Row>
            <Col md="12">
              <legend className="col-form-label">Con atto registrato presso l’Agenzia delle Entrate:</legend>
            </Col>
            <Col md={6}>
              <AsyncSelectWrapper
                name="rentRegisteredBy"
                control={control}
                register={register}
                label="Agenzia delle Entrate di"
                errors={errors}
                isDisabled={canEdit ? false : true}
                loadOptions={(input: string) => {
                  if(input.length > 2) {
                    return asyncFnWrapper(input, fetchTowns)
                  }
                }}
                getOptionLabel={option => `${option.town} (${option.province})`}
                getOptionValue={option => `${option.town} (${option.province})`}
                rules={{ maxLength: 80, required: errorFormLabels.REQUIRED }}
                noOptionsMessage={() => 
                  <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                }
              />
            </Col>
            <Col md={4}>
              <DatePickerWrapper
                name="rentRegisteredDate"
                control={control}
                label="In data"
                className="form-control"
                errors={errors}
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                rules={{ required: errorFormLabels.REQUIRED }}
                disabled={canEdit ? false : true}
              />
            </Col>
            <Col md={2}>
              <InputWrapper
                name="rentRegisteredAt"
                label="Al n."
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: 50 
                }}
              />
            </Col>
          </Row>
        </ShowOnCondition>
        <ShowOnCondition 
          showWhen={
            rendAdditionalInfoRequired(entityName) &&
            parseInt(formWatcher.propertyType?.value) === 3
          }
        >
          <Row>
            <Col md={12}>
              <InputWrapper
                name="usufructDescription"
                label="Specificare uso, usufrutto o abitazione"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ maxLength: 128 }}
              />
            </Col>
          </Row>
        </ShowOnCondition>
        <ShowOnCondition 
          showWhen={
            rendAdditionalInfoRequired(entityName) &&
            parseInt(formWatcher.propertyType?.value) === 4
          }
        >
          <Row>
            <Col md={12}>
              <InputWrapper
                name="otherType"
                label="Altro (specificare)"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: 128
                }}
              />
            </Col>
          </Row>
        </ShowOnCondition>
        <ShowOnCondition 
          showWhen={
            rendAdditionalInfoRequired(entityName) &&
            (
              parseInt(formWatcher.propertyType?.value) === 3 ||
              parseInt(formWatcher.propertyType?.value) === 4
            )
          }
        >
          <Row>
            <Col md={12}>
              <legend>Schema di autorizzazione (delega):</legend>
              <p className="small">
                Lo schema di autorizzazione/delega va compilato nei casi in cui il Cliente che richiede la fornitura,
                non ha direttamente il titolo registrato sull’immobile. In questi casi è necessaria l’autorizzazione dell’avente titolo
                (es.: proprietario, locatario, etc.) che dichiari di aver messo a disposizione l’immobile al sottoscrittore del Contratto
                di fornitura (es.: Il proprietario dell’immobile è uno dei coniugi e il contratto è sottoscritto dall’altro).</p>
                <legend>Il sottoscritto:</legend>
            </Col>
            <Col md={6}>
              <InputWrapper
                name="authName"
                label="Nome"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: 128
                }}
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                name="authSurname"
                label="Cognome"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: 128
                }}
              />
            </Col>
            <Col md={6}>
              <AsyncSelectWrapper
                name="authBirthPlace"
                control={control}
                register={register}
                label="Nato a"
                errors={errors}
                isDisabled={canEdit ? false : true}
                loadOptions={(input: string) => {
                  if(input.length > 2) {
                    return asyncFnWrapper(input, fetchTowns)
                  }
                }}
                getOptionLabel={option => `${option.town} (${option.province})`}
                getOptionValue={option => `${option.town} (${option.province})`}
                rules={{ maxLength: 80, required: errorFormLabels.REQUIRED }}
                noOptionsMessage={() => 
                  <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                }
              />
            </Col>
            <Col md={6}>
              <DatePickerWrapper
                name="authBirthDate"
                control={control}
                label="Data di nascita"
                className="form-control"
                errors={errors}
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  validate: value => mustBeAdult(value) || errorFormLabels.ADULT_REQUIRED
                }}
                disabled={canEdit ? false : true}
              />
            </Col>
            <Col md={6}>
              <InputWrapper 
                type="text"
                disabled={canEdit ? false : true}
                className="form-control text-uppercase"
                name="authFiscalCode"
                label="Codice fiscale"
                rules={{
                  maxLength: 20,
                  required: errorFormLabels.REQUIRED,
                  validate: value => codiceFISCALE(value) || errorFormLabels.INVALID_CF
                }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <AsyncSelectWrapper
                name="authResidenceTown"
                control={control}
                register={register}
                label="Residente a"
                errors={errors}
                isDisabled={canEdit ? false : true}
                loadOptions={(input: string) => {
                  if(input.length > 2) {
                    return asyncFnWrapper(input, fetchTowns)
                  }
                }}
                getOptionLabel={option => `${option.town} (${option.province})`}
                getOptionValue={option => `${option.town} (${option.province})`}
                rules={{ maxLength: 80, required: errorFormLabels.REQUIRED }}
                noOptionsMessage={() => 
                  <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectWrapper
                name="authPropertyType"
                control={control}
                label="Titolare del diritto di"
                isDisabled={canEdit? false : true}
                errors={errors}
                options={cadastralFieldsValues?.authPropertyType ?? []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <ShowOnCondition showWhen={parseInt(formWatcher.authPropertyType?.value) === 3}>
              <Col md={6}>
                <InputWrapper 
                  type="text"
                  disabled={canEdit ? false : true}
                  className="form-control"
                  name="authOtherType"
                  label="Altro (specificare)"
                  rules={{
                    maxLength: 128,
                    required: errorFormLabels.REQUIRED,
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
            </ShowOnCondition>
          </Row>
          <ShowOnCondition showWhen={parseInt(formWatcher.authPropertyType?.value) === 2}>
            <Row>
              <Col md={12}>
                <legend>Con atto registrato presso l’Agenzia delle Entrate:</legend>
              </Col>
              <Col md={6}>
                <AsyncSelectWrapper
                  name="authRentRegisteredBy"
                  control={control}
                  register={register}
                  label="Agenzia delle Entrate di"
                  errors={errors}
                  isDisabled={canEdit ? false : true}
                  loadOptions={(input: string) => {
                  if(input.length > 2) {
                    return asyncFnWrapper(input, fetchTowns)
                  }
                }}
                  getOptionLabel={option => `${option.town} (${option.province})`}
                  getOptionValue={option => `${option.town} (${option.province})`}
                  rules={{ maxLength: 80, required: errorFormLabels.REQUIRED }}
                  noOptionsMessage={() => 
                    <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                  }
                />
              </Col>
              <Col md={4}>
              <DatePickerWrapper
                name="authRentRegisteredDate"
                control={control}
                label="In data"
                className="form-control"
                errors={errors}
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                rules={{ required: errorFormLabels.REQUIRED }}
                disabled={canEdit ? false : true}
              />
            </Col>
            <Col md={2}>
              <InputWrapper
                name="authRentRegisteredAt"
                label="Al n."
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: 50 
                }}
              />
            </Col>
            </Row>
          </ShowOnCondition>
          <Row>
            <Col md={12}>
              <legend className="col-form-label">DICHIARO di aver messo a disposizione il suddetto immobile al Sig./Sig.ra</legend>
            </Col>
            <Col md={6}>
              <InputWrapper
                name="beneficiaryName"
                label="Nome"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: 128
                }}
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                name="beneficiarySurname"
                label="Cognome"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: 128
                }}
              />
            </Col>
            <Col md={12}>
              <InputWrapper 
                type="text"
                disabled={canEdit ? false : true}
                className="form-control text-uppercase"
                name="beneficiaryFiscalCode"
                label="Codice fiscale"
                rules={{
                  maxLength: 20,
                  required: errorFormLabels.REQUIRED,
                  validate: value => codiceFISCALE(value) || errorFormLabels.INVALID_CF
                }}
                register={register}
                errors={errors}
              />
            </Col>
          </Row>
        </ShowOnCondition>
      </div>
    </div>
  )
}

export default PropertyDeclaration