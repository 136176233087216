import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldValues, useFormContext } from "react-hook-form";
import { Row, Col } from "reactstrap";

import { contractApi } from "../../../../redux/contract/contract.api";
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component";
import InputWrapper from "../../inputFields/Input/InputWrapper.component";
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component";
import { errorFormLabels } from "../../utils/formLabels";
import { CustomerTypes, getCustomerType } from "../../utils/utils";
import {
  codiceFISCALE,
  emailCheck,
  mustBeAdult,
  vatNumberRegExpCheck,
} from "../../utils/validations";
import ScoreCheck from "../../../ScoreCheck/ScoreCheck.component";
import { useUpdateScoreCheckHook } from "./hooks/useUpdateScoreCheckHook.hooks";
import { useEffect, useState } from "react";
import WithSkeleton from "../../../WithSkeleton/WithSkeleton.component";
import ShowOnCondition from "../../../auth/ShowOnCondition.component";

export type CustomerDataFormProps = {
  canEdit?: boolean;
  fieldsValues?: FieldValues;
  setScoreCheckResult: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  scoreCheckResult?: boolean;
};

const CustomerData = ({
  canEdit,
  fieldsValues: proposalFormValues,
  setScoreCheckResult,
  scoreCheckResult,
}: CustomerDataFormProps) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const formWatcher = watch();

  const [fetchScoreCheck] =
    contractApi.endpoints.fetchScoreCheck.useLazyQuerySubscription();

  const [isScoreCheckLoading, setIsScoreCheckLoading] = useState(false);
  const isScoreCheckPassed = useUpdateScoreCheckHook({
    watch,
    defaultValue: formWatcher?.scoreCheckPassed,
    setIsLoading: setIsScoreCheckLoading,
    isDomestic: getCustomerType(
      CustomerTypes.DOMESTIC_CUSTOMER,
      formWatcher.customerType
    ),
    fetchScoreCheck,
  });

  const ScoreCheckWithSkeleton = WithSkeleton(ScoreCheck);

  useEffect(() => {
    setScoreCheckResult(isScoreCheckPassed);
  }, [isScoreCheckPassed, setScoreCheckResult]);
  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="address-book" className="me-2" />
        Dati cliente
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={12}>
            <InputWrapper
              name="customerCode"
              label="Codice cliente"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{
                value: formWatcher.customerCode || null,
                required: errorFormLabels.REQUIRED,
                maxLength: 20,
              }}
            />
          </Col>
        </Row>
        <Row>
          {getCustomerType(
            CustomerTypes.BUSINESS_CUSTOMER,
            formWatcher.customerType
          ) && (
            <Col md={12}>
              <InputWrapper
                name="customerBusinessName"
                label="Ragione sociale"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{
                  value: formWatcher.customerBusinessName || null,
                  required: errorFormLabels.REQUIRED,
                  maxLength: 45,
                }}
              />
            </Col>
          )}
          {getCustomerType(
            CustomerTypes.DOMESTIC_CUSTOMER,
            formWatcher.customerType
          ) && (
            <>
              <Col md={6}>
                <InputWrapper
                  name="customerName"
                  label="Nome"
                  type="text"
                  register={register}
                  disabled={canEdit ? false : true}
                  className="form-control"
                  errors={errors}
                  rules={{
                    value: formWatcher.customerName || null,
                    required: errorFormLabels.REQUIRED,
                    maxLength: 80,
                  }}
                />
              </Col>
              <Col md={6}>
                <InputWrapper
                  name="customerSurname"
                  label="Cognome"
                  type="text"
                  register={register}
                  disabled={canEdit ? false : true}
                  className="form-control"
                  errors={errors}
                  rules={{
                    value: formWatcher.customerSurname || null,
                    required: errorFormLabels.REQUIRED,
                    maxLength: 80,
                  }}
                />
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col>
            <InputWrapper
              name="customerFiscalCode"
              label="Codice fiscale"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control text-uppercase"
              errors={errors}
              rules={{
                value: formWatcher.customerFiscalCode || null,
                required: errorFormLabels.REQUIRED,
                validate: (value) => {
                  if (
                    getCustomerType(
                      CustomerTypes.DOMESTIC_CUSTOMER,
                      formWatcher.customerType
                    )
                  ) {
                    return codiceFISCALE(value) || errorFormLabels.INVALID_CF;
                  } else {
                    return (
                      codiceFISCALE(value) ||
                      vatNumberRegExpCheck(value) ||
                      errorFormLabels.INVALID_CF_VAT
                    );
                  }
                },
                maxLength: 20,
              }}
            />
          </Col>
          {getCustomerType(
            CustomerTypes.DOMESTIC_CUSTOMER,
            formWatcher.customerType
          ) && (
            <Col md={6}>
              <DatePickerWrapper
                name="customerBirthDate"
                control={control}
                label="Data di nascita"
                className="form-control"
                maxDate={new Date()}
                errors={errors}
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                rules={{
                  required: errorFormLabels.REQUIRED,
                  validate: (value) =>
                    mustBeAdult(value) || errorFormLabels.ADULT_REQUIRED,
                }}
                disabled={canEdit ? false : true}
              />
            </Col>
          )}
          {getCustomerType(
            CustomerTypes.BUSINESS_CUSTOMER,
            formWatcher.customerType
          ) && (
            <Col md={6}>
              <InputWrapper
                name="customerVatNumber"
                label="Partita IVA"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{
                  value: formWatcher.customerSurname || null,
                  required: errorFormLabels.REQUIRED,
                  pattern: {
                    value: /^[0-9]{11}$/,
                    message: errorFormLabels.INVALID_VAT,
                  },
                  maxLength: 11,
                }}
              />
            </Col>
          )}
          <ShowOnCondition showWhen={proposalFormValues?.isScoreCheckActive}>
            <Col md={12}>
              <ScoreCheckWithSkeleton
                isLoading={isScoreCheckLoading}
                isSmall
                isFiscalCode={getCustomerType(
                  CustomerTypes.DOMESTIC_CUSTOMER,
                  formWatcher.customerType
                )}
                customerScore={scoreCheckResult}
              />
            </Col>
          </ShowOnCondition>
        </Row>
        <Row>
          {getCustomerType(
            CustomerTypes.DOMESTIC_CUSTOMER,
            formWatcher.customerType
          ) && (
            <>
              <Col>
                <SelectWrapper
                  name="customerBirthCountry"
                  control={control}
                  register={register}
                  label="Nazionalità"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  options={proposalFormValues?.customerBirthCountry ?? []}
                  rules={{
                    required: errorFormLabels.REQUIRED,
                  }}
                />
              </Col>
              {formWatcher.customerBirthCountry?.label === "Italia" && (
                <>
                  <Col md={6}>
                    <InputWrapper
                      name="customerBirthPlace"
                      label="Comune di nascita"
                      type="text"
                      register={register}
                      disabled={canEdit ? false : true}
                      className="form-control"
                      errors={errors}
                      rules={{
                        value: formWatcher.customerBirthPlace || null,
                        required: errorFormLabels.REQUIRED,
                        maxLength: 80,
                      }}
                    />
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
        <Row>
          <Col md={12}>
            <InputWrapper
              name="contactEmail"
              label="E-mail"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control text-lowercase"
              errors={errors}
              rules={{
                value: formWatcher.contactEmail || null,
                required: errorFormLabels.REQUIRED,
                validate: (value) =>
                  emailCheck(value) || errorFormLabels.INVALID_EMAIL,
                maxLength: 80,
              }}
            />
          </Col>
        </Row>
        {getCustomerType(
          CustomerTypes.BUSINESS_CUSTOMER,
          formWatcher.customerType
        ) && (
          <>
            <Row>
              <Col md={12}>
                <legend>Legale rappresentante:</legend>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <DatePickerWrapper
                  name="identityBirthDate"
                  control={control}
                  label="Data di nascita"
                  className="form-control"
                  errors={errors}
                  maxDate={new Date()}
                  showYearDropdown
                  dateFormat="dd/MM/yyyy"
                  rules={{
                    required: errorFormLabels.REQUIRED,
                    validate: (value) =>
                      formWatcher.id
                        ? mustBeAdult(value) || errorFormLabels.ADULT_REQUIRED
                        : true,
                  }}
                  disabled={canEdit ? false : true}
                />
              </Col>
              <Col md={6}>
                <SelectWrapper
                  name="identityBirthCountry"
                  control={control}
                  register={register}
                  label="Nazionalità"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  options={proposalFormValues?.customerBirthCountry || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
            </Row>
            {formWatcher.identityBirthCountry?.label === "Italia" && (
              <Row>
                <Col md={10}>
                  <InputWrapper
                    name="identityTownName"
                    label="Comune di nascita"
                    type="text"
                    register={register}
                    disabled={canEdit ? false : true}
                    className="form-control"
                    errors={errors}
                    rules={{
                      value: formWatcher.identityTownName || null,
                      required: formWatcher.id
                        ? false
                        : errorFormLabels.REQUIRED,
                      maxLength: 80,
                    }}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerData;
