import { useMemo } from "react"
import { Container } from "reactstrap"
import { useParams } from "react-router"

import ContractsListStyle from "./style"
import ShowOnCondition from "../../components/auth/ShowOnCondition.component"
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop.component"
import DefaultPageContainer from "../DefaultPageContainer/DefaultPageContainer.component"
import PageHeader from "../PageHeader/PageHeader.component"
import HeaterContractsListContainer from "../../components/lists/HeaterContractsList/HeaterContractsListContainer.component"
import FixedTermContractsListContainer from "../../components/lists/FixedTermContractsList/FixedTermContractsListContainer.component"
import PermanentContractsListContainer from "../../components/lists/PermanentContractsList/PermanentContractsListContainer.component"
import { getEntityLabelFromPath, getEntityNameFromPath, isNewCustomerContract, isOngoingCustomerContract } from "../../redux/contract/contract.utils"
import { ListsPageContext } from "../providers/ListsPageProvider.provider"
import { useUpdateEntityName } from "./hooks/useUpdateEntityName.hooks"
import { useUpdateSelectedTab } from "./hooks/useUpdateSelectedTab.hooks"
import ProposalListContainer from "../../components/lists/ProposalList/ProposalListContainer.component"
import OngoingCustomersListContainer from "../../components/lists/OngoingCustpmersList/OngoingCustomersList.component"

const ContractsListPage = () => {
  const { pathEntityName } = useParams()
  const entityName = useMemo(() => getEntityNameFromPath(pathEntityName), [pathEntityName])
  const entityLabel = useMemo(() => getEntityLabelFromPath(pathEntityName), [pathEntityName])
  
  //Set shared contract data like entityName/contractId for easy retrieve in children components
  const [listData, setListData] = useUpdateEntityName(entityName)
  const [selectedTab, setSelectedTab] = useUpdateSelectedTab(entityName)

  return (
    <ListsPageContext.Provider
      value={{
        listData,
        setListData,
        selectedTab,
        setSelectedTab,
      }}
    >
      <ScrollToTop />
      <DefaultPageContainer>
        <ContractsListStyle>
          <PageHeader pageTitle={entityLabel} icon="list" />
          <Container className="contract-container">
            <ShowOnCondition showWhen={isNewCustomerContract(listData.entityName)}>
              <ProposalListContainer />
            </ShowOnCondition>
            <ShowOnCondition showWhen={isOngoingCustomerContract(listData.entityName)}>
              <OngoingCustomersListContainer />
            </ShowOnCondition>
            <ShowOnCondition showWhen={listData.entityName === "heater"}>
              <HeaterContractsListContainer />
            </ShowOnCondition>
            <ShowOnCondition showWhen={listData.entityName === "fixedTermContract"}>
              <FixedTermContractsListContainer />
            </ShowOnCondition>
            <ShowOnCondition showWhen={listData.entityName === "permanentContract"}>
              <PermanentContractsListContainer />
            </ShowOnCondition>
          </Container>
        </ContractsListStyle>
      </DefaultPageContainer>
    </ListsPageContext.Provider>
  )
}

export default ContractsListPage