import { FieldValues, FormProvider, useForm } from "react-hook-form";
import SingleColorPalette from "./SingleColorPalette/SingleColorPalette.component";
import { Form } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCurrentName } from "../../../redux/settings/settings.selectors";
import { setCurrentPalette } from "../../../redux/settings/settings.actions";
import { useEffect, useMemo } from "react";

const ColorPaletteForm = () => {
  const availableColors = useMemo(() => [
    {
      name: "blueHorizon",
      displayName: "Blue Horizon",
      colors: {
        palette1: "#1c66ab",
        palette2: "#287ac3",
        palette3: "#d3d72c",
        palette4: "#287ac3",
        palette5: "#dde1e6",
        palette6: "#287ac3",
        palette7: "#53a0df",
      },
    },
    {
      name: "midnightEnergy",
      displayName: "Midnight Energy",
      colors: {
        palette1: "#212121",
        palette2: "#333333",
        palette3: "#61B33B",
        palette4: "#656565",
        palette5: "#b4b4b4",
        palette6: "#287ac3",
        palette7: "#53a0df",
      },
    },
    {
      name: "solarGlow",
      displayName: "Solar Glow",
      colors: {
        palette1: "#1a1a1a",
        palette2: "#f8c101",
        palette3: "#f8c101",
        palette4: "#697077",
        palette5: "#dde1e6",
        palette6: "#5b52cf",
        palette7: "#7771cc",
      },
    },
    {
      name: "crimsonPulse",
      displayName: "Crimson Pulse",
      colors: {
        palette1: "#6C0004",
        palette2: "#BB0001",
        palette3: "#F2E43D",
        palette4: "#090F5B",
        palette5: "#dde1e6",
        palette6: "#090F5B",
        palette7: "#5B8BAA",
      },
    },
    {
      name: "ecoVerdant",
      displayName: "Eco Verdant",
      colors: {
        palette1: "#0A5C35",
        palette2: "#4EBD74",
        palette3: "#E8B22F",
        palette4: "#011D28",
        palette5: "#B9C8B5",
        palette6: "#011D28",
        palette7: "#52513D",
      },
    },
  ], [])

  const currentPaletteName = useAppSelector(selectCurrentName)
  const formProps = useForm<FieldValues>({
    defaultValues: {
        selectedColorPalette: currentPaletteName
    }
  })

  const dispatch = useAppDispatch()

  useEffect(() => {
    const subscription = formProps.watch((value, { name, type }) => {
      if(name === "selectedColorPalette"){
        let selected = availableColors.find((e) => e.name === value[name])
        dispatch(setCurrentPalette(selected))
      }
    });
    return () => subscription.unsubscribe();
  }, [formProps, dispatch, availableColors])


  return (
    <FormProvider {...formProps}>
      <Form className="btn-group">
        {availableColors.map((item) => (
          <SingleColorPalette key={item.name} {...item} />
        ))}
      </Form>
    </FormProvider>
  );
};

export default ColorPaletteForm;
