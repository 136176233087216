import { useFetchContractProposalDataQuery, useFetchOtpDataQuery } from "../../../redux/contract/contract.api"
import WithSpinner from "../../WithSpinner/WithSpinner.component"
import OtpForm from "./OtpForm.component"

type OtpFormContainerProps = {
  contractId: string
  entityName: string
  toggleFinalizationModal: () => void
}

const OtpFormWithSpinner = WithSpinner(OtpForm)

const OtpFormContainer = ({
  contractId,
  entityName,
  ...rest
}: OtpFormContainerProps) => {
  const { 
    data: otpSignersData, 
    isFetching: isFetchingOtpSigners, 
    isLoading: isLoadingOtpSigners,
  } = useFetchOtpDataQuery({ contractId, entityName })
  const fetchingOtpData = isFetchingOtpSigners || isLoadingOtpSigners

  const { 
    data: proposalData, 
    isFetching: isFetchingProposal, 
    isLoading: isLoadingProposal,
  } = useFetchContractProposalDataQuery({ contractId, entityName })
  const fetchingProposalData = isFetchingProposal || isLoadingProposal

  const isLoading = fetchingProposalData || fetchingOtpData

  return (
    <OtpFormWithSpinner 
      isLoading={isLoading}
      otpSignersData={otpSignersData}
      entityName={entityName}
      contractId={contractId}
      permissions={proposalData?.links}
      {...rest}
    />
  )
}

export default OtpFormContainer