import styled from "styled-components"

const SettingsPageStyle = styled.div`
  header {
    background-color: ${({ theme }) => theme.colors.palette6};
    color: ${({ theme }) => theme.colors.grayscale[100]};
    padding: 1rem;

    .lead {
      font-size: 0.85rem;
      margin: 0;
    }

    .other-links-container {
      display: flex;
      justify-content: flex-end;
    }

    .other-links {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.grayscale[100]};
      border-radius: 0.25rem;
      color: ${({ theme }) => theme.colors.grayscale[100]};
      font-size: 0.85rem;
      font-weight: 700;
      padding: 0.375rem 0.75rem;
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
    }
  }

  main {
    padding: 2.5rem 0;

    .main-title {
      color: ${({ theme }) => theme.colors.palette6};
      font-size: 34px;
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  .btn-group {
    .btn {
      padding: 1.5rem;
    }
    .btn-outline-primary {
      border-color: ${({ theme }) => theme.colors.lightGrey};
    }
    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      background-color: ${({ theme }) => theme.colors.lightGrey};
    }
    .btn:hover {
      background-color: ${({ theme }) => theme.colors.lightGrey};
    }
  }
`;

export default SettingsPageStyle