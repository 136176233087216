import { useMemo } from "react"

import WithSkeleton from "../../WithSkeleton/WithSkeleton.component"
import ProductForm from "./ProductForm.component"
import { useFetchFormFieldsValuesQuery } from "../../../redux/contract/contract.api"
import { useFetchContractProductQuery } from "../../../redux/contract/product/product.api"
import { useAppSelector } from "../../../redux/hooks"
import { selectContractProposalData } from "../../../redux/contract/contract.selectors"
import { useSelectContractId, useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"

const ProductFormWithSpinner = WithSkeleton(ProductForm)

const ProductFormContainer = () => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  //Select contract data with permissions
  const selectCustomerData = useMemo(() => selectContractProposalData({ entityName, contractId }), [entityName, contractId])
  const { data: customerData } = useAppSelector(selectCustomerData)

  //Select form fields values
  const { 
    data: formFields,
    isFetching: isFetchingFormFields, 
    isLoading: isLoadingFormFields,
  } = useFetchFormFieldsValuesQuery({ entityName, formName: "productDetail" })
  const fetchingFormfields = isFetchingFormFields || isLoadingFormFields

  const {
    data: productData,
    isFetching: isFetchingProductData,
    isLoading: isLoadingProductData,
  } = useFetchContractProductQuery({ entityName, contractId })
  const fetchingProductData = isFetchingProductData || isLoadingProductData

  const fetchingData = fetchingProductData || fetchingFormfields

  return (
    <ProductFormWithSpinner 
      isLoading={fetchingData}
      permissions={customerData.links}
      customerType={customerData.customerType}
      productFieldsValues={formFields || {}}
      productData={productData}
    />
  )
}

export default ProductFormContainer