import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";

import SetupCreationFormStyle from "./style";
import { exportCsv } from "../../../redux/setup/setup.api";
import { downloadFile, mimeFileTypes } from "../../../utils/files.utils";
import { isUniqueNameFound } from "../../../redux/setup/setup.utils";
import { useState } from "react";
import SetupImportStatusModal from "../../SetupImportStatusModal/SetupImportStatusModal.component";
import { selectSetupUniqueName } from "../../../redux/setup/setup.selectors";
import { useAppSelector } from "../../../redux/hooks";
import SetupImportFilesForm from "../SetupImportFilesForm/SetupImportFilesForm.component";

const SetupImportCsvForm = () => {
  const setupUniqueName = useAppSelector(selectSetupUniqueName);

  const [runExport] = exportCsv.useLazyQuerySubscription();

  const [visibility, setVisibility] = useState(false);
  const toggle = () => setVisibility(!visibility);

  const downloadCsv = async () => {
    const { data } = await runExport(setupUniqueName);
    downloadFile(data.body64, data.title, mimeFileTypes["csv"]);
  };

  return (
    <SetupCreationFormStyle>
      <div className="grouped">
        <div className="grouped-header">
          <span className="step">1</span>
          Esporta il file CSV
        </div>
        <div className="grouped-body text-center">
          <Button
            color="secondary"
            target="_blank"
            disabled={!isUniqueNameFound(setupUniqueName)}
            onClick={downloadCsv}
          >
            <FontAwesomeIcon icon="download" className="me-2" />
            <span>Esporta CSV</span>
          </Button>
        </div>
      </div>
      <div className="grouped">
        <div className="grouped-header">
          <span className="step">2</span>
          Compila il file CSV
        </div>
        <div className="grouped-body">
          Compila il file seguendo l'impostazione indicata nel template, così
          che durante il processo di importazione non si verifichino errori
          dovuti a una scorretta formattazione dei campi e dei relativi valori
          richiesti.
          <br />
          <strong>Nota bene</strong>: la prima riga del template fornito non
          sarà processata, ricordati quindi di non modificarla/sovrascriverla
          perché tali valori non saranno comunque considerati.
        </div>
      </div>
      <SetupImportFilesForm
        toggle={toggle}
        isStepped
        fileTypeLabel="CSV"
        importType="import-csv"
        contentType="text/csv"
      />
      <div className="grouped">
        <div className="grouped-header">
          <span className="step">4</span>
          Esito importazione
        </div>
        <div className="grouped-body">
          Caricato il file, un processo in backgroud elaborerà la richiesta e
          gestirà l'importazione massiva dei dati (il completamento
          dell'operazione può richiedere tempo in base alla quantità di
          informazioni). Cliccando il bottone sopra “Esito importazioni“, potrai
          controllare se l’importazione è andata a buon fine oppure no, ed in
          tal caso controllare quali elementi non sono stati importati.
        </div>
      </div>
      <SetupImportStatusModal isOpen={visibility} toggle={toggle} />
    </SetupCreationFormStyle>
  );
};

export default SetupImportCsvForm;
