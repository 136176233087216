import styled from 'styled-components'

export const MaintenancePageStyle = styled.div`
  text-align: center; 
  padding: 0; 
  background: ${({ theme }) => theme.colors.primary[600]}; 
  color: ${({ theme }) => theme.colors.grayscale[50]};
  width: 100%; height: 100vh;
  padding: 1rem;

  .logo {
    height: 80px;
  }

  article { 
    height: 100%;
    margin: 0 auto; 
    text-align: center; 
    align-items: center; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h1 { font-size: 43px; font-weight: 500; text-align: center; margin-bottom: 0; margin-top: 35px;}
  
  .paragraph{ margin: 1rem 0; font-size: 20px; font-weight: 300; }
  
  .footer{ font-size: 13px; font-weight: 300; }

  a { color: ${({ theme }) => theme.colors.grayscale[50]}; font-weight: bold;}
  a:hover { text-decoration: none; }

  svg.icon { width: 50px; height: auto; margin-top: 1em; }
  
  @media screen and (min-width: 1024px){
    .footer{ margin-top: auto; }
    .content{ margin: auto; }
    article{ height: 100%; display: flex; flex-direction: column; }
  }
  @media screen and (max-width: 1023px){
    body{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
  }
`