import AttachmentsDashboard from "../../../components/AttachmentsDashboard/AttachmentsDashboard.component"
import BankAccountDashboard from "../../../components/BankAccountDashboard/BankAccountDashboard.component"
import ProposalFormContainer from "../../../components/forms/ProposalForm/ProposalFormContainer.component"
import SignedContractDashboardContainer from "../../../components/SignedContractDashboard/SignedContractDashboardContainer.component"
import GasAndPowerContractTabs from "./GasAndPowerContractTabs/GasAndPowerContractTabs.component"
import { useFetchContractProposalDataQuery, useFetchFormFieldsValuesQuery } from "../../../redux/contract/contract.api"
import { ContractTabsMap } from "../../../redux/contract/contract.utils"
import { useSelectContractId, useSelectEntityName, useSelectSelectedStep } from "../../providers/ContractsPageProvider.provider"
import GasAndPowerCadastralDataWrapper from "./GasAndPowerCadastralDataWrapper/GasAndPowerCadastralDataWrapper.component"

const GasAndPowerContainer = () => {
  const contractId = useSelectContractId()
  const entityName = useSelectEntityName()
  const selectedStep = useSelectSelectedStep()
  const timestamp = new Date().toString()
  const { 
    data: fieldsValues,
    isFetching: isFetchingFormFields, 
    isLoading: isLoadingFormFields,
  } = useFetchFormFieldsValuesQuery({ entityName, timestamp: timestamp })
  const fetchingFormfields = isFetchingFormFields || isLoadingFormFields

  const { 
    data: proposalData,
    isFetching: isFetchingProposalData, 
    isLoading: isLoadingProposalData,
    isUninitialized
  } = useFetchContractProposalDataQuery({ entityName, contractId }, { skip: !contractId })
  const fetchingProposalData = isFetchingProposalData || isLoadingProposalData

  const isFetchingData = fetchingFormfields || fetchingProposalData

  return (
    <>
      <GasAndPowerContractTabs />
      {selectedStep === ContractTabsMap.PROPOSAL_STEP &&
        <ProposalFormContainer
          proposalData={isUninitialized ? undefined : proposalData}
          fetchingProposalData={isFetchingData}
          fieldsValues={fieldsValues ?? {}}
        />
      }
      {selectedStep === ContractTabsMap.PAYMENT_STEP &&
        <BankAccountDashboard />
      }
      {selectedStep === ContractTabsMap.CADASTRAL_STEP &&
        <GasAndPowerCadastralDataWrapper />
      }
      {selectedStep === ContractTabsMap.ATTACHMENTS_STEP &&
        <AttachmentsDashboard />
      }
      {selectedStep === ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP &&
        <SignedContractDashboardContainer />
      }
    </>
  )
}

export default GasAndPowerContainer