import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"

import TextAreaWrapper from "../../inputFields/TextArea/TextAreaWrapper.component"
import { FormProps } from "../../utils/types"

const InternalReference = ({
  canEdit
}: FormProps) => {
  const { register } = useFormContext()

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="comments" className="me-2" />
        Riferimento interno
      </div>
      <div className="grouped-body privacy">
        <Row>
          <Col md={12}>
            <TextAreaWrapper
              register={register}
              name="reference"
              className="form-control"
              label="Riferimento interno"
              rules={{ maxLength: 512 }}
              disabled={canEdit ? false : true}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default InternalReference