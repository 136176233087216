import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component";
import { FieldValues, useFormContext } from "react-hook-form";
import { errorFormLabels } from "../../utils/formLabels";
import { ReactComponent as PensoInVerdeIcon } from "../../../../assets/svg/penso-verde.svg"
import { fetchElectricContractPriceList } from "../../../../redux/contract/pod/pod.api";
import { useSelectContractId } from "../../../../pages/providers/ContractsPageProvider.provider";
import { useSwitchContractPriceListHook } from "../hooks/useSwitchContractPriceListHook.hooks";

interface Props {
  canEdit?: boolean;
  formFields: FieldValues;
  entityName: string;  
  userEmail: string
}
const PodPriceListSwitch = ({ canEdit, formFields, entityName, userEmail }: Props) => {
  const {
    watch,
    register,
    control,
    formState: { errors },
  } = useFormContext();  
  const formWatcher = watch()

  const contractId = useSelectContractId()
  const [triggerFetchContractPriceList] = fetchElectricContractPriceList.useLazyQuerySubscription()
  const contractPriceList = useSwitchContractPriceListHook({
    electricUseType: formWatcher.useType?.value,
    fetchContractPriceListCode: triggerFetchContractPriceList,
    contractId,
    entityName,
    userEmail,
    watch
  })

  return (
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="bolt" className="me-2" />
          Cambio listino utenza
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={6}>
              <SelectWrapper
                name="useType"
                control={control}
                label="Tipo uso"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={formFields?.electricUseType || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <Col md={6}>
            <SelectWrapper
                name="contractPriceListCode"
                control={control}
                label="Offerta"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={contractPriceList}
                rules={{ required: errorFormLabels.REQUIRED }}
                getOptionLabel={(option) => (
                  <>
                    {option.hasGreenEnergy === true && (
                      <PensoInVerdeIcon className="penso-in-verde-icon" />
                    )}
                    <span>{option.label}</span>
                  </>
                )}
              />
            </Col>
          </Row>
        </div>
      </div>
  );
};

export default PodPriceListSwitch;
