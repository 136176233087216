import { useCallback, useEffect, useState } from "react"
import { FieldValues, UseFormWatch } from "react-hook-form"

type UpdateContractPriceListHookType = {
  fetchContractPriceListCode: (values: Record<string, unknown>) => any
  contractId: string
  entityName: string
  userEmail: string,
  gasUseType?: string | number
  pdrId?: string
  logicalPoint: string | number
  watch: UseFormWatch<FieldValues>
  setUseType: (value: any) => void
}
export const useUpdateContractPriceListHook = ({
  pdrId,
  gasUseType,
  fetchContractPriceListCode,
  watch,
  setUseType,
  ...rest
}: UpdateContractPriceListHookType) => {  
  const [contractPriceList, setContractPriceList] = useState([])
  const updateContractPriceListCode = useCallback(async (values: Record<string, unknown>) => {
    const response = await fetchContractPriceListCode(values)
    setContractPriceList(response.data)
  }, [fetchContractPriceListCode])

  //Fetch data on render if podData is set
  useEffect(() => {
    if(pdrId && gasUseType) {
      updateContractPriceListCode({
        pdrId,
        gasUseType,
        ...rest
      })
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Fetch data when "gasUseTypeId" field fires onChange event
  const subscription = watch((data, { name, type }) => {      
    if(name === "gasUseTypeId") {
      setUseType(data[name].value)
      updateContractPriceListCode({
        ...rest,
        pdrId,
        gasUseType: data[name].value
      })
    }
    if(name === "logicalPoint" && data["gasUseTypeId"]?.value) {
      updateContractPriceListCode({
        ...rest,
        pdrId,
        gasUseType: data["gasUseTypeId"].value,
        logicalPoint: data[name].value
      })
    }
  })
  
  useEffect(() => {    
    return () => subscription.unsubscribe()
  }, [subscription])

  return contractPriceList
}