import { FieldValues, UseFieldArrayRemove, useFormContext } from "react-hook-form"
import moment from 'moment-timezone'
import { Button, Col } from "reactstrap"

import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import ShowOnCondition from "../../../auth/ShowOnCondition.component"
import { errorFormLabels } from "../../utils/formLabels"
import { canI } from "../../../auth/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CanI from "../../../auth/CanI.component"
import AttachmentRowStyle from "./style"
import { useDeleteAttachmentMutation } from "../../../../redux/contract/attachment/attachment.api"
import { useState } from "react"
import DeleteItem from "../../../DeleteItemModal/DeleteItemModal.component"
import classNames from "classnames"

type AttachmentRowProps = {
  index: number
  field: any
  formFields: FieldValues
  entityName: string
  removeFile: UseFieldArrayRemove
}

const AttachmentRow = ({
  index,
  field,
  formFields,
  entityName,
  removeFile,
}: AttachmentRowProps) => {
  const { control, register, formState: { isSubmitting, errors } } = useFormContext()
  const [deleteItem, { isLoading: isDeletingItem }] = useDeleteAttachmentMutation()
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const toggleDelete = () => setDeleteIsOpen(prevState => !prevState)

  let canEdit = true
  if(field?.links) {
    canEdit = canI(`${entityName}AttachmentUPDATE`, field.links).abilityCheck
  }
  const defaultInsertDate = field?.insertDate || null

  return (
    <AttachmentRowStyle
      className={classNames({
        "is-loading": (isSubmitting && !field.insertDate) || isDeletingItem
      })}
    >
      <Col md={12} lg={5}>
        <InputWrapper 
          type="text"
          className="form-control"
          label="File"
          name={`dropzoneRow.${index}.userFileName` as const}
          readOnly
          register={register}
        />
        {defaultInsertDate &&
          <p className="attachment-datetime">
            Caricato il &nbsp;
            <strong>{moment(defaultInsertDate).format('DD/MM/YYYY')}</strong> &nbsp;
            alle <strong>{moment.utc(defaultInsertDate).tz('Europe/Rome').format('HH:mm')}</strong>
          </p>
        }
      </Col>
      <Col md={5} lg={3}>
        <ShowOnCondition showWhen={parseInt(field.attachmentTypeId?.value) !== 11 }>
          <SelectWrapper
            name={`dropzoneRow.${index}.attachmentTypeId` as const}
            control={control}
            label="Tipologia"
            isDisabled={canEdit ? false : true}
            register={register}
            options={formFields?.attachmentType || []}
            rules={{ required: errorFormLabels.REQUIRED }}
            errors={errors}
          />
        </ShowOnCondition>
      </Col>
      <Col md={5} lg={3}>
        <SelectWrapper
          name={`dropzoneRow.${index}.supplyPointId` as const}
          control={control}
          label="Punto di prelievo"
          isDisabled={canEdit ? false : true}
          register={register}
          options={formFields?.supplyPointId || []}
        />
      </Col>
      <Col md={2} lg={1}>
        <div className="action-buttons">
          <ShowOnCondition showWhen={field.internalFileURL}>
            <Button
              color="warning"
              size="sm"
              href={field.internalFileURL}
              title="Scarica allegato"
              outline
            >
              <FontAwesomeIcon icon="eye" />
            </Button>
          </ShowOnCondition>
          <ShowOnCondition showWhen={field.links}>
            <CanI doWhat="DELETE" withPermissions={field?.links || []} entityName={`${entityName}Attachment`}>
              {({ action }) => (
                <>
                  <Button 
                    outline 
                    size="sm" 
                    color="danger"
                    title="Elimina"
                    onClick={toggleDelete}
                  >
                    <FontAwesomeIcon icon="trash-alt" size="sm" />
                  </Button>
                  <DeleteItem
                    isOpen={deleteIsOpen}
                    toggle={toggleDelete}
                    deleteFn={async () => {
                      await deleteItem(action)
                      toggleDelete()
                    }}
                    isDeleting={isDeletingItem}
                  />
                </>
              )}
            </CanI>
          </ShowOnCondition>
          <ShowOnCondition showWhen={!field.links}>
            <Button 
              outline 
              size="sm" 
              color="danger"
              title="Elimina"
              onClick={() => {
                removeFile(index)
                //TODO remove file from queue
              }}
            >
              <FontAwesomeIcon icon="trash-alt" size="sm" />
            </Button>
          </ShowOnCondition>
        </div>
      </Col>
    </AttachmentRowStyle>
  )
}

export default AttachmentRow