import classNames from 'classnames'

type TdTableProps = {
  value?: string
  sortParam?: string
  sortingName?: string
  otherClasses?: string[]
  children?: JSX.Element
}

const TdTable = ({ 
  value, 
  sortParam, 
  sortingName, 
  otherClasses = [], 
  children
}: TdTableProps) => {
  return (
    <td
      className={
        classNames({
          "active-col": sortParam && sortingName && sortParam.indexOf(sortingName) !== -1,
          [otherClasses.join(" ")]: !!otherClasses.length
        })
      }
    >
      {value || children}
    </td>
  )
}

export default TdTable