import { getCurrentUser } from "../../../firebase";
import { api } from "../../api";
import { ResponseType } from "../../api/types";
import {
  AttachmentItemType,
  HeaterAttachmentItemType,
} from "../contract.types";
import { getEntityUriName } from "../contract.utils";
import { uploadAttachment, uploadMandatorydAttachments } from "./utils";

export const attachmentApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchAttachments: build.query({
      query: ({ entityName, contractId }) => ({
        url: `${getEntityUriName(
          entityName
        )}/attachments?contractId=${contractId}`,
      }),
      providesTags: (result, error, args) => {
        if (result) {
          return [
            ...result.map(
              (item: any) => ({ type: "Attachment", id: item.id } as const)
            ),
            { type: "Attachment" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "Attachment" as const, id: "LIST" }];
        }
      },
      transformResponse: (response: ResponseType, meta, arg) => response.data,
    }),
    submitAttachment: build.mutation({
      async queryFn({ attachments, entityName }) {
        const firebaseUser = await getCurrentUser();
        const token = await firebaseUser.getIdToken();
        const result = await Promise.allSettled(
          attachments.map(
            (attachmentData: AttachmentItemType | HeaterAttachmentItemType) =>
              uploadAttachment(attachmentData, entityName, token)
          )
        );
        return { data: result };
      },
      invalidatesTags: (result, error, args) => [
        { type: "Contract" },
        { type: "Attachment", id: "LIST" },
      ],
    }),
    deleteAttachment: build.mutation({
      query: (actionUrl: string) => ({
        url: actionUrl,
        method: "DELETE",
      }),
      invalidatesTags: (response, error, args) => [
        { type: "Contract" },
        { type: "Attachment", id: "LIST" },
      ],
    }),
    fetchContractPods: build.query({
      query: ({ entityName, contractId }) => ({
        url: `${getEntityUriName(entityName)}/pods/${getEntityUriName(
          entityName
        )}/${contractId}`,
      }),
    }),
    fetchContractPdrs: build.query({
      query: ({ entityName, contractId }) => ({
        url: `${getEntityUriName(entityName)}/pdrs/${getEntityUriName(
          entityName
        )}/${contractId}`,
      }),
    }),
    openAttachmentsIntegration: build.mutation({
      query: ({ actionUrl, values }) => ({
        url: actionUrl,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (response, error, args) => [
        { type: "Contract" },
        { type: "Attachment", id: "LIST" },
      ],
    }),
    closeAttachmentsIntegration: build.mutation({
      query: ({ actionUrl, values }) => ({
        url: actionUrl,
        method: "POST",
        body: values,
      }),
      invalidatesTags: (response, error, args) => [
        { type: "Contract" },
        { type: "Attachment", id: "LIST" },
      ],
    }),
    fetchActiveUserAttachments: build.query({
      query: ({ entityName, contractId }) => ({
        url: `${getEntityUriName(
          entityName
        )}/attachments/mandatory?contractId=${contractId}`,
      }),
      providesTags: (result, error, args) => [
        ...result!.map(
          (item: any) => ({ type: "Attachment", id: item.id } as const)
        ),
        { type: "Attachment" as const, id: "ACTIVE_USER_LIST" },
      ],
    }),
    submitActiveUserAttachments: build.mutation({
      async queryFn({ attachments, entityName }) {
        const firebaseUser = await getCurrentUser();
        const token = await firebaseUser.getIdToken();
        const result = await Promise.allSettled(
          attachments.map((attachmentData: any) =>
            uploadMandatorydAttachments(attachmentData, entityName, token)
          )
        );
        return { data: result };
      },
      invalidatesTags: (result, error, args) => [
        { type: "Contract" },
        { type: "Attachment", id: "ACTIVE_USER_LIST" },
      ],
    }),
  }),
});

export const {
  useFetchAttachmentsQuery,
  useSubmitAttachmentMutation,
  useFetchContractPodsQuery,
  useFetchContractPdrsQuery,
  useLazyFetchContractPdrsQuery,
  useDeleteAttachmentMutation,
  useOpenAttachmentsIntegrationMutation,
  useCloseAttachmentsIntegrationMutation,
  useFetchActiveUserAttachmentsQuery,
  useSubmitActiveUserAttachmentsMutation,
} = attachmentApi;
export const {
  endpoints: { fetchContractPdrs },
} = attachmentApi;
