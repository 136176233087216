import { api } from "../../api"
import { ResponseType } from "../../api/types"
import { getAPIUrl } from "../../api/utils"
import { getEntityUriName } from "../contract.utils"

export const podApi = api.injectEndpoints({
  endpoints: build => ({
    fetchElectricUseTypeId: build.query({
      query: ({ entityName, contractTypeId }) => ({
        url: `${getAPIUrl()}/form-items/use-types?OpeningCausal=${entityName}&supplyPointType=ee&ContractType=${contractTypeId}`
      }),
      transformResponse: (response: ResponseType, meta, args) => response.data,
    }),
    fetchElectricContractPriceList: build.query({
      query: ({ podId, userEmail, contractId, entityName, electricUseType }) => ({
        url: `${getAPIUrl()}/form-items/offers?FornitureType=E&podId=${podId || ''}&ElectricUseType=${electricUseType}&userEmail=${encodeURIComponent(userEmail)}&openingCausal=${entityName}&contractId=${contractId}`,
      }),
      transformResponse: (response: { data: any }, meta, args) => response.data,
    }),
    fetchElectricVendors: build.query({
      query: ({ entityName, searchedValue }) => ({
        url: `${getAPIUrl()}/${getEntityUriName(entityName)}/pods/vendors?vendorName=${searchedValue}&limit=20`
      }),
      transformResponse: (response: { data: any }, meta, args) => response.data,
    }),
    fetchElectricVatClassList: build.query({
      query: ({ 
        customerType,
        entityName,
        useType, 
      }) => ({
        url: `${getAPIUrl()}/form-items/available-vats?customerType=${customerType}&openingCausal=${entityName}&useType=${useType}&supplyPointType=ee`
      }),
      transformResponse: (response: { data: any }, meta, args) => response.data,
    }),
    fetchExciseClass: build.query({
      query: ({
        customerType,
        entityName,
        useType,
        vatClass,
      }) => ({
        url: `${getAPIUrl()}/form-items/available-excise?customerType=${customerType}&openingCausal=${entityName}&useType=${useType}&vatClass=${vatClass}&supplyPointType=ee`
      }),
      transformResponse: (response: { data: any }, meta, args) => response.data,
    }),
    fetchPodData: build.query({
      query: ({ entityName, podId }) => ({
        url: `${getAPIUrl()}/${getEntityUriName(entityName)}/pods/${podId}`
      }),
      providesTags: (response, error, arg) => [{ type: "Pod", id: response?.id }],
    }),
    submitPodData: build.mutation({
      query: ({ entityName, values }) => ({
        url: `${getAPIUrl()}/${getEntityUriName(entityName)}/pods${values.id ? `/${values.id}` : ''}`,
        method: values.id ? "PUT" : "POST",
        body: values
      }),
      invalidatesTags: (response, error, { values: { supplyNo } }) => [
        { type: "Contract" },
        { type: "Pod", id: "LIST" },
        { type: "CadastralData" as const, id: "LIST"},
        { type: "Pod", id: response?.id },
      ],
    }),
    deletePodItem: build.mutation({
      query: (actionUrl: string) => ({
        url: actionUrl,
        method: "DELETE"
      }),
      invalidatesTags: (response, error, args) => [
        { type: "Contract" },
        { type: "CadastralData", id: "LIST"},
        { type: "Pod", id: "LIST" },
        { type: "List" },
      ],
    }),
    fetchPodItemHistory: build.query({
      query: ({ entityName, pdpType, itemId }) => ({
        url: `${getAPIUrl()}/supply-point-logs?supplyPointId=${itemId}`
      }),
      transformResponse: (response: { items: [] }, meta, args) => response.items,
    }),
    updatePodItemStatus: build.mutation({
      query: ({ actionUrl, values }) => ({
        url: actionUrl,
        method: "PATCH",
        body: values
      }),
      invalidatesTags: (response, error, args) => [
        { type: "Contract" },
        { type: "CadastralData", id: "LIST"},
        { type: "Pod", id: "LIST" },
        { type: "List" },
      ],
    })
  })
})

export const {
  useLazyFetchPodDataQuery,
  useDeletePodItemMutation,
  useLazyFetchPodItemHistoryQuery,
  useUpdatePodItemStatusMutation,
  useSubmitPodDataMutation,
} = podApi

export const {
  endpoints: {
    fetchElectricUseTypeId,
    fetchElectricContractPriceList,
    fetchElectricVendors,
    fetchElectricVatClassList,
    fetchExciseClass,
  }
} = podApi