import { Col, Row } from "reactstrap"
import { selectUserAvailableNewCustomerEntities, selectUserAvailableOngoingCustomerEntities, selectUserAvailableOtherEntities } from "../../../redux/auth/auth.selectors"
import { useAppSelector } from "../../../redux/hooks"
import EntitySectionShortcuts from "../EntitiySectionShortcuts/EntitySectionShortcuts.component"

const AvailableEntitiesHeader = () => {
    const availableNewCustomerContracts = useAppSelector(selectUserAvailableNewCustomerEntities)
    const availableOngoingCustomerContracts = useAppSelector(selectUserAvailableOngoingCustomerEntities)
    const availableOtherContracts = useAppSelector(selectUserAvailableOtherEntities)

    return (
        <>
            {!!availableNewCustomerContracts.length || !!availableOngoingCustomerContracts.length || !!availableOtherContracts.length ? (
                <>
                    <Row>
                        <Col>
                            <h1 className="main-title">Operazioni disponibili</h1>
                        </Col>
                    </Row>
                    <EntitySectionShortcuts />
                </>
            ) : (
                <Row>
                    <Col>
                    <h5>
                        Al momento la tua utenza non ha i permessi per poter effettuare operazioni su contratti e o.t.
                    </h5>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default AvailableEntitiesHeader