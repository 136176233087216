import { useState } from "react"
import LoginEmailForm from "../../components/forms/LoginEmailForm/LoginEmailForm.component"

import LoginPageStyle from "./style"
import DefaultPageContainer from "../DefaultPageContainer/DefaultPageContainer.component"
import { LoginSteps } from "./utils"
import LoginPasswordForm from "../../components/forms/LoginPasswordForm/LoginPasswordForm.componet"
import { ResetPasswordReqType } from "../../redux/auth/auth.types"

const LoginPage = () => {
  const [loginStep, setLoginStep] = useState(LoginSteps.USER_EMAIL_STEP)
  const [userData, setUserData] = useState<ResetPasswordReqType>()

  return (
    <DefaultPageContainer>
      <LoginPageStyle>
        <div className="form-container">
          {loginStep === LoginSteps.USER_EMAIL_STEP &&
            <LoginEmailForm setLoginStep={setLoginStep} setUserData={setUserData} />
          } 
          {loginStep === LoginSteps.USER_PASSWORD_STEP &&
            <LoginPasswordForm setLoginStep={setLoginStep} userData={userData!} />
          }
        </div>
      </LoginPageStyle>
    </DefaultPageContainer>
  )
}

export default LoginPage