import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { FieldValues } from "react-hook-form"
import { Button, Card, CardBody, CardTitle } from "reactstrap"

import { useSelectContractId, useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { selectCurrentUserRole } from "../../../redux/auth/auth.selectors"
import { UserRoles } from "../../../redux/auth/auth.utils"
import { selectContractStatusId } from "../../../redux/contract/contract.selectors"
import { BankAccountItemData, CadastralDataItemType } from "../../../redux/contract/contract.types"
import { ContractStatusMap, getItemStatusLabel, getSupplyPointStatusIcon } from "../../../redux/contract/contract.utils"
import { useDeletePdrItemMutation, useLazyFetchPdrDataQuery, useLazyFetchPdrItemHistoryQuery } from "../../../redux/contract/pdr/pdr.api"
import { useAppSelector } from "../../../redux/hooks"
import { isThisItemLoading } from "../../../utils"
import CanI from "../../auth/CanI.component"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import DeleteItem from "../../DeleteItemModal/DeleteItemModal.component"
import PdpStatusHistoryModal from "../../PdpStatusHistoryModal/PdpStatusHistoryModal.component"
import PdpStatusUpdateModal from "../../PdpStatusUpdateModal/PdpStatusUpdateModal.component"
import PdpDetailModal from "../PdpDetailModal/PdpDetailModal.component"
import PdrItemStyle from "./style"

type PdrItemProps = {
  item: any
  cadastralItem: CadastralDataItemType
  formFields: FieldValues
  customerType: Record<string, string>
  bankAccountData: BankAccountItemData[]
}

const PdrItem = ({
  item,
  cadastralItem,
  ...rest
}: PdrItemProps) => {  
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  const role = useAppSelector(selectCurrentUserRole)
  const contractStatus = useAppSelector(selectContractStatusId({ entityName, contractId }))
  const [isOpenNewPdp, setIsOpenNewPdp] = useState(false)
  const toggleNewPdp = () => setIsOpenNewPdp(prevState => !prevState)
  const [isOpenEditPdp, setIsOpenEditPdp] = useState(false)
  const toggleEditPdp = () => setIsOpenEditPdp(prevState => !prevState)
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const toggleDelete = () => setDeleteIsOpen(prevState => !prevState)
  const [isOpenStatusHistory, setIsOpenStatusHistory] = useState(false)
  const toggleStatusHistory = () => setIsOpenStatusHistory(prevState => !prevState)
  const [isOpenUpdateStatus, setIsOpenUpdateStatus] = useState(false)
  const toggleUpdateStatus = () => setIsOpenUpdateStatus(prevState => !prevState)

  //PDR api
  const [fetchPdrItemHistoryData, {
    data: pdrHistoryData,
    isLoading: isLoadingPdrHistoryData,
    isFetching: isFetchingPdrHistoryData,
  }, {
    lastArg: { itemId: lastPdrHistoryLoadedItemId },
  }] = useLazyFetchPdrItemHistoryQuery()
  const fetchingHistoryData = isLoadingPdrHistoryData || isFetchingPdrHistoryData

  const [fetchPdrData, {
    data: pdrData,
    isLoading: isLoadingPdrData,
    isFetching: isFetchingPdrData,
  }, {
    lastArg: { pdrId: lastPdrLoadedItemId }
  }] = useLazyFetchPdrDataQuery()
  const fetchingPdrData = isLoadingPdrData || isFetchingPdrData

  const [deletePdrItem, { isLoading: isDeletingPdr }] = useDeletePdrItemMutation()

  return (
    <PdrItemStyle>
      <header>
        <FontAwesomeIcon icon="flame" className="me-2" />
        PDR
      </header>
      {item ? (
        <Card>
          <CardBody>
            <CardTitle>
              <FontAwesomeIcon
                className="me-2"
                icon={getSupplyPointStatusIcon(item.supplyPointStatusId)}
                title={getItemStatusLabel(item.supplyPointStatusId)}
              />
              {item.no}
            </CardTitle>
            {
              (role === UserRoles.USER_ROLE_ADMIN || role === UserRoles.USER_ROLE_VERIFIER) &&
              contractStatus >= ContractStatusMap.DONE_STATUS &&
                <CanI 
                  doWhat="GET" 
                  withPermissions={item.links} 
                  entityName={`${entityName}Pdr`}
                >
                  {({ action }) => (
                    <div className="mb-2">
                      <ButtonWithLoader 
                        label="Vedi lo storico del contratto"
                        fontAwesomeIcon={["fas", "history"]}
                        size="sm"
                        color="secondary"
                        outline
                        disabled={isThisItemLoading({ 
                          itemId: item.id, 
                          lastLoadedItemId: lastPdrHistoryLoadedItemId, 
                          otherLoadingChecks: fetchingHistoryData 
                        })}
                        isLoading={isThisItemLoading({ 
                          itemId: item.id, 
                          lastLoadedItemId: lastPdrHistoryLoadedItemId, 
                          otherLoadingChecks: fetchingHistoryData 
                        })}
                        onClick={async() => {
                          await fetchPdrItemHistoryData({ entityName, pdpType: "pdr", itemId: item.id })
                          toggleStatusHistory()
                        }}
                      />
                      <PdpStatusHistoryModal 
                        isOpen={isOpenStatusHistory}
                        toggle={toggleStatusHistory}
                        itemHistoryData={pdrHistoryData!}
                      />
                    </div>
                  )}
                </CanI>
            }
            <CanI 
              doWhat="PATCH" 
              withPermissions={item.links} 
              entityName={`${entityName}Pdr`}
            >
              {({ action }) => (
                <div className="mb-2">
                  <Button 
                    outline 
                    size="sm" 
                    color="secondary"
                    onClick={toggleUpdateStatus}
                  >
                    <FontAwesomeIcon icon="exchange-alt" className="me-2" />
                    Modifica stato contratto
                  </Button>
                  <PdpStatusUpdateModal 
                    isOpen={isOpenUpdateStatus}
                    toggle={toggleUpdateStatus}
                    currentPdpStatus={item.supplyPointStatusId}
                    actionUrl={action}
                    pdpType="pdr"
                  />
                </div>
              )}
            </CanI>
            <ButtonWithLoader 
              label="Dettagli"
              fontAwesomeIcon={["fas", "eye"]}
              color="secondary"
              outline
              size="sm"
              className="mb-2"
              isLoading={isThisItemLoading({ 
                itemId: item.id, 
                lastLoadedItemId: lastPdrLoadedItemId, 
                otherLoadingChecks: fetchingPdrData
              })}
              onClick={async() => {
                await fetchPdrData({ pdrId: item.id, entityName })
                setIsOpenEditPdp(true)
              }}
            />
            <CanI 
              doWhat="DELETE" 
              withPermissions={item.links} 
              entityName={`${entityName}Pdr`}
            >
              {({ action }) => (
                <>
                  <div className="mb-2">
                    <Button 
                      outline 
                      size="sm" 
                      color="danger"
                      onClick={toggleDelete}
                    >
                      <FontAwesomeIcon icon="trash-alt" size="sm" className="me-2" />
                      Elimina
                    </Button>
                  </div>
                  <DeleteItem
                    isOpen={deleteIsOpen}
                    toggle={toggleDelete}
                    deleteFn={async () => {
                      await deletePdrItem(action)
                      toggleDelete()
                    }}
                    isDeleting={isDeletingPdr}
                  />
                </>
              )}
            </CanI>
          </CardBody>
          <PdpDetailModal
            pdpType="pdr"
            cadastralDetailData={cadastralItem}
            isOpen={isOpenEditPdp}
            toggle={toggleEditPdp}
            pdpData={pdrData}
            {...rest}
          />
        </Card>
      ) : (
        <Card>
          <CardBody>
            <p className="small">Nessun PDR associato a questo punto di fornitura</p>
            <CanI 
              doWhat="CREATE" 
              withPermissions={cadastralItem.links} 
              entityName={`${entityName}Pdr`}
            >
              {() => (
                <>
                  <Button
                    size="md"
                    color="secondary"
                    outline
                    onClick={toggleNewPdp}
                  >
                    <FontAwesomeIcon icon={['fas', 'plus-circle']} className="me-2" />
                    Aggiungi punto di fornitura
                  </Button>
                  <PdpDetailModal 
                    pdpType="pdr"
                    cadastralDetailData={cadastralItem}
                    isOpen={isOpenNewPdp}
                    toggle={toggleNewPdp}
                    {...rest}
                  />
                </>
              )}
            </CanI>
          </CardBody>
        </Card>
      )}
    </PdrItemStyle>
  )
}

export default PdrItem