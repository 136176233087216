import { Modal, ModalHeader, ModalBody, ListGroup } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import HistoryEventsList from './HistoryEventsList/HistoryEventsList.component'

type PdpStatusHistoryModalProps = {
  isOpen: boolean
  toggle: () => void
  itemHistoryData: []
}

const PdpStatusHistoryModal = ({ 
  isOpen,
  toggle,
  itemHistoryData
}: PdpStatusHistoryModalProps) => {
  return (
    <Modal className="status-modal" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon className="me-2" icon="history" />
        Storico dei cambiamenti di stato del contratto
      </ModalHeader>

      <ModalBody className="notes">
        <ListGroup>
          <HistoryEventsList events={itemHistoryData} />
        </ListGroup>
      </ModalBody>
    </Modal>
  )
}

export default PdpStatusHistoryModal
