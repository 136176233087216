import classNames from "classnames"
import { Table } from "reactstrap"

import PdfTBody from "./PdfTBody/PdfTBody.component"
import PdfTHeader from "./PdfTHeader/PdfTHeader.component"

type PdfListTableProps = {
  page: number
  filters: string
  isFetching: boolean
  setupUniqueName: string
}

const PdfListTable = ({
  isFetching,
  ...rest
}: PdfListTableProps) => {
  return (
    <Table 
      responsive
      className={
        classNames({
          'is-fetching': isFetching === true
        })
      }
    >
      <PdfTHeader />
      <PdfTBody 
        {...rest}
      />
    </Table>
  )
}

export default PdfListTable