import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { Alert, Form } from "reactstrap"

import { Permissions } from "../../../redux/api/types"
import { useSubmitPaperContractMutation } from "../../../redux/contract/contract.api"
import { AttachmentItemType } from "../../../redux/contract/contract.types"
import ShowOnCondition from "../../auth/ShowOnCondition.component"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import { setUpPaperContractSubmitValues } from "./utils"
import PaperContractDropzone from "./PaperContractDropzone/PaperContractDropzone.component"
import PaperSignatureFormStyle from "./style"
import { isAlertDescriptionNeeded } from "./utils"
import { useSetSelectedStep } from "../../../pages/providers/ContractsPageProvider.provider"
import { ContractTabsMap } from "../../../redux/contract/contract.utils"

type PaperSignatureFormProps = {
  entityName: string
  contractId: string
  permissions: Permissions
  formFields?: FieldValues
  attachmentsData?: AttachmentItemType[]
  toggleFinalizationModal: () => void
}

const PaperSignatureForm = ({
  entityName,
  attachmentsData,
  contractId,
  toggleFinalizationModal,
}: PaperSignatureFormProps) => {
  const setSelectedStep = useSetSelectedStep()!

  const formProps = useForm({
    defaultValues: attachmentsData ? ({
      ...attachmentsData,
      contractId,
      paperContract: null,
    }) : ({
      contractId,
      paperContract: null,
    })
  })
  const { handleSubmit, formState: { isSubmitting } } = formProps

  const [submitPaperContract] = useSubmitPaperContractMutation()
  const onSubmit = async (values: any) => {
    const submitValues = setUpPaperContractSubmitValues(values, contractId)
    await submitPaperContract({ entityName, contractId, values: submitValues })
    toggleFinalizationModal()
    setSelectedStep(ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP)
  }

  return (
    <PaperSignatureFormStyle>
      <ShowOnCondition showWhen={isAlertDescriptionNeeded(entityName)}>
        <Alert color="warning">
          <h4 className="alert-heading">Nota bene</h4>
          <p className="alert-description">
            Nel caso in cui il file "Contratto firmato" sia già provvisto 
            di eventuali altre tipologie di allegato (ad es. documenti d'identità, bollette, documenti relativi alle accise, ecc.), 
            <strong>non</strong> è necessario caricare questi "altri" documenti anche nella sezione 
            "Aggiungi allegati al contratto" sottostante.        
          </p>
        </Alert>
      </ShowOnCondition>
      <FormProvider {...formProps}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <PaperContractDropzone 
            entityName={entityName}
          />
          <div className="text-end">
            <ButtonWithLoader
              isLoading={isSubmitting}
              type="submit"
              disabled={isSubmitting}
              label="Finalizza contratto"
              fontAwesomeIcon={["fas", "signature"]}
            />
          </div>
        </Form>
      </FormProvider>
    </PaperSignatureFormStyle>
  )
}

export default PaperSignatureForm