import AvailableSetupHeader from "./AvailableSetupHeader/AvailableSetupHeader.component";
import AvailableSetupRows from "./AvailableSetupRows/AvailableSetupRows.component";

const AvailableSetup = () => {
    return (
      <>
        <AvailableSetupHeader />
        <AvailableSetupRows />
      </>
    );
}

export default AvailableSetup