import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { Button, Col, Container, Form, Row } from "reactstrap"

import ButtonWithLoader from "../../../ButtonWithLoader/ButtonWithLoader.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import { ResetPasswordFormType } from "../../../../redux/auth/auth.types"
import { errorFormLabels } from "../../utils/formLabels"

import ResetPasswordFormStyle from "./style"
import { useResetUserPasswordMutation } from "../../../../redux/auth/auth.api"
import { Link } from "react-router-dom"

const ResetPasswordForm = ({ oobCode }: Omit<ResetPasswordFormType, "password">) => {
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<ResetPasswordFormType>({
    defaultValues: {
      oobCode
    }
  })
  const [resetPasswordDone, setResetPasswordDone] = useState(false)
  const [inputType, setInputType] = useState<"password" | "text">("password")
  const togglePasswordVisibility = () => {
    setInputType(prevState => prevState === "password" ? "text" : "password")
  }

  const [resetPassword] = useResetUserPasswordMutation()
  const onSubmit = async (values: ResetPasswordFormType) => {
    const response = await resetPassword(values)
    if(response && !("error" in response)) {
      setResetPasswordDone(true)
    }
  }

  return (
    <ResetPasswordFormStyle>
      <div className="form-container">
        <Container fluid>
          <div className="login-icon">
            <FontAwesomeIcon icon="key" />
          </div>
          <p className="lead">Inserisci la nuova password per procedere:</p>
          {resetPasswordDone ? (
            <div className="reset-feedback">
              <p>Ora puoi tornare alla <Link to="/">home page</Link> ed effettuare il log in con la nuova password.</p>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <InputWrapper 
                    className="form-control"
                    name="password"
                    type={inputType}
                    label="Nuova password"
                    register={register}
                    errors={errors}
                    rules={{ 
                      required: errorFormLabels.REQUIRED,
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                        message: 'La password deve essere composta da almeno 8 caratteri di cui almeno uno maiuscolo, uno minuscolo e almeno un numero.'                      
                      }
                    }}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-between">
                <Button outline className="show-pwd" onClick={togglePasswordVisibility}>
                  {inputType === 'password' ? (
                    <>
                      <FontAwesomeIcon className="me-1" icon={['fas', 'eye']} />
                      <span>Mostra password</span>
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon className="me-1" icon={['fas', 'eye-slash']} />
                      <span>Nascondi password</span>
                    </>
                  )}
                </Button>
                <ButtonWithLoader
                  isLoading={isSubmitting}
                  type="submit"
                  disabled={isSubmitting}
                  label="Salva"
                  fontAwesomeIcon={["fas", "save"]}
                />
              </div>
            </Form>
          )}
        </Container>
      </div>
    </ResetPasswordFormStyle>
  )
}

export default ResetPasswordForm