import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useFormContext } from "react-hook-form"
import { Button, Col, Row } from "reactstrap"

import CheckboxWrapper from "../../inputFields/Checkbox/CheckboxWrapper.component"
import { errorFormLabels } from "../../utils/formLabels"
import { CustomerTypes, getCustomerType } from "../../utils/utils"
import CodCivModal from "./CodCivModal.component"
import GeneralConditionsModal from "./GeneralConditionsModal.component"

const GeneralConditions = () => {
  const { register, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  const [gcModalVisibility, gcSetModalVisibility] = useState(false)
  const toggleGc = () => gcSetModalVisibility(prevState => !prevState)
  const [cvModalVisibility, cvSetModalVisibility] = useState(false)
  const toggleCv = () => cvSetModalVisibility(prevState => !prevState)

  return (
    <>
      {formWatcher.customerType &&
        <div className="grouped">
          <div className="grouped-header">
            <FontAwesomeIcon icon="clipboard-check" className="me-2" />
            Condizioni Generali di Contratto
          </div>
          <div className="grouped-body privacy">
            <Row className="mt-2">
              <Col>
                <Button
                  className="mb-3"
                  onClick={toggleGc}
                >
                  Leggi condizioni
                </Button>
                <GeneralConditionsModal 
                  toggle={toggleGc}
                  isOpen={gcModalVisibility}
                  formWatcher={formWatcher}
                />
              </Col>
              <Col md={12}>
                <CheckboxWrapper 
                  name="generalConditions"
                  label="Ho letto le Condizioni Generali di Contratto"
                  className="form-check-input"
                  register={register}
                  errors={errors}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
            </Row>
          </div>
        </div>
      }
      {formWatcher.customerType &&
        <div className="grouped">
          <div className="grouped-header">
            <FontAwesomeIcon icon="user-lock" className="me-2" />
            Presa visione degli articoli 1341 e 1342 Cod. Civ.
          </div>
          <div className="grouped-body privacy">
            <Row>
              <Col md={12}>
                {getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, formWatcher.customerType) &&
                  <p className="text">
                    Ai sensi e per gli effetti degli articoli 1341 e 1342 Cod. Civ. il Cliente dichiara di aver preso visione e di approvare specificatamente le seguenti clausole di cui alle &nbsp;
                    <Button color="link" className="link-button p-0" onClick={toggleGc} size="sm">Condizioni Generali di Contratto</Button>: Art. 3 (Conclusione ed efficacia del Contratto); Art. 4 (Decorrenza della fornitura, durata del Contratto e recesso); Art. 5 (Clausola risolutiva espressa, risoluzione e sospensione della fornitura); Art. 7 (Variazione delle condizioni di fornitura - Recesso dal rapporto con il precedente fornitore); Art. 8 (Cessione del Contratto - Cessione, affitto e usufrutto d’azienda - Cessione del credito); Art. 9 (Condizioni economiche, corrispettivi); Art. 10 (Fatturazione e pagamenti, calcolo dei corrispettivi); Art. 13 (Garanzie); Art. 16 (Limitazione di Responsabilità di ABenergie); Art. 17 (Utilizzo della fornitura e uso Improprio); Art. 18 (Foro Competente e fonti normative); Art. 19 (Elezione di domicilio e comunicazioni).
                  </p>
                }
                {getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, formWatcher.customerType) &&
                  <p className="text">
                    Ai sensi e per gli effetti degli articoli 1341 e 1342 Cod. Civ. il Cliente dichiara di aver preso visione e di approvare specificatamente le seguenti clausole di cui alle
                    &nbsp;<Button color="link" className="link-button p-0" onClick={toggleGc} size="sm">Condizioni Generali di Contratto</Button>: Art. 3 (Conclusione ed efficacia del Contratto); Art. 4 (Decorrenza della fornitura, durata del Contratto e recesso); Art. 5 (Clausola risolutiva espressa, risoluzione e sospensione della fornitura); Art. 7 (Variazione delle condizioni di fornitura - Recesso dal rapporto con il precedente fornitore); Art. 8 (Cessione del Contratto - Cessione, affitto e usufrutto d’azienda - Cessione del credito); Art. 9 (Condizioni economiche, corrispettivi); Art. 10 (Fatturazione e pagamenti, calcolo dei corrispettivi); Art. 13 (Garanzie); Art. 16 (Limitazione di Responsabilità di ABenergie); Art. 17 (Utilizzo della fornitura e uso Improprio); Art. 18 (Foro Competente e fonti normative); Art. 19 (Elezione di domicilio e comunicazioni).
                  </p>
                }
                <CodCivModal
                  toggle={toggleCv}
                  isOpen={cvModalVisibility}
                  formWatcher={formWatcher}
                />
              </Col>
              <Col md={12}>
                <CheckboxWrapper 
                  name="civilCode"
                  label="Ho preso visione e accetto quanto ai sensi degli articoli 1341 e 1342 Cod. Civ."
                  className="form-check-input"
                  register={register}
                  errors={errors}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
            </Row>
          </div>
        </div>
      }
    </>
  )
}

export default GeneralConditions