import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"

import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import MoneyInputWrapper from "../../inputFields/MoneyInput/MoneyInputWrapper.component"
import TextAreaWrapper from "../../inputFields/TextArea/TextAreaWrapper.component"
import { errorFormLabels, maxLengthLabel } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"

const FramingContribution = ({
  canEdit,
}: FormProps) => {
  const { register, formState: { errors } } = useFormContext()

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="id-card" className="me-2" />
        Inquadramento e retribuzione
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={12}>
            <TextAreaWrapper
              name="duties"
              label="Mansioni"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED, 
                maxLength: {
                  value: 512,
                  message: maxLengthLabel(512)
                } 
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="ccnlFraming"
              label="Inquadramento art.27 CCNL ApL"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="role"
              label="Qualifica"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="level"
              label="Livello/Categoria"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="ccnl"
              label="CCNL di riferimento"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={12}>
            <MoneyInputWrapper
              name="hourlyRetribution"
              label="Retribuzione ordinaria comunicata"
              type="number"
              step=".01"
              errors={errors}
              register={register}
              className="form-control"
              rules={{
                required: errorFormLabels.REQUIRED, 
                min: {
                  value: 0.01,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={12}>
            <TextAreaWrapper
              name="extraCcnlRetribution"
              label="Elementi della retribuzione eccedenti il CCNL"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED, 
                maxLength: {
                  value: 512,
                  message: maxLengthLabel(512)
                } 
              }}
            />
          </Col>
          <Col md={12}>
            <TextAreaWrapper
              name="thirdElement"
              label="Terzo elemento"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED, 
                maxLength: {
                  value: 512,
                  message: maxLengthLabel(512)
                } 
              }}
            />
          </Col>
          <Col md={12}>
            <TextAreaWrapper
              name="variableElements"
              label="Elementi variabili"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED, 
                maxLength: {
                  value: 512,
                  message: maxLengthLabel(512)
                } 
              }}
            />
          </Col>
          <Col md={12}>
            <p className="small">
              L'utilizzatore dichiara sotto la propria responsabilità che tutte le informazioni rilasciate in questo contratto sono corrette e veritiere.
              <br />
              Le condizioni generale riportate sul retro costituiscono parte integrante del presente contratto.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default FramingContribution