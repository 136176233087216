import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { Button } from "reactstrap"
import { useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"

import { Permissions } from "../../../redux/api/types"
import CanI from "../../auth/CanI.component"
import CadastralDataDetailModal, { CadastralDataDetailModalProps } from "../CadastralDataDetailModal/CadastralDataDetailModal.component"
import CadastralDashboardControlButtonsStyle from "./style"

type CadastralDashboardControlButtonsProps = {
  permissions: Permissions
} & Pick<CadastralDataDetailModalProps, "cadastralFieldsValues">

const CadastralDashboardControlButtons = ({
  permissions,
  ...rest
}: CadastralDashboardControlButtonsProps) => {
  const entityName = useSelectEntityName()
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(prevState => !prevState)

  return (
    <CadastralDashboardControlButtonsStyle>
      <CanI doWhat={"CREATE"} withPermissions={permissions} entityName={`${entityName}CadastralData`}>
        {() => (
          <>
            <Button
              size="md"
              color="secondary"
              outline
              onClick={toggle}
            >
              <FontAwesomeIcon icon={['fas', 'plus-circle']} className="me-2" />
              Aggiungi punto di fornitura
            </Button>
            <CadastralDataDetailModal 
              isOpen={isOpen}
              toggle={toggle}
              {...rest}
            />
          </>
        )}
      </CanI>
    </CadastralDashboardControlButtonsStyle>
  )
}

export default CadastralDashboardControlButtons