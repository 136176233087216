import { FormProvider, FieldValues, useForm } from "react-hook-form";
import { Form } from "reactstrap";
import {
  useSelectContractId,
  useSelectEntityName,
} from "../../../pages/providers/ContractsPageProvider.provider";
import { selectCurrentUser } from "../../../redux/auth/auth.selectors";

import {
  BankAccountItemData,
  CadastralDataItemType,
} from "../../../redux/contract/contract.types";
import { useSubmitPodDataMutation } from "../../../redux/contract/pod/pod.api";
import { useAppSelector } from "../../../redux/hooks";
import ShowOnCondition from "../../auth/ShowOnCondition.component";
import { canI } from "../../auth/utils";
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component";
import { useWhenFieldChangesHook } from "./hooks/useWhenFieldChangesHook.hooks";
import PodFAttachment from "./PodFAttachment/PodFAttachment.component";
import PodGeneralData from "./PodGeneralData/PodGeneralData.component";
import PodOfferConfig from "./PodOfferConfig/PodOfferConfig.component";
import PodSupply from "./PodSupply/PodSupply.component";
import Promotions from "./Promotions/Promotions.component";
import RealState from "./RealState/RealEstate.component";
import Sales from "./Sales/Sales.component";
import {
  isContractualChangesNeeded,
  isDeactivationNeeded,
  isMeasureGroupShiftNeeded,
  isNonePopulated,
  isPlantVerifyNeeded,
  isPriceListSwitchNeeded,
  promotionsVisiblity,
  setDefaultFormData,
} from "./utils";
import { isNewCustomerContract } from "../../../redux/contract/contract.utils";
import PodDeactivation from "./PodDeactivation/PodDeactivation.component";
import PodContractualChanges from "./PodContractualChanges/PodContractualChanges.component";
import { useEffect, useState } from "react";
import PodPriceListSwitch from "./PodPriceListSwitch/PodPriceListSwitch.component";
import PodPlantVerify from "./PodPlantVerify/PodPlantVerify.component";
import PodMeasureGroupShift from "./PodMeasureGroupShift/PodMeasureGroupShift.component";

export type PodFormProps = {
  customerType: Record<string, string>;
  podFieldsValues: FieldValues;
  podData?: any;
  cadastralDetailData: CadastralDataItemType;
  bankAccountData: BankAccountItemData[];
  togglePdpDetailModal: () => void;
};

const PodForm = ({
  podFieldsValues,
  customerType,
  cadastralDetailData,
  bankAccountData,
  podData,
  togglePdpDetailModal,
}: PodFormProps) => {
  const entityName = useSelectEntityName();
  const contractId = useSelectContractId();
  const { email, role } = useAppSelector(selectCurrentUser)!;
  let canEdit = true;
  if (podData) {
    canEdit = canI(`${entityName}PodUPDATE`, podData.links).abilityCheck;
  } 
  const formProps = useForm<FieldValues>({
    defaultValues: {
      cadastralDataId: cadastralDetailData.id,
      bankAccountDetailId: bankAccountData ? bankAccountData[0].id : undefined,
      ...setDefaultFormData({
        podFieldsValues,
        podData,
        customerType,
        entityName,
      }),
    }
  });
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = formProps;

  const [submitPodData] = useSubmitPodDataMutation();
  const onSubmit = async (values: FieldValues) => {
    await submitPodData({ entityName, values });
    togglePdpDetailModal();
  };

  useWhenFieldChangesHook({
    watch,
    field: "town",
    set: "distributor",
    to: null,
    setValue,
  });
  useWhenFieldChangesHook({
    watch,
    field: "electricUseTypeId",
    set: "contractPriceListCode",
    to: null,
    setValue,
  });
  useWhenFieldChangesHook({
    watch,
    field: "electricUseTypeId",
    set: "vatClassId",
    to: null,
    setValue,
  });
  useWhenFieldChangesHook({
    watch,
    field: "accountHolderAtecoCode",
    set: "vatClassId",
    to: null,
    setValue,
  });
  useWhenFieldChangesHook({
    watch,
    field: "electricUseTypeId",
    set: "electricExciseClassId",
    to: null,
    setValue,
  });

  const formWatch = watch();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
    if (isContractualChangesNeeded(entityName)) {
      setIsSubmitDisabled(!isNonePopulated(formWatch));
    }
  }, [formWatch, entityName]);

  return (
    <FormProvider {...formProps}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PodGeneralData
          canEdit={canEdit}
          entityName={entityName}
          fieldsValues={podFieldsValues}
          userRole={role}
        />
        <ShowOnCondition showWhen={isNewCustomerContract(entityName)}>
          <PodSupply
            canEdit={canEdit}
            entityName={entityName}
            fieldsValues={podFieldsValues}
            customerType={customerType}
          />
          <PodOfferConfig
            canEdit={canEdit}
            entityName={entityName}
            fieldsValues={podFieldsValues}
            customerType={customerType}
            contractId={contractId}
            userEmail={email}
          />
          <PodFAttachment
            entityName={entityName}
            customerType={customerType}
            canEdit={canEdit}
            fieldsValues={podFieldsValues}
          />
          <RealState
            canEdit={canEdit}
            entityName={entityName}
            fieldsValues={podFieldsValues}
            customerType={customerType}
            extendedPodDataNeeded={cadastralDetailData?.extendedPodDataNeeded}
          />
          <ShowOnCondition showWhen={promotionsVisiblity(entityName)}>
            <Promotions canEdit={canEdit} entityName={entityName} />
          </ShowOnCondition>
          <Sales
            canEdit={canEdit}
            fieldsValues={podFieldsValues}
            entityName={entityName}
          />
        </ShowOnCondition>

        <ShowOnCondition showWhen={isDeactivationNeeded(entityName)}>
          <PodDeactivation
            canEdit={canEdit}
            formFields={podFieldsValues}
            entityName={entityName}
          />
        </ShowOnCondition>

        <ShowOnCondition showWhen={isContractualChangesNeeded(entityName)}>
          <PodContractualChanges
            canEdit={canEdit}
            formFields={podFieldsValues}
            entityName={entityName}
          />
        </ShowOnCondition>

        <ShowOnCondition showWhen={isPriceListSwitchNeeded(entityName)}>
          <PodPriceListSwitch
            canEdit={canEdit}
            formFields={podFieldsValues}
            entityName={entityName}
            userEmail={email}
          />
        </ShowOnCondition>

        <ShowOnCondition showWhen={isPlantVerifyNeeded(entityName)}>
          <PodPlantVerify
            canEdit={canEdit}
            formFields={podFieldsValues}
            entityName={entityName}
          />
        </ShowOnCondition>

        <ShowOnCondition showWhen={isMeasureGroupShiftNeeded(entityName)}>
          <PodMeasureGroupShift
            canEdit={canEdit}
            formFields={podFieldsValues}
            entityName={entityName}
          />
        </ShowOnCondition>

        {canEdit && (
          <div className="text-end">
            <ButtonWithLoader
              isLoading={isSubmitting}
              type="submit"
              disabled={isSubmitting || isSubmitDisabled}
              label="Salva"
              fontAwesomeIcon={["fas", "save"]}
            />
          </div>
        )}
      </Form>
    </FormProvider>
  );
};

export default PodForm;
