import { ListGroupItem } from "reactstrap"
import styled from "styled-components"

const BankAccountItemStyle = styled(ListGroupItem)`
  margin-bottom: 2.5rem;

  .cadastral-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .85rem;
  }

  .bank-account-preview {
    display: flex;
    align-items: center;
  }

  .context-menu {
    display: flex;

    button {
      margin: 0 0 .25rem;
    }
  }
`

export default BankAccountItemStyle