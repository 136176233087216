import moment from "moment"
import { setUpSubmitValues } from "../AttachmentsForm/utils"

export function isAlertDescriptionNeeded(entityName: string) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'takeover' ||
    entityName === 'transfer' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection' ||
    entityName === 'contextualTransfer'
  ) ? true : false
}

export function calculateDaysBeforeNow(days: number) {
  const dateToCalculate = new Date()
  dateToCalculate.setDate(dateToCalculate.getDate() - days)
  return dateToCalculate
}

export function setUpPaperContractSubmitValues(values: any, contractId: string) {
  const paperContract = {
    ...values.paperContract,
    contractId,
    signingDate: moment(values.signingDate).format('YYYY-MM-DD')
  }

  const attachments = setUpSubmitValues(values.dropzoneRow || [], contractId)

  return {
    paperContract,
    attachments,
  }
}