import { Container } from 'reactstrap'
import { useLocation } from 'react-router'
import DefaultPageContainer from '../DefaultPageContainer/DefaultPageContainer.component';

const NoMatchPage = () => {
  const location = useLocation()

  return (
    <DefaultPageContainer>
      <div className="page page-404 py-5">
        <Container>
          <h3 className="title">
            La pagina <code>{location.pathname}</code> non esiste!
          </h3>
        </Container>
      </div>
    </DefaultPageContainer>
  );
}

export default NoMatchPage
