import { useEffect, useState } from "react"

import { ListsTabsMap } from "../../../redux/contract/contract.utils"

export const useUpdateSelectedTab = (entityName: string) => {
  const [selectedTab, setSelectedTab] = useState(ListsTabsMap.PROPOSAL_TAB)

  //Reset tab to proposal on contract entityName change
  useEffect(() => {
    setSelectedTab(ListsTabsMap.PROPOSAL_TAB)
  }, [entityName])

  return [selectedTab, setSelectedTab] as const
}