import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FieldValues, useForm } from "react-hook-form"
import { useLocation, useNavigate } from "react-router"
import { Col, Form, Row } from "reactstrap"

import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import ScrollToTop from "../../ScrollToTop/ScrollToTop.component"
import InputWrapper from "../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../inputFields/Select/SelectWrapper.component"
import { useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { fetchUserParent, useSubmitUserDataMutation } from "../../../redux/user/user.api"
import { UserDataType } from "../../../redux/user/user.types"
import { canI } from "../../auth/utils"
import { errorFormLabels } from "../utils/formLabels"
import { emailCheck } from "../utils/validations"
import AsyncSelectWrapper from "../inputFields/AsyncSelect/AsyncSelectWrapper.component"
import ShowOnCondition from "../../auth/ShowOnCondition.component"
import { asyncFnWrapper } from "../utils/utils"

type UserFormProps = {
  fieldsValues?: FieldValues
  userData?: UserDataType
}

const UserForm = ({
  userData,
  fieldsValues
}: UserFormProps) => {
  const navigate = useNavigate()
  const actualUrl = useLocation()
  const entityName = useSelectEntityName()
  const canEdit = userData ? canI(`${entityName}UPDATE`, userData.links || []).abilityCheck : true

  const { register, control, handleSubmit, watch, formState: { errors } } = useForm<UserDataType>({
    defaultValues: userData ? ({
      ...userData,
    }) : ({})
  })

  const formWatcher = watch()
  const [fetchParent] = fetchUserParent.useLazyQuerySubscription()

  const [submitUserData, { isLoading: isSubmitting }] = useSubmitUserDataMutation()
  const onSubmit = async (values: UserDataType) => {
    submitUserData(values)
      .unwrap()
      .then(response => {
        //Update actual URL with contract id if it's a POST mutation
        if (!values.id && "data" in response) {
          navigate(`${actualUrl.pathname}/${response.data.id}`)
        }
      })
      .catch((error: any) => console.error('rejected', error))
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ScrollToTop />
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="user-cog" className="me-2" />
          Dati utente
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={6}>
              <SelectWrapper
                name="enabled"
                control={control}
                label="Utente abilitato"
                options={fieldsValues?.enabled || []}
                rules={{ required: errorFormLabels.REQUIRED }}
                isDisabled={canEdit ? false : true}
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                name="name"
                label="Nome"
                type="text"
                register={register}
                className="form-control"
                errors={errors}
                rules={{ required: errorFormLabels.REQUIRED, maxLength: 80 }}
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                name="surname"
                label="Cognome"
                type="text"
                register={register}
                className="form-control"
                errors={errors}
                rules={{ required: errorFormLabels.REQUIRED, maxLength: 80 }}
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                name="code"
                label="Codice"
                type="text"
                register={register}
                className="form-control"
                errors={errors}
                rules={{ required: errorFormLabels.REQUIRED, maxLength: 80 }}
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                name="email"
                label="Email"
                type="text"
                register={register}
                className="form-control"
                errors={errors}
                rules={{
                  required: errorFormLabels.REQUIRED,
                  validate: value => emailCheck(value) || errorFormLabels.INVALID_EMAIL,
                  maxLength: 80
                }}
              />
            </Col>
            <Col md={6}>
              <SelectWrapper
                name="roleId"
                control={control}
                label="Tipologia utente"
                options={fieldsValues?.role || []}
                initialValue={userData?.role || []}
                rules={{ required: errorFormLabels.REQUIRED }}
                isDisabled={canEdit ? false : true}
                errors={errors}
              />
            </Col>
            <Col md={12}>
              <SelectWrapper
                name="offerCode"
                control={control}
                label="Gruppo visibilità offerta"
                options={fieldsValues?.offerCode || []}
                initialValue={userData?.offerCode || []}
                rules={{ required: errorFormLabels.REQUIRED }}
                isDisabled={canEdit ? false : true}
                errors={errors}
              />
            </Col>
            <Col md={12}>
              <SelectWrapper
                name="usersVisibility"
                control={control}
                label="Visibilità utenti"
                options={fieldsValues?.usersVisibility || []}
                initialValue={userData?.usersVisibility || []}
                rules={{ required: errorFormLabels.REQUIRED }}
                isDisabled={canEdit ? false : true}
                errors={errors}
              />
            </Col>
            <ShowOnCondition showWhen={parseInt(formWatcher.usersVisibility?.value) === 2}>
              <Col md={12}>
                <AsyncSelectWrapper
                  name="parentId"
                  control={control}
                  register={register}
                  label="Utente genitore"
                  errors={errors}
                  isDisabled={canEdit ? false : true}
                  loadOptions={(input: string) => {
                    if (input.length > 2) {
                      return asyncFnWrapper(input, fetchParent)
                    }
                  }}
                  noOptionsMessage={() =>
                    <span className="autocomplete-suggestion">Indica le prime lettere dell'utente genitore per trovarlo nella lista</span>
                  }
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
            </ShowOnCondition>
            <Col md={12}>
              <SelectWrapper
                name="signatureMethod"
                control={control}
                label="Metodi di firma utilizzabili"
                options={fieldsValues?.signatureMethod || []}
                rules={{ required: errorFormLabels.REQUIRED }}
                isDisabled={canEdit ? false : true}
                errors={errors}
                isMulti
              />
            </Col>
            <Col md={12}>
              <SelectWrapper
                name="openingCausals"
                control={control}
                label="Contratti visibili"
                options={fieldsValues?.openingCausal || []}
                rules={{ required: errorFormLabels.REQUIRED }}
                isDisabled={canEdit ? false : true}
                errors={errors}
                isMulti
                getOptionLabel={option =>
                  <>
                    <strong>{option.label}</strong>: {option.description}
                  </>
                }
                filterOption={(candidate, input) => {
                  if (input) {
                    const lowerCaseCandidate = candidate.data.label.toLowerCase() as string
                    return lowerCaseCandidate.includes(input.toLowerCase())
                  }
                  return true
                }}
              />
            </Col>
            <Col md={12}>
              <SelectWrapper
                name="permissionGroup"
                control={control}
                label="Permessi utente"
                options={fieldsValues?.permissionGroup || []}
                rules={{ required: errorFormLabels.REQUIRED }}
                isDisabled={canEdit ? false : true}
                errors={errors}
                isMulti
                getOptionLabel={option =>
                  <>
                    <strong>{option.label}</strong>: {option.description}
                  </>
                }
                filterOption={(candidate, input) => {
                  if (input) {
                    const lowerCaseCandidate = candidate.data.label.toLowerCase() as string
                    return lowerCaseCandidate.includes(input.toLowerCase())
                  }
                  return true
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      {canEdit &&
        <div className="text-end">
          <ButtonWithLoader
            isLoading={isSubmitting}
            type="submit"
            disabled={isSubmitting}
            label="Salva"
            fontAwesomeIcon={["fas", "save"]}
          />
        </div>
      }
    </Form>
  )
}

export default UserForm