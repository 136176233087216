import { UserRoles } from "../../../redux/auth/auth.utils"
import { getEntityUriName } from "../../../redux/contract/contract.utils"

export function isUserScoreNeeded(userRole: string) {
  return (
    userRole === UserRoles.USER_ROLE_ADMIN ||
    userRole === UserRoles.USER_ROLE_VERIFIER
  ) ? true : false
}

export function isReportColNeeded(userRole: string) {
  return (
    userRole === UserRoles.USER_ROLE_ADMIN ||
    userRole === UserRoles.USER_ROLE_VERIFIER ||
    userRole === UserRoles.USER_ROLE_READER ||
    userRole === UserRoles.USER_ROLE_SUPPORT
  ) ? true : false
}

export function buildFetchUrl(entityName: string) {
  return `${getEntityUriName(entityName)}`
}

export function buildExcelExportUrl(entityName: string, itemsPerPage: number) {
  return `${getEntityUriName(entityName)}/excels?pageSize=${itemsPerPage}`
}

export function buildPdpExcelExportUrl(entityName: string, itemsPerPage: number) {
  return `${getEntityUriName(entityName)}/excels-pdp?pageSize=${itemsPerPage}`
}