export function isSupplyEqualToResidence(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransfer' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isResidenceSelfDeclarationNeeded(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransfer' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isResidence(value?: string){
  return value === '1' ? true : false
}