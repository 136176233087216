export default function getColorByScoreCheck(score?: boolean){
    switch (score) {
        case true:
            return "succeeded"   
        case  false:
            return "failed"
        default:
            return "none"
    }
}

export function getLabelByScoreCheck(score?: boolean){
    switch (score) {
        case true:
            return "Cliente affidabile"   
        case  false:
            return "Cliente non affidabile"
        default:
            return "Nessuna valutazione sul cliente"
    }
}