import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { FieldValues } from "react-hook-form"
import { Button, Spinner } from "reactstrap"
import { useSelectContractId, useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { Permissions } from "../../../redux/api/types"
import { useLazyFetchContractBankAccoutItemQuery } from "../../../redux/contract/bankAccountDetail/bankAccountDetail.api"

import { BankAccountItemData } from "../../../redux/contract/contract.types"
import CanI from "../../auth/CanI.component"
import BankAccountDetailModal from "../BankAccountDetailModal/BankAccountDetailModal.component"
import BankAccountItemStyle from "./style"

export type BankAccountItemProps = {
  item: BankAccountItemData
  permissions: Permissions
  bankAccountFieldsValues: FieldValues
}

const BankAccountItem = ({ 
  item, 
  ...rest
}: BankAccountItemProps) => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(prevState => !prevState)
  const [fetchItemData, {
    data: itemData,
    isFetching,
    isLoading,
  }] = useLazyFetchContractBankAccoutItemQuery()

  return (
    <BankAccountItemStyle>
      <div className="cadastral-info">
        <div className="bank-account-preview">
          <FontAwesomeIcon icon={['fas', 'money-bill-wave']} className="me-3" />
          <span>{item.name} {item.surname} <br /> {item.iban} - {item.fiscalCode}</span>
        </div>
        <div className="context-menu">
          <CanI doWhat="GET" withPermissions={item.links} entityName={`${entityName}BankAccountDetail`}>
            {() => (
              <Button
                size="sm"
                color="secondary"
                outline
                onClick={async () => {
                  await fetchItemData({ contractId, itemId: item.id, entityName })
                  setIsOpen(true)
                }}
                disabled={isFetching || isLoading}
              >
                {isFetching || isLoading ? (
                  <Spinner size="sm" className="me-2" />
                ) : (
                  <FontAwesomeIcon icon={['fas', 'eye']} className="me-2" />
                )}
                Dettagli
              </Button>
            )}
          </CanI>
        </div>
      </div>
      <BankAccountDetailModal 
        isOpen={isOpen}
        toggle={toggle}
        bankAccountDetailData={itemData}
        {...rest}
      />
    </BankAccountItemStyle>
  )
}

export default BankAccountItem