import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"

import { useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider"
import { contractApi } from "../../../../redux/contract/contract.api"
import ShowOnCondition from "../../../auth/ShowOnCondition.component"
import AsyncSelectWrapper from "../../inputFields/AsyncSelect/AsyncSelectWrapper.component"
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { asyncFnWrapper, CustomerTypes, getCustomerType } from "../../utils/utils"
import PreviousHolder from "./PreviousHolder/PreviousHolder.comopnent"
import { calculateDaysBeforeNow, isAccuntHolderAtecoCodeNeeded, isContractTypeNeeded, isMeterReadingNeeded, isSupplyFormNeeded, isSupplyStartDateNeeded } from "./utils"

const PdrSupply = ({
  canEdit,
  fieldsValues: pdrFieldsValues,
  customerType,
  ...rest
}: FormProps) => {
  const entityName = useSelectEntityName()
  const { register, control, watch, setValue, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  const [fetchTowns] = contractApi.endpoints.fetchTowns.useLazyQuerySubscription()
  const [fetchPostalCodes] = contractApi.endpoints.fetchPostalCodes.useLazyQuerySubscription()

  return (
    <ShowOnCondition showWhen={isSupplyFormNeeded(entityName)}>
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="list" className="me-2" />
          Fornitura GAS
        </div>
        <div className="grouped-body">
          <ShowOnCondition showWhen={entityName !== 'audaxChangeSupplier'}>
            <Row>
              <Col md={12}>
                <SelectWrapper
                  name="as"
                  control={control}
                  label="Il richiedente in qualità di"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  register={register}
                  options={pdrFieldsValues?.as || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              <ShowOnCondition showWhen={parseInt(formWatcher.as?.value) === 6}>
                <Col md={12}>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="asOther"
                    label="Altro (specificare)"
                    rules={{ 
                      maxLength: 80,
                      required: errorFormLabels.REQUIRED
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
              </ShowOnCondition>
              <Col md="12">
                <legend className="col-form-label">Dati fornitura:</legend>
              </Col>
              <ShowOnCondition showWhen={isMeterReadingNeeded(entityName)}>
                <Col md={6}>
                  <InputWrapper
                    type="number"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="meterReading"
                    label="Lettura contatore (mc)"
                    rules={{ 
                      min: 0,
                      maxLength: 20,
                      required: errorFormLabels.REQUIRED
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
                <Col md={6}>
                  <DatePickerWrapper
                    name="meterReadingDate"
                    control={control}
                    label="Data lettura contatore"
                    className="form-control"
                    errors={errors}
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    minDate={calculateDaysBeforeNow(7)}
                    disabled={canEdit ? false : true}
                    rules={{ required: errorFormLabels.REQUIRED }}
                  />
                </Col>
              </ShowOnCondition>
              <ShowOnCondition showWhen={isSupplyStartDateNeeded(entityName)}>
                <Col md={12}>
                  <DatePickerWrapper
                    name="supplyStartDate"
                    control={control}
                    label="Fornitura in possesso da"
                    className="form-control"
                    errors={errors}
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    disabled={canEdit ? false : true}
                    rules={{ required: errorFormLabels.REQUIRED }}
                  />
                </Col>
              </ShowOnCondition>
            </Row>
          </ShowOnCondition>
          <PreviousHolder 
            canEdit={canEdit!}
            entityName={entityName}
            fieldsValues={pdrFieldsValues}
          />
          <ShowOnCondition 
            showWhen={
              getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType!) &&
              isAccuntHolderAtecoCodeNeeded(entityName)
            }
          >
            <Row>
              <Col md={12}>
                <InputWrapper
                  type="text"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="accountHolderAtecoCode"
                  label="Codice ATECO 2007"
                  register={register}
                  rules={{ 
                    maxLength: 8,
                    required: errorFormLabels.REQUIRED,
                  }}
                />
              </Col>
            </Row>
          </ShowOnCondition>
          <Row>
            <Col md={isContractTypeNeeded(entityName) ? 6 : 12}>
              <SelectWrapper
                name="supplyUseType"
                control={control}
                label="Utilizzo della fornitura"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={pdrFieldsValues?.supplyUseType || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <ShowOnCondition showWhen={parseInt(formWatcher.contractType?.value) === 2}>
              <>
                <Col md={3}>
                  <SelectWrapper
                    name="audaxToponymId"
                    control={control}
                    label="Toponimo"
                    isDisabled={canEdit ? false : true}
                    errors={errors}
                    register={register}
                    options={pdrFieldsValues?.toponymIc || []}
                    rules={{ required: errorFormLabels.REQUIRED }}
                  />
                </Col>
                <Col md={7}>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="audaxStreetName"
                    label="Indirizzo"
                    rules={{ 
                      required: errorFormLabels.REQUIRED,
                      maxLength: 80,
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
                <Col md={2}>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="audaxStreetNo"
                    label="Civico"
                    rules={{ 
                      required: errorFormLabels.REQUIRED,
                      maxLength: 4,
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
                <Col md={10}>
                  <AsyncSelectWrapper
                    name="audaxTownName"
                    control={control}
                    register={register}
                    label="Comune"
                    errors={errors}
                    isDisabled={canEdit ? false : true}
                    loadOptions={(input: string) => {
                      if(input.length > 2) {
                        return asyncFnWrapper(input, fetchTowns)
                      }
                    }}
                    getOptionLabel={option => `${option.town} (${option.province})`}
                    getOptionValue={option => `${option.town} (${option.province})`}
                    rules={{ required: errorFormLabels.REQUIRED }}
                    whenFieldChanges={() => setValue("postalCode", null)}
                    noOptionsMessage={() => 
                      <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                    }
                  />
                </Col>
                <Col md={2}>
                  <SelectWrapper
                    name="audaxPostalCode"
                    control={control}
                    register={register}
                    label="CAP"
                    isDisabled={canEdit? false : true}
                    errors={errors}
                    rules={{ required: errorFormLabels.REQUIRED }}
                    watchedFieldProps={{
                      watch: watch,
                      watchedFieldName: "audaxTownName",
                      triggerWatchFn: fetchPostalCodes,
                      returnedValuePropertyName: "town",
                      defaultWatchedFieldValue: formWatcher.audaxTownName || null,
                    }}
                  />
                </Col>
              </>
            </ShowOnCondition>
          </Row>
        </div>
      </div>
    </ShowOnCondition>
  )
}

export default PdrSupply