import { FieldValues, useForm } from "react-hook-form"
import { Col, Form, Row } from "reactstrap"
import classNames from 'classnames'

import { OtpSignerType } from "../../../../redux/contract/contract.types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import { euPhoneCheck } from "../../utils/validations"
import { errorFormLabels } from "../../utils/formLabels"
import ShowOnCondition from "../../../auth/ShowOnCondition.component"
import ButtonWithLoader from "../../../ButtonWithLoader/ButtonWithLoader.component"
import { useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider"

type OtpSignerProps = {
  signer: OtpSignerType
  toggleOtpSignatureModal: () => void
  requestOtpCode: Function
  isSubmitting: boolean
}

const OtpSigner = ({
  signer: { 
    displayName,
    signed,
    ...rest
  },
  toggleOtpSignatureModal,
  requestOtpCode,
  isSubmitting,
}: OtpSignerProps) => {  
  const entityName = useSelectEntityName()
  const { register, handleSubmit, formState: { errors } } = useForm<OtpSignerType>({
    defaultValues: {
      displayName,
      ...rest
    }
  })

  const onSubmit = (values: FieldValues) => {
    requestOtpCode({ values, entityName })
      .unwrap()
      .then(() => toggleOtpSignatureModal())
      .catch((error: any) => console.error('rejected', error))
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="grouped">
        <div className={classNames({ [`grouped-header`]: true, signed: signed })}>
          <FontAwesomeIcon icon="address-card" className="me-2" />
          {signed ? (
            <>
              <strong>{`${displayName}`}</strong> ha firmato
            </>
          ) : (
            <>
              Dati di <strong>{`${displayName}`}</strong>
            </>
          )}
          {signed &&
            <FontAwesomeIcon icon="check-circle" className="ms-2" />
          }
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={12}>
              <InputWrapper
                type="text"
                className="form-control text-uppercase"
                name="displayName"
                label="Nominativo"
                register={register}
                readOnly
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control text-uppercase"
                name="fiscalCode"
                label="Codice fiscale/P.IVA"
                register={register}
                readOnly
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control phone-number"
                name="mobilePhone"
                label="Cellulare"
                register={register}
                errors={errors}
                rules={{ 
                  maxLength: 20,
                  required: errorFormLabels.REQUIRED,
                  validate: value => euPhoneCheck(value) || errorFormLabels.INVALID_PHONE,
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      <ShowOnCondition showWhen={!signed}>
        <div className="text-end">
          <ButtonWithLoader
            className="mb-5"
            isLoading={isSubmitting}
            type="submit"
            disabled={isSubmitting}
            label="Invia SMS"
            fontAwesomeIcon={["fas", "comment-sms"]}
          />
        </div>
      </ShowOnCondition>
    </Form>
  )
}

export default OtpSigner