import { useEffect, useState } from "react"
import { FieldValues, UseFormWatch } from "react-hook-form"

type UpdateVatClassHookProps = {
  watch: UseFormWatch<FieldValues>
  defaultValue: string
}

export const useUpdateVatClassHook = ({
  watch,
  defaultValue
}: UpdateVatClassHookProps) => {
  const [vatClass, setVatClass] = useState(defaultValue)

  const subscription = watch((data, { name, type }) => {
    if(name === "vatClassId") {
      setVatClass(data[name]?.value ?? null)
    }
  })
  useEffect(() => {
    return () => subscription.unsubscribe()
  }, [subscription])

  return vatClass
}