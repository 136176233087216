import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PodForm, { PodFormProps } from '../../forms/PodForm/PodForm.component'
import { FieldValues } from 'react-hook-form'
import PdrForm from '../../forms/PdrForm/PdrForm.component'

type PdpDetailModalProps = {
  pdpType: "pod" | "pdr"
  isOpen: boolean
  toggle: () => void
  formFields: FieldValues
  pdpData?: any
} & Omit<PodFormProps, "togglePdpDetailModal" | "podFieldsValues" | "podData">

const PdpDetailModal = ({ 
  pdpType,
  isOpen, 
  toggle,
  formFields,
  pdpData,
  ...rest
}: PdpDetailModalProps) => (
  <Modal 
    size="lg" 
    backdrop="static"
    isOpen={isOpen} 
    toggle={toggle}
    keyboard={false}
  >
    <ModalHeader toggle={toggle}>
      <FontAwesomeIcon icon={pdpType === "pod" ? "bolt" : "flame"} className="me-2" />
      {pdpType === "pod" ? "POD" : "PDR"}
    </ModalHeader>
    <ModalBody>
      {pdpType === "pod" ? (
        <PodForm 
          togglePdpDetailModal={toggle}
          podFieldsValues={formFields}
          podData={pdpData}
          {...rest}
        />
      ) : (
        <PdrForm 
          togglePdpDetailModal={toggle}
          pdrFieldsValues={formFields}
          pdrData={pdpData}
          {...rest}
        />
      )}
    </ModalBody>
  </Modal>
)

export default PdpDetailModal
