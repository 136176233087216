import { FieldValues } from "react-hook-form"

import ScrollToTop from "../../ScrollToTop/ScrollToTop.component"
import { Permissions } from "../../../redux/api/types"
import { AttachmentItemType, OtpSignersType } from "../../../redux/contract/contract.types"
import OtpSignerFields from "./OtpSignerFields/OtpSignerFields.component"

type OtpFormProps = {
  otpSignersData: OtpSignersType
  contractId: string
  permissions: Permissions
  formFields?: FieldValues
  attachmentsData?: AttachmentItemType[]
  toggleFinalizationModal: () => void
}

const OtpForm = ({
  otpSignersData,
  toggleFinalizationModal,
  ...rest
}: OtpFormProps) => {
  //If a signer requests the OTP code, the other signers request buttons will be tempoararly disabled
  

  return (
    <div className="my-2">
      <ScrollToTop />
      <h6 className="mb-4">Finalizza il contratto apponendo la firma con OTP</h6>
      {otpSignersData?.signers.map(item => 
        <div key={item.id}>
          <OtpSignerFields 
          item={item}
          otpSignersData={otpSignersData}
          toggleFinalizationModal={toggleFinalizationModal}/>
        </div>
      )}
    </div>
  )
}

export default OtpForm