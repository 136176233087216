import { LIST_DEFAULT_ITEMS_PER_PAGE } from "../../components/lists/utils"
import { api } from "../api"
import { ResponseType } from "../api/types"
import { getEntityUriName } from "../contract/contract.utils"

export const listApi = api.injectEndpoints({
  endpoints: build => ({
    fetchListFilters: build.query({
      query: ({ entityName }) => ({
        url: `${getEntityUriName(entityName)}/form-items/filters`
      }),
      transformResponse: (response: ResponseType, meta, arg) => response.data,
    }),
    fetchListData: build.query({
      query: ({ apiUrl, sortParam = "", filters, page = 1, pageSize = LIST_DEFAULT_ITEMS_PER_PAGE }) => 
        ({ url: `${apiUrl}?page=${page}&pageSize=${pageSize}&sorts=${sortParam}${filters ? `&${filters}` : ``}` }),
      providesTags: [{ type: 'List' }],
    }),
    fetchExcelDownloadLink: build.query({
      query: (apiUrl) => ({
        url: apiUrl
      })
    })
  })
})

export const {
  useFetchListFiltersQuery,
  useFetchListDataQuery,
} = listApi

export const {
  endpoints: {
    fetchExcelDownloadLink
  }
} = listApi