import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"
import { useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider"

import { contractApi } from "../../../../redux/contract/contract.api"
import { ContractStatusMap } from "../../../../redux/contract/contract.utils"
import ShowOnCondition from "../../../auth/ShowOnCondition.component"
import AsyncSelectWrapper from "../../inputFields/AsyncSelect/AsyncSelectWrapper.component"
import { asyncFnWrapper } from "../../utils/utils"
import { relatedSwitchVisibility } from "../utils"

const RelatedChangeSupplier = () => {
  const entityName = useSelectEntityName()
  const { register, control, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  const [fetchRelatedChangeSupplier] = contractApi.endpoints.fetchRelatedChangeSupplier.useLazyQuerySubscription()

  return (
    <ShowOnCondition 
      showWhen={
        relatedSwitchVisibility(entityName) &&
        formWatcher.relatedChangeSupplier
      }
    >
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="exchange-alt" className="me-2" />
          Cambio fornitore associato
        </div>
        <div className="grouped-body">
          <Row>
            <Col>
              <AsyncSelectWrapper
                name="relatedChangeSupplier"
                label="Per associare un contratto di cambio fornitore inserire il relativo codice proposta"
                placeholder="Codice cambio fornitore associato*"
                control={control}
                register={register}
                errors={errors}
                loadOptions={(input: string) => {
                  if(input.length > 2) {
                    return asyncFnWrapper(input, fetchRelatedChangeSupplier)
                  }
                }}
                isDisabled={formWatcher.statusId >= ContractStatusMap.DONE_STATUS}
                initialValue={formWatcher.relatedChangeSupplier || null}
                noOptionsMessage={() => 
                  <span className="autocomplete-suggestion">Indica le prime lettere del codice cambio fornitore per trovarlo nella lista</span>
                }
              />
              <p className="small text-muted">
                **Attenzione: sono selezionabili solo ed esclusivamente i contratti "Cambio fornitore" <strong>già firmati</strong>.
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </ShowOnCondition>
  )
}

export default RelatedChangeSupplier