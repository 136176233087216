import { FC } from 'react'
import { useLocation } from 'react-router'

import { checkRoute } from './utils'

type DropdownActiveCheckType = {
  children: FC
  searchedValue: string
}

const DropdownActiveCheck = ({ children, searchedValue }: DropdownActiveCheckType ) => {
  const { pathname } = useLocation()
  const isActive = checkRoute(pathname, searchedValue) ? { className: "active" } : {}

  return children(isActive)
}

export default DropdownActiveCheck
