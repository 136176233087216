//Prepares attachment for submit
export function setUpSubmitValues(attachments: any[], contractId: string) {
  const submitAttachments = attachments.reduce((accumulator, attachment) => {
    let valuesToAdd = {
      contractId,
      podId: attachment.supplyPointId?.label?.indexOf('POD-') !== -1 ? attachment.supplyPointId : null,
      pdrId: attachment.supplyPointId?.label?.indexOf('PDR-') !== -1 ? attachment.supplyPointId : null
    }
    delete attachment.supplyPointId

    return [...accumulator, Object.assign(attachment, valuesToAdd)]
  }, [])
  
  return submitAttachments
}