import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { ClockLoader } from "react-spinners"

import { useSelectEntityName } from "../../../../../../pages/providers/ListsPageProvider.provider"
import { getEntityPath } from "../../../../../../redux/contract/contract.utils"
import { UserItemType } from "../../../../../../redux/list/list.types"
import ShowOnCondition from "../../../../../auth/ShowOnCondition.component"
import { canI } from "../../../../../auth/utils"
import TdTable from "../../../../TdTable/TdTable.component"
import { useUpdateUsersVisibilityMutation } from "../../../../../../redux/user/user.api"
import { FormGroup, Input } from "reactstrap"

type UserTRowProps = {
  item: UserItemType
}

const UserTRow = ({
  item,
}: UserTRowProps) => {
  const entityName = useSelectEntityName()
  const [updateUsersVisibility, { isLoading }] = useUpdateUsersVisibilityMutation()
  const updateUsersVisibilityPermissions = canI("userPATCH", item.links)

  return (
    <tr>
      <TdTable value={item.name} />
      <TdTable value={item.code} />
      <TdTable value={item.email} />
      <TdTable>
        {isLoading ? (
          <ClockLoader
            color="#95ad66"
            aria-label="Loading Spinner"
            data-testid="loader"
            size={20}
          />
        ) : (
          <FormGroup switch>
            <Input
              type="switch"
              checked={item.enabled}
              onChange={() => { }}
              onClick={() => {
                updateUsersVisibility({
                  apiUrl: updateUsersVisibilityPermissions.actionUrl,
                  values: {
                    enabled: !item.enabled
                  }
                })
              }}
              disabled={updateUsersVisibilityPermissions.abilityCheck === false}
            />
          </FormGroup>
        )}
      </TdTable>
      <TdTable otherClasses={["actions-col"]}>
        <div className="buttons-container">
          <ShowOnCondition showWhen={canI(`${entityName}GET`, item.links).abilityCheck}>
            <Link
              target="_blank"
              title="Vedi dettaglio"
              className="btn btn-outline-secondary btn-sm mx-1"
              to={`/${getEntityPath(entityName)}/${item.id}/`}
            >
              <FontAwesomeIcon size="sm" icon="eye" />
            </Link>
          </ShowOnCondition>
        </div>
      </TdTable>
    </tr>
  )
}

export default UserTRow