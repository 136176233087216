import { api } from "../api";
import {
  SETUP_DEFAULT_STATUS_PER_PAGE,
  SETUP_DEFAULT_UPLOADED_PER_PAGE,
} from "./setup.utils";

export const setupApi = api.injectEndpoints({
  endpoints: (build) => ({
    exportCsv: build.query({
      query: (setupUniqueName: string) => ({
        url: `/setup/${setupUniqueName}/export-csv`,
      }),
    }),
    fetchErrorDetail: build.query({
      query: (id: string) => ({
        url: `/jobs/${id}/error-detail`,
      }),
    }),
    importFile: build.mutation({
      query: ({
        setupUniqueName,
        importType,
        values,
      }: {
        setupUniqueName: string;
        importType: string;
        values: object;
      }) => ({
        url: `/setup/${setupUniqueName}/${importType}`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: [{ type: "Setup" }],
    }),
    fetchUploadedList: build.query({
      query: ({
        setupUniqueName,
        sortParam = "",
        filters,
        page = 1,
        pageSize = SETUP_DEFAULT_UPLOADED_PER_PAGE,
      }) => ({
        url: `/setup/${setupUniqueName}?page=${page}&pageSize=${pageSize}&sorts=${sortParam}${
          filters ? `&${filters}` : ``
        }`,
      }),
      providesTags: [{ type: "Setup" }],
    }),
    fetchStatusList: build.query({
      query: ({
        sortParam = "",
        filters,
        page = 1,
        pageSize = SETUP_DEFAULT_STATUS_PER_PAGE,
      }) => ({
        url: `/jobs?page=${page}&pageSize=${pageSize}&sorts=${sortParam}${
          filters ? `&${filters}` : ``
        }`,
      }),
      providesTags: [{ type: "Setup", id: "STATUS_LIST" }],
      transformResponse: (response: { data: any }, meta, args) => response.data,
    }),
  }),
});

export const {
  useFetchUploadedListQuery,
  useImportFileMutation,
  useFetchStatusListQuery,
} = setupApi;

export const {
  endpoints: { exportCsv, fetchErrorDetail },
} = setupApi;
