import { useState } from "react"

import TableFooter from "../TableFooter/TableFooter.component"
import WithSkeleton from "../../WithSkeleton/WithSkeleton.component"
import UserListFilters from "./UserListFilters/UserListFilters.component"
import UserListTable from "./UserListTable/UserListTable.component"
import { useFetchListDataQuery } from "../../../redux/list/list.api"
import { LIST_DEFAULT_ITEMS_PER_PAGE } from "../utils"
import { buildFetchUrl } from "./utils"

const UserListTableWithSkeleton = WithSkeleton(UserListTable)

const UserList = () => {
  const [filters, setFilters] = useState("")
  const [page, setPage] = useState(1)

  //Fetch list data trigger
  const {
    data: listData,
    isFetching: isFetchingListData,
    isLoading: isLoadingListData, 
  } = useFetchListDataQuery({
    apiUrl: buildFetchUrl(),
    filters,
    page
  })

  return (
    <section className="list-container">
      <UserListFilters
        setFilters={setFilters}
      />
      <UserListTableWithSkeleton 
        isLoading={isLoadingListData}
        isFetching={isFetchingListData}
        page={page}
        filters={filters}
      />
      <TableFooter
        listData={listData}
        page={page}
        setPage={setPage}
        itemsPerPage={LIST_DEFAULT_ITEMS_PER_PAGE}
      />
    </section>
  )
}

export default UserList