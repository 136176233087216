import React from 'react'

import WithSpinnerStyle from './style'

interface SpinnerProps {
  isLoading: boolean,
  [key: string]: any
}

const WithSpinner = (WrappedComponent: React.ElementType) => {
  const Spinner = ({ isLoading, ...otherProps }: SpinnerProps) => {
    return isLoading ? (
      <WithSpinnerStyle>
        <div className="spinner-overlay">
          <div className="spinner-container" />
          <span className="spinner-label">Caricamento...</span>
        </div>
      </WithSpinnerStyle>
    ) : (
      <WrappedComponent {...otherProps} />
    )
  }

  return Spinner
}

export default WithSpinner