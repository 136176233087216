import moment from "moment"
import { ListGroup, ListGroupItem } from "reactstrap"
import { NoteType } from "../../../redux/contract/contract.types"
import NotesListStyle from "./style"

type NotesListProps = {
  notes: NoteType[]
}

const NotesList = ({
  notes
}: NotesListProps) => {
  return (
    <NotesListStyle>
      {notes && notes.length ?
        <ListGroup>
            {notes.map((note: NoteType, i: number) => 
              <ListGroupItem key={i}>
                <p>
                  Nota inserita il <strong>{moment.utc(note.sendingDate).tz('Europe/Rome').format('DD-MM-YYYY HH:mm') }</strong>
                  <br />
                  da <strong>{note.sender}</strong>
                </p>
                <blockquote>
                  "{note.message}"
                </blockquote>
              </ListGroupItem>
            )}
        </ListGroup>
      :
        <span>Nessuna nota presente</span>
      }
    </NotesListStyle>
  )
}

export default NotesList