import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FieldValues, useForm } from "react-hook-form"
import { Button, Col, Form, Row } from "reactstrap"

import { selectCurrentUserRole } from "../../../../redux/auth/auth.selectors"
import { useAppSelector } from "../../../../redux/hooks"
import ShowOnCondition from "../../../auth/ShowOnCondition.component"
import ButtonWithLoader from "../../../ButtonWithLoader/ButtonWithLoader.component"
import DatePickerWrapper from "../../../forms/inputFields/DatePicker/DatePickerWrapper.component"
import InputWrapper from "../../../forms/inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../../forms/inputFields/Select/SelectWrapper.component"
import { isResponsibleUser, isValidatorUser, setDefaultFiltersValues, setQueryString } from "./utils"

type ProposalFiltersProps = {
  selectedFilters: string
  setFilters: (filters: string) => void
  setPage: React.Dispatch<React.SetStateAction<number>>
  fieldsValues: FieldValues
  refetch: Function
}

const ProposalFilters = ({
  selectedFilters,
  setFilters,
  fieldsValues,
  refetch,
  setPage,
}: ProposalFiltersProps) => {
  const role = useAppSelector(selectCurrentUserRole)

  const { register, control, reset, handleSubmit, formState: { isSubmitting } } = useForm()

  const onSubmit = async (values: any) => {
    const queryParams = setQueryString(values)
    const filters = queryParams.length ? queryParams.join('&') : ""
    //If selected filters are not equal to the former ones
    //reset selected page to 1 and set filters with new values
    if(selectedFilters !== filters) {
      setFilters(filters)
      setPage(1)
    }
    //Otherwise, refetch with the same query
    else {
      refetch()
    }  
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="filter" className="me-2" />
          Applica filtri
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={3}>
              <SelectWrapper 
                name="statusId"
                label="Status contratto"
                control={control}
                options={fieldsValues?.statusId ?? []}
              />
            </Col>
            <Col md={3}>
              <InputWrapper 
                register={register}
                name="proposalCode"
                type="text"
                className="form-control"
                label="Codice proposta"
              />
            </Col>
            <Col md={3}>
              <SelectWrapper 
                name="customerType"
                label="Tipologia cliente"
                control={control}
                options={fieldsValues?.customerType ?? []}
              />
            </Col>
            <Col md={3}>
              <InputWrapper 
                register={register}
                name="customerName"
                type="text"
                className="form-control"
                label="Nome cliente"
              />
            </Col>
            <Col md={3}>
              <InputWrapper 
                register={register}
                name="customerEmail"
                type="text"
                className="form-control text-lowercase"
                label="Email cliente"
              />
            </Col>
            <ShowOnCondition
              showWhen={
                isValidatorUser(role) || 
                isResponsibleUser(role)
              }
            >
              <Col md={3}>
                <SelectWrapper 
                  name="roleId"
                  label="Tipologia utente"
                  control={control}
                  options={fieldsValues?.role ?? []}
                />
              </Col>
              <Col md={3}>
                <SelectWrapper 
                  name="companyId"
                  label="Rete di vendita"
                  control={control}
                  options={fieldsValues?.company ?? []}
                />
              </Col>
            </ShowOnCondition>
            <Col md={3}>
              <DatePickerWrapper
                name="signingDateStart"
                control={control}
                label="Data firma da"
                className="form-control"
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
              />
            </Col>
            <Col md={3}>
              <DatePickerWrapper
                name="signingDateEnd"
                control={control}
                label="Data firma a"
                className="form-control"
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
              />
            </Col>
            <Col md={3}>
              <DatePickerWrapper
                name="insertDateStart"
                control={control}
                label="Data caricamento da"
                className="form-control"
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
              />
            </Col>
            <Col md={3}>
              <DatePickerWrapper
                name="insertDateEnd"
                control={control}
                label="Data caricamento a"
                className="form-control"
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
              />
            </Col>
            <Col md={3}>
              <InputWrapper 
                register={register}
                name="agent"
                type="text"
                className="form-control text-lowercase"
                label="Nome agente"
              />
            </Col>
          </Row>
          <div className="text-end my-3">
            <Button
              className="me-1"
              outline
              size="lg"
              color="warning"
              onClick={() => reset(setDefaultFiltersValues())}
            >
              <FontAwesomeIcon icon="undo" className="me-2" />
              Reset
            </Button>
            <ButtonWithLoader
              isLoading={isSubmitting}
              type="submit"
              size="lg"
              disabled={isSubmitting}
              label="Filtra"
              fontAwesomeIcon={["fas", "filter"]}
            />
          </div>
        </div>
      </div>
    </Form>
  )
}

export default ProposalFilters