import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { useState } from "react"
import { Link } from "react-router-dom"

import { useSelectEntityName } from "../../../../../../pages/providers/ListsPageProvider.provider"
import { useDeleteProposalDataMutation } from "../../../../../../redux/contract/contract.api"
import { getContractStatusIcon, getEntityPath, getUserScoreLabel } from "../../../../../../redux/contract/contract.utils"
import { ContractListType } from "../../../../../../redux/list/list.types"
import CanI from "../../../../../auth/CanI.component"
import ButtonWithLoader from "../../../../../ButtonWithLoader/ButtonWithLoader.component"
import DeleteItem from "../../../../../DeleteItemModal/DeleteItemModal.component"
import TdTable from "../../../../TdTable/TdTable.component"
import NotesModal from "../../../../../NotesModal/NotesModal.component"
import ChatModal from "../../../../../ChatModal/ChatModal.component"
import { Button } from "reactstrap"
import classNames from "classnames"

type OngoingCustomersTRowProps = {
  item: ContractListType
  sortParam: string
}

const OngoingCustomersTRow = ({
  item,
  sortParam,
}: OngoingCustomersTRowProps) => {
  const entityName = useSelectEntityName()
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const toggleDelete = () => setDeleteIsOpen(prevState => !prevState)
  
  const [notesIsOpen, setNotesIsOpen] = useState(false)
  const toggleNotes = () => setNotesIsOpen(prevState => !prevState)
  const [chatIsOpen, setChatIsOpen] = useState(false)
  const toggleChat = () => setChatIsOpen(prevState => !prevState)
  const [deleteItem, { isLoading: isDeletingItem }] = useDeleteProposalDataMutation()

  return (
    <tr>
      <TdTable
        sortParam={sortParam}
        sortingName="statusId"
        otherClasses={['text-center', 'status']}
      >
        <FontAwesomeIcon
          size="lg" 
          className="me-2" 
          icon={getContractStatusIcon(item.contractStatusId)} 
          title={item.contractStatusName}
        />
      </TdTable>
      <TdTable 
        value={item.proposalCode}
        sortParam={sortParam}
        sortingName="proposalCode"
      />
      <TdTable value={item.customerType} />
      <TdTable otherClasses={['score-circle-container']}>
          <span 
            title={getUserScoreLabel(item.scoreCheckPassed)}
            className={classNames({
              "score-circle": true,
              "bad": item.scoreCheckPassed === false,
              "good": item.scoreCheckPassed === true,
              "unknown": item.scoreCheckPassed === null || item.scoreCheckPassed === undefined
            })}
          />          
      </TdTable>
      <TdTable value={item.customerName} />
      <TdTable 
        value={item.customerEmail}
        sortParam={sortParam}
        sortingName="customerEmail"
        otherClasses={['break-word']}
      />
      <TdTable 
        value={item.userAgentName}
        sortParam={sortParam}
        sortingName="agent"
      />
      <TdTable 
        value={item.signingDate ? moment(item.signingDate).format('DD-MM-YYYY') : ""}
        sortParam={sortParam}
        sortingName="signingDate"
      />
      <TdTable 
        value={item.insertDate ? moment(item.insertDate).format('DD-MM-YYYY') : ""}
        sortParam={sortParam}
        sortingName="insertDate"
      />
      <TdTable>
        <>
          <span 
            title={`POD validati ${item.validatedPods} su ${item.totalPods}`}
          >
            <strong>POD:</strong> {item.validatedPods}/{item.totalPods}
          </span>
          <br />
          <span 
            title={`PDR validati ${item.validatedPdrs} su ${item.totalPdrs}`}
          >
            <strong>PDR:</strong> {item.validatedPdrs}/{item.totalPdrs}
          </span>
        </>
      </TdTable>
      <TdTable>
        <div className="communication-actions-container">
          <CanI
            doWhat={"GET"} 
            withPermissions={item.links} 
            entityName="notes"
          >
            {({ action }) => (
              <>
                <Button 
                  title="Note" 
                  onClick={toggleNotes} 
                  size="sm" 
                  color="link" 
                  outline 
                  className={classNames({
                    "mx-1": true,
                    "active": item.notes === true
                  })}
                >
                  <FontAwesomeIcon icon="comments" />
                </Button>
                <NotesModal
                  itemId={item.id}
                  fetchNotesUrl={action}
                  permissions={item.links}
                  isOpen={notesIsOpen}
                  toggle={toggleNotes}
                  entityName={item.contractType}
                />
              </>
            )}
          </CanI>
        </div>
      </TdTable>
      <TdTable>
        <div className="communication-actions-container">
        <CanI 
            doWhat={"GET"} 
            withPermissions={item.links} 
            entityName="communications"
          >
            {({ action }) => (
              <>
                <Button 
                  title="Comunicazioni" 
                  onClick={toggleChat} 
                  size="sm" 
                  color="link" 
                  outline 
                  className={classNames({
                    "mx-1": true,
                    "active": item.reports === true
                  })}
                >
                  <FontAwesomeIcon icon="envelope" />
                </Button>
                <ChatModal
                  itemId={item.id}
                  fetchChatUrl={action}
                  permissions={item.links}
                  isOpen={chatIsOpen}
                  toggle={toggleChat}
                  entityName={item.contractType}
                />
              </>
            )}
          </CanI>
        </div>
      </TdTable>
      <TdTable otherClasses={["actions-col"]}>
        <div className="buttons-container">
          <CanI doWhat={"GET"} withPermissions={item.links} entityName={entityName}>
            {({ action }) => (
              <Link
                target="_blank"
                title="Vedi dettaglio"
                className="btn btn-outline-secondary btn-sm mx-1"
                to={`/${getEntityPath(entityName)}/${item.id}/`}
              >
                <FontAwesomeIcon size="sm" icon="eye" />
              </Link>
            )}
          </CanI>
          <CanI doWhat={"DELETE"} withPermissions={item.links} entityName={entityName}>
            {({ action }) => (
              <>
                <ButtonWithLoader
                  size="sm"
                  fontAwesomeIcon={['fas', 'trash-alt']}
                  isLoading={isDeletingItem}
                  outline
                  onClick={toggleDelete}
                  color="danger"
                />
                <DeleteItem
                  isOpen={deleteIsOpen}
                  toggle={toggleDelete}
                  deleteFn={async () => {
                    await deleteItem(action)
                    toggleDelete()
                  }}
                  isDeleting={isDeletingItem}
                />
              </>
            )}
          </CanI>
        </div>
      </TdTable>
    </tr>
  )
}

export default OngoingCustomersTRow