import classNames from "classnames"
import { Table } from "reactstrap"

import OngoingCustomersTBody from "./OngoingCustomersTBody/OngoingCustomersTBody.component"
import OngoingCustomersTHeader from "./OngoingCustomersTHeader/OngoingCustomersTHeader.component"
import { ListDataType } from "../../../../redux/list/list.types"

type OngoingCustomersTableProps = {
  sortParam: string
  setSortParam: (value: string) => void
  page: number
  filters: string
  isFetching: boolean
  listData: ListDataType
}

const OngoingCustomersTable = ({
  isFetching,
  ...rest
}: OngoingCustomersTableProps) => {
  return (
    <Table 
      responsive
      className={
        classNames({
          'is-fetching': isFetching === true
        })
      }
    >
      <OngoingCustomersTHeader 
        {...rest}
      />
      <OngoingCustomersTBody 
        {...rest}
      />
    </Table>
  )
}

export default OngoingCustomersTable