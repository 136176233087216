import { getCurrentUser } from "../../firebase"
import { api } from "../api"
import { ContractMutationResponseType, ResponseType } from "../api/types"
import { uploadAttachment } from "./attachment/utils"
import { NoteType, ReportType } from "./contract.types"
import { activeSupplyPointsFirst, getActiveUserValidateBlockAll, getEntityUriName, getSupplyPointsByClientCode, handleError, uploadPaperContract } from "./contract.utils"

export const contractApi = api.injectEndpoints({
  endpoints: build => ({
    fetchFormFieldsValues: build.query({
      query: ({ entityName, formName, timestamp }: {entityName: string, formName?: string, timestamp?: string}) => ({
        url: `${getEntityUriName(entityName)}/form-items?formName=${formName ?? ""}`,
      }),
      transformResponse: (response: ResponseType, meta, arg) => response?.data,
    }),
    fetchDistributors: build.query({
      query: ({ entityName, cadastralDataId, searchedValue }) => ({
        url: `distributors?contractType=${entityName}&cadastralDataId=${cadastralDataId}&distributorName=${searchedValue}&limit=20`,
      }),
      transformResponse: (response: { data: any }, meta, args) => response.data,
    }),
    fetchTowns: build.query({
      query: (searchedValue: string) => 
        ({ url: `form-items/towns/small-info?townName=${searchedValue}` }),
      transformResponse: (response: ResponseType, meta, arg) => response?.data,
    }),
    fetchScoreCheck: build.query({
      query: ({fiscalCode}) => 
        ({ url: `score-checks?fiscalCode=${fiscalCode}` }),
      transformResponse: (response: ResponseType, meta, arg) => response?.data,
    }),
    fetchPostalCodes: build.query({
      query: (searchedValue: string) => 
        ({ url: `form-items/postal-codes?townName=${searchedValue}` }),
      transformResponse: (response: ResponseType, meta, arg) => response?.data
    }),
    fetchContractProposalData: build.query({
      query: ({ entityName, contractId }) => 
        ({ url: `${getEntityUriName(entityName)}/${contractId}/` }),
      providesTags: (result, error, { contractId }) => [{ type: 'Contract', id: contractId }],
    }),
    fetchRelatedChangeSupplier: build.query({
      query: (searchedValue: string) => ({
        url: `change-suppliers/proposal-codes?proposalCode=${searchedValue}`
      }),
      transformResponse: (response: ResponseType, meta, arg) => response.data,
    }),
    submitProposalData: build.mutation<ContractMutationResponseType, any>({
      query: ({ values, entityName }: { values: Record<string, unknown>, entityName: string }) => ({
        url: `${getEntityUriName(entityName)}${values.id ? `/${values.id}` : ``}`,
        method: values.id ? "PUT" : "POST",
        body: values
      }),
      invalidatesTags: (result, error, { values: { id } }) => [{ type: "Contract", id }],
    }),
    deleteProposalData: build.mutation({
      query: (actionUrl: string) => ({
        url: actionUrl,
        method: "DELETE"
      }),
      invalidatesTags: [{ type: 'List' }],
    }),
    fetchOtpData: build.query({
      query: ({ contractId, entityName }) => 
        ({ url: `${getEntityUriName(entityName)}/signers?contractId=${contractId}` }),
      providesTags: (result, error, args) => [{ type: 'Contract', id: "OTP_LIST"}],
    }),
    fetchOtpCode: build.mutation({
      query: ({ entityName, values }) => ({
        url: `${getEntityUriName(entityName)}/signers/${values.id}/otp`,
        method: "POST",
        body: values
      }),
    }),
    submitOtpCode: build.mutation({
      query: ({ entityName, values, isLastSigner }) => ({
        url: `${getEntityUriName(entityName)}/signers/${values.id}/signatures`,
        method: "POST",
        body: values
      }),
      transformResponse: (response: ResponseType, meta, arg) => response?.data,
      invalidatesTags: (result, error, { isLastSigner }) => {
        //If is last signer reload invalidates all contract data
        if(!error && isLastSigner) {
          return [
            { type: "Contract" },
            { type: "BankAccountDetail" },
            { type: "CadastralData" },
            { type: "Pod" },
            { type: "Pdr" },
            { type: "Attachment" },
          ]
        }
        return [{ type: 'Contract', id: "OTP_LIST"}]
      },
    }),
    submitPaperContract: build.mutation({
      async queryFn({ entityName, contractId, values }) {
        const firebaseUser = await getCurrentUser()
        const token = await firebaseUser.getIdToken()
        const paperContractResult = await uploadPaperContract(values.paperContract, contractId, entityName, token)
        if(values.attachments.length) {
          await Promise.allSettled(values.attachments.map((attachmentData: any) => uploadAttachment(attachmentData, entityName, token)))
        }
        return { data: paperContractResult }
      },
      invalidatesTags: (result, error, { contractId }) => [
        { type: "Contract" },
        { type: "BankAccountDetail" },
        { type: "CadastralData" },
        { type: "Pod" },
        { type: "Pdr" },
        { type: "Attachment" },
      ],
    }),
    commitContractFinalization: build.mutation({
      query: ({ entityName, contractId }) => ({
        url: `${getEntityUriName(entityName)}/${contractId}/commit`,
        method: "POST",
      }),
      invalidatesTags: [
        { type: "Contract" },
        { type: "BankAccountDetail" },
        { type: "CadastralData" },
        { type: "Pod" },
        { type: "Pdr" },
        { type: "Attachment" },
      ],
    }),
    fetchCustomerData: build.query({
      query: ({ customerCode }) => ({
        url: `customers/${customerCode}`
      })
    }),
    fetchSupplyPointsData: build.query({
      async queryFn({ entityName, contractId }) {
        try {
          const firebaseUser = await getCurrentUser()
          const accessToken = await firebaseUser.getIdToken()
          const podSupplyPoints = await getSupplyPointsByClientCode({
            contractId, 
            entityName, 
            type: "pods", 
            accessToken
          })
          const pdrSupplyPoints = await getSupplyPointsByClientCode({
            contractId, 
            entityName, 
            type: "pdrs", 
            accessToken
          })
          const validateBlockAll = await getActiveUserValidateBlockAll({
            contractId,
            entityName,
            accessToken
          })

          return {
            data: { 
              validateAll: validateBlockAll.supplyPoints.validateAll,
              blockAll: validateBlockAll.supplyPoints.blockAll,
              pods: activeSupplyPointsFirst(podSupplyPoints.items), 
              pdrs: activeSupplyPointsFirst(pdrSupplyPoints.items)
            } 
          }
        }
        catch(err) {
          return handleError(err)
        }
      },
      providesTags: (response, error, args) => [
        { type: "Pod", id: "LIST" },
        { type: "Pdr", id: "LIST" },
      ],
    }),
    fetchContractPdfUrl: build.query({
      query: ({ entityName, contractId }) => ({
        url: `${getEntityUriName(entityName)}/attachments/signed-contracts?id=${contractId}`
      }),
      transformResponse: (response: any, meta, arg) => response?.signedContractLink ?? "",
    }),
    fetchContractNotes: build.query({
      query: ({ apiUrl, contractId }) => ({
        url: apiUrl
      }),
      transformResponse: (response: { notes: NoteType[] }, meta, arg) => response?.notes ?? [],
      providesTags: (response, error, { contractId }) => [{ type: "Contract", id: `Notes-${contractId}` }]
    }),
    submitContractNote: build.mutation({
      query: ({ apiUrl, values, contractId }) => ({
        url: apiUrl,
        method: "POST",
        body: values
      }),
      invalidatesTags: (result, error, { contractId }) => [
        { type: "Contract", id: `Notes-${contractId}` },
        { type: "List" },
      ]
    }),
    fetchContractReports: build.query({
      query: ({ apiUrl, contractId }) => ({
        url: apiUrl
      }),
      transformResponse: (response: { reports: ReportType[] }, meta, arg) => response?.reports ?? [],
      providesTags: (response, error, { contractId }) => [{ type: "Contract", id: `communication-${contractId}` }]
    }),
    submitContractReport: build.mutation({
      query: ({ apiUrl, values, contractId }) => ({
        url: apiUrl,
        method: "POST",
        body: values
      }),
      invalidatesTags: (result, error, { contractId }) => [
        { type: "Contract", id: `communication-${contractId}` },
        { type: "List" },
      ]
    })
  })
})

export const {
  useFetchFormFieldsValuesQuery,
  useFetchContractProposalDataQuery,
  useLazyFetchContractProposalDataQuery,
  useSubmitProposalDataMutation,
  useDeleteProposalDataMutation,
  useFetchOtpDataQuery,
  useFetchOtpCodeMutation,
  useSubmitOtpCodeMutation,
  useSubmitPaperContractMutation,
  useFetchSupplyPointsDataQuery,
  useFetchContractPdfUrlQuery,
  useLazyFetchContractNotesQuery,
  useSubmitContractNoteMutation,
  useLazyFetchContractReportsQuery,
  useSubmitContractReportMutation,
  useCommitContractFinalizationMutation,
  useLazyFetchScoreCheckQuery
} = contractApi

export const {
  endpoints: {
    fetchCustomerData,
    fetchDistributors
  }
} = contractApi