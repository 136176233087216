import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useLogOutMutation } from "../../../redux/auth/auth.api"
import { useCallback } from "react"
import { useAppSelector } from "../../../redux/hooks"
import { selectUsername } from "../../../redux/auth/auth.selectors"
import DropdownActiveCheck from "../DropdownActiveCheck/DropdownActiveCheck.component"
import { NavLink } from "react-router-dom"

const LogoutNav = () => {
    const username = useAppSelector(selectUsername)
    const [logout] = useLogOutMutation()  
  
    const handleLogout = useCallback(() => {
      logout(undefined)
    }, [logout])

    return (
      <DropdownActiveCheck searchedValue={username ?? "utente"}>
        {(props) => (
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret {...props}>
              <FontAwesomeIcon icon='user' /> {username}
            </DropdownToggle>
            <DropdownMenu>
              <div className='dropdown-group'>
                <DropdownItem tag='div'>
                  <NavLink
                    end
                    className={({ isActive }) =>
                      `nav-link active-link${isActive ? " active" : ""}`
                    }
                    to='/settings'
                  >
                    Settings
                  </NavLink>
                </DropdownItem>
                <DropdownItem tag='div'>
                  <NavLink
                    end
                    className={({ isActive }) =>
                      `nav-link active-link${isActive ? " active" : ""}`
                    }
                    onClick={handleLogout}
                    to='/'
                  >
                    Logout
                  </NavLink>
                </DropdownItem>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </DropdownActiveCheck>
    );
}

export default LogoutNav