import { createReducer } from "@reduxjs/toolkit";
import { setSetupJobName, setSetupUniqueName } from "./setup.actions";
import { SetupStatusItemType } from "./setup.types";
import { setupApi } from "./setup.api";

type SetupState = {
    setupUniqueName: string;
    setupJobName: string;
    setupStatusListData?: SetupStatusItemType[];
    setupPdfListData?: any;
};

const INITIAL_STATE: SetupState = {
    setupUniqueName: "",
    setupJobName: "",
    setupStatusListData: undefined,
};

const setupReducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(setSetupUniqueName, (state, action) => {
            state.setupUniqueName = action.payload;
        })
        .addCase(setSetupJobName, (state, action) => {
            state.setupJobName = action.payload;
        })
        .addMatcher(
            setupApi.endpoints.fetchStatusList.matchFulfilled,
            (state, action) => {
                if (action.payload) {
                    state.setupStatusListData = action.payload?.items;
                }
            }
        )
        .addMatcher(
            setupApi.endpoints.fetchUploadedList.matchFulfilled,
            (state, action) => {
                if (action.payload) {
                    state.setupPdfListData = action.payload?.items;
                }
            }
        )
});

export default setupReducer;
