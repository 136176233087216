import { api } from "../../api"
import { getEntityUriName } from "../contract.utils"

export const productApi = api.injectEndpoints({
  endpoints: build => ({
    fetchContractProduct: build.query({
      query: ({ contractId, entityName }) => ({
        url: `${getEntityUriName(entityName!)}/product-details?contractId=${contractId}`
      }),
      providesTags: (result, error, args) => [{ type: "Product" }],
    }),
    submitContractProduct: build.mutation({
      query: ({ values, entityName }: { values: Record<string, unknown>, entityName: string }) => ({
        url: `${getEntityUriName(entityName!)}/product-details${values.id ? `/${values.id}` : ''}`,
        method: values.id ? "PUT" : "POST",
        body: values
      }),
      invalidatesTags: (result, error, args) => [
        { type: "Contract" },
        { type: "Product" }
      ],
    })
  })
})

export const {
  useFetchContractProductQuery,
  useSubmitContractProductMutation,
} = productApi