import styled from "styled-components"

const GasAndPowerContractTabsStyle = styled.section`
  .subscription-nav {
    margin-bottom: 2.5rem;
    align-items: center;

    .nav-link {
      color: ${({ theme}) => theme.colors.palette6};
      cursor: pointer;
      padding: .5rem;

      &.disabled {
        color: ${({ theme }) => theme.colors.grayscale[200]};
      }
    }
  }
`

export default GasAndPowerContractTabsStyle