import classNames from "classnames"
import { Table } from "reactstrap"
import StetupStatusTHeader from "./StetupStatusTHeader/StetupStatusTHeader.component"
import StetupStatusTBody from "./StetupStatusTBody/StetupStatusTBody.component"

type StetupStatusListTableProps = {
  page: number
  filters: string
  isFetching: boolean
}

const StetupStatus = ({
  isFetching,
  ...rest
}: StetupStatusListTableProps) => {
  return (
    <Table 
      responsive
      className={
        classNames({
          'is-fetching': isFetching === true
        })
      }
    >
      <StetupStatusTHeader />
      <StetupStatusTBody
      {...rest}
      />
    </Table>
  )
}

export default StetupStatus