import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { Form } from "reactstrap"

import CustomerFinancing from "./CustomerFinancing/CustomerFinancing.component"
import GuarantorFinancing from "./GuarantorFinancing/GuarantorFinancing.component"
import PaymentType from "./PaymentType/PaymentType.component"
import ShowOnCondition from "../../auth/ShowOnCondition.component"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import { Permissions } from "../../../redux/api/types"
import { canI } from "../../auth/utils"
import { useWhenFieldChangesHook } from "../hooks/useWhenFieldChangesHook.hooks"
import { guarantorDefaultValue, payerDefaultValue, setDefaultFormData } from "./utils"
import { useSelectContractId, useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { useSubmitContractBankAccountItemMutation } from "../../../redux/contract/bankAccountDetail/bankAccountDetail.api"

type PaymentFormProps = {
  bankAccountFieldsValues: FieldValues
  bankAccountDetailData?: any
  permissions: Permissions
}

const PaymentForm = ({
  bankAccountFieldsValues,
  bankAccountDetailData,
  permissions
}: PaymentFormProps) => {  
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  let canEdit = canI(`${entityName}BankAccountDetailCREATE`, permissions).abilityCheck
  if(bankAccountDetailData) {
    canEdit = canI(`${entityName}BankAccountDetailUPDATE`, bankAccountDetailData.links).abilityCheck
  }

  const formProps = useForm<FieldValues>({ 
    defaultValues: {
      contractId,
      ...setDefaultFormData(bankAccountDetailData)
    }
  })
  const { handleSubmit, watch, setValue, formState: { isSubmitting } } = formProps
  const [submitBankAccount] = useSubmitContractBankAccountItemMutation()
  const onSubmit = async (values: FieldValues) => {
    await submitBankAccount({ values, entityName })
  }
  
  useWhenFieldChangesHook({ 
    watch,
    field: "paymentMode", 
    set: "payer", 
    to: payerDefaultValue,
    setValue
  })

  useWhenFieldChangesHook({ 
    watch,
    field: "paymentMode", 
    set: "guarantor", 
    to: guarantorDefaultValue,
    setValue
  })

  useWhenFieldChangesHook({ 
    watch,
    field: "guarantor", 
    set: "payer", 
    to: payerDefaultValue,
    setValue
  })

  return (
    <FormProvider {...formProps}>
      <Form 
        onSubmit={handleSubmit(onSubmit)}
        className="my-5"
      >
        <PaymentType 
          canEdit={canEdit}
          fieldsValues={bankAccountFieldsValues}
        />
        <CustomerFinancing 
          canEdit={canEdit}
          fieldsValues={bankAccountFieldsValues}
        />
        <GuarantorFinancing 
          canEdit={canEdit}
          fieldsValues={bankAccountFieldsValues}
        />
        <ShowOnCondition showWhen={canEdit}>
          <div className="text-end">
            <ButtonWithLoader
              isLoading={isSubmitting}
              type="submit"
              disabled={isSubmitting}
              label="Salva"
              fontAwesomeIcon={["fas", "save"]}
            />
          </div>
        </ShowOnCondition>
      </Form>
    </FormProvider>
  )
}

export default PaymentForm