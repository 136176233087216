import { useFormContext } from "react-hook-form";
import { ColorPalette } from "../../../../redux/settings/settings.reducer";

interface Props {
  name: string;
  displayName?: string;
  colors: ColorPalette;
}
const SingleColorPalette = ({ colors, displayName, name }: Props) => {
  const {register}  = useFormContext()
  return (
    <label htmlFor={name} className="btn btn-light">
      <input 
      type="radio"
      id={name} 
      {...(register && register("selectedColorPalette"))}
      value={name} 
      />
      <div>
        <h6>{displayName}</h6>
        <div className="p-2" style={{backgroundColor: colors.palette1, border: "1px solid #fff"}}/>
        <div className="p-2" style={{backgroundColor: colors.palette2, border: "1px solid #fff"}}/>
        <div className="p-2" style={{backgroundColor: colors.palette3, border: "1px solid #fff"}}/>
        <div className="p-2" style={{backgroundColor: colors.palette4, border: "1px solid #fff"}}/>
        <div className="p-2" style={{backgroundColor: colors.palette5, border: "1px solid #fff"}}/>
        <div className="p-2" style={{backgroundColor: colors.palette6, border: "1px solid #fff"}}/>
        <div className="p-2" style={{backgroundColor: colors.palette7, border: "1px solid #fff"}}/>
      </div>
    </label>
  );
};

export default SingleColorPalette;
