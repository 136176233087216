import { getEntityUriName } from "../../../redux/contract/contract.utils"

export function buildFetchUrl(entityName: string) {
  return `${getEntityUriName(entityName)}`
}

export function buildExcelExportUrl(entityName: string, itemsPerPage: number) {
  return `${getEntityUriName(entityName)}/excels?pageSize=${itemsPerPage}`
}

export function buildPdpExcelExportUrl(entityName: string, itemsPerPage: number) {
  return `${getEntityUriName(entityName)}/excels-pdp?pageSize=${itemsPerPage}`
}