import { ColorPalette, INITIAL_STATE, SettingsState } from "./settings.reducer";

export const setStorageColors = (items : {
    name: string;
    displayName?: string;
    colors: ColorPalette;
  } | undefined) => {
    const storedColors: SettingsState = {
        currentPalette: items ?? INITIAL_STATE.currentPalette
    }
    localStorage.setItem('storedColors', JSON.stringify(storedColors));
  }

export const getStorageColors = () => {
    const storedColors = localStorage.getItem('storedColors')
    if(storedColors === null)
        return storedColors
    const items = JSON.parse(storedColors);
    return items
}