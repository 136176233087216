import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"
import { useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider"

import ShowOnCondition from "../../../auth/ShowOnCondition.component"
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import ShowableSection from "../../../ShowableSection/ShowableSection.component"
import { isShowableSection } from "../../../../redux/contract/pdr/pdr.utils"
import { getGasExceiseClass } from "../../../../redux/contract/contract.utils"
import { pdrQueriebleSections } from "../../../../redux/contract/pdr/combinations"

const EAttachment = ({
  customerType,
  fieldsValues: pdrFieldsValues,
  canEdit
}: FormProps) => {
  const entityName = useSelectEntityName()
  const { register, control, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  return ( 
    <ShowableSection
            section={pdrQueriebleSections.ALLEGATO_E}
            conditions={[
              entityName ?? "",
              customerType?.value ?? "",
              formWatcher.gasUseTypeId?.value ?? "",
              formWatcher.vatClassId?.value ?? "",
              getGasExceiseClass(formWatcher.gasExciseClassId?.value),
            ]}            
            checkCallback={isShowableSection}
          >
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="list" className="me-2" />
          Allegato E
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={4}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="registeredAt"
                label="Ditta/Società iscritta presso"
                rules={{ 
                  maxLength: 80,
                  required: errorFormLabels.REQUIRED
                }}
                register={register}
                errors={errors}
              />
              <p className="text-muted small">Dichiara che la Ditta/Società qui rappresentata, risulta iscritta presso l'Ufficio Registro delle Imprese della Camera di Commercio, Industria, Artigianato e Agricoltura di...</p>
            </Col>
            <Col md={4}>
              <SelectWrapper
                name="asEAttachment"
                control={control}
                label="In qualità di"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={pdrFieldsValues?.asEAttachment || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <Col md={4}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="activityCarriedOut"
                label="Attività esercitata"
                rules={{ 
                  maxLength: 128,
                  required: errorFormLabels.REQUIRED
                }}
                register={register}
                errors={errors}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <legend className="col-form-label">Sotto la propria personale responsabilità, ai sensi dell’art. 47 del D.P.R. 28.12.2000 n. 445 - dichiarazioni sostitutive dell’atto di notorietà – che la Ditta/Società qui rappresentata utilizza in tutto o in parte il gas naturale nella propria attività riconducibile all’attività industriale.</legend>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <SelectWrapper
                name="sector"
                control={control}
                label="Settore d'uso del gas"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={pdrFieldsValues?.sector || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="atecofinCode"
                label="Codice ATECOFIN 2004"
                rules={{ 
                  maxLength: 8,
                  required: errorFormLabels.REQUIRED
                }}
                register={register}
                errors={errors}
              />
            </Col>
          </Row>
          <ShowOnCondition 
            showWhen={
              parseInt(formWatcher.sector?.value) === 6 || 
              parseInt(formWatcher.sector?.value) === 7 || 
              parseInt(formWatcher.sector?.value) === 8
            }
          >
            <Row>
              <Col md={4}>
                <InputWrapper
                  type="text"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="officeCustomAgency"
                  label="Autorizzata dall'Ufficio dell’Agenzia delle Dogane di"
                  rules={{ 
                    maxLength: 128,
                    required: errorFormLabels.REQUIRED
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md={4}>
                <InputWrapper
                  type="text"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="protocolLetter"
                  label="Con lettera prot"
                  rules={{ 
                    maxLength: 128,
                    required: errorFormLabels.REQUIRED
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md={4}>
                <DatePickerWrapper
                  name="of"
                  control={control}
                  label="Del"
                  className="form-control"
                  errors={errors}
                  showMonthDropdown
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  disabled={canEdit ? false : true}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
            </Row>
          </ShowOnCondition>
          <ShowOnCondition showWhen={parseInt(formWatcher.sector?.value) === 8}>
            <Row>
              <Col md={6}>
                <InputWrapper
                  type="text"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="percentageIndustrialGas"
                  label="% ad uso industriale"
                  rules={{ 
                    maxLength: 128,
                    required: errorFormLabels.REQUIRED
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md={6}>
                <InputWrapper
                  type="text"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="percentageCivilGas"
                  label="% ad uso civile"
                  rules={{ 
                    maxLength: 128,
                    required: errorFormLabels.REQUIRED
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
            </Row>
          </ShowOnCondition>
        </div>
      </div>
    </ShowableSection>
  )
}

export default EAttachment