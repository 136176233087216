import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap"

import { Permissions } from "../../redux/api/types"
import { useLazyFetchContractNotesQuery, useSubmitContractNoteMutation } from "../../redux/contract/contract.api"
import ShowOnCondition from "../auth/ShowOnCondition.component"
import { canI } from "../auth/utils"
import ButtonWithLoader from "../ButtonWithLoader/ButtonWithLoader.component"
import TextAreaWrapper from "../forms/inputFields/TextArea/TextAreaWrapper.component"
import WithSkeleton from "../WithSkeleton/WithSkeleton.component"
import NotesList from "./NotesList/NotesList.component"

const NotesListWithSkeleton = WithSkeleton(NotesList)

type NotesModalProps = {
  itemId: string
  isOpen: boolean
  toggle: () => void
  fetchNotesUrl: string
  permissions: Permissions
  entityName: string
}

type NotesFormType = {
  contractId: string
  description: string
}

const NotesModal = ({
  isOpen,
  toggle,
  fetchNotesUrl,
  itemId,
  permissions,
  entityName,
}: NotesModalProps) => {
  const [fetchNotes, { data: notes, isFetching: isFetchingNotes, isLoading: isLoadingNotes }] = useLazyFetchContractNotesQuery()
  const fetchingNotes = isFetchingNotes || isLoadingNotes
  useEffect(() => {
    if(isOpen === true) {
      fetchNotes({ apiUrl: fetchNotesUrl, contractId: itemId })
    }
  }, [isOpen, fetchNotes, fetchNotesUrl, itemId])

  const { register, handleSubmit, reset, formState: { isSubmitting } } = useForm<NotesFormType>({
    defaultValues: {
      contractId: itemId,
    }
  })
  const [submitNote] = useSubmitContractNoteMutation()
  const onSubmit = async (values: NotesFormType) => {
    await submitNote({
      apiUrl: canI("noteCREATE", permissions).actionUrl,
      values,
      contractId: itemId
    })
    reset()
  }

  return (
    <Modal
      size="lg" 
      isOpen={isOpen} 
      toggle={toggle}
    >
      <ModalHeader toggle={toggle} data-bs-theme="dark">
        <FontAwesomeIcon className="me-2" icon="comments" />
        Elenco delle note
      </ModalHeader>
      <ModalBody>
        <NotesListWithSkeleton 
          isLoading={fetchingNotes}
          notes={notes}
        />
        <ShowOnCondition
          showWhen={canI("noteCREATE", permissions).abilityCheck}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <TextAreaWrapper 
              className="form-control"
              name="description"
              label="Inserisci una nuova nota"
              register={register}
            />
            <div className="text-end">
              <ButtonWithLoader
                isLoading={isSubmitting}
                type="submit"
                disabled={isSubmitting}
                label="Inserisci"
                fontAwesomeIcon={["fas", "paper-plane"]}
              />
            </div>
          </Form>
        </ShowOnCondition>
      </ModalBody>
    </Modal>
  )
}

export default NotesModal