import { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { Form } from "reactstrap"

import GeneralData from "./GeneralData/GeneralData.component"
import CustomerData from "./CustomerData/CustomerData.component"
import InternalReference from "./InternalReference/InternalReference.component"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import ScrollToTop from "../../ScrollToTop/ScrollToTop.component"
import { canI } from "../../auth/utils"
import { Permissions } from "../../../redux/api/types"
import { useSubmitProposalDataMutation } from "../../../redux/contract/contract.api"
import { isItalyCountry, isScoreCheckMandatory, setDefaultFormData } from "./utils"
import { useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { ProposalDataType } from "../../../redux/contract/contract.types"
import ApplicantData from "./ApplicantData/ApplicantData.component"

type OngoingCustomersFormProps = {
  proposalFieldsValues: FieldValues
  userFieldsValues: FieldValues
  proposalData?: ProposalDataType
}

const OngoingCustomersForm = ({ 
  proposalFieldsValues, 
  userFieldsValues, 
  proposalData,
}: OngoingCustomersFormProps) => {
  const navigate = useNavigate()
  const actualUrl = useLocation()
  const entityName = useSelectEntityName()
  const canEdit = proposalData ? canI(`${entityName}UPDATE`, proposalData.links as Permissions).abilityCheck : true
  const defaultCustomerBirthCountry = useMemo(() => proposalFieldsValues?.customerBirthCountry?.find(isItalyCountry), [proposalFieldsValues])
  const [scoreCheckResult, setScoreCheckResult] = useState<boolean | undefined>(
    undefined
  );

  const [submitProposal] = useSubmitProposalDataMutation()
  const formProps = useForm<ProposalDataType>({ 
    defaultValues: setDefaultFormData({ 
      proposalFieldsValues, 
      proposalData, 
      defaultCustomerBirthCountry 
    })
  })
  const { handleSubmit, reset, formState: { isSubmitting } } = formProps  

  const onSubmit = async (values: ProposalDataType) => {
    const response = await submitProposal({ values, entityName })    
    //Update actual URL with contract id if it's a POST mutation
    if(!values.id && "data" in response) {
      navigate(`${actualUrl.pathname}/${response.data.id}`)
    }
  }

  useEffect(() => {    
    reset(setDefaultFormData({
      proposalFieldsValues, 
      proposalData, 
      defaultCustomerBirthCountry 
    })) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalData])

  return (
    <FormProvider {...formProps}>
      <ScrollToTop />
      <Form 
        onSubmit={handleSubmit(onSubmit)} 
        className="my-5"
      >
        <GeneralData 
          canEdit={canEdit}
          fieldsValues={proposalFieldsValues}
          userFormValues={userFieldsValues}
        />

        <CustomerData 
          canEdit={canEdit}
          fieldsValues={proposalFieldsValues}
          setScoreCheckResult={setScoreCheckResult}
          scoreCheckResult={scoreCheckResult}
        />

        <ApplicantData 
        canEdit={canEdit}        
        fieldsValues={proposalFieldsValues}
        />

        <InternalReference
          canEdit={canEdit}
        />

        {canEdit &&
          <div className="text-end">
            <ButtonWithLoader
              isLoading={isSubmitting}
              type="submit"
              disabled={isSubmitting || isScoreCheckMandatory({
                isActive: proposalFieldsValues?.isScoreCheckActive,
                isMandatory: proposalFieldsValues?.isScoreCheckMandatory,
                isStatusSucceeded: scoreCheckResult
              })}
              label="Salva"
              fontAwesomeIcon={["fas", "save"]}
            />
          </div>
        }
      </Form>
    </FormProvider>
  )
}

export default OngoingCustomersForm