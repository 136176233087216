import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { SignatureType } from "../../redux/auth/auth.types"

export function getButtonIcon(signatureType: SignatureType): IconProp {
  switch(parseInt(signatureType.value)) {
    case 3:
      return ['fas', 'check']

    case 2:
      return ['fas', 'file-upload']

    default:
      return ['fas', 'mobile-alt']
  }
}