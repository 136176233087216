import { createGlobalStyle } from "styled-components";

const CustomStyles = createGlobalStyle`

//SHOW TABLE AND LABEL FULL WIDTH
.table thead th{
  width: 1%;
  white-space: nowrap;
}

.table tbody td{
  width: 1%;
  white-space: nowrap;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%;
}


//SHOW HORIZONTAL SCROLLBAR RESPONSIVE
.table-responsive{
  margin-bottom: 30px;
}
.table-responsive::-webkit-scrollbar {
    -webkit-appearance: none;
    mix-blend-mode: 20px;
}

.table-responsive::-webkit-scrollbar:vertical {
    width: 5px;
}

.table-responsive::-webkit-scrollbar:horizontal {
    height: 5px;
}

.table-responsive::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.blue};
    border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-track {
    border-radius: 10px;  
    background-color: ${({ theme }) => theme.colors.white}; 
}

/* Aggiungi questo per il colore di sfondo alla barra di scorrimento */
.table-responsive::-webkit-scrollbar-track-piece {
    background-color: ${({ theme }) => theme.colors.lightGrey};
    border-radius: 10px; 
}



//change color in form placeholder
textarea.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: ${({ theme }) => theme.colors.lightGrey};
  text-transform: lowercase;
}
textarea.form-control::-moz-placeholder { /* Firefox 19+ */
  color: ${({ theme }) => theme.colors.lightGrey};
  text-transform: lowercase;
}
textarea.form-control:-ms-input-placeholder { /* IE 10+ */
  color: ${({ theme }) => theme.colors.lightGrey};
  text-transform: lowercase;
}
textarea.form-control:-moz-placeholder { /* Firefox 18- */
  color: ${({ theme }) => theme.colors.lightGrey};
  text-transform: lowercase;
}

.form-control {
  &::placeholder {
    color: ${({ theme }) => theme.colors.lightGrey};
    font-weight: 400;
    text-transform: lowercase;
  }
}

//CHANGE FONT SIZE OF PRIVACY TEXT
.grouped-body.privacy {
  font-size: .85rem;
}

//ALIGN TEXT MODAL HOMEPAGE
.entity-info-modal .desc{
  text-align: center;
}

//FIX NAVBAR CSS
.nav-item{
  .dropdown-menu {
    .dropdown-group{
      margin-bottom: 0;
    }
  }  
}

#navigation-header{
  .navbar{
    background-color: ${({ theme }) => theme.colors.palette1};
    border-bottom: 1px solid ${({ theme }) => theme.colors.palette1};
    .nav-link{
      &:before{
        background-color: ${({ theme }) => theme.colors.palette3};
      }
    }
    li.nav-item{
      .dropdown-menu{
        border-left: 5px solid ${({ theme }) => theme.colors.palette3};
      }
    }
      .dropdown-menu .dropdown-header{
        background-color: ${({ theme }) => theme.colors.palette4};
      }
  }
  & + div {
    & > header{
      background-color: ${({ theme }) => theme.colors.palette2};
    }
  }
}

.contracts-section .contracts-container:before{
  background-color: ${({ theme }) => theme.colors.palette3}!important;
} 

`;

export default CustomStyles;
