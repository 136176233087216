import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"

import { contractApi } from "../../../../redux/contract/contract.api"
import ShowOnCondition from "../../../auth/ShowOnCondition.component"
import AsyncSelectWrapper from "../../inputFields/AsyncSelect/AsyncSelectWrapper.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { asyncFnWrapper } from "../../utils/utils"
import { emailCheck } from "../../utils/validations"

const Sales = ({
  canEdit,
  fieldsValues: podFieldsValues,
}: FormProps) => {
  const { register, control, watch, setValue, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  const [fetchTowns] = contractApi.endpoints.fetchTowns.useLazyQuerySubscription()
  const [fetchPostalCodes] = contractApi.endpoints.fetchPostalCodes.useLazyQuerySubscription()

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="map-marker-alt" className="me-2" />
        Recapito fatture e comunicazioni
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={6}>
            <SelectWrapper
              name="isInvoiceDifferentEmail"
              control={control}
              label="Vuoi ricevere la fattura ad un altro indirizzo email?"
              isDisabled={canEdit ? false : true}
              errors={errors}
              register={register}
              options={podFieldsValues?.isInvoiceDifferentEmail || []}
              rules={{ required: errorFormLabels.REQUIRED }}
            />
          </Col>
          <ShowOnCondition showWhen={parseInt(formWatcher.isInvoiceDifferentEmail?.value) === 1}>
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="invoiceDifferentEmail"
                label="E-mail"
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  validate: value => emailCheck(value) || errorFormLabels.INVALID_EMAIL,
                  maxLength: 80,
                }}
                register={register}
                errors={errors}
              />
            </Col>
          </ShowOnCondition>
        </Row>
        <Row>
          <Col md={6}>
            <SelectWrapper
              name="billingShippingAddress"
              control={control}
              label="Desideri ricevere la fattura anche in formato cartaceo?"
              isDisabled={canEdit ? false : true}
              errors={errors}
              register={register}
              options={podFieldsValues?.billingShippingAddress || []}
              rules={{ required: errorFormLabels.REQUIRED }}
            />
          </Col>
        </Row>
        <ShowOnCondition showWhen={parseInt(formWatcher.billingShippingAddress?.value) === 3}>
          <Row>
            <Col md={12}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="atName"
                label="Presso"
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: 80,
                }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={3}>
              <SelectWrapper
                name="toponymId"
                control={control}
                label="Toponimo"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={podFieldsValues?.toponymIc || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <Col md={7}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="streetName"
                label="Indirizzo"
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: 80,
                }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={2}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="streetNo"
                label="Civico"
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: 4,
                }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={10}>
              <AsyncSelectWrapper
                name="townName"
                control={control}
                register={register}
                label="Comune"
                errors={errors}
                isDisabled={canEdit ? false : true}
                loadOptions={(input: string) => {
                  if(input.length > 2) {
                    return asyncFnWrapper(input, fetchTowns)
                  }
                }}
                getOptionLabel={option => `${option.town} (${option.province})`}
                getOptionValue={option => `${option.town} (${option.province})`}
                rules={{ required: errorFormLabels.REQUIRED }}
                whenFieldChanges={() => setValue("postalCode", null)}
                noOptionsMessage={() => 
                  <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                }
              />
            </Col>
            <Col md={2}>
              <SelectWrapper
                name="postalCode"
                control={control}
                register={register}
                label="CAP"
                isDisabled={canEdit? false : true}
                errors={errors}
                rules={{ required: errorFormLabels.REQUIRED }}
                watchedFieldProps={{
                  watch: watch,
                  watchedFieldName: "townName",
                  triggerWatchFn: fetchPostalCodes,
                  returnedValuePropertyName: "town",
                  defaultWatchedFieldValue: formWatcher.townName || null,
                }}
              />
            </Col>
          </Row>
        </ShowOnCondition>
      </div>
    </div>
  )
}

export default Sales