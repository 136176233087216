import { ErrorMessage } from "@hookform/error-message"
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form"
import { FormGroup, Label } from "reactstrap"

type PolicyRadioProps = {
    name: string
    control: Control<FieldValues, any>
    required?: boolean
    errors: FieldErrors<FieldValues>
    className?: string,
    defaultChecked?: boolean
}
const PolicyRadio = ({
    name,
    control,
    required,
    errors,
    defaultChecked,
    ...rest
}:PolicyRadioProps) => {

    return (
        <Controller 
        control={control}
        name={name}
        render={({ field: { onChange, value, ref } }) => {
            const idTrue = `${name}PolicyTrue`
            const idFalse = `${name}PolicyTrue`
            const policyTrueOnChange = () => onChange(true)
            const policyFalseOnChange = () => onChange(false)
            return (
            <>
                <FormGroup inline check>
                    <Label id={idTrue}>Presto il consenso {required && "*"}</Label>
                    <input 
                    type="radio"
                    id={idTrue}
                    ref={ref} 
                    checked={value === true} 
                    onChange={policyTrueOnChange}
                    defaultChecked={defaultChecked}
                    {...rest}
                     />
                </FormGroup>
                <FormGroup inline check>
                    <Label id={idFalse}>Nego il consenso {required && "*"}</Label>
                    <input 
                    type="radio" 
                    id={idFalse}
                    ref={ref} 
                    checked={value === false} 
                    onChange={policyFalseOnChange}
                    defaultChecked={!defaultChecked}
                    {...rest} 
                    />
                </FormGroup>
                {errors &&
                    <ErrorMessage
                    errors={errors}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    name={name as any}
                    render={({ message } : {message:string}) => (
                        <div className="invalid-feedback d-block">
                        {message}
                        </div>
                    )}
                    />
                }
            </>
        )}}
        />
    )
}

export default PolicyRadio