import { Container } from "reactstrap"

import UserMassiveCreationForm from "../../components/forms/UserMassiveCreationForm/UserMassiveCreationForm.component"
import DefaultPageContainer from "../DefaultPageContainer/DefaultPageContainer.component"
import PageHeader from "../PageHeader/PageHeader.component"
import UserMassiveCreationPageStyle from "./style"

const UserMassiveCreationPage = () => {
  return (  
    <DefaultPageContainer>
      <UserMassiveCreationPageStyle>
        <PageHeader pageTitle="Creazione massiva utenti" icon="users-gear" />
        <Container className="contract-container">
          <UserMassiveCreationForm />
        </Container>
      </UserMassiveCreationPageStyle>
    </DefaultPageContainer>
  )
}

export default UserMassiveCreationPage