import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component";
import { FieldValues, useFormContext } from "react-hook-form";
import { errorFormLabels } from "../../utils/formLabels";

interface Props {
  canEdit?: boolean;
  formFields: FieldValues;
  entityName: string;
}
const PdrPlantVerify = ({ canEdit, formFields, entityName }: Props) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="fire" className="me-2" />
          Verifica impianti
        </div>
        <div className="grouped-body">
        <Row>
            <Col md={12}>
              <SelectWrapper
                name="variationType"
                control={control}
                label="Richiede la seguente variazione"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={formFields?.variationType || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
          </Row>
        </div>
      </div>
  );
};

export default PdrPlantVerify;
