import React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SignedContractDashboardStyle from './style'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type SignedContractDashboardProps = {
  title: string
  message: string
  buttonLabel: string
  icon: IconProp
  contractPdfUrl: string
}

const SignedContractDashboard = ({
  title, 
  message, 
  buttonLabel, 
  icon, 
  contractPdfUrl 
}: SignedContractDashboardProps) => {
  return(
    <SignedContractDashboardStyle>
      <h1 className="title">{title}</h1>
      <div className="separator my-3"></div>
      <p className="description">{message}</p>
      {buttonLabel && contractPdfUrl ? (
        <p className="lead mb-0">
          <Button
            target="_blank"
            href={contractPdfUrl}
            className="btn ab-button"
            rel="noopener noreferrer"
          >
            {icon &&
              <FontAwesomeIcon icon={icon} size="lg" className="me-2" />
            }
            {buttonLabel}
          </Button>
        </p>
      ) : (
        <p>Si è verificato un errore e il contratto non è al momento scaricabile. Ti invitiamo a provare più tardi.</p>
      )}
    </SignedContractDashboardStyle>
  )
}

export default SignedContractDashboard
