import { MaintenancePageStyle } from './style'
import { ReactComponent as Logo } from '../../assets/svg/maintenance.svg'
import { ReactComponent as Clock } from '../../assets/svg/clock.svg'

type MaintenancePageProps = {
  maintenanceData: Record<string, any>
}

const MaintenancePage = ({
  maintenanceData
}: MaintenancePageProps) => { 
  return (
    <MaintenancePageStyle>
      <article>
        <Logo className="logo"/>
        <div className="content">
          <Clock />
          <h1>{ maintenanceData.under_maintenance_title }</h1>

          <div className="paragraph">
            <p>{ maintenanceData.under_maintenance_message }</p>
          </div>
        </div>

        <div className="footer">
          <p>{ maintenanceData.maintenance_footer_text }</p>
        </div>
      </article>
    </MaintenancePageStyle>
  )
}

export default MaintenancePage