import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router"
import { Col, Container, Row } from "reactstrap"

import UserPageStyle from "./style"
import UserForm from "../../components/forms/UserForm/UserForm.component"
import WithSpinner from "../../components/WithSpinner/WithSpinner.component"
import { useFetchUserDataQuery } from "../../redux/user/user.api"
import DefaultPageContainer from "../DefaultPageContainer/DefaultPageContainer.component"
import PageHeader from "../PageHeader/PageHeader.component"
import { ContractsPageContext } from "../providers/ContractsPageProvider.provider"
import { useFetchFormFieldsValuesQuery } from "../../redux/contract/contract.api"

const UserFormWithSpinner = WithSpinner(UserForm)

const UserPage = () => {
  const { userId } = useParams()
  const entityName = useMemo(() => "user", [])
  //Set shared contract data like entityName/contractId for easy retrieve in children components
  const [contractData, setContractData] = useState<{[key: string]: any}>({ entityName, userId })

  const { 
    data: fieldsValues,
    isFetching: isFetchingFormFields, 
    isLoading: isLoadingFormFields,
  } = useFetchFormFieldsValuesQuery({ entityName: "user" })
  const fetchingFormfields = isFetchingFormFields || isLoadingFormFields

  const {
    data: userData,
    isFetching: isFetchingUser,
    isLoading: isLoadingUser,
    isUninitialized
  } = useFetchUserDataQuery(userId!, { skip: !userId })
  const fetchingUser = isFetchingUser || isLoadingUser

  const isLoading = fetchingFormfields || fetchingUser

  useEffect(() => {
    setContractData(prevState => ({ ...prevState, userId }))
  }, [userId])

  return (
    <ContractsPageContext.Provider
      value={{
        contractData,
        setContractData,
      }}
    >
      <DefaultPageContainer>
        <UserPageStyle>
          <PageHeader pageTitle="Utenti" icon="users-gear" />
          <Container className="contract-container">
            <Row className="justify-content-center">
              <Col xs={12}>
                <UserFormWithSpinner 
                  isLoading={isLoading}
                  fieldsValues={fieldsValues ?? {}}
                  userData={isUninitialized ? undefined : userData}
                />
              </Col>
            </Row>
          </Container>
        </UserPageStyle>
      </DefaultPageContainer>
    </ContractsPageContext.Provider>
  )
}

export default UserPage