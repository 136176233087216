import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useFormContext } from "react-hook-form"
import { Button } from "reactstrap"

import PrivacyPolicyModal from "./PrivacyPolicyModal.component"
import PolicyRadio from "../../inputFields/PolicyRadio/PolicyRadio.component"

const PrivacyPolicy = () => {
  const { watch, control, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  const [visibility, setVisibility] = useState(false)
  const toggle = () => setVisibility(prevState => !prevState)

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="bullhorn" className="me-2" />
        Consensi
      </div>
      <div className="grouped-body privacy">
        <div className="mb-3">
          Dichiaro di aver preso visione dell' 
          <Button color="link" className="link-button" onClick={toggle} size="sm">
            informativa sulla privacy
          </Button>{' '}.
          <PrivacyPolicyModal 
            isOpen={visibility}
            toggle={toggle}
          />
        </div>
        <div className="mb-3">
          <p className="mb-2">
            Inoltre,  in  merito  al  trattamento  dei  miei  dati  personali  per  l’invio  di  comunicazioni  
            commerciali  direttamente  da  parte di ABenergie  (marketing diretto – finalità di cui alla lettera e.)    
            e/o da parte di società partner di ABenergie (marketing indiretto – finalità di cui alla lettera f.)
          </p>
          <PolicyRadio
          control={control}
          errors={errors}
          name="commercialCommunication"
          className="form-check-input"
          defaultChecked={formWatcher.commercialCommunication}
           />
        </div>
        <div className="mb-3">
          <p className="mb-2">
            Inoltre, in merito al trattamento dei miei dati personali per finalità di profilazione generica (profilazione – finalità di cui alla lettera g.) 
          </p>
          <PolicyRadio
          control={control}
          errors={errors}
          name="profiling"
          className="form-check-input"
          defaultChecked={formWatcher.profiling}
           />
        </div>
        <div className="mb-3">
          <p className="mb-2">
            Inoltre,  in  merito  al  trattamento  dei  miei  dati  personali  per  la  comunicazione  a  società  partner  di  ABenergie  per  l’invio di loro comunicazioni commerciali (cessione di dati a terzi – finalità di cui alla lettera h.)
          </p>
          <PolicyRadio
          control={control}
          errors={errors}
          name="thirdPartyCommunication"
          className="form-check-input"
          defaultChecked={formWatcher.thirdPartyCommunication}
           />
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy