import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"

import AsyncSelectWrapper from "../../inputFields/AsyncSelect/AsyncSelectWrapper.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import MoneyInputWrapper from "../../inputFields/MoneyInput/MoneyInputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { contractApi } from "../../../../redux/contract/contract.api"
import { errorFormLabels, maxLengthLabel } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { asyncFnWrapper } from "../../utils/utils"
import { emailCheck } from "../../utils/validations"

const BillingData = ({ canEdit, fieldsValues }: FormProps) => {
  const { control, register, setValue, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  const [fetchTowns] = contractApi.endpoints.fetchTowns.useLazyQuerySubscription()
  const [fetchPostalCodes] = contractApi.endpoints.fetchPostalCodes.useLazyQuerySubscription()

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="money-bill" className="me-2" />
        Fatturazione
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={6}>
            <InputWrapper
              name="multiplier"
              label="Moltiplicatore"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={6}>
            <MoneyInputWrapper
              name="regularHourlyPay"
              label="Tariffa ore ordinarie"
              type="number"
              step=".01"
              errors={errors}
              register={register}
              className="form-control"
              rules={{ 
                required: errorFormLabels.REQUIRED,
                min: {
                  value: 0.01,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={12}>
            <InputWrapper
              name="paymentConditions"
              label="Condizioni di pagamento"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="toTheAttentionOf"
              label="All'attenzione di"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="referencePersonEmail"
              label="Email referente fatturazione"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control text-lowercase"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                validate: value => emailCheck(value) || errorFormLabels.INVALID_EMAIL,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={10}>
            <InputWrapper
              name="billingAddress"
              label="Indirizzo fatturazione"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 128,
                  message: maxLengthLabel(128)
                }
              }}
            />
          </Col>
          <Col md={2}>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={canEdit ? false : true}
              name="billingNo"
              label="Civico fatturazione"
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: 4,
              }}
              register={register}
              errors={errors}
            />
          </Col>
          <Col md={8}>
            <AsyncSelectWrapper
              name="billingTownName"
              control={control}
              register={register}
              label="Comune"
              errors={errors}
              isDisabled={canEdit ? false : true}
              loadOptions={(input: string) => {
                if(input.length > 2) {
                  return asyncFnWrapper(input, fetchTowns)
                }
              }}
              getOptionLabel={option => `${option.town} (${option.province})`}
              getOptionValue={option => `${option.town} (${option.province})`}
              rules={{ required: errorFormLabels.REQUIRED }}
              whenFieldChanges={option => {
                setValue("billingPostalCode", null)
                setValue("billingProvince", option.province)
              }}
              noOptionsMessage={() => 
                <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
              }
            />
          </Col>
          <Col md={2}>
            <InputWrapper
              name="billingProvince"
              label="Provincia nascita"
              type="text"
              register={register}
              className="form-control"
              readOnly
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={2}>
            <SelectWrapper
              name="billingPostalCode"
              control={control}
              register={register}
              label="CAP"
              isDisabled={canEdit? false : true}
              errors={errors}
              rules={{ required: errorFormLabels.REQUIRED }}
              watchedFieldProps={{
                watch: watch,
                watchedFieldName: "billingTownName",
                triggerWatchFn: fetchPostalCodes,
                returnedValuePropertyName: "town",
                defaultWatchedFieldValue: formWatcher.billingTownName || null,
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BillingData