import { useState } from "react"
import { Entity } from "../../../redux/auth/auth.reducer"
import { selectCurrentUser } from "../../../redux/auth/auth.selectors"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { newLabelVisible } from "./utils"
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"
import CanI from "../../auth/CanI.component"
import { getEntityPath, getEntityPluralName } from "../../../redux/contract/contract.utils"
import { Link } from "react-router-dom"
import { resetContract } from "../../../redux/contract/contract.actions"
import { getTemplateDownloadPath } from "../../forms/UserMassiveCreationForm/utils"

interface EntityProps {
    entity: Entity
}
const EntityRow = ({
    entity
} : EntityProps) => {
    const { name, icon, description, entityName, inEvidenceUntil }  = entity
    const dispatch = useAppDispatch()
    const { links } = useAppSelector(selectCurrentUser)!
    const [ visibility, setVisibility ] = useState(false)
    const toggle = () => setVisibility(!visibility)
    
    return (
        <div className="entity-row">
          <div className="text-container">
            <FontAwesomeIcon size="lg" className="me-2" icon={icon} />{' '}
            {name}
            {newLabelVisible(inEvidenceUntil) &&
              <sup>Nuovo</sup>
            }
            <div className="more-info">
              <Button 
                onClick={toggle} 
                type="button" 
                color="link"
              >
                Di cosa si tratta?
              </Button>
              <Modal 
                className="entity-info-modal" 
                toggle={toggle}
                isOpen={visibility}
              >
                <ModalBody>
                  <div className="icon">
                    <FontAwesomeIcon icon={['fas', 'info-circle']} />
                  </div>
                  <h1 className="title"><FontAwesomeIcon icon={icon} /> {name}</h1>
                  <p className="desc">{description}</p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    outline
                    onClick={toggle}
                  >
                    <FontAwesomeIcon icon={['fas', 'times-circle']} className="me-1" />
                    Chiudi
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
          <div className="actions-container">
            <CanI doWhat={"GET"} withPermissions={links} entityName={getEntityPluralName(entityName)}>
              {() => (
                <Link className="btn btn-outline-secondary outline" to={`/${getEntityPath(entityName)}/all`}>
                  <FontAwesomeIcon icon="list" className="me-1" />{' '}
                  Vedi tutti
                </Link>
              )}
            </CanI>
            <CanI doWhat={"CREATE"} withPermissions={links} entityName={entityName}>
              {() => (
                <Link 
                  onClick={() => dispatch(resetContract())}
                  className="btn btn-secondary" 
                  to={`/${getEntityPath(entityName)}`}
                >
                  <FontAwesomeIcon icon="plus-circle" className="me-1" />{' '}
                  Crea nuovo
                </Link>
              )}
            </CanI>
            <CanI doWhat="GET" withPermissions={links} entityName={`${entityName}PaperDocument`}>
              {({ action }) => (
                <Button 
                  href={`${getTemplateDownloadPath()}${action}`}
                  target="_blank"
                >
                  <FontAwesomeIcon icon={['fas', 'file-arrow-down']} className="me-1" />
                  Scarica documento
                </Button>
              )}
            </CanI>
          </div>
        </div>
      )
}

export default EntityRow