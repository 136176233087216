import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DropdownActiveCheck from '../DropdownActiveCheck/DropdownActiveCheck.component'
import { Entity } from '../../../redux/auth/auth.reducer'
import MenuItem from '../MenuItem/MenuItem.component'

type ContractsMenuProps = {
  categoryType: string, 
  categoryName: string,
  contracts: Entity[]
}

const ContractsMenu = ({
  categoryType,
  categoryName,
  contracts
}: ContractsMenuProps ) => {

  return (
    <DropdownActiveCheck searchedValue={categoryType}>
      {props => (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret {...props}>
            <FontAwesomeIcon icon={['fas', "file-contract"]} />{' '}
            {categoryName}
          </DropdownToggle>
          <DropdownMenu>
            <div className="menu-items">
              {contracts.map((contract, i) =>
                <MenuItem
                  item={contract}
                  key={i}
                />
              )}
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </DropdownActiveCheck>
  )
}

export default ContractsMenu