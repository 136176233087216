import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NavLink } from "react-router-dom"
import { NavItem } from "reactstrap"

const SetupNav = () => {
    return (
        <NavItem>
            <NavLink end className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`} to="/setup">
                <FontAwesomeIcon icon={['fas', 'gear']} />{' '}
                Setup
            </NavLink>
        </NavItem>
    )
}

export default SetupNav