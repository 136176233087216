import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type WorkSiteRealEstateInstructionsProps = {
  isOpen: boolean
  toggle: () => void
}

const WorkSiteRealEstateInstructions = ({ 
  isOpen, 
  toggle 
}: WorkSiteRealEstateInstructionsProps) => (
  <Modal className="utility-modal" isOpen={isOpen} toggle={toggle} size="lg">
    <ModalHeader toggle={toggle}>
      <FontAwesomeIcon className="me-2" icon="info-circle" />
      Indicazioni per la compilazione
    </ModalHeader>
    <ModalBody className="utility-modal">
      <h6 className="strong">Modulo istanza - altri usi cantiere</h6>
      <p>Esempi:</p>
      <ul>
        <li>
          in base a denuncia di inizio attività (D.I.A.) presentata ai sensi dell’art.22, comma 3 del DPR 380/01 e successive modificazioni/art.4, comma 7 della Legge 493/93 e successive modificazioni” al Comune di …………. in data …………. in relazione alla quale è intervenuto il silenzio-assenso del predetto comune, non essendo necessaria per l’esecuzione di dette opere né concessione edilizia né autorizzazione;
        </li>
        <li>in base ad autorizzazione n. …………., del …………., rilasciata da ………….</li>
        <li>in  assenza  di  qualsivoglia  provvedimento  o  comunicazione  all’Amministrazione  competente,  non  essendo  questi  necessari  per l’esecuzione di dette opere;</li>
        <li>
          (Nel caso di infrastruttura di telecomunicazioni scegliere fra uno dei seguenti punti)
          <ul>
            <li>
              in  base  a  richiesta  di  autorizzazione  di  cui  all’art.87  del  decreto  legislativo  1.8.2003,  n.259,  presentata  al  comune  di …………., in data …………., in relazione alla quale non è intervenuto alcun provvedimento di diniego secondo modalità e termini indicati nel citato decreto;
            </li>
            <li>
              in  base  a  denuncia  di  inizio  attività  (D.I.A.)  di  cui  all'art.87  del  decreto  legislativo  1.8.2003,  n.259,  presentata  al  comune  di …………., in data …………., in relazione alla quale non è intervenuto alcun provvedimento di diniego alla data di presentazione della presente dichiarazione;
            </li>
          </ul>
        </li>
      </ul>
      <p className="text">(occorre inoltre aggiungere) dichiara altresì che l’installazione dell’impianto (scegliere fra uno dei seguenti punti):</p>
      <ul>
        <li>
          non presuppone la realizzazione di opere civili o, comunque, l’effettuazione di scavi e l’occupazione di suolo pubblico; 
        </li>
        <li>
          presuppone la realizzazione di opere civili o, comunque, l'effettuazione di scavi e l'occupazione di suolo pubblico e di avere presentato  in  proposito  relativa  richiesta  di  autorizzazione  ai  sensi  dell'art.88  del  decreto  legislativo  1.8.2003,  n.259,  in  data …………., in relazione alla quale non è intervenuto entro i termini indicati dalla predetta norma alcun provvedimento di diniego
        </li>
      </ul>
    </ModalBody>
  </Modal>
)

export default WorkSiteRealEstateInstructions