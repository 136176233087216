import { FieldValues } from "react-hook-form"

import { setDefaultDate } from "../utils/utils"

export function setDefaultFormData(proposalData?: FieldValues) {
  return proposalData ? ({
    ...proposalData,
    employeeBirthDate: setDefaultDate(proposalData.employeeBirthDate),
    startDate: setDefaultDate(proposalData.startDate),
  }) : ({})
}

export function setDefaultValuesBeforeSubmit(values: FieldValues) {
  return {
    ...values,
    monthlyGrossPay: values?.monthlyGrossPay === "" ? null : parseFloat(values?.monthlyGrossPay),
    defaultPay: values?.defaultPay === "" ? null : parseFloat(values?.defaultPay),
    contingency: values?.contingency === "" ? null : parseFloat(values?.contingency),
    provinceElement: values?.provinceElement === "" ? null : parseFloat(values?.provinceElement),
    absorbableMin: values?.absorbableMin === "" ? null : parseFloat(values?.absorbableMin),
    totalMonthlyGrossPay: values?.totalMonthlyGrossPay === "" ? null : parseFloat(values?.totalMonthlyGrossPay)
  } as FieldValues
}