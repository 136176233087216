import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component";
import { FieldValues, useFormContext } from "react-hook-form";
import { errorFormLabels } from "../../utils/formLabels";
import { CustomerTypes, getCustomerType } from "../../utils/utils";

interface Props {
  canEdit?: boolean;
  formFields: FieldValues;
  entityName: string;
}
const PodMeasureGroupShift = ({ canEdit, formFields, entityName }: Props) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const formWatcher = watch();
  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="bolt" className="me-2" />
        Spostamento gruppo di misura
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={12}>
            {getCustomerType(
              CustomerTypes.BUSINESS_CUSTOMER,
              formWatcher.customerType
            ) ? (
              <SelectWrapper
                name="variationType"
                control={control}
                label="Richiede la seguente variazione"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={formFields?.variationType || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            ) : (
              <SelectWrapper
                name="displacementType"
                control={control}
                label="Richiede il seguente spostamento"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={formFields?.displacementType || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PodMeasureGroupShift;
