import { FieldValues } from "react-hook-form";
import { setDefaultDate } from "../utils/utils";

export function isPropertyDeclarationRequired(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransfer' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function setDefaultFormData(cadastralDetailData?: FieldValues) {
  return cadastralDetailData ? ({
    ...cadastralDetailData,
    rentRegisteredDate: setDefaultDate(cadastralDetailData.rentRegisteredDate),
    authBirthDate: setDefaultDate(cadastralDetailData.authBirthDate),
    authRentRegisteredDate: setDefaultDate(cadastralDetailData.authRentRegisteredDate),
  }) : ({})
}