import { useMemo } from "react"

import { selectContractProposalData } from "../../../redux/contract/contract.selectors"
import { useAppSelector } from "../../../redux/hooks"
import PaperSignatureForm from "./PaperSignatureForm.component"

type PaperSignatureFormContainerProps = {
  contractId: string
  entityName: string
  toggleFinalizationModal: () => void
}

const PaperSignatureFormContainer = ({
  entityName,
  contractId,
  ...rest
}: PaperSignatureFormContainerProps) => {
  //Select proposal data to get contract permissions
  const selectProposalData = useMemo(() => selectContractProposalData({ contractId, entityName }), [
    contractId, 
    entityName
  ])
  const { data: proposalData } = useAppSelector(selectProposalData)

  return (
    <PaperSignatureForm
      entityName={entityName}
      contractId={contractId}
      permissions={proposalData?.links}
      {...rest}
    />
  )
}

export default PaperSignatureFormContainer