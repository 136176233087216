export function mapTitleToPathOrTitle(
  name?: string,
  toPath: boolean = true
): string | null {
  const titleToPathMap: Record<string, string> = {
    "Distributori": "distributori",
    "Fornitori": "fornitori",
    "Codici REMI": "codici-remi",
    "Codici ATECO": "codici-ateco",
    "Cattivi pagatori": "cattivi-pagatori",
    "Listini": "listini",
    "Canali vendita": "canali-vendita",
  };

  if (toPath) {
    return titleToPathMap[name ?? ''];
  } else {
    // Reverse the mapping
    const pathToTitleMap: Record<string, string> = {};
    for (const title in titleToPathMap) {
      pathToTitleMap[titleToPathMap[title]] = title;
    }
    return pathToTitleMap[name ?? ''];
  }
}

export function getUniqueNameFromPathName(pathName?: string) {
  switch (pathName) {
    case 'listini':
      return 'price-lists';
    case 'distributori':
      return 'distributors'
    case 'fornitori':
      return 'vendors'
    case 'codici-remi':
      return 'remi-codes'
    case 'codici-ateco':
      return 'ateco-codes'
    case 'cattivi-pagatori':
      return 'score-checks'
    case 'canali-vendita':
      return 'visibility-groups'
    default:
      return '';
  }
}

export const isUniqueNameFound = (uniqueName?: string) =>
  uniqueName ? true : false;

export const SETUP_DEFAULT_STATUS_PER_PAGE = 5;
export const SETUP_DEFAULT_UPLOADED_PER_PAGE = 15;

export const createJobName = (pathName?: string, category?: string) => `import-${pathName}-${category}`
