import styled from "styled-components"

const WithSpinnerStyle = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .spinner-label {
    font-size: .85rem;
    display: block;
    margin-top: 1rem;
    color: ${({ theme }) => theme.colors.grayscale[600]};
  }

  .spinner-container {
    display: flex;
    flex-direction: column;
    width: 50px;
    height: 50px;
    border: 3px solid ${({ theme }) => theme.colors.grayscale[200]};
    border-radius: 50%;
    border-top-color: ${({ theme}) => theme.colors.palette6};
    margin: auto;

    animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`

export default WithSpinnerStyle