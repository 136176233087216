import { PodFormCombinations, changeSupplierCombos, contextualTransferCombos, newActivationCombos, newConnectionCombos, takeoverCombos, transferCombos } from "./combinations";

const podFormCombos: PodFormCombinations[] = [
  ...changeSupplierCombos,
  ...transferCombos,
  ...takeoverCombos,
  ...newActivationCombos,
  ...newConnectionCombos,
  ...contextualTransferCombos
];

export const isShowableSection = (queriebleSection: string, conditionSnapshot: string[]) => {
    let queryCombo = podFormCombos.find((combo) => JSON.stringify(combo.conditions) === JSON.stringify(conditionSnapshot))
    if(queryCombo) {
        let visibleSection = queryCombo.visibleSections.find((section) => section === queriebleSection)
        return visibleSection ? true : false
    }
    return false
}