import styled from "styled-components"

const MaintenanceAlertStyle = styled.div`
  left: 0;
  margin: 1.5rem auto;
  position: fixed;
  right: 0;
  top: 0;
  width: auto;
  z-index: 999;
`

export default MaintenanceAlertStyle