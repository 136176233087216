import { useState } from "react";
import { useFetchOtpCodeMutation } from "../../../../redux/contract/contract.api";
import { OtpSignerType, OtpSignersType } from "../../../../redux/contract/contract.types";
import OtpSignatureModal from "../OtpSignatureModal/OtpSignatureModal.component";
import OtpSigner from "../OtpSigner/OtpSigner.component";
import { checkLastSigner } from "../utils";

interface Props {
    item: OtpSignerType,
    otpSignersData: OtpSignersType,
    toggleFinalizationModal: () => void
}
const OtpSignerFields = ({
    item,
    otpSignersData,
    toggleFinalizationModal
} : Props) => {
  const [requestOtpCode, { isLoading }] = useFetchOtpCodeMutation();
  const [otpSignatureIsOpen, setOtpSignatureIsOpen] = useState(false);
  const toggleOtpSignature = () =>
    setOtpSignatureIsOpen((prevState) => !prevState);

  return (
    <>
      <OtpSigner
        signer={item}
        toggleOtpSignatureModal={toggleOtpSignature}
        requestOtpCode={requestOtpCode}
        isSubmitting={isLoading}
      />
      <OtpSignatureModal
        signer={item}
        isOpen={otpSignatureIsOpen}
        toggle={toggleOtpSignature}
        toggleFinalizationModal={toggleFinalizationModal}
        isLastSigner={checkLastSigner(otpSignersData.signers)}
      />
    </>
  );
};

export default OtpSignerFields;
