import { useEffect } from "react"
import ShowOnCondition from "../auth/ShowOnCondition.component"
import { useFormContext } from "react-hook-form"
import { pdrQueriebleSections } from "../../redux/contract/pdr/combinations"

interface Props {
    children: React.ReactNode,
    section: string,
    conditions: string[],
    checkCallback: (queriebleSection: string, conditionSnapshot: string[]) => boolean,
}
const ShowableSection = ({
    children,
    conditions,
    section,
    checkCallback
} : Props) => {
    const { setValue } = useFormContext()
    const isShowable = checkCallback(section, conditions)
    useEffect(() => {
        if(!isShowable && section === pdrQueriebleSections.ACCISE){
            setValue('gasExciseClassId', "")
        }
    },  [setValue, isShowable, section])

    return (
        <ShowOnCondition showWhen={isShowable}>
            {children}
        </ShowOnCondition>
    )
}

export default ShowableSection