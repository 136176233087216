import classNames from "classnames"
import { Table } from "reactstrap"

import ProposalTBody from "./ProposalTBody/ProposalTBody.component"
import ProposalTHeader from "./ProposalTHeader/ProposalTHeader.component"
import { ListDataType } from "../../../../redux/list/list.types"

type ProposalTableProps = {
  sortParam: string
  setSortParam: (value: string) => void
  page: number
  filters: string
  isFetching: boolean
  listData: ListDataType
}

const ProposalTable = ({
  isFetching,
  ...rest
}: ProposalTableProps) => {
  return (
    <Table 
      responsive
      className={
        classNames({
          'is-fetching': isFetching === true
        })
      }
    >
      <ProposalTHeader 
        {...rest}
      />
      <ProposalTBody 
        {...rest}
      />
    </Table>
  )
}

export default ProposalTable