import { useEffect, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { Button, Col, Collapse, Row, Table } from "reactstrap"

import { useSelectEntityName } from "../../../../../pages/providers/ContractsPageProvider.provider"
import ShowOnCondition from "../../../../auth/ShowOnCondition.component"
import InputWrapper from "../../../inputFields/Input/InputWrapper.component"
import { errorFormLabels } from "../../../utils/formLabels"
import { FormProps } from "../../../utils/types"
import PotentialityRow from "../PotentialityRow/PotentialityRow.component"
import { isPotentialityNeeded } from "./utils"

const Potentiality = ({
  canEdit,
}: FormProps) => {
  const entityName = useSelectEntityName()
  const { register, control, setValue, formState: { errors } } = useFormContext()

  const [ isOpen, setIsOpen ] = useState(false)
  const toggle = () => setIsOpen(prevState => !prevState)

  //Watch all rows total values and update "totalPower" field with the sum of them
  const rowsTotals = useWatch({
    control, 
    name: [
      "heatingTotalPotentiality",
      "hobTotalPotentiality",
      "hotWaterTotalPotentiality",
      "hobWithHovenTotalPotentiality",
      "hovenTotalPotentiality",
      "hotAirTotalPotentiality",
      "otherDeviceTotalPotentiality",
    ]
  })

  //Update "totalPower" field summing all rows totals
  useEffect(() => {
    const totalPowerSum = rowsTotals.reduce((accumulator, value) => {
      return accumulator + (value || 0)
    }, 0)
    setValue("totalPower", totalPowerSum)
  }, [rowsTotals, setValue])  

  return (
    <ShowOnCondition showWhen={isPotentialityNeeded(entityName)}>
      <Row>
        <Col>
          <InputWrapper
            type="number"
            className="form-control"
            disabled={canEdit ? false : true}
            name="totalPower"
            label="Potenzialità totale installata (kW)"
            rules={{ 
              min: 0,
              maxLength: 20,
              required: errorFormLabels.REQUIRED,
              valueAsNumber: true,
              setValueAs: (v) => v === "" ? null : parseInt(v)
            }}
            register={register}
            errors={errors}
          />
        </Col>
      </Row>
      <div className="d-flex mb-2">
        <Button
          size="sm"
          color="secondary"
          outline={isOpen ? false : true}
          onClick={toggle}
          className="ms-auto"
        >
          {isOpen ? "Nascondi tabella" : "Calcola manualmente"}
        </Button>
      </div>
      <Collapse isOpen={isOpen}>
        <Row>
          <Col>
            <Table responsive>
              <thead className="table-light">
                <tr>
                  <th>N. apparecchi</th>
                  <th>Tipologia apparecchi</th>
                  <th>Potenzialità (kW)</th>
                  <th>Totale (kW)</th>
                </tr>
              </thead>
              <tbody>
                <PotentialityRow 
                  fieldName="heating"
                  fieldLabel="Caldaia riscaldamento"
                  canEdit={canEdit!}
                />
                <PotentialityRow 
                  fieldName="hotWater"
                  fieldLabel="Caldaia con produzione acqua calda sanitaria"
                  canEdit={canEdit!}
                />
                <PotentialityRow 
                  fieldName="hob"
                  fieldLabel="Piano cottura"
                  canEdit={canEdit!}
                />
                <PotentialityRow 
                  fieldName="hobWithHoven"
                  fieldLabel="Piano cottura con forno a gas"
                  canEdit={canEdit!}
                />
                <PotentialityRow 
                  fieldName="hoven"
                  fieldLabel="Forno gas"
                  canEdit={canEdit!}
                />
                <PotentialityRow 
                  fieldName="hotAir"
                  fieldLabel="Generatore aria calda"
                  canEdit={canEdit!}
                />
                <PotentialityRow 
                  fieldName="otherDevice"
                  fieldLabel="Altro (specificare)"
                  inputRequired
                  canEdit={canEdit!}
                />
              </tbody>
            </Table>
          </Col>
        </Row>
      </Collapse>
    </ShowOnCondition>
  )
}

export default Potentiality