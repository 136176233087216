import { Alert, Button, ListGroup, ListGroupItem } from 'reactstrap'
import moment from 'moment'

import AttachmentsIntegrationAlertStyle from './style'
// import AttachmentsIntegrationModal from '../forms/AttachmentsIntegrationModal/AttachmentsIntegrationModal.component'
import CanI from '../../auth/CanI.component'
import { Permissions } from '../../../redux/api/types'
import { useState } from 'react'
import AttachmentsIntegrationModal from '../AttachmentsIntegrationModal/AttachmentsIntegrationModal.component'
import { useSelectEntityName } from '../../../pages/providers/ContractsPageProvider.provider'

type AttachmentsIntegrationAlertProps = {
  permissions: Permissions
  attachmentsIntegrationHistory: any[]
}

const AttachmentsIntegrationAlert = ({
  permissions,
  attachmentsIntegrationHistory
}: AttachmentsIntegrationAlertProps) => {
  const entityName = useSelectEntityName()
  const [attachmentsModalIsOpen, setAttachmentsModalIsOpen] = useState(false)
  const toggleAttachmentsModal = () => setAttachmentsModalIsOpen(prevState => !prevState)
  
  return (
    <AttachmentsIntegrationAlertStyle>
      <Alert color="warning">
        <h4 className="alert-heading">Richiesta di integrazione allegati</h4>
        <CanI 
          doWhat="CREATE" 
          withPermissions={permissions} 
          entityName={`${entityName}AttachmentsIntegrationRequest`}
        >
          {({ action }) => (
            <>
              <p className="alert-description">
                La richiesta di integrazione allegati verrà inviata tramite e-mail all'agente, il quale 
                potrà effettuare il caricamento dei file mancanti e "chiudere" la procedura di integrazione.
              </p>
              <Button
                className="ab-button"
                onClick={toggleAttachmentsModal}
              >
                Procedi
              </Button>
              <AttachmentsIntegrationModal 
                actionUrl={action} 
                isOpen={attachmentsModalIsOpen}
                toggle={toggleAttachmentsModal}
              />
            </>
          )}
        </CanI>
        {!!attachmentsIntegrationHistory.length &&
          <ListGroup className="my-3">
            <h4 className="integration-history-title">Storico richieste di integrazione</h4>
            {attachmentsIntegrationHistory.map((({ insertDate, requestingUser, integrationStatus, expirationDate, integrationCauses }, i) => 
              <ListGroupItem key={i}>
                <p>
                  Il <strong>{moment(insertDate).format('DD/MM/YYYY HH:mm')}</strong>{' '}
                  l'utente <strong>{requestingUser}</strong> ha richiesto un'integrazione degli allegati con la seguente motivazione:
                  <br />
                  <strong>{integrationCauses}</strong>
                </p>
                <p className="mb-0">
                  Stato richiesta: <strong>{integrationStatus.label}</strong>
                  {!!expirationDate &&
                    <>
                      <br />
                      In scadenza il <strong>{moment(expirationDate).format('DD/MM/YYYY HH:mm')}</strong>
                    </>
                  }
                </p>
              </ListGroupItem>
            ))}
          </ListGroup>
        }
      </Alert>
    </AttachmentsIntegrationAlertStyle>
  )
}

export default AttachmentsIntegrationAlert