import { DefaultTheme } from "styled-components";
import { BaseColors, ExtistingColors } from "./styled";
import { ColorPalette } from "../redux/settings/settings.reducer";

const baseColors: BaseColors = {
  graySeashell: {
    50: "#f8f8f8",
    100: "#f1f1f1",
    200: "#dcdcdc",
    300: "#bdbdbd",
    400: "#989898",
    500: "#7c7c7c",
    600: "#656565",
    700: "#525252",
    800: "#464646",
    900: "#3d3d3d",
    950: "#292929",
  },
  blueLochmara: {
    50: "#f2f7fd",
    100: "#e5effb",
    200: "#c2daf5",
    300: "#8ec0ec",
    400: "#53a0df",
    500: "#287ac3",
    600: "#1c66ab",
    700: "#18528c",
    800: "#174573",
    900: "#183c63",
    950: "#102742",
  },
  greenApple: {
    50: "#f3fcf1",
    100: "#e5f9df",
    200: "#cbf1c1",
    300: "#a2e491",
    400: "#71cf59",
    500: "#4db733",
    600: "#3a9524",
    700: "#307520",
    800: "#2a5d1e",
    900: "#234d1a",
    950: "#0e2a09",
  },
  yellowWattle: {
    50: "#f9fbeb",
    100: "#eff5cc",
    200: "#e5ed9b",
    300: "#dbe361",
    400: "#d3d72c",
    500: "#c9c629",
    600: "#ad9f21",
    700: "#8b771d",
    800: "#745f1f",
    900: "#634f20",
    950: "#392a0f",
  },
  redRibbon: {
    50: "#fef2f2",
    100: "#fee6e5",
    200: "#fccfcf",
    300: "#f9a8a8",
    400: "#f57779",
    500: "#ec474f",
    600: "#dc3545",
    700: "#b7192c",
    800: "#99182c",
    900: "#83182c",
    950: "#490812",
  },
};

const extendedColors: (currentPalette: ColorPalette) => ExtistingColors = (currentPalette: ColorPalette) => {
  return {
    ...baseColors,
    ...currentPalette,
    grayscale: baseColors.graySeashell,
    primary: baseColors.blueLochmara,
    secondary: baseColors.yellowWattle,
    success: baseColors.greenApple,
    danger: baseColors.redRibbon,

    blue: "#337ab7",
    indigo: "#1abed9",
    violet: "#af24e8",
    orange: "#ff8300",
    green: "#4db733",
    yellow: "#d3d72c",
    red: "#dc3545",
    white: "#ffffff",
    lightGrey: "#dddddd",
  };
};
export const appTheme: (currentPalette: ColorPalette) => DefaultTheme = (currentPalette: ColorPalette) => {
  return {
    borderRadius: "10px",
    colors: extendedColors(currentPalette),
    boxShadow: `0 0 15px -10px ${baseColors.graySeashell[600]}`,
  };
};
