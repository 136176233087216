export function isSupplyFormNeeded(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransfer' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isMeterReadingRequired(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isAnnualConsumptionNeeded(entityName: string) {
  return (
    entityName === ''
  ) ? true : false
}

export function isVendorNameNeeded(entityName: string) {
  return (
    entityName === 'contextualTransfer'
  ) ? true : false
}

export function isSupplyStartDateNeeded(entityName: string) {
  return (
    entityName === 'contextualTransfer'
  ) ? true : false
}

export function isTemporaryConnectionNeeded(entityName: string) {
  return (
    entityName === 'newConnection'
  ) ? true : false
}

export function isPreviousHolderRequired(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransfer'
  ) ? true : false
}

export function isOldAccountHolderClientCodeNeeded(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'takeover' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isOldAccountHolderPersonTypeNeeded(entityName: string) {
  return (
    entityName === 'contextualTransfer'
  ) ? true : false  
}

export function isAccountHolderAtecoCodeNeeded(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransfer'
  ) ? true : false
}

export function disconnectableVisibility(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? false : true
}

export function isIndustrySectorNeeded(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? false : true
}