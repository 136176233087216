import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"
import { useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider"

import ShowOnCondition from "../../../auth/ShowOnCondition.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import PodDomesticUseRealEstateForm from "./PodDomesticUseRealEstateForm/PodDomesticUseRealEstateForm.component"
import PodOtherUseRealEstateForm from "./PodOtherUseRealEstateForm/PodOtherUseRealEstateForm.component"
import PodWorkSiteRealEstateForm from "./PodWorkSiteRealEstateForm/PodWorkSiteRealEstateForm.component"
import { isRealEstateGroupNeeded } from "./utils"

type RealStateProps = {
  extendedPodDataNeeded: boolean
} & FormProps

const RealState = ({
  customerType,
  canEdit,
  fieldsValues: podFieldsValues,
  ...rest
}: RealStateProps) => {
  const entityName = useSelectEntityName()
  const { register, control, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  return (
    <ShowOnCondition
      showWhen={
        isRealEstateGroupNeeded(entityName)
      }
    >
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="house" className="me-2" />
          Immobile
        </div>
        <div className="grouped-body">
          {/* TODO add <RealEstateFieldChangesConditions /> */}
          <Row>
            <PodDomesticUseRealEstateForm
              canEdit={canEdit}
              entityName={entityName}
              fieldsValues={podFieldsValues}
              customerType={customerType}
              {...rest}
            />
            <ShowOnCondition
              showWhen={
                parseInt(formWatcher.contractType?.value) === 3 ||
                parseInt(formWatcher.contractType?.value) === 4
              }
            >
              <Col md={12}>
                <SelectWrapper
                  name="realEstateUseType"
                  control={control}
                  label="Tipologia uso immobile"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  register={register}
                  options={podFieldsValues?.realEstateUseType || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              <PodOtherUseRealEstateForm
                canEdit={canEdit!}
                entityName={entityName}
                fieldsValues={podFieldsValues}
                customerType={customerType}
                {...rest}
              />
              <PodWorkSiteRealEstateForm 
                canEdit={canEdit}
                entityName={entityName}
                fieldsValues={podFieldsValues}
                customerType={customerType}
                {...rest}
              />
            </ShowOnCondition>
          </Row>
        </div>
      </div>
    </ShowOnCondition>
  )
}

export default RealState