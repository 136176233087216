import ThTable from "../../../ThTable/ThTable.component"

type ProposalTHeaderProps = {
  sortParam: string
  setSortParam: (value: string) => void
  page: number
  filters: string
}

const ProposalTHeader = (props: ProposalTHeaderProps) => {

  return (
    <thead className="table-light">
      <tr>
        <ThTable label="Status" />
        <ThTable 
          isSortable
          sortingName="proposalCode"
          label="Codice proposta"
          {...props}
        />
        <ThTable label="Tipologia cliente" />
        <ThTable label="Valutazione" />
        <ThTable label="Nome cliente" />
        <ThTable 
          isSortable
          sortingName="customerEmail"
          label="Email cliente"
          {...props}
        />
        <ThTable 
          isSortable
          sortingName="agent"
          label="Nome agente"
          {...props}
        />
        <ThTable 
          isSortable
          sortingName="signingDate"
          label="Data firma"
          {...props}
        />
        <ThTable 
          isSortable
          sortingName="insertDate"
          label="Data caricamento"
          {...props}
        />
        <ThTable label="Utenze" />
        <ThTable label="Note" />
        <ThTable label="Comunicazioni" />
        <ThTable />
      </tr>
    </thead>
  )
}

export default ProposalTHeader