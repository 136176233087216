import styled from "styled-components"

const LandingPageStyle = styled.div`
  header {
    background-color: ${({ theme}) => theme.colors.palette6};
    color: ${({ theme }) => theme.colors.grayscale[100]};
    padding: 1rem;
    
    .lead {
      font-size: .85rem;
      margin: 0;
    }

    .other-links-container {
      display: flex;
      justify-content: flex-end;
    }

    .other-links {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.grayscale[100]};
      border-radius: 0.25rem;
      color: ${({ theme }) => theme.colors.grayscale[100]};
      font-size: .85rem;
      font-weight: 700;
      padding: 0.375rem 0.75rem;
      text-decoration: none;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    }
  }

  main {
    padding: 2.5rem 0;

    .main-title {
      color: ${({ theme}) => theme.colors.palette6};
      font-size: 34px;
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  .contracts-shortcuts {
    margin: 1.5rem auto;
    text-align: right;

    .title {
      font-size: .85rem;
      font-weight: 700;
    }

    .links-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn {
        color: ${({ theme}) => theme.colors.palette6};
        border-color: ${({ theme}) => theme.colors.palette6};
        margin-left: .5rem;

        &:hover {
          background-color: ${({ theme}) => theme.colors.palette6};
          color: ${({ theme}) => theme.colors.grayscale[100]};
        }
      }
    }
  }

  .card {
    margin-bottom: 1.5rem;
    border-radius: .75rem;
    box-shadow: 0 0 15px -7px ${({ theme}) => theme.colors.grayscale[200]};
    overflow: hidden;

    .card-header {
      font-weight: 700;
      font-size: .85rem;
      text-transform: uppercase;
      background-color: ${({ theme}) => theme.colors.palette6};
      color: ${({ theme}) => theme.colors.grayscale[50]};
      border-radius: unset;

      &.inverted {
        color: ${({ theme}) => theme.colors.palette6};
        background-color: ${({ theme}) => theme.colors.grayscale[50]};
      }
    }
    .card-body {
      .card-text {
        font-size: .9rem;
      }

      button {
        font-size: 1rem;
      }
    }
  }

  .contracts-section{
    margin-bottom: 5rem;

    .section-title {
      font-size: 26px;
      font-weight: bold;
      color: ${({ theme}) => theme.colors.palette6};
    }
    .section-subtitle {
      font-size: 18px;
      font-weight: 600;
    }

    .contracts-container {
      margin-top: 1.5rem;
      padding-left: 1.25rem;
      padding-top: 1rem;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: .25rem;
        left: 0;
        top: 0;
        background-color: ${({ theme}) => theme.colors.secondary[400]};
        height: 100%;
      }

      .entity-row {
        display: flex;
        align-items: flex-start;
        padding: 1rem;
        border: none;
        box-shadow: 0px 3px 10px ${({ theme}) => theme.colors.grayscale[200]};
        margin-bottom: 1.5rem;
        border-radius: 5px;

        @media (max-width: 576px) {
          flex-wrap: wrap;
        }
        .text-container {
          font-size: 18px;
          color: ${({ theme}) => theme.colors.palette6};
          font-weight: bold;
          text-transform: uppercase;
          flex-grow: 1;
          line-height: 1.2;

          sup {
            font-size: 10px;
            margin-left: .5rem;
            font-weight: bold;
            padding: .1rem .5rem;
            background-color: ${({ theme}) => theme.colors.secondary[400]};
            border-radius: 10px;
            vertical-align: super;
          }

          svg {
            font-size: 1rem;
          }
          .more-info {
            margin-top: .25rem;

            @media (max-width: 576px) {
              margin-top: 0;
            }

            button {
              padding: 0;
              font-size: 14px;
              font-weight: bold;
              text-decoration: underline;
            }
          }
        }

        .actions-container {
          display: flex;

          @media (max-width: 576px) {
            margin-top: 1.5rem;
            margin-left: auto;
          }

          .btn {
            margin-left: 1rem;

            color: ${({ theme}) => theme.colors.grayscale[50]};
            background-color: ${({ theme}) => theme.colors.palette6};
            border-color: ${({ theme}) => theme.colors.palette6};

            &:hover {
              color: ${({ theme}) => theme.colors.palette6};
              background-color: transparent;
            }

            &.outline {
              color: ${({ theme}) => theme.colors.palette6};
              border-color: ${({ theme}) => theme.colors.palette6};
              background-color: transparent;

              &:hover {
                color: ${({ theme}) => theme.colors.grayscale[50]};
                background-color: ${({ theme}) => theme.colors.palette6};
              }
            }
          }
        }
      }
    }
  }
`

export default LandingPageStyle