import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { ClockLoader } from "react-spinners"

import { useSelectEntityName } from "../../../../../../pages/providers/ListsPageProvider.provider"
import { getEntityPath } from "../../../../../../redux/contract/contract.utils"
import ShowOnCondition from "../../../../../auth/ShowOnCondition.component"
import TdTable from "../../../../TdTable/TdTable.component"
import { useUpdateUsersVisibilityMutation } from "../../../../../../redux/user/user.api"
import { FormGroup } from "reactstrap"
import { SetupPdfListItemType } from "../../../../../../redux/setup/setup.types"
import { boltOrFireIconName } from "./utils"
import moment from "moment"

type PdfTRowProps = {
  item: SetupPdfListItemType
}

const PdfTRow = ({
  item,
}: PdfTRowProps) => {
  const entityName = useSelectEntityName()
  const [updateUsersVisibility, { isLoading }] = useUpdateUsersVisibilityMutation()
  
  return (
    <tr>
      <TdTable>
        {isLoading ? (
          <ClockLoader
            color='#95ad66'
            aria-label='Loading Spinner'
            data-testid='loader'
            size={20}
          />
        ) : (
          <FormGroup switch>
            <FontAwesomeIcon
              size='xl'
              icon={boltOrFireIconName(item?.fornitureType?.value)}
            />
          </FormGroup>
        )}
      </TdTable>
      <TdTable value={item.code} />
      <TdTable value={item.name} />
      <TdTable value={item.customerType?.label} />
      <TdTable 
        value={item.startDate ? moment(item.startDate).format('DD/MM/YYYY') : ""}
      />
      <TdTable 
        value={item.endDate ? moment(item.endDate).format('DD/MM/YYYY') : ""}
      />
      <TdTable otherClasses={["actions-col"]}>
        <div className='buttons-container float-end'>
          <ShowOnCondition
            showWhen={item.pdfUploaded}
          >
            <Link
              target='_blank'
              title='Vedi dettaglio'
              className='btn btn-outline-secondary btn-sm mx-1'
              to={item.pdfUrl}
            >
              <FontAwesomeIcon size='sm' icon='download' />
            </Link>
          </ShowOnCondition>
        </div>
      </TdTable>
    </tr>
  );
}

export default PdfTRow