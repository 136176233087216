import { Row } from "reactstrap"
import styled from "styled-components"

const AttachmentRowStyle = styled(Row)`
  transition: opacity .3s ease;
  
  &.is-loading {
    opacity: .5;
  }

  .attachment-datetime {
    font-size: .75rem;
    margin: -.75rem 0 1rem;
    padding: 0 .25rem;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    .btn {
      width: 34px;
      margin: .15rem;
    }
  }
`

export default AttachmentRowStyle