import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PdpStatusUpdateForm from '../forms/PdpStatusUpdateForm/PdpStatusUpdateForm.component'
import { useUpdatePodItemStatusMutation } from '../../redux/contract/pod/pod.api'
import { useUpdatePdrItemStatusMutation } from '../../redux/contract/pdr/pdr.api'

type PdpStatusUpdateModalProps = {
  isOpen: boolean
  toggle: () => void
  currentPdpStatus: number
  actionUrl: string
  pdpType: "pod" | "pdr"
}

const PdpStatusUpdateModal = ({
  isOpen,
  toggle,
  pdpType,
  ...rest
}: PdpStatusUpdateModalProps) => {
  const [triggerUpdatePodItemStatus] = useUpdatePodItemStatusMutation()
  const [triggerUpdatePdrItemStatus] = useUpdatePdrItemStatusMutation()

  return(
    <div>
      <Modal className="status-modal" isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <FontAwesomeIcon className="me-2" icon="exchange-alt" />
          Modifica lo stato
        </ModalHeader>
        <ModalBody>
          <PdpStatusUpdateForm 
            toggle={toggle}
            updateFunction={pdpType === "pod" ? triggerUpdatePodItemStatus : triggerUpdatePdrItemStatus}
            {...rest}
          />
        </ModalBody>
      </Modal>
    </div>
  )
}

export default PdpStatusUpdateModal
