import moment from "moment"

export const newLabelVisible = (value: Date | undefined) => {
    const newUntil = moment(value, ['YYYY-MM-DD', 'DD/MM/YYYY'])
    const now = moment()
  
    if(value) {
      return now.diff(newUntil, 'days') > 0 ? false : true
    }
  
    return false
  }