import { useState } from "react"

import TableFooter from "../TableFooter/TableFooter.component"
import WithSkeleton from "../../WithSkeleton/WithSkeleton.component"
import StetupStatusListTable from "./StetupStatusListTable/StetupStatusListTable.component"
import { useAppSelector } from "../../../redux/hooks"
import { selectSetupJobName } from "../../../redux/setup/setup.selectors"
import { useFetchStatusListQuery } from "../../../redux/setup/setup.api"
import { SETUP_DEFAULT_STATUS_PER_PAGE } from "../../../redux/setup/setup.utils"

const StetupStatusListTableWithSkeleton = WithSkeleton(StetupStatusListTable)

const StetupStatusList = () => {
  const setupJobName = useAppSelector(selectSetupJobName)
  const [filters] = useState(`jobName=${setupJobName}`)
  const [page, setPage] = useState(1)

  //Fetch list data trigger
  const {
    data: listData,
    isFetching: isFetchingListData,
    isLoading: isLoadingListData, 
  } = useFetchStatusListQuery({
    page,
    filters
  })

  return (
    <section className="list-container">
      <StetupStatusListTableWithSkeleton 
        isLoading={isLoadingListData}
        isFetching={isFetchingListData}
        page={page}
        filters={filters}
      />
      <TableFooter
        listData={listData}
        page={page}
        setPage={setPage}
        itemsPerPage={SETUP_DEFAULT_STATUS_PER_PAGE}
      />
    </section>
  )
}

export default StetupStatusList